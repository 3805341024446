import axios from 'axios'

// import components
import { EndPoint } from '../../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  test: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  local: 'http://127.0.0.1:8080/favorites',
}

export default async function RetrieveFavoritesCallToBackend(email) {
  try {
    const response = await axios.post(
      END_POINTS[EndPoint],
      {
        purpose: 'retrieve_favorites',
        email: email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    let userToken

    if (response?.data?.body?.success) {
      // localhost has body
      userToken = response.data.body.user_token
    } else if (response?.data?.success) {
      userToken = response.data.user_token
    }

    return userToken
  } catch (e) {
    console.log(e.message)
    return null
  }
}
