import * as React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CardMedia from '@mui/material/CardMedia'
// import { useNavigate } from "react-router-dom";
import mixpanel from 'mixpanel-browser'

mixpanel.init('d60542dff5aaf889760e7cc2f6316298', { debug: false })

// import images
// import TestImage from "../../../assets/images/living_room.png";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

const propTypes = {
  image: PropTypes.node,
  brand: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
  path: PropTypes.string,
}

const defaultProps = {
  image: null,
  brand: null,
  type: null,
  price: null,
  path: null,
}

LandingFurniture.propTypes = propTypes
LandingFurniture.defaultProps = defaultProps

export default function LandingFurniture({ image, brand, type, price, path }) {
  // let navigate = useNavigate();

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        onClick={() => {
          window.open(path)
          mixpanel.track('(Explore) Clicked on Product')
          // navigate(path);
          // navigate("/see-more");
        }}
        target="_blank"
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx={{
            marginRight: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // [customTheme.breakpoints.down("sm")]: {
            //   alignItems: "flex-start",
            // },
          }}
        >
          <CardMedia
            component="img"
            image={image}
            alt=""
            sx={{
              width: '240px',
              height: '240px',
              borderRadius: '16px',
              [customTheme.breakpoints.down('sm')]: {
                width: '200px',
                height: '200px',
              },
            }}
          />

          <Typography
            sx={{
              color: '#2a2a2a',
              fontSize: '16px',
              fontWeight: '500',
              marginTop: '8px',
              paddingLeft: '4px',
              textAlign: 'center',
              // [customTheme.breakpoints.down("md")]: {
              //   textAlign: "inherit",
              // },
            }}
          >
            {brand} • {type}
          </Typography>

          <Typography
            sx={{
              color: '#2a2a2a',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '8px',
              paddingLeft: '4px',
            }}
          >
            ${price}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}
