import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AnalyticsBrowser } from '@segment/analytics-next'

// Import components
import mixpanel from '../../components/atoms/Mixpanel/Mixpanel'
import DesignQuestions from '../../components/templates/DesignQuestions/DesignQuestions'
import { UserContext } from '../../App'
import { segmentWriteKey } from '../../components/atoms/Segment/Segment'

// Import utils
import { sortSavedDesignsArray } from '../../utils/utils'

// Import pages
import AllMyDesigns from './pages/AllMyDesigns'
import CMSRoomSelect from './pages/CMSRoomSelect'
import CMSSelectItems from './pages/CMSSelectItems'
// import CMSSelectSofas from "./pages/CMSSelectSofas";
// import CMSSelectColors from "./pages/CMSSelectColors";
import CMSSwipeImages from './pages/CMSSwipeImages'
import CMSBudget from './pages/CMSBudget'
import LoadingResults from './pages/LoadingResults'
import ResultsError from './pages/ResultsError'

// import global store
import { Context } from '../../context/context'

// Import services
import getCMSBetaSuggestion from '../../services/getCMSBetaSuggestion'

// import Call to Backened file
import CMSDesignsCallToBackend from './pages/CMSDesignsGetCallToBackend'

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

const propTypes = {}

function CMSRouter() {
  const [globalState, globalDispatch] = useContext(Context)
  const [cmsDesigns, setCmsDesigns] = useState([])

  const { user } = React.useContext(UserContext)

  useEffect(() => {
    async function fetchCMSDesign() {
      let showUserDesigns = false
      const email =
        null !== localStorage.getItem('user')
          ? JSON.parse(localStorage.getItem('user')).email
          : null
      if (email) {
        const response = await CMSDesignsCallToBackend(email)

        if (response) {
          const sortedDesigns = sortSavedDesignsArray(
            response.data.body.user_token,
          )
          setCmsDesigns(sortedDesigns)
          showUserDesigns = true
        }
      } else {
        if (
          null !== localStorage.getItem('cmsDesigns') &&
          '[]' !== localStorage.getItem('cmsDesigns')
        ) {
          setCmsDesigns(JSON.parse(localStorage.getItem('cmsDesigns')))
          showUserDesigns = true
        }
      }
      if (!showUserDesigns) {
        globalDispatch({
          type: 'SET_WORKFLOW_PROP_VALUE',
          payload: {
            propName: 'hasCookies',
            value: false,
          },
        })
        globalDispatch({
          type: 'SET_WORKFLOW_PROP_VALUE',
          payload: {
            propName: 'showCMSSavedDesignsPage',
            value: false,
          },
        })
      } else {
        globalDispatch({
          type: 'SET_WORKFLOW_PROP_VALUE',
          payload: {
            propName: 'hasCookies',
            value: true,
          },
        })
      }
    }
    fetchCMSDesign()
  }, [])

  let navigate = useNavigate()

  const onNext = (propName, value) => {
    // update the values here
    globalDispatch({
      type: 'NEXT_PAGE',
    })
    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName,
        value,
      },
    })
  }

  function setIsLoading(bool) {
    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName: 'isLoading',
        value: bool,
      },
    })
  }

  function setError(error) {
    globalDispatch({
      type: 'SET_ERROR',
      error,
    })
  }

  const onComplete = (propName, value) => {
    // handle the db call + navigate here
    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName,
        value,
      },
    })

    const finalRoomInfo = {
      ...globalState,
      [propName]: value,
    }

    setIsLoading(true)

    getCMSBetaSuggestion({
      ...finalRoomInfo,
    })
      .then((response) => {
        setError(null)
        const planId = response['plan_id']
        const budget = response['plan_param']['selected_budget']
        const style = response['plan_param']['selected_design_type']

        analytics.track('Moodboard Created', {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: 'Craft My Style Moodboard',
          room: 'Living Room',
          budget: budget,
          style: style,
          moodboard_id: planId,
        })
        mixpanel.track('(CMS) Created Moodboard', {
          Email: user?.email,
          'Room Type Chosen By User':
            response['plan_param']['selected_room_type'],
          'Budget Chosen By User': budget,
          'Style Chosen By Craftle': style,
        })

        navigate('/designs/e/' + planId)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }

  const callAlert = (title, message) => {
    console.log(title.toString(), message.toString())
  }

  const pages = [
    {
      pageTitle: 'ROOM SELECTION',
      title: 'Which room are you designing?',
      content: (
        <CMSRoomSelect
          propName="roomType"
          onNextPress={onNext}
          callAlert={callAlert}
        />
      ),
    },
    {
      pageTitle: 'DESIGN SELECTION',
      title: 'Do you like this design?',
      body: 'Select the heart if you like the design, or select the “X” if you don’t. You must select at least ten designs.',
      content: (
        <CMSSwipeImages
          propName="designWeights"
          onNextPress={onNext}
          callAlert={callAlert}
        />
      ),
    },
    {
      pageTitle: 'ITEM SELECTION',
      title: 'Select the items you want in your plan',
      body: 'You can choose more than one item.',
      content: (
        <CMSSelectItems
          propName="furnitureOptions"
          onNextPress={onNext}
          callAlert={callAlert}
        />
      ),
    },
    /**
    {
      pageTitle: "SOFA AND COUCH SELECTION",
      title: "Select which sofas/couches you want as options",
      body: "You can choose more than one option.",
      content: (
        <CMSSelectSofas
          propName="furnitureOptions"
          onNextPress={onNext}
          callAlert={callAlert}
        />
      ),
    },
    */
    /**
    {
      pageTitle: "COLOR SELECTION",
      title: "How much color do you like in your rooms?",
      content: (
        <CMSSelectColors
          propName="colorOptions"
          onNextPress={onNext}
          callAlert={callAlert}
        />
      ),
    },
    */
    {
      pageTitle: 'BUDGET SELECTION',
      title: 'What is your maximum budget for this room?',
      //In "body", how do we bold the variable number (e.g. "$1420")
      body: 'Drag the slider to change your budget. You’ll have more options available if you set the budget higher.',
      content: (
        <CMSBudget
          propName="budget"
          onNextPress={onComplete}
          callAlert={callAlert}
        />
      ),
    },
  ]

  return globalState.error ? (
    <ResultsError />
  ) : globalState.isLoading ? (
    <LoadingResults />
  ) : globalState.showCMSSavedDesignsPage ? (
    <AllMyDesigns cmsDesigns={cmsDesigns} />
  ) : (
    <DesignQuestions
      pageTitle={pages[globalState.workflowPage].pageTitle}
      title={pages[globalState.workflowPage].title}
      body={pages[globalState.workflowPage].body}
    >
      {pages[globalState.workflowPage].content}
    </DesignQuestions>
  )
}

CMSRouter.propTypes = propTypes

export default CMSRouter
