import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import CardMedia from "@mui/material/CardMedia";

// import components
import LandingFurniture from '../../molecules/LandingFurnitureHighlighted2/LandingFurnitureHighlighted2'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import icons

// import images
const ZodiacProfile1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-1.png'
const Zodiac1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-1.png'
const ZodiacProfile2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-2.png'
const Zodiac2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-2.png'
const ZodiacProfile3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-3.png'
const Zodiac3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-3.png'
const ZodiacProfile4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-4.png'
const Zodiac4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-4.png'
const ZodiacProfile5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-profile-5.png'
const Zodiac5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/zodiac-moodboard-5.png'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingZodiac() {
  const ref = useRef(null)

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          background: '-webkit-linear-gradient(225deg, #3E33BD, #5C1A7B)',
          marginTop: '48px',
          width: '100vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '40px 0',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '90vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#FFFFFF',
              fontSize: '32px',
              fontWeight: 'bold',
            }}
          >
            📅 Zodiac Moodboards
          </Typography>

          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: '16px',
              marginTop: '16px',
              // fontWeight: "bold",
            }}
          >
            Explore furniture that was designed for you based on your zodiac
            sign.
          </Typography>

          <ScrollButtonLeft scrollRef={ref} left="5.5vw" top="54%" />
          <Box
            ref={ref}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              width: '95vw',
              marginLeft: '-5vw',
              overflowX: 'scroll',
              paddingLeft: '5vw',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <LandingFurniture
              path="/moodboards/capricorn"
              profile={ZodiacProfile1}
              name="Capricorn"
              dates="Dec 22 - Jan 19"
              image={Zodiac1}
            />
            <LandingFurniture
              path="/moodboards/aquarius"
              profile={ZodiacProfile2}
              name="Aquarius"
              dates="Jan 20 - Feb 18"
              image={Zodiac2}
            />
            <LandingFurniture
              path="/moodboards/pisces"
              profile={ZodiacProfile3}
              name="Pisces"
              dates="Feb 19 - Mar 20"
              image={Zodiac3}
            />
            <LandingFurniture
              path="/moodboards/aries"
              profile={ZodiacProfile4}
              name="Aries"
              dates="Mar 21 - Apr 19"
              image={Zodiac4}
            />
            <LandingFurniture
              path="/moodboards/taurus"
              profile={ZodiacProfile5}
              name="Taurus"
              dates="Apr 20 - May 20"
              image={Zodiac5}
            />
          </Box>
          <ScrollButtonRight scrollRef={ref} right="5.5vw" top="54%" />

          {/* <LandingSeeMore
            text="See more Zodiac Furniture Moodboards"
            mixpanelTracking="See more Zodiac Furniture Moodboards"
          /> */}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default LandingZodiac
