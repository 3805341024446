import React from 'react'
import { useLoading, Oval } from '@agney/react-loading'

function Loading() {
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Oval width="60" />,
  })

  return (
    /* Accessibility props injected to container */
    <section {...containerProps}>
      {indicatorEl} {/* renders only while loading */}
    </section>
  )
}

export default Loading
