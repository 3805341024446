import { customTheme } from '../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    maxWidth: '692px',
    margin: '181px auto 0 auto',
    [customTheme.breakpoints.down('md')]: {
      padding: '0 16px',
      margin: '80px auto 0 auto',
    },
  },
  bannerImage: {
    borderRadius: '16px',
    width: '100%',
  },
}
