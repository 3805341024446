import React from 'react'
import { Box } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import CardMedia from '@mui/material/CardMedia'
import { housingTypes } from './SignUpData.js' // Import your housingTypes data
import { cityList } from './CityData.js'

// Import components
import { classes } from './SignUpStyle.js'

// Define main and "Other" housing types
export const mainHousingTypes = housingTypes.filter(
  (type) =>
    ![
      'Modular and Prefabricated Home',
      'Mobile Home',
      'Tiny Home',
      'Loft',
      'Cottage or Bungalow',
      'Villa',
      'Farmhouse',
    ].includes(type.label),
)

export const otherHousingTypes = housingTypes.filter((type) =>
  [
    'Modular and Prefabricated Home',
    'Mobile Home',
    'Tiny Home',
    'Loft',
    'Cottage or Bungalow',
    'Villa',
    'Farmhouse',
  ].includes(type.label),
)

// Toggle the visibility of "Other" housing types
export const handleOtherToggle = (
  showOtherHousingTypes,
  setShowOtherHousingTypes,
  selectedHousingType,
  setSelectedHousingType,
) => {
  // Check if the current selectedHousingType is an "Other" type
  const isOtherSelected = otherHousingTypes.some(
    (type) => type.label === selectedHousingType,
  )

  // If collapsing and an "Other" type is selected, reset selectedHousingType
  if (showOtherHousingTypes && isOtherSelected) {
    setSelectedHousingType(null)
  }

  // Toggle the visibility of "Other" housing types
  setShowOtherHousingTypes((prev) => !prev)
}

// Function for fetching cities from the local list
export const debouncedFetchCities = (userInput, setSuggestedCities) => {
  if (userInput.length > 0) {
    const filteredCities = cityList.filter((cityName) =>
      cityName.toLowerCase().startsWith(userInput.toLowerCase()),
    )
    // Limit the results to top 10
    setSuggestedCities(filteredCities.slice(0, 10))
  } else {
    setSuggestedCities([])
  }
}

export const handleCityChange = (
  event,
  setCityAndCountry,
  setSuggestedCities,
) => {
  const userInput = event.target.value
  setCityAndCountry(userInput)
  debouncedFetchCities(userInput, setSuggestedCities)
}

export const handleCitySelect = (
  selectedCity,
  setCityAndCountry,
  setSuggestedCities,
) => {
  setCityAndCountry(selectedCity)
  setSuggestedCities([])
}

export const handleImageSelect = (
  style,
  image,
  setSelectedStyles,
  step,
  remainingImages,
  setRemainingImages,
  setDisplayedImages,
  setStep,
  setError,
) => {
  // Update the count for the selected style with weighted scoring
  setSelectedStyles((prev) => ({
    ...prev,
    [style]: (prev[style] || 0) + 1 + step / 8, // Adding weight based on progress
  }))

  if (step === 18) {
    // Remove the selected image from remainingImages
    let updatedRemainingImages = { ...remainingImages }
    if (updatedRemainingImages[style]) {
      updatedRemainingImages[style] = updatedRemainingImages[style].filter(
        (img) => img !== image,
      )
      if (updatedRemainingImages[style].length === 0) {
        delete updatedRemainingImages[style]
      }
    }

    // Load new images
    let stylesWithImages = Object.keys(updatedRemainingImages)
    const selectedStylesForDisplay = []
    const selectedImages = []

    while (selectedStylesForDisplay.length < 3 && stylesWithImages.length > 0) {
      const randomStyleIndex = Math.floor(
        Math.random() * stylesWithImages.length,
      )
      const newStyle = stylesWithImages.splice(randomStyleIndex, 1)[0]
      selectedStylesForDisplay.push(newStyle)

      const images = updatedRemainingImages[newStyle]
      const imageIndex = Math.floor(Math.random() * images.length)
      const newImage = images.splice(imageIndex, 1)[0]

      selectedImages.push({ style: newStyle, image: newImage })

      if (images.length === 0) {
        delete updatedRemainingImages[newStyle]
      }
    }

    // Update remainingImages and displayedImages
    setRemainingImages(updatedRemainingImages)
    setDisplayedImages(selectedImages)

    // Do not advance the step
    return
  } else {
    setStep(step + 1)
    setError('')
    // Scroll to top after advancing the step
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

export const handleSkip = (setStep, setError, step) => {
  // Increment the step
  setStep(step + 1)
  setError('')
}

export const determineFinalStyle = (selectedStyles) => {
  let determinedStyle
  if (Object.keys(selectedStyles).length === 0) {
    determinedStyle = 'modern'
  } else {
    const sortedStyles = Object.entries(selectedStyles).sort(
      (a, b) => b[1] - a[1],
    )
    if (sortedStyles.length > 1 && sortedStyles[0][1] === sortedStyles[1][1]) {
      determinedStyle = sortedStyles[Math.floor(Math.random() * 2)][0]
    } else {
      determinedStyle = sortedStyles[0][0]
    }
  }
  return determinedStyle
}

export const renderDreamRoomSelection = (
  displayedImages,
  imageLoadStates,
  setSelectedStyles,
  step,
  remainingImages,
  setRemainingImages,
  setDisplayedImages,
  setStep,
  setError,
  setImageLoadStates,
) => {
  return (
    <Box sx={classes.dreamRoomSelectContainer}>
      {displayedImages.map(({ style, image }) => {
        const isLoaded = imageLoadStates[image]
        return (
          <Box key={`${style}-${image}`} sx={classes.dreamRoomImgContainer}>
            {!isLoaded && (
              <Skeleton
                variant="rectangular"
                width={356}
                height={490}
                sx={{ borderRadius: '16px' }}
              />
            )}
            <CardMedia
              component="img"
              image={image}
              alt="Interior Design Image"
              onClick={() =>
                handleImageSelect(
                  style,
                  image,
                  setSelectedStyles,
                  step,
                  remainingImages,
                  setRemainingImages,
                  setDisplayedImages,
                  setStep,
                  setError,
                )
              }
              onLoad={() => {
                setImageLoadStates((prevState) => ({
                  ...prevState,
                  [image]: true,
                }))
              }}
              sx={{
                ...classes.dreamRoomImg,
                display: isLoaded ? 'block' : 'none',
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export const handleComplete = async (
  birthday,
  setError,
  selectedStyles,
  firstName,
  selectedHousingType,
  selectedColorAmount,
  selectYouColors,
  selectedPiecesAmount,
  selectedPets,
  selectedChildren,
  selectedTeens,
  selectedAdults,
  cityAndCountry,
  selectedPetTypes,
  selectedMaterialTypes,
  setUserPreferences,
  user,
  GoogleLoginCallToBackend,
  saveUserToken,
  setStep,
  step,
) => {
  // Combine the birthday fields into a single string
  const formattedBirthday = `${birthday.year}-${birthday.month}-${birthday.day}`

  // Check if the birthday is valid (all fields are filled)
  if (!birthday.year || !birthday.month || !birthday.day) {
    setError('Please complete your birthday before proceeding.')
    return
  }

  // Get the determined style
  const finalStyle = determineFinalStyle(selectedStyles)

  // Collect all user preferences into one object
  const preferences = {
    first_name: firstName,
    housing_type: selectedHousingType,
    preferred_color_amount: selectedColorAmount,
    preferred_colors: selectYouColors,
    preferred_pieces_amount: selectedPiecesAmount,
    pets_at_home: selectedPets,
    children_at_home: selectedChildren,
    teens_at_home: selectedTeens,
    adults_at_home: selectedAdults,
    date_of_birth: formattedBirthday,
    city_and_country: cityAndCountry,
    pet_types: selectedPetTypes,
    avoid_materials: selectedMaterialTypes,
    preferred_design_style: finalStyle,
  }
  setUserPreferences(preferences)

  if (user) {
    // If user is signed in, record preferences and take them directly to profile page.
    try {
      const userInfo = {
        email: user.email || '',
      }

      // Get data from the DB
      const response = await GoogleLoginCallToBackend(userInfo, preferences)

      if (response) {
        // Store the user in localStorage
        await saveUserToken(response)
        document.location.href = '/profile'
      } else {
        throw new Error('Login failed, please try again.')
      }
    } catch (error) {
      setError('An error occurred during login. Please try again later.')
    }
  } else {
    // If user is not signed in, take them to final sign up page.
    setStep(step + 1)
    setError('')
  }
}

export const handleContinue = ({
  user,
  setError,
  step,
  firstName,
  selectedHousingType,
  selectedColorAmount,
  selectYouColors,
  selectedPiecesAmount,
  selectedChildren,
  selectedTeens,
  selectedAdults,
  selectedPetTypes,
  birthday,
  cityAndCountry,
  selectedPets,
  setSelectedPetTypes,
  setStep,
  setViewedEarlySignupPage,
}) => {
  setError('') // Clear any existing errors

  if (step === 1) {
    if (!firstName.trim()) {
      setError('Please enter your first name')
      return
    }
  } else if (step === 3) {
    if (selectedHousingType === null) {
      setError('Please select a housing type')
      return
    }
  } else if (step === 5) {
    if (!selectedColorAmount) {
      setError('Please select an option')
      return
    }
  } else if (step === 6) {
    if (selectYouColors.length === 0) {
      setError('Please select at least one color')
      return
    }
  } else if (step === 8) {
    if (!selectedPiecesAmount) {
      setError('Please select an option')
      return
    }
  } else if (step === 10) {
    const totalResidents = selectedChildren + selectedTeens + selectedAdults
    if (totalResidents === 0) {
      setError('Please select at least one resident (excluding pets)')
      return
    }
  } else if (step === 12) {
    if (selectedPetTypes.length === 0) {
      setError('Please select at least one pet type')
      return
    }
  } else if (step === 15) {
    const { month, day, year } = birthday

    // Check if any of the birthday fields are empty
    if (!month || !day || !year) {
      setError('Please complete all fields (MM/DD/YYYY) before continuing.')
      return
    }

    // Validate month
    if (month < 1 || month > 12) {
      setError('Please enter a valid month (01-12).')
      return
    }

    // Validate day
    if (day < 1 || day > 31) {
      setError('Please enter a valid day (01-31).')
      return
    }

    // Validate year
    const currentYear = new Date().getFullYear()
    if (year < 1900 || year > currentYear || year.length !== 4) {
      setError(`Please enter a valid year (1900-${currentYear}).`)
      return
    }
  } else if (step === 17) {
    if (!cityList.includes(cityAndCountry)) {
      setError('Please add your city')
      return
    }
  }

  if (step === 1 && user) {
    // Skip seeing sign up page if already logged in
    setStep(step + 2)
  } else if (step === 10 && selectedPets === 0) {
    // Skip pet types question if no pets are selected
    setSelectedPetTypes([])
    setStep(step + 2)
  } else {
    if (step === 1 && !user) {
      // User will see the early sign up page
      setViewedEarlySignupPage(true)
    }
    setStep(step + 1)
  }

  // Scroll to top after advancing the step
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const handlePrevious = ({
  user,
  setError,
  step,
  selectedPets,
  setStep,
}) => {
  setError('')
  if (step === 3 && user) {
    // Skip back over signup question if already logged in
    setStep(step - 2)
  } else if (step === 11 && selectedPets === 0) {
    // Skip back over pet types question if no pets
    setStep(step - 2)
  } else {
    setStep(step - 1)
  }

  // Scroll to top after going to the previous step
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

// Handle color selection for step 6
export const handleColorSelect = (
  option,
  selectYouColors,
  setSelectYouColors,
) => {
  if (selectYouColors.includes(option.label)) {
    // Remove it
    setSelectYouColors(selectYouColors.filter((item) => item !== option.label))
  } else {
    // Add it
    setSelectYouColors([...selectYouColors, option.label])
  }
}
