import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    margin: '24px 16px 0 16px',
    backgroundColor: '#FFF3C4',
    padding: '16px 24px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    maxWidth: '744px',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  msg: {
    textAlign: 'left',
  },
  btn: {
    minWidth: 'fit-content',
    backgroundColor: 'inherit',
  },
}
