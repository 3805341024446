import React, { useState } from 'react'
import { Box, TextField, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'

// import components
import { classes } from './LogInStyle'
import Text from '../../components/atoms/Text/Text'
import Button from '../../components/atoms/Button/Button'
import LogInCallToBackend from './LogInCallToBackend'
import GoogleSignInButton from './GoogleSignInButton'

export default function LogIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  let navigate = useNavigate()

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('user', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  async function startLogIn(event) {
    if (event) {
      event.preventDefault()
    }

    let userInfo = {
      email: email,
      password: password,
    }

    // Assign the values from the inputs
    userInfo.email = document.getElementById('email').value
    userInfo.password = document.getElementById('password').value

    // Get data from the DB
    const response = await LogInCallToBackend(userInfo)

    if (response) {
      // Store the user in localStorage
      saveUserToken(response).then(() => {
        document.location.href = '/'
      })
    } else {
      setShowErrorMsg(true)
    }
  }

  // Equivalent to StartSearch(), but if the user presses "Enter" on the keyboard
  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      startLogIn()
    }
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.backgroundGradient} />
      <Box sx={classes.content}>
        <Text variant="h1" sx={classes.h1}>
          Log In
        </Text>

        <GoogleSignInButton />

        <Box sx={classes.orContainer}>
          <Divider sx={classes.orDivider} />
          <Text sx={classes.orText}>OR</Text>
          <Divider sx={classes.orDivider} />
        </Box>

        <form onSubmit={startLogIn} style={classes.sectionInputForm}>
          <TextField
            label="(required) Email"
            variant="standard"
            id="email"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={handleKeyDown}
            sx={classes.textField}
          />
          <TextField
            label="(required) Password"
            type="password"
            variant="standard"
            id="password"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={handleKeyDown}
            sx={classes.textField}
          />
          {showErrorMsg ? (
            <Text sx={classes.errorMsg}>
              We couldn&apos;t find this account. Please try again, or go to
              &quot;Sign up&quot; below.
            </Text>
          ) : null}
          <Button type="submit" sx={classes.logInButton}>
            Log In
          </Button>
        </form>

        <Box sx={classes.signUpContainer}>
          <Text sx={classes.newtoCraftleText}>New to Craftle? </Text>
          <Button
            variant="fourth"
            onClick={() => {
              navigate('/sign-up')
            }}
            sx={classes.signUpButton}
          >
            Sign up
          </Button>
        </Box>

        <Text sx={classes.legalNotice}>
          By continuing, you agree to Craftle&apos;s{' '}
          <Button variant="fourth" href="/terms" sx={classes.legalBtns}>
            Terms of Use
          </Button>
          . Read our{' '}
          <Button variant="fourth" href="/policy" sx={classes.legalBtns}>
            Privacy Policy
          </Button>
          .
        </Text>
      </Box>
    </Box>
  )
}
