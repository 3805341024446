import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

// import components
import { EndPoint } from '../../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  test: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  local: 'http://127.0.0.1:8080/favorites',
}

export default async function FavoritingCallToBackend(
  favorited,
  email,
  product_id,
  product_name,
  allImages,
  brand,
  price,
  product_type,
  link,
  length,
  width,
  height,
  dimensions,
) {
  const responseFromAWS = await (async () => {
    if (favorited && !product_id) {
      product_id = uuidv4() // assign product id a randomly generated uuid
    }

    try {
      const endPoint = END_POINTS[EndPoint] || END_POINTS.prod
      const response = await axios.post(endPoint, {
        request_method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        post_data: JSON.stringify({
          purpose: 'favoriting',
          favorited: favorited,
          email: email,
          product_id: product_id,
          product_name: product_name,
          allImages: allImages,
          brand: brand,
          price: price,
          product_type: product_type,
          link: link,
          length: length,
          width: width,
          height: height,
          dimensions: dimensions, // For items that don't have separated length, width, height
        }),
      })
      return response
    } catch (e) {
      console.log(e.message)
    }
  })()

  if (responseFromAWS?.data?.body?.success) {
    return responseFromAWS
  } else {
    return null
  }
}
