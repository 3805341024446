import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import components
import {
  Blog,
  BlogPost1,
  BlogPost2,
  BlogPost3,
  BlogPost4,
  BlogPost5,
  BlogPost6,
  BlogPost7,
  BlogPost8,
  BlogPost9,
  BlogPost10,
  BlogPost11,
  BlogPost12,
  BlogPost17,
  BlogPost18,
} from '../pages/blog'
import WebAppBar from '../components/organisms/WebAppBar/WebAppBar'
import Footer from '../components/organisms/Footer/Footer'

const blogRoutes = (navLinks) => (
  <>
    <Route
      path="/blog"
      element={
        <>
          <Helmet>
            <title>Blog on Interior Design and Furniture - Craftle</title>
            <meta
              name="description"
              content="For everything interior design and furniture related, check out our entertaining and informative blog."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <Blog />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/baby-and-kids-furniture"
      element={
        <>
          <Helmet>
            <title>Baby & Kids&apos; Furniture: 5 Best Places to Buy</title>
            <meta
              name="description"
              content="Baby & kids' furniture isn’t always easy to find out there.
                    You want to find furniture that’s fun and exciting for kids but
                    also safely built and affordable to buy."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost1 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/britney-spears-tiny-furniture"
      element={
        <>
          <Helmet>
            <title>
              Britney Spears’ Tiny Furniture: Girl, Is This Absolutely Amazing
              or an Accident?
            </title>
            <meta
              name="description"
              content="Britney Spears’ tiny furniture may be small, but it’s gotten
                    our attention in a big way. She’s done it again."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost2 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/interior-design-vs-interior-architect"
      element={
        <>
          <Helmet>
            <title>
              Interior Design vs Interior Architect: What’s the Difference
            </title>
            <meta
              name="description"
              content="This article will explain the key differences between interior design and interior architecture. It will also discuss how they are two different disciplines within the world of interior design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost3 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/how-is-furniture-measured"
      element={
        <>
          <Helmet>
            <title>
              How is Furniture Measured? Everything You Need to Know Easily
              Explained
            </title>
            <meta
              name="description"
              content="Learn how to measure furniture for your home. Whether it's a sofa, table, or chair you're looking for, we'll help you find the perfect fit."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost4 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/tip-furniture-delivery-guys"
      element={
        <>
          <Helmet>
            <title>
              Tip Furniture Delivery Guys How Much? Avoid Embarrassment With
              This Guide
            </title>
            <meta
              name="description"
              content="Unsure of how much to tip furniture delivery guys? Here's a guide that can help make the process easier for you."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost5 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/depreciation-life-of-furniture"
      element={
        <>
          <Helmet>
            <title>
              Depreciation Life of Furniture: How Long Does It Keep Its Value?
            </title>
            <meta
              name="description"
              content="The depreciation life of furniture is a topic that has been hotly debated among business owners and economists alike. This article explores the various factors that contribute to how long furniture retains its value."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost6 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/furniture-mod-for-minecraft"
      element={
        <>
          <Helmet>
            <title>
              Furniture Mod for Minecraft: 15 Best Mods to Make Your World Look
              Wicked Amazing
            </title>
            <meta
              name="description"
              content="Looking to make your Minecraft world stand out from the rest? Look no further than these 15 furniture mods, each of which offers unique and innovative ways to customize your game."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost7 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/dates-for-high-point-furniture-market"
      element={
        <>
          <Helmet>
            <title>Dates for High Point Furniture Market This Year</title>
            <meta
              name="description"
              content="Wondering when the next High Point furniture market is? Wonder no more! We've got all the details on this year's event, straight from the source."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost8 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/how-long-can-head-lice-live-on-furniture"
      element={
        <>
          <Helmet>
            <title>
              How Long Can Head Lice Live on Furniture? Separating New Facts
              from Old Fiction
            </title>
            <meta
              name="description"
              content="Learn everything you need to know about head lice, including how long they can live on furniture and what to do if they get on you or your belongings."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost9 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/fixer-upper-do-they-keep-furniture"
      element={
        <>
          <Helmet>
            <title>
              Fixer Upper: Do They Keep Furniture or Do They Give It Back?
            </title>
            <meta
              name="description"
              content="Homeowners on the popular TV show Fixer Upper receive a stunning makeover of their home, but what happens to all the furniture used to stage the house? Find out in this article!"
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost10 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/interior-design-types-of-styles"
      element={
        <>
          <Helmet>
            <title>Interior Design Types of Styles</title>
            <meta
              name="description"
              content="When it comes to interior design, there are many different styles that you can choose from. Each style has its own unique characteristics and can be used to create a specific look in your home. Here is a look at some of the most popular interior design styles, and what they entail."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost12 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/interior-design-for-minecraft"
      element={
        <>
          <Helmet>
            <title>
              Interior Design for Minecraft: 19 Ideas to Make Your Friends
              Jealous | Craftle
            </title>
            <meta
              name="description"
              content="Find out how you can make your friends green with envy by following our comprehensive guide on interior design for Minecraft. From humble abodes to palaces fit for a king, we've got 203 ideas that will inspire and delight!"
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost11 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/transitional-interior-design-style"
      element={
        <>
          <Helmet>
            <title>
              Transitional Interior Design Style • Definition, Tips, and Helpful
              Guide | Craftle
            </title>
            <meta
              name="description"
              content="In this blog post, we define the transitional interior design style and give you some tips if you decide to use this style for your home."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost17 />
          <Footer />
        </>
      }
    />
    <Route
      path="/blog/best-interior-design-apps"
      element={
        <>
          <Helmet>
            <title>Best Interior Design Apps of 2023 | Craftle</title>
            <meta
              name="description"
              content="In this blog post, we show you the best interior design apps according to your preferences and needs."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <BlogPost18 />
          <Footer />
        </>
      }
    />
  </>
)

export default blogRoutes
