import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'

// import components
import { classes } from './LivingRoomStyle'
import Text from '../../../components/atoms/Text/Text'
import DepartmentCategory from '../../../components/molecules/CategoryItem/CategoryItem'
import CategoryHelpfulInfoSection from '../../../components/organisms/CategoryHelpfulInfoSection/CategoryHelpfulInfoSection'
import ShopCategoryBackendCall from '../../../components/molecules/ShopCategoryBackendCall/ShopCategoryBackendCall'

export default function LivingRoomFurniture() {
  const [subCategories, setSubCategories] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    //Living room's id is 0
    const fetchSubCategories = async () => {
      setLoading(true)
      const result = await ShopCategoryBackendCall(0)
      setSubCategories(result)
      setLoading(false)
    }

    fetchSubCategories()
  }, [])

  const transformCategoryName = (name) => {
    return name.replace(/&/g, '').replace(/\s+/g, '-').toLowerCase()
  }

  const categoryHelpfulInfo = `Living room furniture plays a crucial role in creating a comfortable and inviting space for family and friends to gather. A well-furnished living room can make all the difference in making your home feel warm and welcoming.
        
        One essential piece of living room furniture is a comfortable sofa or sectional. This is where you and your guests will spend the most time, so it's important to choose a piece that is both stylish and comfortable. Consider adding a few throw pillows and a cozy blanket for added comfort and visual interest.
        
        A coffee table is another important piece of living room furniture. This is where you can set down drinks and snacks, as well as display books and decorative items. Choose a coffee table that complements your sofa and other furniture, and consider adding a matching end table or two for additional surface space.

        In addition to the sofa and coffee table, living room furniture can include a variety of other pieces. Consider adding a bookshelf or media console to store books, games, and electronics. A comfortable armchair or recliner can provide a cozy spot for reading or relaxing. And if you have the space, a rug can help to define the space and add a touch of color and texture to the room.

        Overall, the key to choosing the right living room furniture is to consider the layout and functionality of the space, as well as your personal style. With the right pieces, you can create a comfortable and inviting living room that is perfect for spending time with loved ones.`

  const categoryPeopleAlsoAsk = [
    {
      question: 'What kind of furniture goes in a living room?',
      answer:
        "Typically, a living room will have a variety of furniture, including a sofa or chairs, a coffee table, and perhaps a bookshelf or media console. The specific pieces of furniture that you choose for your living room will depend on the size of the space and your personal style. Some people might also choose to include a rug, curtains, or other accessories in their living room. It's important to arrange the furniture in a way that is both functional and aesthetically pleasing, and that allows for easy movement and conversation.",
    },
    {
      question: 'What can be used instead of sofa in living room?',
      answer:
        'There are many different furniture pieces that can be used instead of a sofa in a living room. Some options include a loveseat, a sectional, a daybed, or even a large armchair. The type of furniture you choose will depend on your personal style and the size of your living room. Additionally, you may want to consider the layout of your space and how the furniture will be used. For example, if you want a seating option that can accommodate a lot of people, a sectional may be a good choice. If you want something more compact and cozy, a loveseat or large armchair might be a better option. Ultimately, the right furniture for your living room will depend on your individual needs and preferences.',
    },
    {
      question: 'Which type of sofa is best?',
      answer:
        'When choosing a sofa, the most important factor to consider is comfort. Take your time to try out different sofas and find one that is comfortable for you to sit on for extended periods of time. Additionally, the size of the sofa should be proportional to the size of your living space. A large sofa can make a small room feel cramped, while a small sofa can look lost in a large room. The style of your sofa should also match the overall style of your space. The material of your sofa will determine its durability, comfort, and care requirements, so choose wisely. Sofa features, such as reclining seats and built-in storage, can make your sofa more functional and comfortable. Finally, consider your budget and look for a sofa that fits your needs and your budget. A higher-quality sofa will likely last longer and be more comfortable than a cheaper one.',
    },
    {
      question: 'Which shape of a sofa is best for the living room?',
      answer:
        'The most popular shapes for sofas in living rooms are generally rectangular or L-shaped. These shapes provide plenty of seating space and can be easily positioned in a variety of arrangements to suit the layout of the room. Other popular shapes include circular or curved sofas, which can create a more modern and contemporary look, and loveseats, which are smaller two-seater sofas that are perfect for cozy spaces. Ultimately, the right shape of sofa for your living room will depend on the size and layout of the room, as well as your personal style preferences.',
    },
    {
      question: 'How can I make my living room look stylish?',
      answer: (
        <>
          Here&apos;s how you can make your living room look stylish:
          <ol>
            <li>
              Choose a cohesive color scheme and stick with it throughout the
              room. This can be achieved by choosing a main color and then using
              different shades of that color in your furniture, accessories, and
              decorations.
            </li>
            <li>
              Add texture to the room by using different fabrics and materials,
              such as velvet, leather, and wool.
            </li>
            <li>
              Add plants, artwork, and other decorative items to add visual
              interest to the room.
            </li>
            <li>
              Declutter and organize your living space to create a clean and
              inviting atmosphere.
            </li>
            <li>
              Choose a focal point, such as a fireplace or a large piece of
              artwork, and arrange your furniture around it.
            </li>
            <li>Use accent colors to add visual interest to the room.</li>
            <li>
              Add layers of lighting to create a warm and inviting atmosphere.
            </li>
            <li>
              Incorporate interesting textures and patterns into your design.
            </li>
            <li>Display your favorite items and decorations.</li>
          </ol>
          Overall, the key to making a living room look stylish is to create a
          cohesive design that reflects your personal style and taste. By
          choosing a color scheme, incorporating interesting textures and
          patterns, and adding decorative items, you can create a space that is
          both beautiful and functional.
        </>
      ),
    },
    {
      question: 'How do you update a boring living room?',
      answer: (
        <>
          There are many ways to update a boring living room and make it feel
          more welcoming and stylish. Here are a few ideas:
          <ol>
            <li>
              Add some colorful throw pillows or blankets to your couch or
              chairs. This is an easy and inexpensive way to add some
              personality and warmth to the room.
            </li>
            <li>
              Hang some new artwork or photographs on the walls. Choose pieces
              that reflect your personal style and interests.
            </li>
            <li>
              Swap out old curtains or window treatments for something new and
              fresh. This can have a big impact on the overall look and feel of
              the room.
            </li>
            <li>
              Consider painting the walls a new color. This can be a big
              undertaking, but a fresh coat of paint can completely transform a
              space.
            </li>
            <li>
              Add some plants or greenery to the room. Not only do plants add a
              natural touch, but they can also help purify the air and create a
              more calming atmosphere.
            </li>
            <li>
              Update your lighting. Consider adding a new lamp or overhead light
              fixture to add some visual interest and improve the room&apos;s
              functionality.
            </li>
            <li>
              Invest in some new furniture. If your budget allows, consider
              replacing old or outdated pieces with something more modern and
              stylish. This can completely change the look and feel of the room.
            </li>
          </ol>
        </>
      ),
    },
    {
      question: 'How can I update my living room without buying new furniture?',
      answer: (
        <>
          If you&apos;re looking to update your living room without buying new
          furniture, there are still plenty of options available to you. Here
          are a few ideas:
          <ol>
            <li>
              Re-arrange your furniture. Sometimes, simply moving your furniture
              around can make a big difference in the look and feel of the room.
              Try creating a new layout or grouping pieces together in a
              different way.
            </li>
            <li>
              Add some new accents. New throw pillows, blankets, or decorative
              objects can freshen up the room without requiring any major
              purchases.
            </li>
            <li>
              Update your lighting. Adding a new lamp or overhead light fixture
              can add some visual interest and improve the room&apos;s
              functionality.
            </li>
            <li>
              Hang some new artwork or photographs. Choose pieces that reflect
              your personal style and interests to add some personality to the
              space.
            </li>
            <li>
              Add some plants or greenery. Not only do plants add a natural
              touch, but they can also help purify the air and create a more
              calming atmosphere.
            </li>
            <li>
              Swap out old curtains or window treatments for something new. This
              can have a big impact on the overall look and feel of the room.
            </li>
            <li>
              Paint the walls or trim. A fresh coat of paint can completely
              transform a space, and it&apos;s a relatively inexpensive way to
              update the room.
            </li>
            <li>
              Update your accessories. New rugs, curtains, or decorative objects
              can add some interest and personality to the space without
              requiring any major purchases.
            </li>
          </ol>
        </>
      ),
    },
  ]

  return (
    <Box>
      <Box sx={classes.container}>
        {/* Temporary message */}
        <Box sx={classes.handSelectedMsg}>
          <Text>We’ll be adding other rooms soon 🌟</Text>
        </Box>

        {/* title */}
        <Text variant="h1" sx={classes.title}>
          Living Room
        </Text>

        {/* categories */}
        {loading ? (
          <Box sx={classes.spinnerContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={classes.gridContainer}>
              {subCategories?.map((category) => (
                <DepartmentCategory
                  key={category.category_id}
                  image={category.image_url}
                  category={category.category_name}
                  link={`/living-room/${transformCategoryName(
                    category.category_name,
                  )}-${category.category_id}`}
                />
              ))}
            </Box>

            {/* category helpful information section */}
            <CategoryHelpfulInfoSection
              title="Living Room Furniture"
              categoryHelpfulInfo={categoryHelpfulInfo}
              categoryPeopleAlsoAsk={categoryPeopleAlsoAsk}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
