export const housingTypes = [
  {
    label: 'Dormitory',
    isSelected: false,
  },
  {
    label: 'Single-Family Home',
    isSelected: false,
  },
  {
    label: 'Condo',
    isSelected: false,
  },
  {
    label: 'Townhouse',
    isSelected: false,
  },
  {
    label: 'Apartment',
    isSelected: false,
  },
  {
    label: 'Co-op',
    isSelected: false,
  },
  {
    label: 'Duplex/Triplex/Fourplex',
    isSelected: false,
  },
  {
    label: 'Modular and Prefabricated Home',
    isSelected: false,
  },
  {
    label: 'Mobile Home',
    isSelected: false,
  },
  {
    label: 'Tiny Home',
    isSelected: false,
  },
  {
    label: 'Loft',
    isSelected: false,
  },
  {
    label: 'Cottage or Bungalow',
    isSelected: false,
  },
  {
    label: 'Villa',
    isSelected: false,
  },
  {
    label: 'Farmhouse',
    isSelected: false,
  },
]

export const interiorStyleImages = {
  bohemian: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/bohemian/bohemian-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/bohemian/bohemian-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/bohemian/bohemian-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/bohemian/bohemian-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/bohemian/bohemian-5.jpg',
  ],
  eclectic: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/eclectic/eclectic-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/eclectic/eclectic-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/eclectic/eclectic-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/eclectic/eclectic-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/eclectic/eclectic-5.jpg',
  ],
  industrial: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/industrial/industrial-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/industrial/industrial-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/industrial/industrial-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/industrial/industrial-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/industrial/industrial-5.jpg',
  ],
  'mid-century-modern': [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/mid-century-modern/mid-century-modern-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/mid-century-modern/mid-century-modern-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/mid-century-modern/mid-century-modern-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/mid-century-modern/mid-century-modern-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/mid-century-modern/mid-century-modern-5.jpg',
  ],
  modern: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/modern/modern-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/modern/modern-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/modern/modern-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/modern/modern-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/modern/modern-5.jpg',
  ],
  rustic: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/rustic/rustic-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/rustic/rustic-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/rustic/rustic-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/rustic/rustic-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/rustic/rustic-5.jpg',
  ],
  scandinavian: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/scandinavian/scandinavian-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/scandinavian/scandinavian-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/scandinavian/scandinavian-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/scandinavian/scandinavian-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/scandinavian/scandinavian-5.jpg',
  ],
  traditional: [
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/traditional/traditional-1.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/traditional/traditional-2.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/traditional/traditional-3.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/traditional/traditional-4.jpg',
    'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/interior-styles/traditional/traditional-5.jpg',
  ],
}

export const colorAmount = [
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/color/less-color.jpg',
    label: 'I’m more of a neutrals fan',
    value: 'less',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/color/some-color.jpg',
    label: 'I like splashes here and there',
    value: 'some',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/color/more-color.jpg',
    label: 'I love lots of it',
    value: 'more',
  },
]

export const youColors = [
  {
    label: 'Whites and Creams',
    colors: ['#FFFFFF', '#FFFCF3', '#F8F5EB', '#F1E9DC'],
  },
  {
    label: 'Beiges',
    colors: ['#FAF0E6', '#FFF0DB', '#E4D5B7', '#D9B99B'],
  },
  {
    label: 'Browns',
    colors: ['#D9A679', '#8C6546', '#663C20', '#4A2D21'],
  },
  {
    label: 'Grays',
    colors: ['#D4D4D4', '#9C9C9C', '#686868', '#4A4A4A'],
  },
  {
    label: 'Blacks',
    colors: ['#3E3E3E', '#2E2E2E', '#242424', '#000000'],
  },
  {
    label: 'Reds',
    colors: ['#F9544B', '#EB4226', '#7D0C0C', '#672930'],
  },
  {
    label: 'Pinks',
    colors: ['#FDD4CE', '#FCC6E2', '#FFBEC2', '#C0809C'],
  },
  {
    label: 'Oranges',
    colors: ['#FFA24D', '#EE8A30', '#F2751D', '#C05C0A'],
  },
  {
    label: 'Yellows',
    colors: ['#FFFF8A', '#FFD64E', '#FFDA00', '#F2BC2A'],
  },
  {
    label: 'Greens',
    colors: ['#B3CF83', '#49AF5C', '#4E764D', '#1D4623'],
  },
  {
    label: 'Turquoise',
    colors: ['#D0F0E3', '#9FD5B5', '#4AD3CD', '#0E7C64'],
  },
  {
    label: 'Blues',
    colors: ['#B1BAFF', '#4586FD', '#2727FF', '#000995'],
  },
  {
    label: 'Purples',
    colors: ['#EDB3FF', '#D38BFF', '#B625E9', '#5B005B'],
  },
]

export const piecesAmount = [
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/pieces/less-pieces.jpg',
    label: 'I prefer fewer pieces',
    value: 'less',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/pieces/some-pieces.jpg',
    label: 'I prefer some pieces',
    value: 'some',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/pieces/more-pieces.jpg',
    label: 'I prefer many pieces',
    value: 'more',
  },
]

export const livesInOptions = [
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/live-in-home/adults.svg',
    label: 'Adults',
    age: '19+ yrs old',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/live-in-home/teens.svg',
    label: 'Teens',
    age: '13 - 18 yrs old',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/live-in-home/children.svg',
    label: 'Children',
    age: '0 - 12 yrs old',
  },
  {
    img: 'https://craftle-static.s3.us-east-2.amazonaws.com/images/sign-up-flow/live-in-home/pets.svg',
    label: 'Pets',
    age: '',
  },
]

export const petTypes = [
  {
    label: 'Dogs',
    isSelected: false,
  },
  {
    label: 'Cats',
    isSelected: false,
  },
  {
    label: 'Fish',
    isSelected: false,
  },
  {
    label: 'Birds',
    isSelected: false,
  },
  {
    label: 'Rabbits',
    isSelected: false,
  },
  {
    label: 'Hamsters',
    isSelected: false,
  },
  {
    label: 'Guinea Pigs',
    isSelected: false,
  },
  {
    label: 'Turtles',
    isSelected: false,
  },
  {
    label: 'Snakes',
    isSelected: false,
  },
  {
    label: 'Lizards',
    isSelected: false,
  },
  {
    label: 'Ferrets',
    isSelected: false,
  },
  {
    label: 'Others',
    isSelected: false,
  },
]

export const materialTypes = [
  {
    label: 'Metal (e.g. nickel, chrome, stainless steel)',
    isSelected: false,
  },
  {
    label: 'Plastic',
    isSelected: false,
  },
  {
    label: 'Glass',
    isSelected: false,
  },
  {
    label: 'Wood',
    isSelected: false,
  },
  {
    label: 'Fabrics (e.g. wool, cotton, synthetic blends)',
    isSelected: false,
  },
  {
    label: 'Real Leather',
    isSelected: false,
  },
  {
    label: 'Faux Leather',
    isSelected: false,
  },
  {
    label: 'Stone (e.g. marble, granite, quartz)',
    isSelected: false,
  },
  {
    label: 'Ceramic',
    isSelected: false,
  },
  {
    label: 'Latex',
    isSelected: false,
  },
  {
    label: 'Foam (including memory foam, polyurethane)',
    isSelected: false,
  },
]
