import React, { useState, memo } from 'react'
import { Box, Divider } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'
import { classes } from './ModalLogInCMSResultsStyle'
import { customTheme } from '../../atoms/CustomTheme/CustomTheme'
import LogInCallToBackend from '../../../pages/profileRelated/LogInCallToBackend'
import Textfield from '../../molecules/Textfield/Textfield'
import GoogleSignInButton from '../../../pages/profileRelated/GoogleSignInButton'

// import icons
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg'

const ModalLogInCMSResults = memo(function ModalLogInCMSResults({
  redirectHref,
  setShowLoginModal,
}) {
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down('sm'),
  )

  const handleClose = () => {
    setShowLoginModal(false)
  }

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('user', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const userInfo = {
      email,
      password,
    }

    const response = await LogInCallToBackend(userInfo)

    if (response) {
      saveUserToken(response).then(() => {
        document.location.href = redirectHref
      })
    } else {
      setShowErrorMsg(true)
    }
  }

  if (!setShowLoginModal) {
    return null
  }

  return (
    <Box
      sx={
        isMobileOrTabletScreen ? classes.mobileBackground : classes.background
      }
    >
      <Box
        sx={
          isMobileOrTabletScreen
            ? classes.mobileModalContainer
            : classes.modalContainer
        }
      >
        <Box
          onClick={handleClose}
          sx={
            isMobileOrTabletScreen
              ? classes.mobileIconContainer
              : classes.iconContainer
          }
        >
          <XIcon />
        </Box>
        <Text variant="h2" sx={classes.prompt}>
          Log in to start new designs
        </Text>

        <GoogleSignInButton redirectHref={redirectHref} />

        <Box sx={classes.orContainer}>
          <Divider sx={classes.orDivider} />
          <Text sx={classes.orText}>OR</Text>
          <Divider sx={classes.orDivider} />
        </Box>

        <Box
          sx={
            isMobileOrTabletScreen
              ? classes.sectionInputForm
              : classes.actionsContainer
          }
        >
          <form onSubmit={handleSubmit}>
            <Textfield
              label="Email"
              type="text"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="example@gmail.com"
              autoComplete="email"
              sx={classes.textField}
            />
            <Textfield
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              autoComplete="current-password"
              sx={classes.textField}
            />
            {showErrorMsg && (
              <Text sx={classes.errorMsg}>
                We couldn&apos;t find this account. Please try again, or go to
                &quot;Sign up&quot; below.
              </Text>
            )}
            <Button type="submit" variant="primary" sx={classes.logInButton}>
              Log In
            </Button>
          </form>
        </Box>
        <Box sx={classes.signUpContainer}>
          <Text sx={classes.newtoCraftleText}>New to Craftle? </Text>
          <Button
            variant="fourth"
            target="_blank"
            href={'/sign-up'}
            sx={classes.signUpButton}
          >
            Sign up
          </Button>
        </Box>
      </Box>
    </Box>
  )
})

ModalLogInCMSResults.propTypes = {
  redirectHref: PropTypes.string,
  setShowLoginModal: PropTypes.bool,
}

ModalLogInCMSResults.defaultProps = {
  redirectHref: '/',
  setShowLoginModal: false,
}

export default ModalLogInCMSResults
