import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    marginTop: '160px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '80px',
    padding: '0 24px',
    boxSizing: 'border-box',
    [customTheme.breakpoints.down('md')]: {
      gap: '40px',
    },
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 16px',
      gap: '0px',
    },
  },

  mdUpIllustration: {
    maxWidth: '504px',
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  smIllustration: {
    maxWidth: '504px',
    display: 'none',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '24px',
      display: 'inherit',
      maxWidth: '424px',
    },
  },

  column2: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    [customTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  hashtag: {
    fontWeight: 'bold',
    padding: '8px 16px',
    backgroundColor: '#DAEBCF',
    borderRadius: '100px',
    width: 'fit-content',
  },
  title: {
    marginTop: '16px',
    fontSize: '40px',
  },
  subtitle: {
    marginTop: '16px',
  },
  primaryBtn: {
    marginTop: '24px',
  },
}
