const AppConfig = {
  Mixpanel: {
    ProjectId: '2520550e141c0603f903276ff55be391',
    DebugEnabled: false,
  },
  GrowthBook: {
    SDKEndpoint:
      'https://cdn.growthbook.io/api/features/prod_rR7ITMiGW5EotTvcqOw2Ph6PduO5aIp6dTRs5WBa0',
  },
  //   Env: process.env["NEXT_PUBLIC_APP_ENV"]
  //     ? process.env["NEXT_PUBLIC_APP_ENV"]
  //     : "prod",
  Env: 'prod',
}

export default AppConfig
