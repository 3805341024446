import * as React from 'react'
import { Box, Grid } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import PropTypes from 'prop-types'

// import components
import { classes } from './BlogAppReviewStyle'
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'

// import icon
const externalLinkIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg'

const propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  availableOnArray: PropTypes.array.isRequired,
  costArray: PropTypes.string.isRequired,
  difficultyToLearnArray: PropTypes.string.isRequired,
  productDetails: PropTypes.string.isRequired,
  proArray: PropTypes.array.isRequired,
  conArray: PropTypes.array.isRequired,
}

const defaultProps = {
  title: null,
  link: null,
  date: null,
  image: null,
  availableOnArray: null,
  costArray: null,
  difficultyToLearnArray: null,
  productDetails: null,
  proArray: null,
  conArray: null,
}

BlogAppReview.propTypes = propTypes
BlogAppReview.defaultProps = defaultProps

export default function BlogAppReview({
  title,
  link,
  image,
  availableOnArray,
  costArray,
  difficultyToLearnArray,
  productDetails,
  proArray,
  conArray,
}) {
  return (
    <Box sx={classes.container}>
      <Text variant="h3" sx={classes.title}>
        {title}
      </Text>
      <Button
        variant="secondary"
        icon={externalLinkIcon}
        href={link}
        sx={classes.button}
      >
        Visit Now
      </Button>
      <CardMedia component="img" image={image} alt="" sx={classes.image} />
      <Grid container sx={classes.tagGridContainer}>
        <Grid item sm={4} xs={12} sx={classes.tagGridItem}>
          <Box sx={classes.tagContainer}>
            <Text sx={classes.tagTitle}>Available on</Text>
            {availableOnArray.map((tag, index) => (
              <Box key={index} sx={classes.tag}>
                {tag}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item sm={4} xs={12} sx={classes.tagGridItem}>
          <Box sx={classes.tagContainer}>
            <Text sx={classes.tagTitle}>Cost</Text>
            {/* <Box sx={classes.tag}>{cost}</Box> */}
            {costArray.map((tag, index) => (
              <Box key={index} sx={classes.tag}>
                {tag}
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item sm={4} xs={12} sx={classes.tagGridItem}>
          <Box sx={classes.tagContainer}>
            <Text sx={classes.tagTitle}>Difficulty to learn</Text>
            {/* <Box sx={classes.tag}>{difficultyToLearn}</Box> */}
            {difficultyToLearnArray.map((tag, index) => (
              <Box key={index} sx={classes.tag}>
                {tag}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Box sx={classes.productDetailsContainer}>
        <Text sx={classes.productDetailsTitle}>Product Details</Text>
        <Text sx={classes.productDetails}>{productDetails}</Text>
      </Box>
      <Grid container sx={classes.proConContainer}>
        <Grid item sm={6} xs={12} sx={classes.proConItem}>
          <Text sx={classes.proConTitle}>Pros</Text>
          <ul style={{ margin: 0 }}>
            <Text sx={classes.proCon}>
              {proArray.map((pro, index) => (
                <li key={index}>{pro}</li>
              ))}
            </Text>
          </ul>
        </Grid>
        <Grid item sm={6} xs={12} sx={classes.proConItem}>
          <Text sx={classes.proConTitle}>Cons</Text>
          <ul style={{ margin: 0 }}>
            <Text sx={classes.proCon}>
              {conArray.map((con, index) => (
                <li key={index}>{con}</li>
              ))}
            </Text>
          </ul>
        </Grid>
      </Grid>
    </Box>
  )
}
