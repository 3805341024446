import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    boxShadow: 'none',
    padding: '2px',
  },
  disabledBox: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  cardContainer: {
    position: 'relative',
  },
  selectedIconContainer: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    borderRadius: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  furnitureImage: {
    // borderRadius: "0px",
    [customTheme.breakpoints.down('md')]: {
      // width: "28vw",
      // height: "28vw",
    },
    [customTheme.breakpoints.down('sm')]: {
      // width: "40vw",
      // height: "40vw",
    },
  },
  labelContainer: {
    // padding: "0px",
    paddingTop: '8px',
    paddingBottom: '16px',
    textAlign: 'center',
  },
  labelText: {
    fontWeight: 'bold',
  },
}
