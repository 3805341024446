import React from 'react'
import { Box } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import { Grid } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import components
import ProductDescription from '../../components/organisms/ProductDescription/ProductDescription'
import CraftMyStyle from '../../components/organisms/LandingCraftMyStyle/LandingCraftMyStyle'
// import ProductImages from "../../components/organisms/ProductImages/ProductImages";

// import images
// import TestImage from "../../../assets/images/living_room.png";

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

const propTypes = {
  image: PropTypes.node,
  name: PropTypes.string,
  brand: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  productImg1: PropTypes.node,
  productImg1Credit1: PropTypes.string,
  productImg2: PropTypes.node,
  productImg2Credit2: PropTypes.string,
  description2: PropTypes.string,
}
const defaultProps = {
  image: null,
  name: null,
  brand: null,
  type: null,
  price: null,
  description: null,
  url: null,
  productImg1: null,
  productImg1Credit1: null,
  productImg2: null,
  productImg2Credit2: null,
  description2: null,
}

ProductPage.propTypes = propTypes
ProductPage.defaultProps = defaultProps

function ProductPage({
  image,
  name,
  brand,
  type,
  price,
  description,
  url,
  productImg1,
  productImg1Credit1,
  productImg2,
  productImg2Credit2,
  description2,
}) {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '120px 0',
          [customTheme.breakpoints.down('md')]: { margin: '80px 0' },
        }}
      >
        <Grid
          container
          spacing={8}
          sx={{
            width: '90vw',
            [customTheme.breakpoints.down('md')]: { width: '98vw' },
          }}
        >
          <Grid item lg={6} md={6} sx={{ paddingTop: '0' }}>
            <CardMedia
              component="img"
              image={image}
              alt=""
              sx={{
                position: 'relative',
                width: '100%',
                borderRadius: '16px',
              }}
            />
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sx={{
              [customTheme.breakpoints.down('sm')]: {
                paddingTop: '24px !important',
              },
            }}
          >
            <ProductDescription
              name={name}
              brand={brand}
              type={type}
              price={price}
              description={description}
              url={url}
              productImg1={productImg1}
              productImg1Credit1={productImg1Credit1}
              productImg2={productImg2}
              productImg1Credit2={productImg2Credit2}
              description2={description2}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '24px' }} />
        <CraftMyStyle />
      </Box>
    </ThemeProvider>
  )
}

export default ProductPage
