import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    position: 'absolute',
    top: '80px',
    right: '56px',
    backgroundColor: '#FFF',
    borderRadius: '8px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    padding: '16px 24px',
    zIndex: 10,
    width: 'fit-content',
    maxWidth: '246px',
    display: 'flex',
    flexDirection: 'column',
    [customTheme.breakpoints.down('md')]: {
      top: '72px',
      right: '4px',
    },
    [customTheme.breakpoints.down('xs')]: {
      right: '4px',
    },
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: '16px',
  },
  btn: {
    marginTop: '16px',
    width: '100%',
    // alignSelf: "flex-start",
  },

  speechBubbleTriangle: {
    borderLeft: '32px solid transparent',
    borderRight: '32px solid transparent',
    borderBottom: '32px solid #FFF',
    position: 'absolute',
    right: '16px',
    top: '-16px',
    [customTheme.breakpoints.down('md')]: {
      right: '2vw',
    },
  },
}
