import axios from 'axios'

// import components
import { EndPoint } from '../../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://b493go548k.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend',
  test: 'https://wsr62w4eqb.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend-for-testing',
  local: 'http://127.0.0.1:8080/furniture/suggest',
}

export default async function CMSDesignsGetCallToBackend(email) {
  const responseFromAWS = await (async () => {
    try {
      const response = await axios.post(END_POINTS[EndPoint], {
        request_method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        post_data: JSON.stringify({
          purpose: 'get_cms_design',
          email: email,
        }),
      })
      return response
    } catch (e) {
      console.log(e.message)
    }
  })()

  if (responseFromAWS?.data?.body) {
    if (responseFromAWS?.data?.body?.success) {
      return responseFromAWS
    }
  } else {
    return null
  }
}
