import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    padding: '105px 16px 80px 16px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1280px',
    width: '100%',
    margin: '0 auto',
    alignItems: 'center',
    boxSizing: 'border-box',
    [customTheme.breakpoints.down('sm')]: {
      padding: '81px 16px 80px 16px',
    },
  },
  handSelectedMsg: {
    backgroundColor: '#DAEBCF',
    padding: '8px 16px',
    width: 'fit-content',
    borderRadius: '8px',
  },
  title: {
    marginTop: '24px',
    fontSize: '48px',
    textAlign: 'center',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },
  spinnerContainer: {
    marginTop: '40px',
  },
  gridContainer: {
    marginTop: '40px',
    display: 'grid',
    gap: '24px 24px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    maxWidth: '1008px',
    [customTheme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [customTheme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}
