import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

// Import the atom component
import MultiSelectButton from '../../atoms/multiSelectButton/MultiSelectButton'

// Import styles
import { classes } from './MultiSelectBtnGroupStyle.js'

export default function MultiSelectBtnGroup({
  options,
  selectionOptions,
  onSelectionChange,
  inlineBlock,
  gap,
}) {
  // Handler for button clicks
  const handleButtonClick = (label) => {
    // Create a copy of the current selection options
    let updatedSelection

    if (selectionOptions.includes(label)) {
      // If the label is already selected, remove it from the selection
      updatedSelection = selectionOptions.filter((option) => option !== label)
    } else {
      // If the label is not selected, add it to the selection
      updatedSelection = [...selectionOptions, label]
    }

    // Call the parent update function to update the state
    onSelectionChange(updatedSelection)
  }

  return (
    <Box sx={{ ...classes.buttonGroupContainer, gap: gap ? gap : 'inherit' }}>
      {options.map((option) => (
        <MultiSelectButton
          key={option.label}
          isSelected={selectionOptions.includes(option.label)}
          onClick={() => handleButtonClick(option.label)}
          inlineBlock={inlineBlock}
        >
          {option.label}
        </MultiSelectButton>
      ))}
    </Box>
  )
}

MultiSelectBtnGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
  selectionOptions: PropTypes.arrayOf(PropTypes.string).isRequired, // Update to array of strings
  onSelectionChange: PropTypes.func.isRequired,
  inlineBlock: PropTypes.bool,
  gap: PropTypes.string,
}

MultiSelectBtnGroup.defaultProps = {
  options: [],
  selectionOptions: [], // Default to empty array
  onSelectionChange: () => {},
  inlineBlock: false,
  gap: null,
}
