import minMaxObject from './min_max_object.json'

export function calculateMinMaxCostForBudgets({
  // roomType,
  furnitureOptions,
  designWeights,
  // budget,
}) {
  let minBudget = 50
  let maxBudget = 100

  const selectedDesign = Object.keys(designWeights)[0]

  Object.entries(designWeights).forEach((item) => {
    const [key, val] = item
    if (val > designWeights[selectedDesign]) {
      designWeights = key
    }
  })

  furnitureOptions.forEach((furnitureOption) => {
    const furnitureColorPrices = minMaxObject[furnitureOption][selectedDesign]
    let maxOfMins = 0
    let maxOfMax = 50

    Object.entries(furnitureColorPrices).forEach((item) => {
      const [, minMaxCost] = item

      let currMinCost = minMaxCost['min_cost']
      let currMaxCost = minMaxCost['max_cost']

      let minCost = currMinCost ? currMinCost : 0
      minCost = Math.min(1000, minCost) // Can't be more than $1000, wierd edge case of rustic purple sofa min cost of $10

      let maxCost = currMaxCost ? currMaxCost : 50

      maxOfMins = Math.max(minCost, maxOfMins)
      maxOfMax = Math.max(maxCost, maxOfMax)
    })

    minBudget = minBudget + maxOfMins + 50
    maxBudget = maxBudget + maxOfMax
  })

  return {
    minBudget: minBudget,
    maxBudget: maxBudget,
  }
}

export function shuffleArray(array) {
  let i = array.length - 1
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export function MinMaxFromObj(obj) {
  const min = Math.min(...Object.values(obj))
  const max = Math.max(...Object.values(obj))
  const minKey = Object.entries(obj).find(([key, value]) =>
    value === min ? key : null,
  )[0]
  const maxKey = Object.entries(obj).find(([key, value]) =>
    value === max ? key : null,
  )[0]
  return [minKey, maxKey]
}

export function validateEmail(e) {
  // TODO: Change this regex
  // This requirement is a willful violation of RFC 5322,
  // which defines a syntax for e-mail addresses that is
  // simultaneously too strict (before the "@" character),
  // too vague (after the "@" character), and too lax (allowing
  // comments, whitespace characters, and quoted strings in
  // manners unfamiliar to most users) to be of practical use here.
  // NOTE: RFC 2822 describes a compliant version.
  // Check out: http://www.regular-expressions.info/email.html
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(e).toLowerCase())
}

export function getCurrentDate() {
  const date = new Date()
  return date
}

export function convertDateToReadableFormat(date) {
  date = new Date(date)
  const currentDate =
    (date.getMonth() + 1).toString() +
    '/' +
    date.getDate() +
    '/' +
    date.getFullYear()

  return currentDate
}

export function sortSavedDesignsArray(savedDesigns) {
  // Sorts in descending order
  savedDesigns.sort(function (a, b) {
    var keyA = new Date(a.lastUpdated),
      keyB = new Date(b.lastUpdated)
    // Compare the 2 dates
    if (keyA > keyB) return -1
    if (keyA < keyB) return 1
    return 0
  })

  return savedDesigns
}
