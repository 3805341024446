import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  // guestModeNotification function styles
  container: {
    boxShadow: 'none',
    borderRadius: 0,
    overflow: 'visible',
    marginTop: '10px',
    marginRight: '8px',
    cursor: 'pointer',
    [customTheme.breakpoints.down('md')]: {
      marginTop: 0,
      marginLeft: '8px',
    },
  },
  innerContainer: {
    position: 'relative',
  },

  ourPicksContainer: {
    border: 'solid',
    borderRadius: '8px',
    borderWidth: '2px',
    borderColor: '#2A2A2A',
    width: '144px',
    height: '144px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 16px',
    boxSizing: 'border-box',
    [customTheme.breakpoints.down('md')]: {
      width: '26vw',
      height: '26vw',
    },
    [customTheme.breakpoints.down('sm')]: {
      width: '45vw',
      height: '45vw',
      borderRadius: '8px',
    },
  },
  ourPicksTextContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  scrollText: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'inherit',
    [customTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  swipeText: {
    display: 'none',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
      fontWeight: 'bold',
      display: 'inherit',
    },
  },
}
