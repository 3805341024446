import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'

// Import Components
import { classes } from './ButtonStyle'

export default function CraftleButton({
  children = '',
  variant = 'primary',
  href = null,
  sx = {},
  onClick = () => {}, // Retain this for other uses
  target = null,
  style = {},
  icon = null,
  iconRight = false, // Add iconRight prop to control icon placement
  type = '',
  roundedIcons = false,
  onMouseDown = undefined,
  input = false,
  inputType = '',
  inputAccept = '',
  inputOnChange = undefined, // File input handler
}) {
  // Adjust padding based on the presence of an icon
  const adjustedPadding = icon ? '10px 22px' : '14px 22px'

  // Apply common styles, variant-specific styles, and inline styles
  const buttonStyles = {
    ...classes.commonStyles,
    padding: adjustedPadding,
    ...(variant === 'primary' && classes.primaryButton),
    ...(variant === 'secondary' && classes.secondaryButton),
    ...(variant === 'tertiary' && classes.tertiaryButton),
    ...(variant === 'fourth' && classes.fourthButton),
    ...sx, // Merge in any additional styles passed in via sx prop
    ...style, // Merge in any inline styles passed in via style prop
  }

  return (
    <Button
      onClick={onClick} // Retain onClick for other uses
      href={href}
      target={target}
      sx={buttonStyles}
      type={type}
      onMouseDown={onMouseDown}
    >
      {/* Conditionally render the icon on the left or right based on iconRight prop */}
      {!iconRight && icon && (
        <CardMedia
          component="img"
          image={icon}
          alt=""
          sx={{
            ...classes.icon,
            borderRadius: roundedIcons ? '50%' : 'inherit',
            marginRight: '8px',
          }}
        />
      )}

      {children}

      {/* Render the icon on the right if iconRight is true */}
      {iconRight && icon && (
        <CardMedia
          component="img"
          image={icon}
          alt=""
          sx={{
            ...classes.icon,
            borderRadius: roundedIcons ? '50%' : 'inherit',
            marginLeft: '8px',
          }}
        />
      )}

      {/* Directly include the input within the button */}
      {input && (
        <input
          type={inputType}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            opacity: 0, // Make input invisible but clickable
            cursor: 'pointer',
          }}
          accept={inputAccept}
          onChange={inputOnChange} // Trigger file input change
        />
      )}
    </Button>
  )
}

CraftleButton.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'fourth',
    null,
  ]),
  href: PropTypes.string,
  target: PropTypes.string,
  sx: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.object,
  icon: PropTypes.string,
  iconRight: PropTypes.bool, // Add prop type for iconRight
  type: PropTypes.string,
  roundedIcons: PropTypes.bool,
  onMouseDown: PropTypes.func,
  input: PropTypes.bool, // Whether it has an input field
  inputType: PropTypes.string, // Type of input (e.g., "file")
  inputAccept: PropTypes.string, // Accepted input file types
  inputOnChange: PropTypes.func, // OnChange handler for input
}
