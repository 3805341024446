import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

// import styles or additional components if needed
import { classes } from './MultiSelectButtonStyle'
import Text from '../../../components/atoms/Text/Text'

export default function MultiSelectButton({
  children,
  isSelected,
  onClick,
  inlineBlock,
}) {
  return (
    <Box
      sx={{
        ...classes.container,
        backgroundColor: isSelected ? '#FFE9AF' : '#FFFFFF',
        display: inlineBlock ? 'inline-block' : 'inherit',
        '&:hover': {
          backgroundColor: isSelected ? '#FFDE88' : '#F5F5F5',
        },
      }}
      onClick={onClick}
    >
      <Text sx={classes.text}>{children}</Text>
    </Box>
  )
}

MultiSelectButton.propTypes = {
  children: PropTypes.string,
  isSelected: PropTypes.bool, // New prop to indicate selection status
  onClick: PropTypes.func, // onClick handler for button interaction
  inlineBlock: PropTypes.bool,
}

MultiSelectButton.defaultProps = {
  children: '',
  isSelected: false, // Default state is not selected
  onClick: () => {},
  inlineBlock: false,
}
