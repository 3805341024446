import React from 'react'
import { Box, Grid } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// import components

// import images

let customTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function Policy() {
  return (
    <ThemeProvider theme={customTheme}>
      <Grid
        container
        sx={{
          maxWidth: '692px',
          width: '100%',
          margin: 'auto',
          marginTop: '100px',
          marginBottom: '100px',
          [customTheme.breakpoints.down('md')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
            marginTop: '50px',
          },
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              fontSize: '50px',
              lineHeight: 1.3,
              fontWeight: 'bold',
              textAlign: 'center',
              paddingBottom: '30px',
            }}
          >
            Privacy Policy
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            This Privacy Policy describes what personal information we collect,
            how we use this information, how this information may be shared, and
            what choices and rights you have with respect to this information.
            Access and use of our Platform is subject to this Privacy Policy.
            <Box sx={{ height: '24px' }}></Box>
            Our “Platform” is made up of Craftle app, website, products, and
            services on which this Privacy Policy is posted and that are
            Craftle-branded or operated by the Craftle group, including our
            plug-ins, embedded content made available on other sites, and
            browser extensions.
            <Box sx={{ height: '24px' }}></Box>
            The terms &quot;we,&quot; &quot;us,&quot; or &quot;our&quot; refer
            to Craftle Inc., a Delaware corporation. The &quot;Craftle
            group&quot; refers to corporate affiliates of Craftle Inc. By “you,”
            we are referring to visitors and users of any part of our Platform.
          </Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            1. Collection of Information
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We collect personal information that you provide us, from third
            parties, and automatically when you use our Platform. We collect
            information associated with your device using cookies and similar
            technologies. We may also receive information about your location.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Information You Provide Us</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We collect information that you provide us, including your account
            and profile information, content you submit or post to our Platform,
            your purchase information, your communications, and information you
            provide to us as part of surveys and contests.
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Account and Profile Information.</b> When you create an
                account on our Platform, we collect information you provide us,
                like your name, email address, interests, gender, age, and
                profile information. If you are a professional, you may also
                provide us information related to your business, including a
                business description and areas served.
              </li>
              <li>
                <b>Device Address Book.</b> If you choose to, we collect
                information from your device’s address book so that you can
                share messages with your address book contacts or add contacts
                to group chats. You can always revoke this consent by changing
                the settings on your device.
              </li>
              <li>
                <b>Phone Number.</b> We may ask you to provide and verify your
                telephone number when you first launch the App. Additionally,
                this process may be triggered if you want to create a new group,
                have updates on your orders, or we suspect improper activity on
                your account.
              </li>
              <li>
                <b>Content You Submit.</b> We collect information and content
                that you submit, post to, like, share or create in our Platform,
                including your photos, comments, reviews, searches, photo notes,
                discussions, project details and costs, and style preferences.
              </li>
              <li>
                <b>Purchases.</b> If you purchase or sell products or services
                or make or receive payments within our Platform, we collect
                certain information in order to complete your purchases, sales,
                and payments, such as your phone number, address, and payment
                method (for example, credit card or bank account information).
              </li>
              <li>
                <b>Communications.</b> When you communicate with us (via email,
                phone, through our Platform, or otherwise) and when you use our
                Platform to communicate with others, we may record, monitor, or
                otherwise collect details about your communications (including
                call time, and call duration). Certain professionals and sellers
                on our Platform may choose to use our call tracking feature in
                order to better manage their business. You are using our
                Platform when you make calls to these professionals and sellers
                using their phone number displayed on our Platform. We will
                provide you notice or obtain your consent in accordance with
                applicable law before recording or monitoring any of these
                calls. Otherwise, to the extent you communicate with
                professionals, sellers, and other users outside our Platform,
                those communications are not subject to this Privacy Policy.
              </li>
              <li>
                <b>Surveys and Contests.</b> If you participate in our surveys,
                contests, or promotions, we will collect the information you
                provide to us.
              </li>
            </ul>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Automatically Collected Information</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Each time you access the Platform, information is sent to our server
            by your Internet browser or app and stored in our log files. We
            collect information about your use of our Platform, including your
            search activity, the pages you view, the date and time of your
            visit, and if you use our browser extensions, the content you
            interact with on third-party sites (for example, if you save an
            image from a third-party site to your Craftle account), and how you
            navigate and interact with a page or screen. We also collect
            information that your device provides to us in connection with your
            use of our Platform, such as your browser type, type of device,
            browser language, IP address, mobile carrier, unique device
            identifier, and requested and referring URLs.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Cookies and Similar Technologies</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We use cookies and similar technologies in connection with your use
            of our Platform to collect information related to what you see and
            interact with on our Platform and other Platform access and use.
            Please note that unless required by law, we do not change system
            behavior within our Platform in response to browser requests not to
            be tracked.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Location Information</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We will receive your device&apos;s location if you enable this
            through your browser or mobile device. We may infer your general
            location from your IP address. You may also choose to provide us
            your location information by inputting this information in our
            Platform, including in your Craftle settings, or when you checkout
            as a shipping or billing address.
          </Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            2. Profile
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            You have additional choices about the information on your profile,
            such as lifestyle, living arrangement, family members, photo, and
            city or area. You don’t have to provide additional information on
            your profile; however, profile information helps you to get more
            from our Service, including personalizing group and content
            recommendations to you, and helping products and business
            opportunities find you. It’s your choice whether to include
            additional information on your profile.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Your profile is visible to all members and customers of our Service.
            Your profile includes your name, your user name, and may include
            optional information if provided by the user.
          </Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            3. Information from Others
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We collect information that others make available to us, including
            information from other platforms, publicly available information,
            information from other users of our Platform, information from our
            partners, service providers, and corporate affiliates, and
            information from third-party sites.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <ul>
              <li>
                <b>Other Platforms.</b> We may receive information about you
                from third-party platforms when you allow the third-party
                platform to share this information with us or allow Craftle to
                obtain this information from the third party. For example, you
                may sign-up for our Platform through Facebook, Google, or Apple;
                upload your contacts from your third-party email service; use
                our Platform (or a third-party integration on our Platform) to
                manage your calendar on another platform or to manage and send
                communications (e.g., email or text messages).
              </li>
              <li>
                <b>Publicly Available Information.</b> If you are a professional
                or seller, we may collect information about you from publicly
                available sources and where permitted by applicable law or with
                your permission, make it available as part of our Platform. For
                example, we may receive your name and contact information from
                industry associations and dealer networks in which you
                participate and display your affiliation with these
                organizations. We also may collect and display the details of
                any license you have using publicly available records.
              </li>
              <li>
                <b>Other Users and Customers.</b> Others may post content on our
                Platform that includes information about you (for example, as
                part of photos, stories, and reviews) or provide us your
                information in connection with using our Platform (e.g., to
                manage their business, organize photoshoots of their projects,
                participate in referral programs, and to advertise through our
                Platform). In order to enhance the quality of our Platform,
                professionals and sellers on our Platform may provide us
                information about your engagement with them, including whether
                you have contacted them or responded to them, whether your
                project has been completed, and whether you have received a
                product you have ordered. If you are a professional or seller,
                Platform users and visitors may provide us with feedback and
                public reviews about you.
              </li>
              <li>
                <b>Our Partners and Service Providers.</b> We may receive
                information about you from our partners and service providers.
                For example, our payment processors may update your payment
                information, advertising partners may help us send
                communications to your address, and third parties may help us
                prevent fraud by verifying your contact information. In
                addition, our banking partner that issues the Craftle credit
                card(s) may provide us with information regarding whether you
                have such a card, related to any card reward or loyalty program,
                or resulting from prescreening you for card offers.
              </li>
              <li>
                <b>Craftle Group.</b> Subject to the privacy policies of our
                corporate affiliates and in accordance with applicable laws, we
                may receive information about you from them.
              </li>
              <li>
                <b>Third-Party Sites.</b> Certain third-party sites embed a
                Craftle plug-in, widget, or content, including through
                Craftle-branded buttons, badges, and slideshows and Craftle
                co-branded content partnerships. Our plug-ins, widgets, embedded
                content, and pixels may collect information pursuant to our
                Cookie Policy. You may also choose to use our browser
                extensions, which collect the information described in this
                Privacy Policy like the rest of our Platform.
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            4. Use of Information
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We use the personal information we collect to provide, support, and
            personalize our Platform and to communicate with you about our
            Platform (on and off our Platform). The ways we do this include the
            following:
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <ul>
              <li>
                to provide and improve our Platform and its features and
                functionality, including to display content you post publicly,
                fulfill your purchases through our Platform, process, store, and
                deliver your communications; to provide customer service and
                support; to respond to your inquiries or requests; to back-up
                our systems; and to improve the security of our Platform;
              </li>
              <li>
                to evaluate use of our Platform for our business purposes and to
                provide and improve our products and services, including new and
                co-branded products and services and our Platform&apos;s
                functionality;
              </li>
              <li>
                to personalize your experience, such as tailoring the content
                and information made available to you and our support services;
              </li>
              <li>
                to display relevant advertising (on our Platform and third-party
                sites) for us and other businesses and to evaluate the success
                of such advertising;
              </li>
              <li>
                to provide feedback to professionals and sellers on our
                Platform;
              </li>
              <li>
                to communicate with you as described in our agreements with you
                (for example, the Craftle Terms of Use) or this Privacy Policy;
              </li>
              <li>
                to prevent fraud and to maintain a quality experience for our
                business purposes or legal reasons, including to filter for spam
                and communications that violate our agreements with you (for
                example, the Craftle Terms of Use);
              </li>
              <li>
                to enforce compliance with our agreements with you (for example,
                the Craftle Terms of Use) and applicable law; to comply with our
                legal obligations; to prosecute or defend a lawsuit,
                arbitration, or similar legal proceeding; to respond to lawful
                requests by public authorities (including national security or
                law enforcement requirements);
              </li>
              <li>
                to perform research and analysis for our business purposes; and
              </li>
              <li>
                to award badges and similar designations and if you decide to
                participate, to administer surveys, contests, and promotions.
              </li>
            </ul>
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            If you provide us information about your contacts (for example, when
            you sign up for our Platform through Facebook), in order to
            facilitate a more connected experience, we may inform your contacts
            who have accounts on our Platform that you also have joined our
            Platform and follow them on your behalf so that you may view their
            public activities on our Platform.
            <Box sx={{ height: '24px' }}></Box>
            We may use your communications generally for quality assurance
            purposes and training and to improve our products and services. We
            may use your communications to understand and help manage the
            quality of requests received, and responses sent, by professionals
            and sellers on our Platform and to provide them with feedback. We
            may use automated technology to review your communications and
            improve user experience.
            <Box sx={{ height: '24px' }}></Box>
            We may use your log-in information, cookie information, device
            information, IP addresses, and other information to identify you and
            log your use across devices, our Platform, and third-party sites. We
            may use this information to personalize your experience on our
            Platform.
            <Box sx={{ height: '24px' }}></Box>
            For your convenience, we and our payment processors will store
            information about the payment method you use in connection with the
            Platform. Note that while our payment processors may store your
            complete credit card number, Craftle does not. You may remove or
            change your payment method on Craftle through your settings.
            <Box sx={{ height: '24px' }}></Box>
            If you make purchases on Craftle as a visitor and subsequently
            create an account, for your reference, we will make information
            available to you about any prior purchases completed using the email
            address for your account.
            <Box sx={{ height: '24px' }}></Box>
            Any use of your information that is incompatible with these uses
            will be disclosed to you in advance so that you may opt out of, or
            agree to, such uses at your discretion.
          </Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            5. Sharing Your Information
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We do not rent or sell your personal contact information (such as
            your email address associated with your account registration) to
            third parties to use for their direct marketing purposes. We share
            personal information in the following ways:
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Platform Visitors and Users</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            One of the main purposes of having a Craftle account is to share
            home renovation and design ideas with others. Through Craftle,
            others have the ability to find, contact, view, and follow your
            profile and public activity on Craftle (for example, if you
            &quot;like&quot; content on our Platform or if you create a post and
            do not mark it private) and link it to your username. You can
            customize your Craftle profile and whether users may follow you
            through your Craftle settings. Your Craftle content (for example,
            photos, notes on others&apos; photos, projects, discussions, and
            social media profiles), followers, and others you follow are
            publicly viewable on the Platform and open to comments by other
            Craftle users. You also may choose to make certain information
            public on our Platform. If you are a professional or seller on
            Craftle, we may also display the following information, but not
            limited to, so that we can help visitors and users evaluate you:
            aggregated information on our Platform about your Platform activity
            (e.g. response rate), and information that is publicly available
            from third parties. We have policies that place restrictions on
            Platform visitors&apos; and users&apos; use of information and
            content you choose to make available publicly through the Platform.
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Service Providers and Partners</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We may share your information with service providers and partners as
            reasonably needed for our uses described in this Privacy Policy. We
            ask our service providers to limit their use of that information for
            use on behalf of Craftle and for improving their services and
            products (in a de-identified form if legally required). Only if you
            specifically agree to share your contact information with a
            third-party partner, for example, as part of a contest or promotion
            we sponsor with such third party, will we share your contact
            information with that third-party partner for their direct marketing
            purposes.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            In addition, we may share information about you with third-party
            platforms when you allow the third-party platform to receive this
            information from us or allow Craftle to share this information with
            the third party. Our Platform may include third-party social media
            features and other third-party plug-ins and widgets. When these
            features are displayed on our Platform, they are operated or hosted
            by third parties and are subject to their own privacy policies
            instead of this Privacy Policy. For example, you may sign-up for our
            Platform through Facebook, Google, or Apple.
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Advertisers and Ad Networks</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We use advertising networks and partners to serve advertisements for
            us and other businesses, including interest-based advertising, on
            third-party sites and to assist us in evaluating the effectiveness
            of our advertising. We may also work with advertising networks and
            partners to serve ads on our Platform and to communicate with you at
            the addresses they have for you. We may provide advertising
            networks, advertising partners, and businesses advertising on our
            platform with aggregate information but other than hashed
            information or device identifiers, we do not disclose your name,
            contact information, or other personal information. For example, we
            may provide a hashed version of your email address or other
            information to advertising networks (e.g., Facebook and Google) to
            serve advertisements to you or others. To opt-out of the use of your
            information for this purpose, please send a request as provided
            under the Contact Us section.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We and the businesses advertising on our Platform, advertising
            networks, and advertising partners may also use cookies or similar
            technologies to track your activities on our Platform and other
            online properties in order to provide you with tailored ads across
            websites, apps, and devices and to understand how those ads perform.
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Corporate Affiliates and Business Transfers</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We may share information from or about you with (a) joint ventures
            or other companies in the Craftle group, or (b) a potential or
            actual acquirer, successor, or assignee as part of any
            reorganization, merger, sale, joint venture, assignment, transfer,
            or other disposition of all or any portion of our business, assets,
            or stock (including in bankruptcy or similar proceedings). In these
            cases, their use will be subject to this Privacy Policy.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <b>Legal Obligations</b>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We may disclose information from or about you, as required or
            permitted by applicable law, if we believe that such disclosure is
            reasonably necessary to (a) comply with legal process and law
            enforcement instructions and orders, such as a search warrant,
            subpoena, statute, judicial proceeding, or other legal process
            served on us, a service provider, or a partner (which may include
            disclosure to law enforcement, courts, or governmental authorities);
            (b) prevent, investigate, or identify possible wrongdoing in
            connection with our Platform; (c) protect our rights, reputation,
            property, or that of our visitors, users, corporate affiliates, or
            the public; or (d) enforce compliance with our agreements with you
            (for example, the Craftle Terms of Use).
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            6. Communications
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            An essential part of our services that you sign up for when you
            create an account on our Platform is to receive communications from
            the Craftle group through email and our Platform. If you use our
            mobile app or provide us with your cell phone number, these
            communications also include text messages and push notifications. We
            communicate with you about your account, privacy, and interactions
            with your content. We also ask for your feedback about your
            experience in connection with our Platform or about your home
            renovation and design plans. In accordance with applicable law, we
            may send you marketing communications (e.g., with tips, offers, and
            similar services) from the Craftle group. These communications may
            feature us, our Platform, co-branded products and services,
            professionals, sellers, and other businesses on our Platform.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            If you provide us your phone number, we may call and otherwise
            contact you to help you with using the Platform (e.g., to discuss
            your home remodeling or design project, to find professionals, or to
            assist with your order or if you are a professional, to find
            products or to provide you tips about your professional profile). If
            you are a professional or other business, we may use your contact
            information (whether publicly available or provided to Craftle) to
            call and otherwise contact you about our Platform, including to
            market and sell our products or services or those available on our
            Platform.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            You may change your communication preferences at any time through
            your Craftle settings if you have a Craftle account, and you may
            also opt-out of similar types of marketing emails from us directly
            through the email. To opt-out of our text messages at any time, you
            can follow the instructions provided in the text message, which
            typically is to reply “STOP.” You may opt-out of sales and marketing
            phone calls from us by letting us know during the call or by
            contacting us as described in the Contact Us section. Your
            communication preferences on Craftle are independent and generally
            will not affect or change the communications you receive through the
            other portions of the Platform. Please be aware that you cannot
            opt-out of receiving account- and privacy-related messages from us.
            If you wish to stop receiving push notifications from us, you can
            turn off push notifications for the mobile application in the
            settings of your mobile device.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Our Platform also allows you and others to send messages in our
            Platform, share content with others outside our Platform, and if you
            make your contact information available, for others to contact you
            outside of our Platform.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            7. Your Choices and Rights
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We respond to all requests we receive from inBoxiduals wishing to
            exercise their data protection rights in accordance with applicable
            data protection laws. We may ask you to verify your identity in
            order to help us respond to your request securely and efficiently.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Even where not legally required, we will use reasonable efforts to
            complete requests to access or delete your personal information. You
            may request to receive a copy of your Craftle information through
            your Craftle settings. You may access your Craftle information by
            contacting us as provided in the Contact Us section. To request
            permanent deletion of your personal information, please contact us
            as described in the Contact Us section.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            To edit your personal information, you can manage your profile on
            our Platform, as well as your Craftle settings. You may also contact
            us about correcting or updating your personal information (including
            your marketing preferences) using the methods provided in the
            Contact Us section. We will consider your request in accordance with
            applicable laws.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            8. Account Termination and Data Retention
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We generally retain your personal data as long as you keep your
            account open or as needed to provide you the Service. This includes
            data you or others provided to us and data generated or inferred
            from your use of our Service. In some cases we choose to retain
            certain information (e.g., insights about Service use) in a
            depersonalized or aggregated form. The information you provide to
            Craftle is encrypted. We take reasonable measures to protect
            information about you from loss, theft, misuse and unauthorized
            access, disclosure, alteration and destruction.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Unless you are a professional or seller on Craftle, you can
            deactivate your Craftle account at any time by going to your Craftle
            settings. To deactivate your account as a professional or seller on
            Craftle, please contact us as described in the Contact Us section of
            this Privacy Policy. When you deactivate your account, you will
            cease to provide ongoing or future data to us that is associated
            with your account. Your Craftle profile will no longer be accessible
            on the Platform but in accordance with applicable law and the
            Craftle Terms of Use your photos, reviews, and other content may
            still be available through our Platform. In order to request
            permanent deletion of the personal information associated with your
            account, please contact us as described in the Contact Us section.
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We will keep your personal information only for as long as necessary
            to fulfill the purposes for which we are processing your personal
            information unless the law permits or requires us to retain your
            personal information for a longer period. For example, we may need
            to keep your personal data for quality assurance purposes for our
            Platform or to defend future legal claims. We also will retain
            de-identified information after your account has been deleted.
            Information you have shared with others will remain visible after
            your account has been deactivated or deleted and after the
            information has been deleted from your own profile or account. We do
            not control information or content that others have copied out of
            our Platform. Please note that copies of information that you have
            updated, modified, or deleted may continue to reside in our systems
            for a period of time, and we may maintain copies of this information
            as part of our business records.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            9. Children
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Our Platform is intended for general audiences and is not directed
            to children under 16. We do not knowingly collect personal
            information from children under 16. In the event that we learn that
            we have collected personal information from a child under age 16, we
            will take appropriate steps to delete that information. If you
            become aware or believe that a child has provided us with personal
            information, please contact us as provided in the Contact Us
            section.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            10. Data Security
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We use reasonable and appropriate measures to protect your personal
            information from loss, misuse, unauthorized access, disclosure,
            alteration, and destruction, taking into account the risks involved
            in the processing and the nature of the personal information.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            11. Updates to this Privacy Policy
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We may revise this Privacy Policy from time to time. The most
            current version of our Privacy Policy will govern our use of
            information about you. If we make material changes to our Privacy
            Policy, we will notify you by email or by posting a notice on our
            Platform or by other means, to offer you an opportunity to review
            the changes before they become effective. By continuing to access or
            use our Platform after those changes become effective, you are
            subject to the revised Privacy Policy.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            12. Contact Us
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            Within the Craftle group, Craftle Inc. is responsible for the use of
            your personal information. If you have questions or comments about
            this Privacy Policy (including about the manner in which we or our
            service providers treat your personal information), you may contact
            us at help@craftle.app.
          </Box>
          <Box sx={{ height: '24px' }}></Box>

          <Box
            sx={{
              fontSize: '20px',
              lineHeight: 1.5,
              fontWeight: 'bold',
              marginTop: '50px',
              marginBottom: '20px',
            }}
          >
            13. Additional Information for InBoxiduals from California
          </Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            We do not honor do not track requests.
            <Box sx={{ height: '24px' }}></Box>
            California&apos;s “Shine the Light” law (Civil Code Section §
            1798.83) applies to customers who are California residents to
            request certain information regarding our disclosure of personal
            information to third parties for their direct marketing purposes. To
            make such a request, please send an email to angela.kim@craftle.app.
            <Box sx={{ height: '24px' }}></Box>
            This section provides notice and specific information for California
            consumers to address the California Consumer Privacy Act
            (&quot;CCPA&quot;).
            <Box sx={{ height: '24px' }}></Box>
            We may collect the following categories of personal information from
            you:
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            <ul>
              <li>
                Contact information and other identifiers (for example, real
                name, address, email address, account name, or other similar
                identifiers);
              </li>
              <li>
                User ID or device identifiers (for example, IP address or other
                similar identifiers);
              </li>
              <li>
                Customer records (for example, customer records containing
                personal information, such as name, address, and telephone
                number);
              </li>
              <li>
                Protected classifications (but only gender, age range, and
                ethnicity, which you choose to provide);
              </li>
              <li>
                Purchase history and preferences (for example, records of
                products or services purchased or considered);
              </li>
              <li>
                Usage data (for example, internet activity information,
                including browsing history and interactions with a website or
                advertisement);
              </li>
              <li>
                Precise geolocation data (for example, latitude/longitude from a
                particular device, but only with your consent);
              </li>
              <li>
                Audiovisual information (for example, visual, audio, or similar
                information, only if you choose to provide this information);
              </li>
              <li>
                Work history (for example, professional information, if this
                information is provided).
              </li>
            </ul>
          </Box>
          <Box sx={{ height: '24px' }}></Box>
          <Box sx={{ fontSize: '16px', lineHeight: 1.5 }}>
            The Use of Information section of this Privacy Policy describes the
            purposes for which we collect your personal information.
            <Box sx={{ height: '24px' }}></Box>
            We do not disclose your data in exchange for money except at your
            request or direction. For California residents, the CCPA defines
            &quot;selling&quot; personal information to include providing it to
            a third party in exchange for valuable services. We disclose
            information to certain third parties in exchange for valuable
            services, such as advertising services, which may constitute a
            &quot;sale&quot; under the CCPA. You may opt-out of this disclosure
            of your data for commercial purposes in the settings. As provided in
            our Terms of Use, use of the Craftle Platform by anyone under 16
            years of age is strictly prohibited. Consequently, we do not
            knowingly disclose information regarding inBoxiduals under 16 years
            of age to third parties in exchange for consideration.
            <Box sx={{ height: '24px' }}></Box>
            In addition to the rights described above and in the Your Choices
            and Rights section, California consumers may also, up to twice every
            12 months and subject to certain exceptions, request us to provide
            information about how we have used and shared your personal
            information in the prior 12 months. Please submit these requests for
            information, as well as requests to access or delete your
            information, through your privacy settings, or by emailing us at
            help@craftle.app.
            <Box sx={{ height: '24px' }}></Box>
            Your authorized agent may submit a request to know, delete, or opt
            out on your behalf via this online support form or by emailing us at
            help@craftle.app. We may still require you to directly verify your
            identity and confirm that you have provided the authorized agent
            permission to submit the request. Whether you submit your request
            directly or through an authorized agent, generally, the only means
            Craftle has to verify your identity is for you: to respond to a
            specific Craftle email from the email address associated with your
            Craftle account; to click a confirmation or similar button in a
            specific Craftle email from the email address associated with your
            Craftle account; or to submit your request from your logged-in
            account.
            <Box sx={{ height: '24px' }}></Box>
            The Collection of Information section of this Privacy Policy
            describes the sources from which we collect your personal
            information. The Use of Information section of this Privacy Policy
            describes the purposes for which we collect your personal
            information. The Sharing Your Information section of this Privacy
            Policy describes the types of third parties with which we share your
            personal information.
            <Box sx={{ height: '24px' }}></Box>
            Consistent with the CCPA, except to the extent reasonably related to
            the value of your personal information, we will not treat California
            consumers differently for exercising their rights under the CCPA.
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Policy
