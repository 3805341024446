import React, { useState } from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import { useNavigate } from 'react-router-dom'

// import components
import { classes } from './AIFurnitureShopperResults1Style'
import Text from '../../components/atoms/Text/Text'
import Button from '../../components/atoms/Button/Button'
import Textfield from '../../components/molecules/Textfield/Textfield'

// import images

// import icons
const sunIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/sun.svg'
const minimizeIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/minus.svg'
const externalLink =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg'
const picture1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/1.jpg'
const picture2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/2.jpg'
const picture3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/3.jpg'
const picture4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/4.jpg'
const picture5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/ai-furniture-shopper/5.jpg'
const selectedHeart =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg'
const unselectedHeart =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-not-filled.svg'
const loader =
  'https://craftle-static.s3.us-east-2.amazonaws.com/animations/loader-dual-ring.gif'

export default function AIFurnitureShopperResults1() {
  const [loaderDisplay, setLoaderDisplay] = useState('none')
  const [favorited1, setFavorited1] = useState(false)
  const [favorited2, setFavorited2] = useState(false)
  const [favorited3, setFavorited3] = useState(false)
  const [favorited4, setFavorited4] = useState(false)
  const [favorited5, setFavorited5] = useState(false)
  const [input, setInput] = useState('')

  let navigate = useNavigate()

  return (
    <Box sx={classes.container}>
      <Box sx={{ ...classes.loadingSection, display: loaderDisplay }}>
        <Box sx={classes.loadingModal}>
          <CardMedia
            component="img"
            image={loader}
            alt=""
            sx={classes.loaderIcon}
          />
          <Text sx={classes.loaderText}>
            Looking for awesome products. Hang tight!
          </Text>
        </Box>
      </Box>

      <Box sx={classes.topSeparator} />

      <Box sx={classes.content}>
        <Box sx={classes.leftColumn}>
          <Box sx={classes.sticky}>
            {/* Commands */}
            <Box sx={classes.commandsContainer}>
              <Box sx={classes.commandButton}>
                <Text sx={classes.commandText}>Start Over</Text>
              </Box>
              <Box sx={{ ...classes.commandButton, padding: '10px 24px' }}>
                <Text sx={classes.commandText}>Chat History</Text>
                <Box sx={classes.numberBubbleContainer}>
                  <Text sx={classes.commandText}>1</Text>
                </Box>
              </Box>
              <Box sx={classes.commandButton}>
                <Text sx={classes.commandText}>Share</Text>
              </Box>
            </Box>

            {/* Left column content */}
            <Box sx={classes.leftColumnContent}>
              <Box sx={classes.matchingItemsContainer}>
                <Text sx={classes.matchingItemsText}>5 matching items</Text>
              </Box>
              <Text variant="h1" sx={classes.title}>
                I found a few options you might like 🎉
              </Text>
              <Text sx={classes.subtitle}>
                From what I could find, Kim Kardashian has a modern aesthetic.
                Here are a few curved sofas under $3,000 that match Kim
                Kardashian&apos;s living room aesthetics. If you want me to find
                sofas that are more specific, tell me more below.
              </Text>
              <Box sx={classes.searchContainer}>
                <Textfield
                  type="text"
                  id="email"
                  placeholder="Try: I also want it to be green"
                  onChange={(event) => setInput(event.target.value)}
                  value={input}
                  sx={classes.textfield}
                />
                <Button
                  variant="primary"
                  onClick={() => {
                    setLoaderDisplay('inherit')
                    setTimeout(() => {
                      navigate('/ai-furniture-shopper/search/e/679')
                    }, 3000)
                  }}
                  sx={classes.searchButton}
                >
                  Send
                </Button>
              </Box>
              <Box sx={classes.tipsContainer}>
                <Box sx={classes.tipsTitleRow}>
                  <Box sx={classes.tipsTitleRowLeft}>
                    <CardMedia
                      component="img"
                      image={sunIcon}
                      alt="Tips icon"
                      sx={classes.tipsIcon}
                    />
                    <Text sx={classes.tipsTitleText}>Tips to consider</Text>
                  </Box>
                  <CardMedia
                    component="img"
                    image={minimizeIcon}
                    alt="Minimize icon"
                    sx={classes.minimizeIcon}
                  />
                </Box>
                <Text sx={classes.tipsContent}>
                  <>
                    <ol style={{ margin: 0, paddingLeft: '24px' }}>
                      <li>What is your preferred color for the curved sofa?</li>
                      <li>
                        Are you interested in a specific style? For example,
                        modern, vintage, rustic, etc.?
                      </li>
                    </ol>
                  </>
                </Text>
                <Text sx={classes.tipsMoreButton}>Click for more</Text>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={classes.separator} />

        <Box sx={classes.rightColumn}>
          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture1}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited1 ? setFavorited1(false) : setFavorited1(true)
                }}
                sx={classes.favoritesContainer}
              >
                {favorited1 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>WAYFAIR</Text>
            <Text sx={classes.itemPrice}>$1,930.00</Text>
            <Text sx={classes.itemDescription}>
              I chose this sofa because it boasts a gracefully curved shape,
              luxurious upholstery, and a contemporary style that will enhance
              any room with an opulent and modern ambiance.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  'https://www.wayfair.com/furniture/pdp/joss-main-alpine-90-upholstered-sofa-stmh2303.html?piid=59670572',
                )
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture2}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited2 ? setFavorited2(false) : setFavorited2(true)
                }}
                sx={classes.favoritesContainer}
              >
                {favorited2 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>WALMART</Text>
            <Text sx={classes.itemPrice}>$1,399.99</Text>
            <Text sx={classes.itemDescription}>
              This curved sofa will give your living room contemporary seating
              and a modern flair. Its unique c-shape silhouette, with an
              asymmetrical back, adds a fluid and contemporary touch, making it
              a perfect addition to any living room, bedroom, or foyer.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  'https://www.walmart.com/ip/Williamspace-86-Modern-Curved-Sofa-Mid-Century-3-Seater-Fabric-Upholstered-Couch-for-Living-Room-Apartment-Grey/1507208285',
                )
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture3}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited3 ? setFavorited3(false) : setFavorited3(true)
                }}
                sx={classes.favoritesContainer}
              >
                {favorited3 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>TOV FURNITURE</Text>
            <Text sx={classes.itemPrice}>$1,499.00</Text>
            <Text sx={classes.itemDescription}>
              The Kendall sofa features a graceful curved silhouette, luxurious
              upholstery, and a modern design that will enhance any space with a
              luxurious and contemporary atmosphere. Its generous fiber-wrapped
              foam padding and sturdy pine frame ensure enduring comfort and
              support. You can choose from multiple colors to suit your
              preference.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  'https://tovfurniture.com/product/kendall-cream-velvet-sofa/',
                )
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture4}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited4 ? setFavorited4(false) : setFavorited4(true)
                }}
                sx={classes.favoritesContainer}
              >
                {favorited4 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>AMAZON</Text>
            <Text sx={classes.itemPrice}>$1,794.00</Text>
            <Text sx={classes.itemDescription}>
              This 4-seat curved sofa embodies elegance and simplicity. The
              refined design of this sofa creates a warm, cozy, and relaxing
              atmosphere with its comfortable, sinuous, and enveloping
              silhouette, while also adding a touch of romance and modernity to
              any residential or contract setting.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  'https://www.amazon.com/Acanva-Century-Modern-Bedroom-Apartment/dp/B09PMX6D6P?th=1',
                )
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>

          <Box sx={classes.itemContainer}>
            <Box sx={classes.imageContainer}>
              <CardMedia
                component="img"
                image={picture5}
                alt="Minimize icon"
                sx={classes.itemPicture}
              />
              <Box
                onClick={() => {
                  favorited5 ? setFavorited5(false) : setFavorited5(true)
                }}
                sx={classes.favoritesContainer}
              >
                {favorited5 ? (
                  <CardMedia
                    component="img"
                    image={selectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    image={unselectedHeart}
                    alt=""
                    sx={classes.favoriteIcon}
                  />
                )}
              </Box>
            </Box>
            <Text sx={classes.itemBrand}>WAYFAIR</Text>
            <Text sx={classes.itemPrice}>$1,499.99</Text>
            <Text sx={classes.itemDescription}>
              This stunning curved sofa&apos;s seating position offers an
              upright and supportive experience, complemented by firm
              cushioning. While it leans more towards a formal ambiance rather
              than a laid-back lounge feel, it certainly makes a bold statement
              with its captivating design.
            </Text>
            <Button
              variant="secondary"
              icon={externalLink}
              onClick={() => {
                window.open(
                  'https://www.wayfair.com/furniture/pdp/wade-logan-alaw-91-velvet-curved-sofa-w002982851.html?piid=303284450',
                )
              }}
              sx={classes.checkItOutButton}
            >
              Shop Now
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
