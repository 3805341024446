import React from 'react'
import { Grid, Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import ImageTextCard from '../../molecules/ImageTextCard/ImageTextCard'
import mixpanel from '../../atoms/Mixpanel/Mixpanel'
import { UserContext } from '../../../App'

const propTypes = {
  cardArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelKey: PropTypes.string,
      imageUrl: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
}

let customTheme = createTheme({})

function ImageTextGrid({ cardArray }) {
  const { user } = React.useContext(UserContext)
  const email = user?.email || 'N/A'

  let renderCards = cardArray.map((card, idx) => {
    return (
      <Grid
        key={card.labelKey + idx.toString()}
        item
        xs={6}
        sm={4}
        justifyContent="center"
      >
        <ImageTextCard
          label={card.label}
          labelKey={card.labelKey}
          imageUrl={card.imageUrl}
          onClick={() => {
            mixpanel.track('(CMS) Selected Room', {
              email: email,
            })
            card.onClick()
          }}
          disabled={card.disabled}
          source="Select Room"
        />
      </Grid>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        minWidth: '100%',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          maxWidth: '750px',
          [customTheme.breakpoints.down('md')]: {
            // maxWidth: "450px",
          },
        }}
      >
        {renderCards}
      </Grid>
    </Box>
  )
}

ImageTextGrid.propTypes = propTypes

export default ImageTextGrid
