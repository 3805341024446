import { customTheme } from '../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    maxWidth: '1440px',
    paddingBottom: '80px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },

  // example results section
  lookalikeFinderExamplesContainer: {},

  // input section
  sectionInputForm: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'row',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  textField: {
    backgroundColor: '#FFFFFF',
    borderRadius: 50,
    width: '400px',
    height: '48px',
    marginRight: '8px',
    boxSizing: 'border-box',
    '& .MuiFormLabel-root': {
      paddingLeft: '24px',
      marginTop: '-8px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '24px',
      paddingRight: '8px',
      marginTop: '8px',
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0,
      display: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    [customTheme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 16px)',
      marginRight: 0,
    },
  },
  searchButton: {
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '8px',
      width: '100%',
    },
  },

  // recommendations section
  sectionResults: {
    margin: '0 0 80px 0',
    padding: '32px',
    maxWidth: '880px',
    width: '100%',
    backgroundColor: '#F3F3F3',
    borderRadius: '16px',
    border: 'solid 4px #2A2A2A',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    [customTheme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  resultsTitle: {
    fontSize: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tipContainer: {
    border: 'solid 2px #BFBFBF',
    borderRadius: '8px',
    padding: '8px 16px',
    marginTop: '16px',
    display: 'none',
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  catalogContainer: {
    marginTop: '8px',
    width: '100%',
    // display: "grid",
    // gridGap: "24px 16px",
    // gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
  },
  noProductsText: {
    marginTop: '24px',
    color: '#D12626',
    fontWeight: 'bold',
  },
  similarResultsText: {
    marginTop: '24px',
    fontWeight: 'bold',
    fontSize: '24px',
  },

  // Product from URL information
  urlItemInfoContainer: {
    marginBottom: '24px',
    border: 'solid 4px #2a2a2a',
    borderRadius: '16px',
    padding: '32px',
    backgroundColor: '#FFE9AF',
    position: 'sticky',
    top: '102px',
    [customTheme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  urlItemHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  urlItemImage: {
    borderRadius: '16px',
    border: 'solid 8px #FFF',
    boxSizing: 'border-box',
    marginTop: '24px',
  },
  urlItemTitle: {
    fontSize: '24px',
    marginTop: '24px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  urlItemDimensions: {
    marginTop: '8px',
  },
  urlItemPrice: { marginTop: '8px', fontSize: '40px', fontWeight: 'bold' },

  // Results
  resultGridItem: {
    width: '100%',
  },
}
