import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'

// import components
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'
import { classes } from './CMSResultsOnboardingStyle'

export default function CMSResultsOnboarding() {
  const [showOnboarding, setShowOnboarding] = useState(() => {
    return localStorage.getItem('showCMSResultsOnboarding') !== null
      ? JSON.parse(localStorage.getItem('showCMSResultsOnboarding'))
      : true
  })

  // Update localStorage when showOnboarding changes
  useEffect(() => {
    localStorage.setItem(
      'showCMSResultsOnboarding',
      JSON.stringify(showOnboarding),
    )
  }, [showOnboarding])

  // Event handler for the button click
  const handleDoneClick = () => {
    setShowOnboarding(false)
  }

  return (
    showOnboarding && (
      <Box sx={classes.container}>
        <Box sx={classes.speechBubbleTriangle} />
        <Text sx={classes.title}>This design auto-saves</Text>
        <Text sx={classes.description}>
          If logged in, you can find this design in <b>My Designs</b>.
        </Text>
        <Button onClick={handleDoneClick} sx={classes.btn}>
          Done
        </Button>
      </Box>
    )
  )
}
