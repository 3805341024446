// import { customTheme } from "../../components/atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: '400px',
    width: '100%',
  },
  imgContainer: {
    maxWidth: '400px',
    width: '100%',
    maxHeight: '400px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px #F3F3F3',
    borderRadius: '16px',
    position: 'relative',
  },
  favoriteContainer: {
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(191, 191, 191, 0.5)',
    borderRadius: '20px',
    position: 'absolute',
    zIndex: 1,
    right: '16px',
    top: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(191, 191, 191, 0.4)',
    },
  },
  favoriteIcon: {
    width: '24px',
    height: '24px',
  },
  img: {
    maxWidth: '400px',
    width: '100%',
    maxHeight: '400px',
    borderRadius: '16px',
  },
  brand: {
    marginTop: '16px',
    color: '#757575',
    fontWeight: 'bold',
  },
  name: { fontSize: '20px', fontWeight: 500, marginTop: '8px' },
  price: {
    marginTop: '8px',
    fontSize: '32px',
    fontWeight: 'bold',
  },
  dimensions: {},
  button: {
    marginTop: '16px',
    fontWeight: 'bold',
    backgroundColor: '#FFE9AF',
  },
}
