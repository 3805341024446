// import React from "react";
import PropTypes from 'prop-types'
import { CrateAndBarrelService } from './CrateAndBarrelService'
import { PotteryBarnService } from './PotteryBarnService'
import { LuluAndGeorgia } from './LuluAndGeorgia'
import { WestElmService } from './WestElmService'
import { IkeaService } from './IkeaService'

const propTypes = {
  pageHTML: PropTypes.object,
  url: PropTypes.string,
}

const defaultProps = {
  pageHTML: null,
  url: 'null',
}

LookalikeFinderStoreSelector.propTypes = propTypes
LookalikeFinderStoreSelector.defaultProps = defaultProps

export default function LookalikeFinderStoreSelector(pageHTML, url) {
  if (
    url.includes('https://crateandbarrel') ||
    url.includes('www.crateandbarrel')
  ) {
    return CrateAndBarrelService(pageHTML)
  }
  if (url.includes('https://potterybarn') || url.includes('www.potterybarn')) {
    return PotteryBarnService(pageHTML)
  }
  if (
    url.includes('https://luluandgeorgia') ||
    url.includes('www.luluandgeorgia')
  ) {
    return LuluAndGeorgia(pageHTML)
  }
  if (url.includes('https://westelm') || url.includes('www.westelm')) {
    return WestElmService(pageHTML)
  }
  if (url.includes('https://ikea') || url.includes('www.ikea')) {
    return IkeaService(pageHTML)
  }

  console.log('No service (brand) found')
  return null
}
