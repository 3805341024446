import * as React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.string,
  fontWeight: PropTypes.string,
}

const defaultProps = {
  children: null,
  fontWeight: '400',
}

BlogParagraph.propTypes = propTypes
BlogParagraph.defaultProps = defaultProps

export default function BlogParagraph({ fontWeight, children }) {
  return (
    <Box
      sx={{
        marginTop: '40px',
        fontWeight: { fontWeight },
      }}
    >
      {children}
    </Box>
  )
}
