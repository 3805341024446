import React from 'react'
import { Box } from '@mui/material'

// import components
import { customTheme } from '../components/atoms/CustomTheme/CustomTheme'
import Text from '../components/atoms/Text/Text'
import PopularFurniture from '../components/organisms/LandingPopularFurniture/LandingPopularFurniture'
import BTSArmy from '../components/organisms/LandingBTSArmy/LandingBTSArmy'
import TrendingInstagram from '../components/organisms/LandingTrendingInstagram/LandingTrendingInstagram'
import CraftMyStyle from '../components/organisms/LandingCraftMyStyle/LandingCraftMyStyle'
import DarkAcademia from '../components/organisms/LandingDarkAcademia/LandingDarkAcademia'
import MeanGirls from '../components/organisms/LandingMeanGirls/LandingMeanGirls'
import WeirdAsF from '../components/organisms/LandingWeirdAsF/LandingWeirdAsF'
import CoolAsS from '../components/organisms/LandingCoolAsS/LandingCoolAsS'
import HarryPotter from '../components/organisms/LandingHarryPotter/LandingHarryPotter'
import Zodiac from '../components/organisms/LandingZodiac/LandingZodiac'

function Explore() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '80px',
        [customTheme.breakpoints.down('md')]: {
          marginTop: 0,
        },
      }}
    >
      <Text
        variant="h1"
        sx={{
          fontSize: '48px',
          marginTop: '80px',
          textAlign: 'center',
        }}
      >
        Explore & Discover
      </Text>

      {/* popular furniture section */}
      <PopularFurniture />

      {/* bts army section */}
      <BTSArmy />

      {/* trending on instagram section */}
      <TrendingInstagram />

      {/* craft my style section */}
      <CraftMyStyle />

      {/* dark academia section */}
      <DarkAcademia />

      {/* mean girls section */}
      <MeanGirls />

      {/* weird as f*** section */}
      <WeirdAsF />

      {/* cool as s*** section */}
      <CoolAsS />

      {/* harry potter houses section */}
      <HarryPotter />

      {/* zodiac section */}
      <Zodiac />
    </Box>
  )
}

export default Explore
