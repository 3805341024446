// RootLayout.js
import React from 'react'
import PropTypes from 'prop-types'
import { Analytics } from '@vercel/analytics/react'

export default function RootLayout({ children }) {
  return (
    <>
      {children}
      <Analytics />
    </>
  )
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
