import React from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
// import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types'

// import components
import { classes } from './HomeHeroSectionStyle'
import Text from '../../atoms/Text/Text'

// import icons
const backDecoration1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/homepage/home-hero-decoration-1.png'
const backDecoration2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/homepage/home-hero-decoration-2.png'
// const heroTextHighlight =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/homepage/hero-text-highlight.svg";
const sparkle =
  'https://craftle-static.s3.us-east-2.amazonaws.com/homepage/sparkle-1.svg'

export default function HomeHeroSection() {
  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={backDecoration1}
        alt=""
        sx={classes.backDecoration1}
      />
      <CardMedia
        component="img"
        image={backDecoration2}
        alt=""
        sx={classes.backDecoration2}
      />
      <Box sx={classes.h1Section}>
        <Text variant="h1" sx={classes.h1}>
          Hi 👋 I’m your AI designer,
          <br />
          here to craft your dream home!
        </Text>
        {/* <CardMedia
          component="img"
          image={heroTextHighlight}
          alt=""
          sx={classes.heroTextHighlight}
        /> */}
        <CardMedia
          component="img"
          image={sparkle}
          alt=""
          sx={classes.sparkle}
        />
      </Box>

      <Text sx={classes.subtitle}>
        Discover personalized furniture picks, room designs, and trends—crafted
        by AI for <span style={{ fontStyle: 'italic' }}>you</span>.
      </Text>
    </Box>
  )
}

HomeHeroSection.propTypes = {
  budgetMoodboardRef: PropTypes.object,
  lookalikeFinderRef: PropTypes.object,
}

HomeHeroSection.defaultProps = {
  budgetMoodboardRef: null,
  lookalikeFinderRef: null,
}
