import React from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import components
import Text from '../../components/atoms/Text/Text'
import OtherBlogPost from '../../components/molecules/OtherBlogPost/OtherBlogPost'

// import icons
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog18/blog-18-banner.jpg'
const otherBannerImages1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-banner.jpg'
const otherBannerImages2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-banner.jpg'
const otherBannerImages3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog3/blog-3-banner.jpg'
const otherBannerImages4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog4/blog-4-banner.jpg'
const otherBannerImages5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-banner.jpg'
const otherBannerImages6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog6/blog-6-banner.jpg'
const otherBannerImages7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-banner.jpg'
const otherBannerImages8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog8/blog-8-banner.jpg'
const otherBannerImages9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog9/blog-9-banner.jpg'
const otherBannerImages10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog10/blog-10-banner.jpg'
const otherBannerImages11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog11/blog-11-banner.jpg'
const otherBannerImages12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog12/blog-12-banner.jpg'
const otherBannerImages17 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog17/blog-17-banner.jpg'

let customTheme = createTheme({})

function Blog() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingBottom: '80px',
      }}
    >
      <Box sx={{ maxWidth: '1137px', width: '100%' }}>
        <Text
          variant="h1"
          sx={{
            fontSize: '48px',
            marginTop: '120px',
            fontWeight: 'bold',
            marginBottom: '32px',
            [customTheme.breakpoints.down('md')]: {
              fontSize: '40px',
            },
          }}
        >
          Blog
        </Text>

        {/* banner blog post */}
        <Link
          to="/blog/best-interior-design-apps"
          style={{ textDecoration: 'none' }}
        >
          <Grid
            container
            spacing={{ sm: 2, md: 3, lg: 5 }}
            sx={{
              marginTop: '32px',
              marginBottom: '100px',
              [customTheme.breakpoints.down('md')]: {
                marginTop: 0,
                marginBottom: '40px',
              },
            }}
          >
            <Grid item md={7}>
              <CardMedia
                component="img"
                image={bannerImage}
                alt=""
                sx={{
                  borderRadius: '10px',
                  border: 'solid 2px #757575',
                  boxSizing: 'border-box',
                }}
              />
            </Grid>
            <Grid item md={5}>
              <Box
                sx={{
                  color: '#757575',
                  fontSize: '14px',
                  fontWeight: 500,
                  [customTheme.breakpoints.down('md')]: {
                    marginTop: '16px',
                  },
                }}
              >
                Nov 27, 2022
              </Box>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '50px',
                  marginTop: '16px',
                  fontWeight: 'bold',
                  lineHeight: '60px',
                  color: '#2a2a2a',
                  [customTheme.breakpoints.down('md')]: {
                    textAlign: 'initial',
                    fontSize: '32px',
                    lineHeight: 1.5,
                  },
                }}
              >
                Best Interior Design Apps of 2023
              </Typography>
              <Box
                sx={{
                  fontSize: '16px',
                  fontWeight: '300px',
                  lineHeight: 1.5,
                  color: '#2a2a2a',
                  textAlign: 'left',
                  marginTop: '16px',
                }}
              >
                From apps that help you find the right furniture to apps that
                help you design your floor plan, we’ll help you find the best
                interior design apps for you.
              </Box>
            </Grid>
          </Grid>
        </Link>

        <Grid container spacing={5}>
          <OtherBlogPost
            link="/blog/baby-and-kids-furniture"
            bannerImage={otherBannerImages1}
            date="APRIL 19, 2022"
            title="Baby & Kids' Furniture: 5 Best Places to Buy"
            snippet="Baby & kids' furniture isn’t always easy to find out there.
            You want to find furniture that’s fun and exciting for kids but
            also safely built and affordable to buy. And as a busy parent,
            it’s harder than ever to find the time to do the research..."
          />
          <OtherBlogPost
            link="/blog/britney-spears-tiny-furniture"
            bannerImage={otherBannerImages2}
            date="May 4, 2022"
            title="Britney Spears’ Tiny Furniture: Girl, Is This Absolutely
            Amazing or an Accident?"
            snippet="Britney Spears’ tiny furniture may be small, but it’s gotten
            our attention in a big way. She’s done it again. She’s grabbed
            our attention with the seemingly bizarre but also oddly
            satisfying topic of teeny, tiny furniture."
          />
          <OtherBlogPost
            link="/blog/interior-design-vs-interior-architect"
            bannerImage={otherBannerImages3}
            date="May 6, 2022"
            title="Interior Design vs Interior Architect: What's the Difference"
            snippet="This article will explain the key differences between interior design and interior architecture. It will also discuss how they are two different disciplines within the world of interior design."
          />
          <OtherBlogPost
            link="/blog/how-is-furniture-measured"
            bannerImage={otherBannerImages4}
            date="May 8, 2022"
            title="How is Furniture Measured? Everything You Need to Know Easily Explained"
            snippet="Learn how to measure furniture for your home. Whether it's a sofa, table, or chair you're looking for, we'll help you find the perfect fit."
          />
          <OtherBlogPost
            link="/blog/tip-furniture-delivery-guys"
            bannerImage={otherBannerImages5}
            date="May 10, 2022"
            title="Tip Furniture Delivery Guys How Much? Avoid Embarrassment With This Guide"
            snippet="Unsure of how much to tip furniture delivery guys? Here's a guide that can help make the process easier for you."
          />
          <OtherBlogPost
            link="/blog/depreciation-life-of-furniture"
            bannerImage={otherBannerImages6}
            date="May 12, 2022"
            title="Depreciation Life of Furniture: How Long Does It Keep Its Value?"
            snippet="The depreciation life of furniture is a topic that has been hotly debated among business owners and economists alike. This article explores the various factors that contribute to how long furniture retains its value."
          />
          <OtherBlogPost
            link="/blog/furniture-mod-for-minecraft"
            bannerImage={otherBannerImages7}
            date="May 14, 2022"
            title="Furniture Mod for Minecraft: 15 Best Mods to Make Your World Look Wicked Amazing"
            snippet="Looking to make your Minecraft world stand out from the rest? Look no further than these 15 furniture mods, each of which offers unique and innovative ways to customize your game."
          />
          <OtherBlogPost
            link="/blog/dates-for-high-point-furniture-market"
            bannerImage={otherBannerImages8}
            date="May 16, 2022"
            title="Dates for High Point Furniture Market This Year"
            snippet="Wondering when the next High Point furniture market is? Wonder no more! We've got all the details on this year's event, straight from the source."
          />
          <OtherBlogPost
            link="/blog/how-long-can-head-lice-live-on-furniture"
            bannerImage={otherBannerImages9}
            date="May 18, 2022"
            title="How Long Can Head Lice Live on Furniture? Separating New Facts from Old Fiction"
            snippet="Learn everything you need to know about head lice, including how long they can live on furniture and what to do if they get on you or your belongings."
          />
          <OtherBlogPost
            link="/blog/fixer-upper-do-they-keep-furniture"
            bannerImage={otherBannerImages10}
            date="May 20, 2022"
            title="Fixer Upper: Do They Keep Furniture or Do They Give It Back?"
            snippet="Homeowners on the popular TV show Fixer Upper receive a stunning makeover of their home, but what happens to all the furniture used to stage the house? Find out in this article!"
          />
          <OtherBlogPost
            link="/blog/interior-design-for-minecraft"
            bannerImage={otherBannerImages11}
            date="May 26, 2022"
            title="Interior Design for Minecraft: 19 Ideas to Make Your Friends Jealous"
            snippet="Find out how you can make your friends green with envy by following our comprehensive guide on interior design for Minecraft. From humble abodes to palaces fit for a king, we've got 203 ideas that will inspire and delight!"
          />
          <OtherBlogPost
            link="/blog/interior-design-types-of-styles"
            bannerImage={otherBannerImages12}
            date="June 10, 2022"
            title="Interior Design Types of Styles"
            snippet="When it comes to interior design, there are many different styles that you can choose from. Each style has its own unique characteristics and can be used to create a specific look in your home. Here is a look at some of the most popular interior design styles, and what they entail."
          />
          <OtherBlogPost
            link="/blog/transitional-interior-design-style"
            bannerImage={otherBannerImages17}
            date="June 20, 2022"
            title="Transitional Interior Design Style • Definition, Tips, and Helpful Guide"
            snippet="In this blog post, we define the transitional interior design style and give you some tips if you decide to use this style for your home."
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default Blog
