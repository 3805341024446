import React from 'react'
import { Box } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import CardMedia from '@mui/material/CardMedia'

mixpanel.init('d60542dff5aaf889760e7cc2f6316298', { debug: false })

// import components
import Text from '../../components/atoms/Text/Text'
import { LookalikeContext } from './LookalikeFinder'

// import images
const loader =
  'https://craftle-static.s3.us-east-2.amazonaws.com/animations/loader-dual-ring.gif'

export default function LookalikeFinderLoading() {
  const { loaderDisplay } = React.useContext(LookalikeContext)

  let classes = {
    loadingSection: {
      display: loaderDisplay,
      marginTop: '24px',
      marginBottom: '80px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loaderIcon: {
      width: '65px',
      height: '65px',
    },
  }

  return (
    <Box sx={classes.loadingSection}>
      <CardMedia
        component="img"
        image={loader}
        alt=""
        sx={classes.loaderIcon}
      />
      <Text>
        We&apos;re searching for your lookalike products...this might take a few
        minutes!
      </Text>
    </Box>
  )
}
