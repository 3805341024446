import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  // Desktop styles
  container: {
    position: 'fixed',
    bottom: '24px',
    backgroundColor: '#FFFFFF',
    padding: '16px 24px',
    zIndex: 2,
    borderRadius: '100px',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  itemCommandsFilled: {
    backgroundColor: '#2A2A2A',
    padding: '16px',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    transitionDuration: '0.4s',
    '&:hover': {
      backgroundColor: 'rgba(42, 42, 42, 0.85)',
    },
  },
  viewItemText: {
    marginLeft: '8px',
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  itemCommandsOutlined: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#757575',
    padding: '16px',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '16px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 1)',
    },
  },
  swapText: {
    marginLeft: '8px',
    fontWeight: 'bold',
  },
  deleteContainer: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#D12626',
    padding: '16px',
    borderRadius: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '16px',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'rgba(255, 241, 241, 1)',
    },
  },
  trashIcon: {
    width: '24px',
  },
  deleteText: {
    marginLeft: '8px',
    fontWeight: 'bold',
    color: '#D12626',
  },
  closeContainer: {
    marginLeft: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 0.5)',
    },
  },
  closeIcon: {
    width: '24px',
  },

  // Mobile styles
  mobileContainer: {
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
  },
  mobileOriginalCommands: {
    marginTop: '-48px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: '8px 16px',
    gap: '16px',
    borderRadius: '20px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
    width: 'fit-content',
  },
  moreIcon: {
    width: '24px',
  },
  mobileOtherCommands: {
    transition: 'bottom 0.5s ease',
    display: 'none',
    [customTheme.breakpoints.down('md')]: {
      display: 'flex',
      position: 'fixed',
      left: 0,
      zIndex: 1001,
      padding: '16px 16px 32px',
      backgroundColor: '#FFF',
      width: '100vw',
      flexDirection: 'column',
      boxSizing: 'border-box',
      borderRadius: '24px 24px 0 0',
      boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
    },
  },
  mobileCloseContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '24px',
    cursor: 'pointer',
    width: 'fit-content',
  },
}
