import axios from 'axios'

// import components
import { EndPoint } from '../../atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://enq4igph18.execute-api.us-east-2.amazonaws.com/default/craftle-acknowledgments-call',
  test: 'https://enq4igph18.execute-api.us-east-2.amazonaws.com/default/craftle-acknowledgments-call',
  local: 'http://127.0.0.1:8080/acknowledgments',
}

export default async function UpdateAcknowledgmentBackendCall(
  userId,
  messageId,
  acknowledged,
) {
  try {
    const response = await axios.get(END_POINTS[EndPoint], {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        purpose: 'update_acknowledgment',
        user_id: userId,
        message_id: messageId,
        acknowledged: acknowledged,
      },
    })

    return response.data
  } catch (e) {
    console.error('Error fetching acknowledgment:', e.message)
    return null
  }
}
