import React from 'react'
import { Grid, Divider, Container } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'

const propTypes = {
  pageTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  children: PropTypes.node,
}

const defaultProps = {
  body: null,
  children: null,
}

let customTheme = createTheme({})

function DesignQuestions({ pageTitle, title, body, children }) {
  return (
    <ThemeProvider theme={customTheme}>
      <Container>
        <Grid container columnSpacing={8}>
          {/** 12 is the total */}
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#FFF',
              [customTheme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: 'inherit',
                marginTop: '160px',
              },
              [customTheme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                height: 'inherit',
                marginTop: '40px',
                textAlign: 'inherit',
                padding: '8px 0',
              },
            }}
          >
            <Text
              variant="h1"
              sx={{
                paddingBottom: '10px',
                color: '#757575',
                fontSize: '16px',
                letterSpacing: '3px',
                fontWeight: 400,
                [customTheme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              }}
            >
              {pageTitle}
            </Text>
            <Text
              variant="h2"
              sx={{
                fontSize: '48px',
                fontWeight: 400,
                lineHeight: 1.2,
                [customTheme.breakpoints.down('md')]: {
                  fontSize: '30px',
                },
                [customTheme.breakpoints.down('sm')]: {
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'inherit',
                },
              }}
            >
              {title}
            </Text>
            <Text
              variant="body"
              sx={{
                paddingTop: '10px',
                [customTheme.breakpoints.down('md')]: {
                  textAlign: 'center',
                },
                [customTheme.breakpoints.down('sm')]: {
                  textAlign: 'inherit',
                },
              }}
            >
              {body}
            </Text>
            <Divider
              sx={{
                width: '30%',
                height: '1px',
                backgroundColor: '#2a2a2a',
                marginTop: '30px',
                [customTheme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

DesignQuestions.propTypes = propTypes
DesignQuestions.defaultProps = defaultProps

export default DesignQuestions
