// import React, { useState } from "react";
import React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { createTheme } from '@mui/material/styles'

// import components
import Text from '../../atoms/Text/Text'

let customTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1536,
      lg: 1280,
      md: 900,
      sm: 600,
      xs: 425,
      zero: 0,
    },
  },
})

const propTypes = {
  peopleAlsoAsk: PropTypes.array,
  upperCategory: PropTypes.bool,
}

const defaultProps = {
  peopleAlsoAsk: null,
  upperCategory: false,
}

PeopleAlsoAsk.propTypes = propTypes
PeopleAlsoAsk.defaultProps = defaultProps

function PeopleAlsoAsk({ peopleAlsoAsk, upperCategory }) {
  let classes = {
    container: {
      backgroundColor: '#FFE9AF',
      border: 'solid 4px #2A2A2A',
      borderRadius: '16px',
      padding: '32px',
      boxSizing: 'border-box',
      maxWidth: '416px',
      [customTheme.breakpoints.down('lg')]: {
        maxWidth: '100%',
      },
      [customTheme.breakpoints.down('md')]: {
        marginTop: '32px',
      },
      [customTheme.breakpoints.down('sm')]: {
        padding: '32px 16px',
      },
    },
    title: {
      fontSize: '32px',
      [customTheme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    qAndASection: {
      marginTop: '32px',
    },
    qAndA: {
      marginTop: '24px',
    },
    question: {
      fontSize: '20px',
      [customTheme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    answer: { marginTop: '16px', whiteSpace: 'pre-line' },
  }

  if (upperCategory === true) {
    classes.container.maxWidth = 'inherit'
  }

  return (
    <Box sx={classes.container}>
      <Text variant="h3" sx={classes.title}>
        People also ask
      </Text>
      <Box sx={classes.qAndASection}>
        {peopleAlsoAsk.map((index) => (
          <Box key={index} sx={classes.qAndA}>
            <Text variant="h4" sx={classes.question}>
              {index.question}
            </Text>
            <Text sx={classes.answer}>{index.answer}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PeopleAlsoAsk
