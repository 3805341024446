import React from 'react'
import { Grid, Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import ImageTextCard from '../../molecules/ImageTextCard/ImageTextCard'

const propTypes = {
  cardArray: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelKey: PropTypes.string,
      imageUrl: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
    }),
  ).isRequired,
  selectedKeys: PropTypes.object,
}

let customTheme = createTheme({})

function ImageTextGridCheckBox({ cardArray, selectedKeys }) {
  let renderCards = cardArray.map((card, idx) => {
    return (
      <Grid key={card.labelKey + idx.toString()} item xs={6} sm={4}>
        <ImageTextCard
          label={card.label}
          labelKey={card.labelKey}
          imageUrl={card.imageUrl}
          onClick={card.onClick}
          disabled={card.disabled}
          selected={selectedKeys[card.labelKey]}
        />
      </Grid>
    )
  })

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
        minWidth: '100%',
      }}
    >
      <Grid
        container
        sx={{
          maxWidth: '750px',
          [customTheme.breakpoints.down('md')]: {
            // maxWidth: "450px",
          },
        }}
      >
        {renderCards}
      </Grid>
    </Box>
  )
}

ImageTextGridCheckBox.propTypes = propTypes

export default ImageTextGridCheckBox
