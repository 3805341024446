import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'

// import components
import Text from '../../../components/atoms/Text/Text'
import { classes } from './CreateCustomizedPlanMoodBoardStyle'

// import icons
import { ReactComponent as ExternalLinkWhiteIcon } from '../../../assets/icons/external-link-white.svg'

// import images
const LandingTinderImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/LandingTinderImage.png'

function CreateCustomizedPlanMoodBoard() {
  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={LandingTinderImage}
        alt=""
        sx={classes.cardMediaStyle}
      />
      <Text sx={classes.personalizedPlanTextStyle}>
        Want a more personalized plan?
      </Text>
      <Box sx={classes.buttonContainer}>
        <Box sx={classes.iconContainer}>
          <ExternalLinkWhiteIcon />
        </Box>
        Create Curated Moodboard
      </Box>
    </Box>
  )
}

export default CreateCustomizedPlanMoodBoard
