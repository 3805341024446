import * as React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'

let customTheme = createTheme({})

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const defaultProps = {
  title: null,
  description: null,
}

BlogAppFAQSubsection.propTypes = propTypes
BlogAppFAQSubsection.defaultProps = defaultProps

export default function BlogAppFAQSubsection({ title, description }) {
  const classes = {
    faqSubtitle: {
      marginTop: '24px',
      fontSize: '20px',
    },
    faqDescription: {
      marginTop: '16px',
    },
  }
  return (
    <ThemeProvider theme={customTheme}>
      <Text variant="h3" sx={classes.faqSubtitle}>
        {title}
      </Text>
      <Text sx={classes.faqDescription}>{description}</Text>
    </ThemeProvider>
  )
}
