import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    backgroundColor: '#2a2a2a',
    padding: '50px 5vw 30px 5vw',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    [customTheme.breakpoints.down('md')]: {},
  },
  gridContainer: {
    maxWidth: '1000px',
  },
  column1Style: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoStyle: {
    height: '28px',
    width: '24.6px',
  },
  dreamHomeText: {
    marginLeft: '10px',
    lineHeight: 1.2,
  },
  legalSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '24px',
  },
  linkStyle: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  linkSeparator: {
    marginTop: '16px',
  },
  dotSeparator: {
    color: '#FFFFFF',
  },
  copyrightText: {
    color: '#FFFFFF',
    marginTop: '8px',
  },
  column2Style: {
    display: 'flex',
    flexDirection: 'column',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '32px',
    },
  },
  sectionTitle: {
    color: '#FFFFFF',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  column3Style: {
    display: 'flex',
    flexDirection: 'column',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '32px',
    },
  },
  affiliateContainer: {
    maxWidth: '1000px',
    width: '100%',
    marginTop: '40px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  affiliateText: {
    color: '#757575',
  },
}
