// import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

export const classes = {
  container: {
    position: 'absolute',
  },
  furnitureClickCommandsContainer: {
    position: 'absolute',
    zIndex: 1001,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  furnitureItemContainer: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  resizeButton: {
    width: '16px',
    height: '16px',
    position: 'absolute',
    backgroundColor: '#FFF',
    bottom: '-8px',
    right: '-8px',
    cursor: 'nwse-resize',
    border: '1px solid #49B900',
    borderRadius: '8px',
    transitionDuration: '0.2s',
    zIndex: 1001,
    '&:hover': {
      backgroundColor: '#49B900',
    },
  },
}
