import React, { useState, memo } from 'react'
import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
import { AnalyticsBrowser } from '@segment/analytics-next'
import CircularProgress from '@mui/material/CircularProgress'
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'
import { classes } from './ModalSignUpStyle.js'
import { customTheme } from '../../atoms/CustomTheme/CustomTheme'
import SignUpCallToBackend from '../../../pages/profileRelated/SignUpCallToBackend'
import Textfield from '../../molecules/Textfield/Textfield'
import { segmentWriteKey } from '../../atoms/Segment/Segment'

const closeIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg'

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

const ModalSignUp = memo(function ModalSignUp({
  displaySignupModal,
  redirectHref,
  userPreferences,
  onClose,
  step,
  setStep,
  setUser,
}) {
  const [showEmailError, setShowEmailError] = useState(false)
  const [showPasswordError, setShowPasswordError] = useState(false)
  const [generalError, setGeneralError] = useState(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down('sm'),
  )

  const handleClose = () => {
    onClose()
  }

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('user', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setShowEmailError(false)
    setShowPasswordError(false)
    setGeneralError(null)

    const userInfo = {
      email,
      password,
    }

    if (!userInfo.email) {
      setShowEmailError(true)
    }
    if (!userInfo.password) {
      setShowPasswordError(true)
    }

    if (userInfo.email && userInfo.password) {
      setIsLoading(true) // Start loading

      try {
        const response = await SignUpCallToBackend(userInfo, userPreferences)
        if (response) {
          analytics.track('Account Created', {
            user_email: userInfo.email,
            user_name: response.first_name || '',
          })
          await saveUserToken(response)

          const signupComplete = response.signup_complete || false

          if (signupComplete) {
            setGeneralError(
              <>
                It looks like there’s already an account with this email. If
                it’s yours, please{' '}
                <a
                  href="/log-in"
                  style={{ color: '#007BFF', textDecoration: 'underline' }}
                >
                  log in
                </a>
                .
              </>,
            )
          } else if (step) {
            // If user is currently in the early step of the signup flow, continue the flow.
            setUser(response)
            setStep(step + 1)
            handleClose()
          } else {
            document.location.href = '/sign-up'
          }
        }
      } catch (error) {
        setGeneralError('An error occurred during sign up. Please try again.')
      } finally {
        setIsLoading(false) // End loading
      }
    }
  }

  if (!displaySignupModal) {
    return null
  }

  return (
    <Box
      sx={
        isMobileOrTabletScreen ? classes.mobileBackground : classes.background
      }
    >
      <Box
        sx={
          isMobileOrTabletScreen
            ? classes.mobileModalContainer
            : classes.modalContainer
        }
      >
        <Box
          onClick={handleClose}
          sx={
            isMobileOrTabletScreen
              ? classes.mobileIconContainer
              : classes.iconContainer
          }
        >
          <CardMedia component="img" image={closeIcon} alt="Close" />
        </Box>
        <Text variant="h2" sx={classes.prompt}>
          Sign up
        </Text>

        <Box
          sx={
            isMobileOrTabletScreen
              ? classes.sectionInputForm
              : classes.actionsContainer
          }
        >
          <form onSubmit={handleSubmit} style={classes.sectionInputForm}>
            <Textfield
              label="(required) Email"
              type="email"
              variant="standard"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder="name@example.com"
              sx={classes.textField}
              disabled={isLoading}
            />
            <Textfield
              label="(required) Password"
              type="password"
              variant="standard"
              id="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              sx={classes.textField}
              disabled={isLoading}
            />
            {showEmailError && (
              <Text sx={classes.errorMsg}>Your email is required.</Text>
            )}
            {showPasswordError && (
              <Text sx={classes.errorMsg}>Your password is required.</Text>
            )}
            {generalError && <Text sx={classes.errorMsg}>{generalError}</Text>}
            <Button type="submit" sx={classes.signUpBtn} disabled={isLoading}>
              Sign Up
            </Button>
          </form>
        </Box>

        {isLoading && (
          <Box sx={classes.loadingOverlay}>
            <CircularProgress />
            <Text sx={classes.loadingText}>Signing up...</Text>
          </Box>
        )}
      </Box>
    </Box>
  )
})

ModalSignUp.propTypes = {
  displaySignupModal: PropTypes.bool,
  redirectHref: PropTypes.string,
  onClose: PropTypes.func,
  userPreferences: PropTypes.object,
  step: PropTypes.number,
  setStep: PropTypes.func,
  setUser: PropTypes.func,
}

ModalSignUp.defaultProps = {
  displaySignupModal: false,
  redirectHref: '/',
  onClose: () => {},
  userPreferences: null,
  step: null,
  setStep: () => {}, // Default to a no-op function
  setUser: () => {},
}

export default ModalSignUp
