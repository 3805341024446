import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    maxWidth: '1264px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginTop: '160px',
    [customTheme.breakpoints.down('md')]: {
      padding: '0 8px',
      marginTop: '80px',
    },
  },
  hashtag: {
    padding: '8px 16px',
    backgroundColor: '#DAEBCF',
    borderRadius: '100px',
    fontWeight: 'bold',
    width: 'fit-content',
  },
  title: {
    marginTop: '16px',
    fontSize: '40px',
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  },
  subTitle: {
    marginTop: '16px',
    textAlign: 'center',
  },
  categorySection: {
    marginTop: '16px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  seeAllProductsLink: {
    marginTop: '8px',
  },
}
