import React, { useState } from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import PropTypes from 'prop-types'

// import components
import Button from '../../atoms/Button/Button'
import Text from '../../atoms/Text/Text'
import { classes } from './DropdownStyle'

// Import icons
const triangle =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/dropdown-triangle.svg'

const propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  width: PropTypes.string,
  onClickAction: PropTypes.func,
  footerText: PropTypes.string,
}

const defaultProps = {
  label: '',
  options: [],
  width: '',
  onClickAction: null,
  footerText: '',
}

Dropdown.propTypes = propTypes
Dropdown.defaultProps = defaultProps

export default function Dropdown({
  label,
  options,
  width,
  onClickAction,
  footerText,
}) {
  const [showMenu, setShowMenu] = useState(false)
  const [selectedOption, setSelectedOption] = useState(options[0].text)

  const handleOnClick = () => {
    // Toggle the current dropdown and set the dropdownOpened
    if (!showMenu) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  const handleOnMouseEnter = () => {
    if (!showMenu) {
      setShowMenu(true)
    }
  }

  const handleOnMouseLeave = () => {
    setShowMenu(false)
  }

  return (
    <Box className="dropdown-container" sx={classes.container}>
      {/* Label */}
      <Text sx={classes.label}>{label}</Text>

      {/* Button and dropdown */}
      <Box onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        <Box
          onClick={handleOnClick}
          sx={[classes.dropdownBtn, { width: width }]}
        >
          <Text sx={classes.selectedItem}>{selectedOption}</Text>
          <CardMedia
            component="img"
            image={triangle}
            alt=""
            sx={classes.triangle}
          />
        </Box>
        {showMenu && (
          <Box
            className="dropdown-content"
            sx={[classes.dropdownContent, { width: width }]}
          >
            {options.map((option, optionKey) => (
              <Button
                key={optionKey}
                variant="fourth"
                onClick={() => {
                  onClickAction(option)
                  setSelectedOption(option.text)
                  setShowMenu(false)
                }}
                sx={classes.dropdownItem}
              >
                {option.text}
              </Button>
            ))}
            {footerText ? (
              <Text sx={classes.footerText}>{footerText}</Text>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  )
}
