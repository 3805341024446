import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import componenets
// import { customTheme } from "../../atoms/CustomTheme/CustomTheme";

// import icons
const chevronLeft =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/chevron-left.svg'
const chevronRight =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/chevron-right.svg'

const propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
}

const defaultProps = {
  children: null,
  width: '',
}

CarouselItem.propTypes = propTypes
CarouselItem.defaultProps = defaultProps

export function CarouselItem({ children, width }) {
  let classes = {
    carouselItem: {
      display: 'inline-block',
    },
  }
  return (
    <Box sx={classes.carouselItem} style={{ width: width }}>
      {children}
    </Box>
  )
}

const propTypes2 = {
  children: PropTypes.node,
}

const defaultProps2 = {
  children: null,
}

Carousel.propTypes = propTypes2
Carousel.defaultProps = defaultProps2

export default function Carousel({ children }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [leftButtonOpacity, setLeftButtonOpacity] = useState('25%')
  const [rightButtonOpacity, setRightButtonOpacity] = useState('25%')

  let classes = {
    carousel: {
      overflow: 'hidden',
    },
    outline: {
      position: 'relative',
      boxSizing: 'border-box',
      borderRadius: '16px',
      backgroundColor: '#FFFFFF',
    },
    inner: {
      whiteSpace: 'nowrap',
      transition: 'transform 0.3s',
      transform: `translateX(-${activeIndex * 100}%)`,
    },
    arrowButtonLeft: {
      margin: 'auto',
      position: 'absolute',
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(255,255,255,0.15)',
      border: 0,
      height: '60%',
      borderRadius: '16px',
      width: '64px',
      opacity: leftButtonOpacity,
      cursor: 'pointer',
    },
    'arrowButtonLeft:hover': {
      opacity: '100%',
    },
    arrowButtonRight: {
      margin: 'auto',
      position: 'absolute',
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(255,255,255,0.15)',
      border: 0,
      height: '60%',
      borderRadius: '16px',
      width: '64px',
      opacity: rightButtonOpacity,
      cursor: 'pointer',
      right: 0,
    },
    'arrowButtonRight:hover': {
      opacity: '100%',
    },
    indicators: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
    },
    button: {
      margin: '5px',
    },
    carouselItemContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
    },
    carouselItem: {
      width: '8px',
      height: '8px',
      borderRadius: '4px',
      backgroundColor: '#BFBFBF',
      cursor: 'pointer',
    },
    active: {
      backgroundColor: '#2A2A2A',
    },
  }

  function updateIndex(newIndex) {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0
    }

    setActiveIndex(newIndex)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  })

  return (
    <Box {...handlers} sx={classes.carousel}>
      <Box sx={classes.outline}>
        <Box sx={classes.inner}>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, { width: '100%' })
          })}
        </Box>
        <button
          onClick={() => {
            updateIndex(activeIndex - 1)
          }}
          onMouseEnter={() => setLeftButtonOpacity('100%')}
          onMouseLeave={() => setLeftButtonOpacity('25%')}
          style={classes.arrowButtonLeft}
        >
          <CardMedia component="img" image={chevronLeft} alt="Product image" />
        </button>
        <button
          onClick={() => {
            updateIndex(activeIndex + 1)
          }}
          onMouseEnter={() => setRightButtonOpacity('100%')}
          onMouseLeave={() => setRightButtonOpacity('25%')}
          style={classes.arrowButtonRight}
        >
          <CardMedia component="img" image={chevronRight} alt="" />
        </button>
      </Box>

      <Box sx={classes.indicators}>
        <Box sx={classes.carouselItemContainer}>
          {React.Children.map(children, (child, index) => {
            return (
              <Box
                onClick={() => {
                  updateIndex(index)
                }}
                sx={[
                  classes.carouselItem,
                  index == activeIndex ? classes.active : null,
                ]}
              />
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
