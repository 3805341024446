import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CardMedia from '@mui/material/CardMedia'

// import components
import LandingFurniture from '../../molecules/LandingFurniture/LandingFurniture'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import images
const TrendingInstaIMG1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-1.jpeg'
const TrendingInstaIMG2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-2.jpeg'
const TrendingInstaIMG3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-3.jpeg'
const TrendingInstaIMG4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-4.jpeg'
const TrendingInstaIMG5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-5.jpeg'
const TrendingInstaIMG6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/trending-instagram-6.jpeg'

// import icons
const ArrowRightIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-right-white.svg'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingTrendingInstagram() {
  const ref = useRef(null)

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: '90vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '48px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#2a2a2a',
            fontSize: '32px',
            fontWeight: 'bold',
          }}
        >
          📸 Trending on Instagram
        </Typography>

        <Typography
          sx={{
            color: '#2a2a2a',
            fontSize: '16px',
            marginTop: '16px',
          }}
        >
          The aesthetics are to die for! Instagrammers love taking pictures with
          these furniture pieces.
        </Typography>

        <ScrollButtonLeft scrollRef={ref} top="54%" />
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
            width: '95vw',
            marginLeft: '-5vw',
            overflowX: 'scroll',
            paddingLeft: '5vw',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box
            sx={{
              marginRight: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '240px',
              background: '-webkit-linear-gradient(225deg, #D04E64, #F58E39)',
              borderRadius: '16px',
              padding: '24px',
              [customTheme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                width: '200px',
                height: '100%',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '32px',
                lineHeight: 1.2,
                color: '#FFFFFF',
                fontWeight: 'bold',
                width: '192px',
                marginBottom: '8px',
              }}
            >
              Sustainable Rattan Material
            </Typography>
            <CardMedia
              component="img"
              image={ArrowRightIcon}
              alt="Left arrow button"
              sx={{ width: '72px', height: '72px' }}
            />
          </Box>
          <LandingFurniture
            image={TrendingInstaIMG1}
            brand="Urban Outfitters"
            type="Vanity"
            price="389"
            url="https://www.urbanoutfitters.com/shop/caroline-rattan-vanity?color=111&type=REGULAR&size=ONE%20SIZE&quantity=1&utm_medium=affiliates&utm_source=LS&utm_campaign=CNN&utm_term=1005739&utm_content=1&ranMID=43176&ranEAID=Fr%2F49%2F7rhGg&ranSiteID=Fr_49_7rhGg-ABMj9BxD6S716NNvvQ5s8A"
            path="/furniture/urban-outfitters/caroline-rattan-vanity"
          />
          <LandingFurniture
            image={TrendingInstaIMG2}
            brand="Serena & Lily"
            type="Accent Chair"
            price="548"
            url="https://www.serenaandlily.com/hanging-rattan-chair/m10499.html?utm_campaign=pjgeneric&publisherId=120661&clickId=3993852426&utm_source=pj&utm_medium=affiliate"
            path="/furniture/serena-and-lily/hanging-rattan-chair"
          />
          <LandingFurniture
            image={TrendingInstaIMG3}
            brand="Lulu and Georgia"
            type="Pendant Light"
            price="325"
            url="https://www.luluandgeorgia.com/products/tarek-pendant-light?utm_source=21181&utm_medium=affiliate&utm_content=8-11565&utm_campaign=product&click=3993853215&clickId=3993853215&ar_clx=yes&ar_channel=pepperjam&ar_campaign=PepperJam&ar_adgroup=PepperJam&ar_ad=PepperJam&source=pepperjam&publisher=21181"
            path="/furniture/lulu-and-georgia/tarek-pendant-light"
          />
          <Box
            sx={{
              marginRight: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: '-webkit-linear-gradient(225deg, #4968D6, #D82E7D)',
              borderRadius: '16px',
              padding: '24px',
              [customTheme.breakpoints.down('sm')]: {
                alignItems: 'flex-start',
                width: '200px',
                height: '200px',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '32px',
                lineHeight: 1.2,
                color: '#FFFFFF',
                fontWeight: 'bold',
                width: '192px',
                marginBottom: '8px',
              }}
            >
              Curves for Days
            </Typography>
            <CardMedia
              component="img"
              image={ArrowRightIcon}
              alt="Left arrow button"
              sx={{ width: '72px', height: '72px' }}
            />
          </Box>
          <LandingFurniture
            image={TrendingInstaIMG4}
            brand="CB2"
            type="Accent Chair"
            price="999"
            url="https://www.cb2.com/gwyneth-ivory-boucle-chair/s265795"
            path="/furniture/cb2/gwyneth-ivory-boucle-chair"
          />
          <LandingFurniture
            image={TrendingInstaIMG5}
            brand="World Market"
            type="Accent Chair"
            price="849.99"
            url="https://www.worldmarket.com/product/faux-sheepskin-ilana-upholstered-chair.do"
            path="/furniture/world-market/faux-sheepskin-ilana-upholstered-chair"
          />
          <LandingFurniture
            image={TrendingInstaIMG6}
            brand="AllModern"
            type="Loveseat"
            price="560"
            url="https://www.wayfair.com/furniture/pdp/allmodern-kearney-60-round-arm-loveseat-w001243679.html?piid=414931009"
            path="/furniture/wayfair/kearny-60-63-round-arm-loveseat-2"
          />
        </Box>
        <ScrollButtonRight scrollRef={ref} top="54%" />

        {/* <LandingSeeMore
          text="See more Trending on Instagram"
          mixpanelTracking="See More Trending on Instagram"
        /> */}
      </Box>
    </ThemeProvider>
  )
}

export default LandingTrendingInstagram
