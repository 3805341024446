import React, { useContext } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Box } from '@mui/material'
import Text from '../../atoms/Text/Text'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { Link } from 'react-router-dom'

// import global store
import { Context } from '../../../context/context'

// import components
import { customTheme } from '../../atoms/CustomTheme/CustomTheme'
import { classes } from './CMSAppBarStyle'
import Button from '../../atoms/Button/Button'

export default function CMSAppBar() {
  const [globalState, globalDispatch] = useContext(Context)

  const showBackButton = () => {
    // Only show back button if
    // 1. CMS workflow isn't completed
    // 2. Not showing Saved Designs
    // 3. Not first CMS page
    // 4. First CMS page BUT with saved designs
    let res =
      !globalState.completed &&
      !globalState.showCMSSavedDesignsPage &&
      (globalState.workflowPage > 0 || globalState.hasCookies)

    return res
  }

  function resetAppState() {
    // When a user hits back, we need to:
    // 1. Reset error to none
    // 2. Disable all loadings
    // 3. Set the completed state to false
    globalDispatch({
      type: 'SET_ERROR',
      error: null,
    })

    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName: 'isLoading',
        value: false,
      },
    })

    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName: 'completed',
        value: false,
      },
    })
  }

  function topRightText() {
    return (
      <Text variant="appBarText" color="secondary" sx={classes.topRightText}>
        {globalState.completed
          ? 'My Designs'
          : globalState.showCMSSavedDesignsPage
            ? ''
            : useMediaQuery(customTheme.breakpoints.down('sm'))
              ? (globalState.workflowPage + 1).toString() + ' / 4'
              : 'Step ' + (globalState.workflowPage + 1).toString() + ' of 4'}
      </Text>
    )
  }

  function backButtonClick() {
    if (globalState.workflowPage === 0) {
      globalDispatch({
        type: 'SET_WORKFLOW_PROP_VALUE',
        payload: {
          propName: 'showCMSSavedDesignsPage',
          value: true,
        },
      })
    } else if (!globalState.completed) {
      globalDispatch({
        type: 'PREVIOUS_PAGE',
      })
    }
    resetAppState()
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.innerContainer}>
        <Box
          sx={{
            ...classes.backButtonContainer,
            display:
              globalState.completed &&
              window.matchMedia('(max-width: 768px)').matches
                ? 'none'
                : 'relative',
          }}
          disabled={!showBackButton()}
          onClick={() => {
            backButtonClick()
          }}
        >
          {showBackButton() && (
            <>
              <Link to="/designs" style={classes.backButton}>
                <ArrowBackIosNewIcon sx={classes.backArrowIcon} />
                <Text
                  variant="appBarText"
                  color="secondary"
                  sx={classes.backButtonTxt}
                >
                  Back
                </Text>
              </Link>
            </>
          )}
        </Box>

        <Box
          onClick={() => {
            window.open('/')
          }}
          sx={{
            ...classes.craftleButton,
            position:
              globalState.completed &&
              window.matchMedia('(max-width: 768px)').matches
                ? 'relative'
                : 'absolute',
            top:
              globalState.completed &&
              window.matchMedia('(max-width: 768px)').matches
                ? 'inherit'
                : '50%',
            left:
              globalState.completed &&
              window.matchMedia('(max-width: 768px)').matches
                ? 'inherit'
                : '50%',
            transform:
              globalState.completed &&
              window.matchMedia('(max-width: 768px)').matches
                ? 'inherit'
                : 'translate(-50%, -50%)',
          }}
        >
          <Text sx={classes.logoTxt}>Craftle</Text>
        </Box>

        <Box>
          {globalState.completed ? (
            <Button variant="fourth" href="/designs">
              {topRightText()}
            </Button>
          ) : (
            topRightText()
          )}
        </Box>
      </Box>
    </Box>
  )
}
