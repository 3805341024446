import axios from 'axios'
import config from '../config.json'

const getPreselectedPlan = ({ visitedId, budget }) => {
  const END_POINT = `${config.END_POINT}designs/getPreselectedPlan`

  return new Promise((resolve, reject) => {
    axios
      .get(END_POINT, {
        params: {
          visitedId,
          budget,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default getPreselectedPlan
