import React, { useEffect, useState, createContext } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'

// Import styles and components
import { classes } from './CategoryCatalogStyle'
import Text from '../../../components/atoms/Text/Text'
import CategoryCatalogItem from '../../../components/molecules/CategoryCatalogItem/CategoryCatalogItem'
import ShopCatalogBackendCall from '../../../components/molecules/ShopCatalogBackendCall/ShopCatalogBackendCall'
import ModalLogInShop from '../../../components/organisms/ModalLogInShop/ModalLogInShop'
import ModalSignUpShop from '../../../components/organisms/ModalSignUpShop/ModalSignUpShop'
import RetrieveFavoritesCallToBackend from '../../../pages/profileRelated/favorites/RetrieveFavoritesCallToBackend'

// Import contexts
import { UserContext } from '../../../App'

export const CategoryCatalogContext = createContext()

export default function CategoryCatalog() {
  const { category } = useParams()
  const { user } = React.useContext(UserContext)

  const [loading, setLoading] = useState(true)
  const [categoryData, setCategoryData] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [userFavorites, setUserFavorites] = useState()

  const location = useLocation()
  const currentUrl = `${window.location.origin}${location.pathname}`

  // const [value, setValue] = React.useState("ratings");

  // Function to extract the category ID from the URL parameter
  const getCategoryID = (category) => {
    const parts = category.split('-')
    return parts[parts.length - 1]
  }

  const categoryID = getCategoryID(category)
  const pageTitle = categoryData?.category_info
    ? categoryData.category_info[0].category_name
        .replace(/-\d+$/, '')
        .replace(/-/g, ' ')
    : ''

  useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true)
      const result = await ShopCatalogBackendCall(categoryID)
      setCategoryData(result)
      setLoading(false)
    }

    fetchCategoryData()
  }, [category, categoryID])

  useEffect(() => {
    if (user) {
      const fetchFavorites = async () => {
        try {
          const favoritesList = await RetrieveFavoritesCallToBackend(user.email)
          setUserFavorites(favoritesList)
        } catch (error) {
          console.error('Error fetching favorites:', error)
        }
      }

      fetchFavorites()
    }
  }, [user])

  return (
    <CategoryCatalogContext.Provider
      value={{
        showLoginModal,
        setShowLoginModal,
        showSignUpModal,
        setShowSignUpModal,
      }}
    >
      <Box>
        {showLoginModal && <ModalLogInShop redirectHref={currentUrl} />}
        {showSignUpModal && <ModalSignUpShop redirectHref={currentUrl} />}
        <Box sx={classes.container}>
          {/* Back button */}
          {/* <Box sx={classes.backSection}>
            <Box sx={classes.backButton}>
              <Text sx={classes.back}>&#x3C; Back to&nbsp;</Text>
              <Text sx={classes.backHighlightedText}>XXX</Text>
            </Box>
          </Box> */}

          {/* Title */}
          <Text variant="h1" sx={classes.title}>
            {/* Convert hyphens to spaces and remove ID */}
            {pageTitle}
          </Text>

          {/* Temporary message */}
          <Box sx={classes.handSelectedMsg}>
            <Text>
              We hand-select new products every day for you to explore 🌟
            </Text>
          </Box>

          {/* Sort by dropdown */}
          {/* Guide: https://www.robinwieruch.de/react-dropdown/ */}
          {/* <Box sx={classes.sortByContainer}>
          <select
            value={value}
            onChange={handleChange}
            style={classes.dropdown}
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Box> */}

          {loading ? (
            <Box sx={classes.spinnerContainer}>
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={classes.categorySection}>
              {/* Items */}
              {categoryData?.catalog_info?.map((category) => (
                <CategoryCatalogItem
                  key={category.product_id}
                  itemInfo={category}
                  userFavorites={userFavorites}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </CategoryCatalogContext.Provider>
  )
}
