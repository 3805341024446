import axios from 'axios'
import config from '../config.json'

const getCMSBetaSuggestion = ({
  roomType,
  furnitureOptions,
  designWeights,
  budget,
}) => {
  const END_POINT = `${config.END_POINT}designs/getCMSV3Suggestion`

  return new Promise((resolve, reject) => {
    axios
      .get(END_POINT, {
        params: {
          preferences: {
            roomType,
            furnitureOptions,
            designWeights,
            budget,
          },
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default getCMSBetaSuggestion
