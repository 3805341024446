import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    marginTop: '24px',
    width: '100%',
  },
  textfieldAndSearch: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textfield: {
    width: '100%',
    '& .MuiFormLabel-root': {
      paddingLeft: '24px',
      marginTop: '-8px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '24px',
      paddingRight: '8px',
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0,
      display: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
}
