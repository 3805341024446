import * as React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
import { createTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import mixpanel from '../../atoms/Mixpanel/Mixpanel'

// import components
import Text from '../../atoms/Text/Text'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1280,
      md: 1000,
      sm: 600,
      xs: 0,
    },
  },
})

CategoryItem.propTypes = {
  image: PropTypes.node,
  category: PropTypes.string,
  link: PropTypes.string,
}

CategoryItem.defaultProps = {
  image: null,
  category: null,
  link: null,
}

export default function CategoryItem({ image, category, link }) {
  const classes = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
      [customTheme.breakpoints.down('md')]: {
        //   maxWidth: "calc(33.33333% - 16px)",
        //   margin: "8px",
      },
      // [customTheme.breakpoints.down("sm")]: {
      //   maxWidth: "calc(50% - 8px)",
      //   margin: "4px",
      // },
    },
    categoryImage: {
      borderRadius: '50%',
    },
    categoryText: {
      marginTop: '16px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  }

  let navigate = useNavigate()

  function CategoryClicked() {
    mixpanel.track(`(Shop) Category Clicked`, {
      Category: category,
    })
    navigate(link)
  }

  return (
    <Box onClick={CategoryClicked} sx={classes.container}>
      <CardMedia
        component="img"
        image={image}
        alt=""
        sx={classes.categoryImage}
      />
      <Text sx={classes.categoryText}>{category}</Text>
    </Box>
  )
}
