import React, { useState, useEffect, useContext, useRef } from 'react'
import CardMedia from '@mui/material/CardMedia'
import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

// import components
import { classes } from './MoodboardItemsStyle'
import { customTheme } from '../../atoms/CustomTheme/CustomTheme'
import { HomePageLivingRoomMoodBoardPlanLocation } from '../../../pages/CraftMyStyle/constants'
import { createObjectURL } from '../ConvertToImgSource/ConvertToImgSource'
import { addMoodboardItem } from './AddMoodboardItem'
import { deleteMoodboardItem } from './DeleteMoodboardItem'
import FurnitureClickCommands from '../../organisms/FurnitureClickCommands/FurnitureClickCommands'
// import { saveMoodboardItems } from "./SaveMoodboardItems";

// import contexts
import { Context } from '../../../context/context'
import { UserContext } from '../../../App'
// import { MoodboardContext } from "../../templates/MoodBoard/MoodBoard";
// import { handleMoveMouseDown } from "./HandleMoveMouseDown";
// import { handleResizeMouseDown } from "./HandleResizeMouseDown";

export default function MoodboardItems({
  selectedFurniturePieces,
  selectedOtherFurniturePieces,
  furniturePositions,
  // planId,
  // homePageView,
}) {
  const [initialRender, setInitialRender] = useState(true)
  const [currentMoodBoardView, setCurrentMoodBoardView] = useState(
    furniturePositions === null || furniturePositions.length === 0
      ? HomePageLivingRoomMoodBoardPlanLocation
      : furniturePositions,
  )
  const [newChangesToBeSaved, setNewChangesToBeSaved] = useState(false)
  const [currentlySaving, setCurrentlySaving] = useState(false)
  const [savesOccurring, setSavesOccurring] = useState(0)

  const [globalState, globalDispatch] = useContext(Context)
  // const { canvasLength } = React.useContext(MoodboardContext);
  const {
    // setCMSResultsShowMobileCanvas,
    CMSResultsSelectedFile,
    itemToDelete,
    // setShowCommandBar,
    cmsAddItem,
    cmsResultsSaving,
    setCmsResultsSaving,
    // setMobileShowOriginalCommands,
  } = React.useContext(UserContext)

  const outerContainerRefs = useRef({})
  const furnitureItemContainerRefs = useRef({})
  const furnitureItemResizeBoxRefs = useRef({})

  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down('md'),
  )

  const thirtyPercent = parseFloat(`${30}%`)

  useEffect(() => {
    if (furniturePositions !== null && furniturePositions.length !== 0) {
      setCurrentMoodBoardView(furniturePositions)
    }
  }, [furniturePositions])

  // Saves the design after a user makes changes (using a timer to prevent too many calls)
  useEffect(() => {
    if (!initialRender && savesOccurring === 0) {
      setTimeout(() => {
        if (savesOccurring === 0) {
          setCmsResultsSaving('')
        }
      }, 2000)
    }
  }, [savesOccurring])
  useEffect(() => {
    if (cmsResultsSaving === 'Saved' && savesOccurring > 0) {
      setSavesOccurring((prev) => prev - 1)
    }
  }, [cmsResultsSaving])
  useEffect(() => {
    if (!currentlySaving && newChangesToBeSaved) {
      setCurrentlySaving(true)
      setNewChangesToBeSaved(false)
      setSavesOccurring((prev) => prev + 1)
      // saveMoodboardItems(
      //   selectedFurniturePieces,
      //   selectedOtherFurniturePieces,
      //   globalState.uploadedImages,
      //   planId,
      //   currentMoodBoardView,
      //   setCmsResultsSaving,
      //   setCurrentlySaving
      // );
    }
  }, [newChangesToBeSaved, currentlySaving])
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false)
      return
    }
    if (!initialRender) {
      setNewChangesToBeSaved(true)
    }
  }, [
    currentMoodBoardView,
    globalState.selectedFurniturePieces,
    globalState.selectedOtherFurniturePieces,
    globalState.uploadedImages,
  ])

  // Adds a new item to the canvas
  useEffect(() => {
    CMSResultsSelectedFile &&
      addMoodboardItem(
        CMSResultsSelectedFile,
        setCurrentMoodBoardView,
        globalState,
        globalDispatch,
      )
  }, [cmsAddItem])

  // Deletes an item from the canvas
  useEffect(() => {
    itemToDelete &&
      deleteMoodboardItem(setCurrentMoodBoardView, itemToDelete, globalDispatch)
  }, [itemToDelete])

  return currentMoodBoardView.map((furnitureLocationInfo) => {
    // const preventDefault = (event) => event.preventDefault();
    const furnitureKey = furnitureLocationInfo['key']

    if (!outerContainerRefs.current[furnitureKey]) {
      outerContainerRefs.current[furnitureKey] = React.createRef()
      furnitureItemContainerRefs.current[furnitureKey] = React.createRef()
      furnitureItemResizeBoxRefs.current[furnitureKey] = React.createRef()
    }

    const outerContainer = outerContainerRefs.current[furnitureKey]
    const furnitureItemContainerRef =
      furnitureItemContainerRefs.current[furnitureKey]
    // const furnitureItemResizeBoxRef =
    //   furnitureItemResizeBoxRefs.current[furnitureKey];
    const currPositionX = furnitureLocationInfo['positionX']
    const currPositionY = furnitureLocationInfo['positionY']
    const currWidth = furnitureLocationInfo['width']
    const initialHeight =
      furnitureItemContainerRef.current !== null
        ? furnitureItemContainerRef.current.offsetHeight
        : 0
    const furnitureToView =
      selectedFurniturePieces && furnitureKey in selectedFurniturePieces
        ? selectedFurniturePieces[furnitureKey]
        : selectedOtherFurniturePieces &&
            furnitureKey in selectedOtherFurniturePieces
          ? selectedOtherFurniturePieces[furnitureKey]
          : globalState.uploadedImages[furnitureKey]
    furnitureLocationInfo.image_url =
      furnitureToView &&
      (furnitureToView['transparent_image_url'] ||
        furnitureToView['image_url'] ||
        furnitureToView['original_image_url'])
    const uploadedImage = furnitureToView && furnitureToView['uploadedImage']
    const imgUrlFromBlog = uploadedImage
      ? createObjectURL(furnitureLocationInfo.image_url)
      : null

    return (
      <Box
        ref={outerContainer}
        key={furnitureLocationInfo.key}
        sx={{
          ...classes.container,
          width: currWidth,
          left: currPositionX,
          top: currPositionY,
          height: initialHeight,
        }}
      >
        {isMobileOrTabletScreen &&
          globalState.currFurnitureTypeSelected === furnitureKey && (
            <Box
              sx={{
                ...classes.furnitureClickCommandsContainer,
                top: parseFloat(currPositionY) < thirtyPercent ? 'auto' : 0,
                bottom:
                  parseFloat(currPositionY) < thirtyPercent ? '-56px' : 'auto',
                left:
                  parseFloat(currPositionX) < parseFloat(`${5}%`)
                    ? '48px'
                    : parseFloat(currPositionX) > parseFloat(`${60}%`)
                      ? '-48px'
                      : 0,
              }}
            >
              <FurnitureClickCommands
                selectedFurnitureType={globalState.currFurnitureTypeSelected}
                isMobile={true}
              />
            </Box>
          )}
        <Box
          ref={furnitureItemContainerRef}
          sx={{
            ...classes.furnitureItemContainer,
            // cursor: !homePageView ? "move" : "inherit",
            // border:
            //   furnitureKey === globalState.currFurnitureTypeSelected
            //     ? "2px solid #49B900"
            //     : "transparent",
            // zIndex:
            //   furnitureKey === globalState.currFurnitureTypeSelected
            //     ? 1000
            //     : "inherit",
          }}
          tabIndex={1}
          // onMouseDown={
          //   homePageView === false
          //     ? (e) => {
          //         if (e.type === "mousedown") {
          //           handleMoveMouseDown(
          //             e,
          //             setMobileShowOriginalCommands,
          //             preventDefault,
          //             furnitureKey,
          //             globalDispatch,
          //             setShowCommandBar,
          //             outerContainer,
          //             setCurrentMoodBoardView,
          //             canvasLength
          //           );
          //         }
          //       }
          //     : null
          // }
          // onTouchStart={
          //   homePageView === false
          //     ? (e) => {
          //         handleMoveMouseDown(
          //           e,
          //           setMobileShowOriginalCommands,
          //           preventDefault,
          //           furnitureKey,
          //           globalDispatch,
          //           setShowCommandBar,
          //           outerContainer,
          //           setCurrentMoodBoardView,
          //           canvasLength
          //         );
          //       }
          //     : null
          // }
          // onClick={(e) => {
          //   homePageView === false
          //     ? handleMoveMouseDown(
          //         e,
          //         setMobileShowOriginalCommands,
          //         preventDefault,
          //         furnitureKey,
          //         globalDispatch,
          //         setShowCommandBar,
          //         outerContainer,
          //         setCurrentMoodBoardView,
          //         canvasLength
          //       )
          //     : null;
          //   homePageView === false ? e.stopPropagation() : null;
          //   setCMSResultsShowMobileCanvas("none");
          // }}
        >
          {uploadedImage ? (
            <img
              src={imgUrlFromBlog}
              alt="uploaded image"
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <CardMedia
              component="img"
              image={furnitureLocationInfo.image_url}
              alt=""
            />
          )}
        </Box>
        {/* <Box
          ref={furnitureItemResizeBoxRef}
          onMouseDown={(e) => {
            if (e.type === "mousedown") {
              // if mouse event
              handleResizeMouseDown(
                e,
                preventDefault,
                outerContainer,
                setCurrentMoodBoardView,
                furnitureKey,
                canvasLength
              );
            }
          }}
          onTouchStart={(e) => {
            if (e.type === "touchstart") {
              // if touch event
              handleResizeMouseDown(
                e,
                preventDefault,
                outerContainer,
                setCurrentMoodBoardView,
                furnitureKey,
                canvasLength
              );
            }
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          sx={{
            ...classes.resizeButton,
            display:
              furnitureKey === globalState.currFurnitureTypeSelected
                ? "block"
                : "none",
          }}
        /> */}
      </Box>
    )
  })
}
