import React from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import components
import { classes } from './BlogPostStyles'
import BlogContentContainer from '../../components/molecules/BlogContentContainer/BlogContentContainer'
import BlogDate from '../../components/molecules/BlogDate/BlogDate'
import BlogTitle from '../../components/molecules/BlogTitle/BlogTitle'
import BlogParagraph from '../../components/molecules/BlogParagraph/BlogParagraph'
// import BlogH2 from "../../components/molecules/BlogH2/BlogH2";
import BlogImage from '../../components/molecules/BlogImage/BlogImage'
import YoutubeiFrame from '../../components/molecules/YoutubeiFrame/YoutubeiFrame'
import BlogInlineAd1 from '../../components/molecules/BlogInlineAd1/BlogInlineAd1'
import BlogInlineAd2 from '../../components/molecules/BlogInlineAd2/BlogInlineAd2'

// import images
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-banner.jpg'
const image1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-1.jpg'
const image2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-2.jpg'
const image3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-3.jpg'
const image4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-4.jpg'
const image5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-5.jpg'
const image6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-6.jpg'
const image7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-7.jpg'
const image8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-8.jpg'
const image9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog2/blog-2-image-9.jpg'

function BlogPost2() {
  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={bannerImage}
        alt=""
        sx={classes.bannerImage}
      />

      <BlogContentContainer>
        <BlogDate date="May 4, 2022" />
        <BlogTitle title="Britney Spears’ Tiny Furniture: Girl, Is This Absolutely Amazing or an Accident?" />
        <BlogParagraph>
          Britney Spears’ tiny furniture may be small, but it’s gotten our
          attention in a big way.
        </BlogParagraph>
        <BlogParagraph>
          She’s done it again. She’s grabbed our attention with the seemingly
          bizarre but also oddly satisfying topic of teeny, tiny furniture.
        </BlogParagraph>
        <BlogParagraph>
          I first noticed her miniature decor from this amazing Instagram
          picture of Britney working out and taking a cute selfie for the ages.
        </BlogParagraph>
        <BlogImage image={image1} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          It took me a minute to see it, but you can see a tiny gold and pink
          bed in the corner of the room.
        </BlogParagraph>
        <BlogImage image={image2} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          I thought it was just a one-time thing. A tiny bed to look at and make
          her day whenever she went to exercise in her home. But later, we got
          another hint that Britney Spears’ tiny furniture wasn’t an
          afterthought, but more of an interior design preference for her home.
        </BlogParagraph>
        <BlogImage image={image3} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          It’s almost hard to tell, but the white recliner next to her enormous
          tree is legitimately tiny. And I got to say, the placement of it next
          to the tree was genius. It really accentuated the sense of scale!
        </BlogParagraph>
        <BlogImage image={image4} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          Let’s take another look at Britney’s loveseat that I love to look at,
          but is more or less not a useful seat.
        </BlogParagraph>
        <BlogImage image={image5} alt="" caption="Instagram @britneyspears" />

        <BlogInlineAd1 />

        <BlogParagraph>
          But I almost wish I could shrink myself down to fit!
        </BlogParagraph>
        <BlogImage image={image6} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          Now at this point, I was wondering…are the furniture for her dogs,
          kids, or even for herself? Though she hasn’t spoken much about her
          tiny decor, I’m willing to bet it’s all for her own amusement!
        </BlogParagraph>
        <BlogImage image={image7} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          I wouldn’t mind being the floormat she steps on while dancing. You
          know, as long as I get to rest on her loveseat here between takes. 🥴
          🥴
        </BlogParagraph>
        <BlogImage image={image8} alt="" caption="Instagram @britneyspears" />
        <BlogParagraph>
          And don’t think Britney Spears’ tiny furniture has gone unnoticed.
          Even Louis here has sat in them. Well, in a way…
        </BlogParagraph>
        <BlogImage image={image9} alt="" caption="Instagram @louishanson" />
        <BlogParagraph>
          Her furniture may be small, but her houses are anything but tiny. I
          mean, just take a look at these houses!
        </BlogParagraph>
        <YoutubeiFrame>
          <iframe
            // originally width: 560px, height: 315px
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/dZzpOdQ7_co"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </YoutubeiFrame>

        <BlogInlineAd2 />
      </BlogContentContainer>
    </Box>
  )
}

export default BlogPost2
