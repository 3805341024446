import { customTheme } from '../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '120px',
  },
  backgroundColor: {
    backgroundColor: '#FCFCFC',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    zIndex: -1,
  },
  content: {
    marginTop: '161px',
    padding: '0 16px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '121px',
    },
    [customTheme.breakpoints.down('xs')]: {
      marginTop: '81px',
    },
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [customTheme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  titleIcon: {
    width: '56px',
    height: '56px',
    marginRight: '16px',
  },
  h1: {
    fontSize: '48px',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '40px',
    },
  },
  preferenceMsgContainer: {
    marginTop: '32px',
    maxWidth: '720px',
    padding: '24px',
    boxSizing: 'border-box',
    backgroundColor: '#FFF',
    borderRadius: '16px',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.05)',
  },
  preferenceMsgHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  preferenceMsgBody: {
    marginTop: '16px',
  },

  // Images
  preferenceImagesContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  img1Container: {
    position: 'relative',
    maxWidth: 'calc(50% - 4px)',
    aspectRatio: '1 / 2', // Width to height ratio of 1:2
  },
  img1: {
    borderRadius: '16px',
    height: '100%',
  },
  imgCaption: {
    position: 'absolute',
    top: '16px',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 'fit-content',
    color: '#FFF',
    textDecoration: 'underline',
    textShadow: '0px 5px 15px rgba(0, 0, 0, 0.4)',
  },
  rightImagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  img2Container: {
    position: 'relative',
  },
  img2: {
    borderRadius: '16px',
    width: '100%',
    aspectRatio: '1 / 1',
  },
  img3Container: {
    position: 'relative',
  },
  img3: {
    borderRadius: '16px',
    width: '100%',
    aspectRatio: '1 / 1',
  },

  futureUpdatesMsg: {
    marginTop: '16px',
    fontWeight: 'bold',
  },

  // Delete account
  deleteAccount: {
    marginTop: '80px',
    fontSize: '20px',
    fontWeight: 'bold',
  },

  notLoggedInTxt: {
    marginTop: '160px',
    fontSize: '20px',
    textAlign: 'center',
    [customTheme.breakpoints.down('sm')]: {
      padding: '8px',
      boxSizing: 'border-box',
    },
  },
  inlineTxt: {
    display: 'inline',
    fontSize: '20px',
  },
}
