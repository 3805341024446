import React, { useContext } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { AnalyticsBrowser } from '@segment/analytics-next'

// Import components
import { classes } from './ImageTextGridCMSResultsStyle'
import { sortFurnitureResult } from '../../../pages/CraftMyStyle/constants'
import Text from '../../atoms/Text/Text'
import Button from '../../atoms/Button/Button'
import ImageTextCardCMSResults from '../../molecules/ImageTextCardCMSResults/ImageTextCardCMSResults'
import mixpanel from '../../atoms/Mixpanel/Mixpanel'
import { segmentWriteKey } from '../../../components/atoms/Segment/Segment'

// Import context
import { Context } from '../../../context/context'
import { UserContext } from '../../../App'

// Import icons
const refreshIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/refresh-cw.svg'
const externalLinkIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg'

const propTypes = {
  furnitureResultObject: PropTypes.object,
  otherRecommendationsObject: PropTypes.object,
  viewer: PropTypes.bool,
}
const defaultProps = {
  furnitureResultObject: {},
  otherRecommendationsObject: {},
  viewer: false,
}

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

function ImageTextGridCMSResults({
  furnitureResultObject,
  otherRecommendationsObject,
  viewer,
}) {
  const [globalState, globalDispatch] = useContext(Context)
  const { user, setShowCommandBar, setCMSResultsShowMobileCanvas } =
    React.useContext(UserContext)

  const furnitureObjects = sortFurnitureResult(
    Object.keys(furnitureResultObject),
  )
  const otherFurnitureObjects = sortFurnitureResult(
    Object.keys(otherRecommendationsObject),
  )

  const acquisitionSource =
    JSON.parse(localStorage.getItem('acquisitionSource')) || 'N/A'

  function swapClicked(furnitureKey) {
    setCMSResultsShowMobileCanvas('none')
    globalDispatch({
      type: 'UPDATE_AND_SWAP',
      command: 'SWAP',
      furnitureTypeSelected: furnitureKey,
    })
    setShowCommandBar('flex')
  }

  function shopNowClicked(currentFurniturePieceInfo) {
    analytics.track('Product-Link Opened', {
      user_email: user?.email,
      user_name: user?.first_name,
      flow: 'Craft My Style',
      survey_acquisition_source: acquisitionSource,
      moodboard_id: globalState.planId,
      product_link: currentFurniturePieceInfo?.product_page_url,
      product_name: currentFurniturePieceInfo?.name,
      product_price: currentFurniturePieceInfo?.cost,
      product_length: 'N/A',
      product_width: 'N/A',
      product_height: 'N/A',
    })
    mixpanel.track(`(CMS) Clicked "View Item"`, {
      'Product Link': currentFurniturePieceInfo?.product_page_url,
    })
    window.open(currentFurniturePieceInfo?.product_page_url)
  }

  /* for desktop */
  let renderFurnitureObjects = furnitureObjects.map((furnitureKey, idx) => {
    const currentFurniturePieceInfo =
      globalState?.selectedFurniturePieces[furnitureKey]

    return (
      <Box
        key={furnitureKey + idx.toString()}
        sx={classes.renderFurnitureObjectsContainer}
      >
        <ImageTextCardCMSResults
          index={idx}
          furnitureKey={furnitureKey}
          cardDetail={currentFurniturePieceInfo}
          viewer={viewer}
        />
        <Text sx={classes.itemCost}>
          $
          {Intl.NumberFormat().format(
            currentFurniturePieceInfo?.cost.toFixed(2).toString(),
          )}
        </Text>
        <Button
          icon={externalLinkIcon}
          onClick={() => {
            shopNowClicked(currentFurniturePieceInfo)
          }}
          sx={classes.shopNowBtn}
        >
          Shop Now
        </Button>
        <Button
          variant="tertiary"
          icon={refreshIcon}
          onClick={() => {
            swapClicked(furnitureKey)
          }}
          sx={classes.swapBtn}
        >
          Swap
        </Button>
      </Box>
    )
  })

  /* for desktop */
  let renderOtherFurnitureObjects = otherFurnitureObjects.map(
    (furnitureKey, idx) => {
      const currentFurniturePieceInfo =
        globalState?.selectedOtherFurniturePieces[furnitureKey]

      return (
        <Box
          key={furnitureKey + idx.toString()}
          sx={classes.renderOtherFurnitureObjectsContainer}
        >
          <ImageTextCardCMSResults
            index={idx}
            furnitureKey={furnitureKey}
            cardDetail={currentFurniturePieceInfo}
            viewer={viewer}
          />
          <Text sx={classes.itemCost}>
            $
            {Intl.NumberFormat().format(
              currentFurniturePieceInfo?.cost.toFixed(2).toString(),
            )}
          </Text>
          <Button
            icon={externalLinkIcon}
            onClick={() => {
              shopNowClicked(currentFurniturePieceInfo)
            }}
            sx={classes.shopNowBtn}
          >
            Shop Now
          </Button>
          <Button
            variant="tertiary"
            icon={refreshIcon}
            onClick={() => {
              swapClicked(furnitureKey)
            }}
            sx={{ ...classes.swapBtn, border: '2px solid #BFBFBF' }}
          >
            Swap
          </Button>
        </Box>
      )
    },
  )

  /* for mobile and tablet */
  let renderFurnitureObjectsMobile = furnitureObjects.map(
    (furnitureKey, idx) => {
      const currentFurniturePieceInfo =
        globalState?.selectedFurniturePieces[furnitureKey]

      return (
        <Box
          key={furnitureKey + idx.toString()}
          sx={classes.renderFurnitureObjectsMobileContainer}
        >
          <ImageTextCardCMSResults
            key={furnitureKey + idx.toString()}
            index={idx}
            furnitureKey={furnitureKey}
            cardDetail={globalState.selectedFurniturePieces[furnitureKey]}
            viewer={viewer}
          />
          <Text sx={classes.itemCost}>
            $
            {Intl.NumberFormat().format(
              currentFurniturePieceInfo?.cost.toFixed(2).toString(),
            )}
          </Text>
          <Button
            icon={externalLinkIcon}
            onClick={() => {
              shopNowClicked(currentFurniturePieceInfo)
            }}
            sx={classes.shopNowBtn}
          >
            Shop Now
          </Button>
          {/* <Button
            variant="tertiary"
            icon={refreshIcon}
            onClick={() => {
              swapClicked(furnitureKey);
            }}
            sx={classes.swapBtn}
          >
            Swap
          </Button> */}
        </Box>
      )
    },
  )

  /* for mobile and tablet */
  let renderOtherFurnitureObjectsMobile = otherFurnitureObjects.map(
    (furnitureKey, idx) => {
      const currentFurniturePieceInfo =
        globalState?.selectedOtherFurniturePieces[furnitureKey]

      return (
        <Box
          key={furnitureKey + idx.toString()}
          sx={classes.renderOtherFurnitureObjectsMobileContainer}
        >
          <ImageTextCardCMSResults
            key={furnitureKey + idx.toString()}
            index={idx}
            furnitureKey={furnitureKey}
            cardDetail={globalState.selectedOtherFurniturePieces[furnitureKey]}
            viewer={viewer}
          />
          <Text sx={classes.itemCost}>
            $
            {Intl.NumberFormat().format(
              currentFurniturePieceInfo?.cost.toFixed(2).toString(),
            )}
          </Text>
          <Button
            icon={externalLinkIcon}
            onClick={() => {
              shopNowClicked(currentFurniturePieceInfo)
            }}
            sx={classes.shopNowBtn}
          >
            Shop Now
          </Button>
          {/* <Button
            variant="tertiary"
            icon={refreshIcon}
            onClick={() => {
              swapClicked(furnitureKey);
            }}
            sx={classes.swapBtn}
          >
            Swap
          </Button> */}
        </Box>
      )
    },
  )

  return (
    <>
      {/* for desktop */}
      <Box
        sx={{
          ...classes.desktopContainer,
          justifyContent:
            Object.keys(furnitureResultObject).length > 0
              ? Object.keys(globalState.result).length < 3
                ? 'center'
                : ''
              : Object.keys(otherRecommendationsObject).length < 3
                ? 'center'
                : '',
        }}
      >
        {renderFurnitureObjects}
        {renderOtherFurnitureObjects}
      </Box>

      {/* for mobile and tablet */}
      <Box sx={classes.mobileContainer}>
        {renderFurnitureObjectsMobile}
        {renderOtherFurnitureObjectsMobile}
      </Box>
    </>
  )
}

ImageTextGridCMSResults.propTypes = propTypes
ImageTextGridCMSResults.defaultProps = defaultProps

export default ImageTextGridCMSResults
