import axios from 'axios'
import config from '../config.json'

const signUpForMailing = ({ email, b2b }) => {
  const END_POINT = `${config.END_POINT}emails/`

  return new Promise((resolve, reject) => {
    axios
      .post(END_POINT, {
        email,
        b2b,
      })
      .then((response) => {
        console.log(response)
        resolve("We will let you know once it's out! :)")
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
  })
}

export default signUpForMailing
