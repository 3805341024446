import React from 'react'
import Footer from '../../components/organisms/Footer/Footer'
import CardMedia from '@mui/material/CardMedia'

// import components
import BlogParallaxRoot from '../../components/molecules/BlogParallaxRoot/BlogParallaxRoot'
import BlogParallaxLayerBack from '../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack'
import BlogParallaxLayerMiddle from '../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle'
import BlogParallaxLayerFront from '../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront'
import BlogContentContainer from '../../components/molecules/BlogContentContainer/BlogContentContainer'
import BlogDate from '../../components/molecules/BlogDate/BlogDate'
import BlogTitle from '../../components/molecules/BlogTitle/BlogTitle'
import BlogParagraph from '../../components/molecules/BlogParagraph/BlogParagraph'
import BlogH2 from '../../components/molecules/BlogH2/BlogH2'
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from '../../components/molecules/BlogImage/BlogImage'
import BlogInlineAd1 from '../../components/molecules/BlogInlineAd1/BlogInlineAd1'
import BlogInlineAd2 from '../../components/molecules/BlogInlineAd2/BlogInlineAd2'

// import images
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-banner.jpg'
const image1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-1.jpg'
const image2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-2.jpg'
const image3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-3.jpg'
const image4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-4.jpg'
const image5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-5.jpg'
const image6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog5/blog-5-image-6.jpg'

function BlogPost5() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 10, 2022" />
          <BlogTitle title="Tip Furniture Delivery Guys How Much? Avoid Embarrassment With This Guide" />
          <BlogParagraph>
            When it comes to tipping for services, there are a lot of different
            factors to consider. How much should you tip? Is it preferable to
            tip in cash or include it in the cost? Who should you tip and who
            shouldn’t you?
          </BlogParagraph>
          <BlogParagraph>
            These are all valid questions with no easy answers. However, when it
            comes to furniture delivery guys, there are a few simple tips that
            can help you avoid any potential embarrassment or awkwardness.
          </BlogParagraph>

          <BlogH2 title="Do You Tip Furniture Delivery?" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            At first glance, the answer to this question might seem obvious - of
            course, you tip furniture delivery! After all, these guys are
            working hard to make sure that your new furniture is delivered
            safely and on time. However, it may be more difficult than you
            think.
          </BlogParagraph>
          <BlogParagraph>
            One tip to keep in mind when it comes to tipping for furniture
            delivery is to always tip in cash. Adding a tip to the bill can lead
            to confusion or embarrassment if the tip amount is not clearly
            stated on the receipt, especially if you are dealing with an
            inexperienced delivery team.
          </BlogParagraph>
          <BlogParagraph>
            Another tip is to take some time to research how much you should
            tip. Different services will have different tipping customs, so you
            want to make sure that you are being respectful and generous but not
            over-tipping either. You want your delivery guys to feel appreciated
            for their hard work, without feeling like they were taken advantage
            of.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="How Much to Tip Delivery Driver?" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            When it comes to tipping delivery drivers, there is no set amount
            that you should be aiming for. The tip amount will depend on a
            number of factors, including how big your order was and how long it
            took for the driver to complete their job.
          </BlogParagraph>
          <BlogParagraph>
            However, generally speaking, most tip amounts fall somewhere between
            10-20% of the total cost of the order. So if you had an order
            totaling $200, you would tip around $20-$40 depending on the
            circumstances.
          </BlogParagraph>
          <BlogParagraph>
            If you aren’t sure exactly how much to tip or are concerned about
            being overly generous or stingy with your tip, there are a few
            things you can keep in mind. First, try to base your tip amount on
            the size of your order and how long it took the delivery driver to
            complete your job, rather than focusing solely on the tip
            percentage.
          </BlogParagraph>
          <BlogParagraph>
            You can also do some research ahead of time to learn more about
            tipping etiquette and customs in your area. This will help you
            better gauge what is considered a reasonable tip amount, and ensure
            that you are being respectful and generous without overdoing it.
          </BlogParagraph>
          <BlogParagraph>
            Overall, when it comes to tipping for furniture delivery services,
            it is important to keep in mind both your own needs and the needs of
            those making deliveries. With these simple tips in mind, you can tip
            with confidence knowing that you are being fair and respectful.
          </BlogParagraph>

          <BlogH2 title="Is $5 a Good Tip for Delivery?" />
          <BlogImage image={image3} alt="" caption="" />
          <BlogParagraph>
            Tipping for services can be a tricky business, and there is no
            one-size-fits-all answer when it comes to how much you should tip.
            However, when it comes to furniture delivery, $5 is generally
            considered to be a good tip.
          </BlogParagraph>
          <BlogParagraph>
            This amount is enough to show your appreciation for the driver’s
            hard work without being too over-generous and will help ensure that
            they are motivated to provide great service.
          </BlogParagraph>
          <BlogParagraph>
            Of course, you may choose to tip more or less depending on the size
            of your order and how long it took the driver to complete their job.
            If you are unsure about what an appropriate tip would be, try basing
            it on the cost of your order or the time it took for delivery,
            rather than focusing completely on the tip percentage.
          </BlogParagraph>
          <BlogParagraph>
            In the end, the most important thing is to be fair and respectful
            when it comes to tipping for furniture delivery. With these simple
            tips in mind, you can be confident that you are doing just that.
          </BlogParagraph>

          <BlogH2 title="Do You Tip Contractors?" />
          <BlogImage image={image4} alt="" caption="" />
          <BlogParagraph>
            When it comes to tipping contractors, there is no set rule or amount
            that you should be aiming for. The tip amount will vary depending on
            the nature of the job, the length of time it took to complete, and
            your personal relationship with the contractor.
          </BlogParagraph>
          <BlogParagraph>
            However, in general, most people tip 10-20% of the total cost of the
            job. So if you had a job that cost $200, you would tip around
            $20-$40.
          </BlogParagraph>
          <BlogParagraph>
            Of course, you may choose to tip more or less depending on how
            satisfied you were with the job and your personal relationship with
            the contractor. If you are unsure about what an appropriate tip
            would be, try basing it on the cost of the job or the time it took
            for the job to be completed, rather than focusing purely on tip
            percentage.
          </BlogParagraph>

          <BlogH2 title="Should I Tip Carpet Installers?" />
          <BlogImage image={image5} alt="" caption="" />
          <BlogParagraph>
            Whether or not you should tip carpet installers largely depends on
            the circumstances. If you are dealing with a large order, such as
            installing new carpeting in your entire home, then it is generally
            customary to tip around 15-20% of the total cost of the job.
            However, if your order was smaller, such as laying carpet in just
            one room, then a tip may not be necessary.
          </BlogParagraph>
          <BlogParagraph>
            In addition to the tip amount, it is also important to consider how
            satisfied you were with the job and your relationship with the
            installer. Tipping more than expected can be seen as generous and
            appreciated by most installers while tipping too little can be
            hurtful and discouraging for those doing the work.
          </BlogParagraph>

          <BlogH2 title="Tip Appliance Delivery?" />
          <BlogImage image={image6} alt="" caption="" />
          <BlogParagraph>
            There is no set tip amount for appliance delivery, but a good rule
            of thumb is to tip 10-15% of the total cost of the job. For example,
            if you had an appliance delivered that cost $200, you would tip
            around $20-$30.
          </BlogParagraph>
          <BlogParagraph>
            If you’re satisfied with the service, you may tip more or less based
            on how pleased you are with the job and your personal connection
            with the delivery driver. If you’re not sure what to give as a tip,
            consider basing it on how much it costs to do the work rather than
            just focusing on percentage.
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogParagraph>
            When it comes to tip furniture delivery guys, there is no
            one-size-fits-all answer. However, in general, $5 is considered a
            good tip amount, as it shows appreciation for the driver’s hard work
            without being too over-generous. When deciding how much to tip your
            delivery drivers, you should consider factors such as the size of
            your order and the time it took to complete their job, rather than
            focusing solely on tip percentage. In the end, the most important
            thing is to be fair and respectful when it comes to tipping for
            furniture delivery services. With these simple tips in mind, you can
            tip with confidence and ensure that those making deliveries are
            motivated to provide great service.
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  )
}

export default BlogPost5
