import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  // MoodBoardComponent styles
  moodboardOuter: {
    border: '4px solid #2A2A2A',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    width: 'fit-content',
  },
  moodboardLoadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: "0 8px",
    width: '528px',
    height: '528px',
    [customTheme.breakpoints.down('md')]: {
      width: '480px',
      height: '480px',
    },
    [customTheme.breakpoints.down(900)]: {
      width: '95vw',
      height: '95vw',
    },
  },
  moodboardLoadTxt: {
    color: '#2a2a2a',
    fontSize: '20px',
    lineHeight: 1.5,
    marginTop: '8px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  moodboardInner: {
    width: '528px',
    height: '528px',
    [customTheme.breakpoints.down('md')]: {
      width: '480px',
      height: '480px',
    },
    [customTheme.breakpoints.down(900)]: {
      width: '95vw',
      height: '95vw',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  // LandingBudgetMoodboard styles
  container: {
    marginTop: '160px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '80px',
    },
  },
  hashtag: {
    padding: '8px 16px',
    backgroundColor: '#DAEBCF',
    borderRadius: '100px',
    fontWeight: 'bold',
  },
  sectionTitle: {
    marginTop: '16px',
    color: '#2a2a2a',
    fontSize: '40px',
    maxWidth: '752px',
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
    [customTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  budgetQuestionContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    // width: "100%",
    [customTheme.breakpoints.down(900)]: {
      // alignItems: "center",
      width: '100%',
    },
  },
  cmsFormContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
  subtitle: {
    fontSize: '24px',
  },
  budgetContainer: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      boxSizing: 'border-box',
      gap: '8px',
    },
  },
  cmsTextField: {
    width: '276px',
    // paddingLeft: "1em",
    position: 'relative',
    '& .MuiFormLabel-root': {
      // paddingLeft: "24px",
      // marginTop: "-8px",
    },
    '& .MuiInputBase-root': {
      paddingLeft: '32px',
      paddingRight: '24px',
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0,
      display: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    [customTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputWithSymbol: {
    '&::before': {
      content: '"$"', // Add `$` symbol
      position: 'absolute',
      left: '16px', // Adjust this as needed based on padding
      top: '48%',
      transform: 'translateY(-50%)',
      color: 'black', // Adjust color as needed
      pointerEvents: 'none',
      zIndex: 1,
      fontSize: '24px',
    },
  },
  cmsSubmitButton: {
    marginLeft: '8px',
    [customTheme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
    },
  },

  cmsResultsBackground: {
    marginTop: '32px',
    backgroundColor: 'rgba(243,243,243,0.5)',
    // backgroundColor: "#DAEBCF",
    padding: '16px 0 24px 0',
    width: '100vw',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resultsTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.2,
    marginBottom: '16px',
  },
  totalCostContainer: {
    position: 'relative',
    margin: '0 auto',
    marginTop: '-36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DAEBCF',
    width: 'fit-content',
    padding: '16px 24px',
    borderRadius: '8px',
    border: 'solid 4px #2A2A2A',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.15)',
    zIndex: 3,
    [customTheme.breakpoints.down(346)]: {
      marginTop: '-16px',
    },
  },
  totalCost: {
    color: '#2A2A2A',
    fontSize: '18px',
    lineHeight: 1.5,
    fontWeight: 'bold',
    textAlign: 'center',
    [customTheme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  commandsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '0 16px',
      boxSizing: 'border-box',
    },
  },
  generateDesignBtn: {
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'row',
    [customTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  shopTheseProductsBtn: {
    marginLeft: '8px',
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '8px',
      marginLeft: 0,
      width: '100%',
    },
  },
}
