const product_type_mappings = {
  'Futon Mattress': ['futon mattress', 'futon mattresses'],
  'Sofa Beds': [
    'sofabed',
    'sofabeds',
    'sofa bed',
    'sofa beds',
    'sofa-bed',
    'sofa-beds',
    'sleeper sofa',
    'sleeper sofas',
  ],
  Futons: ['futon', 'futons'],
  'Coffee Tables': ['coffee table', 'coffee tables'],
  Loveseats: [
    'loveseat',
    'loveseats',
    'love seats',
    'love seat',
    '2 seater sofas',
    '2 seater sofa',
  ],
  'Sectional Sofas': ['sectional', 'sectional sofa'],
  Sofas: ['sofa', 'sofas', 'couch', 'couches'],
  'Accent Chairs': [
    'accent chair',
    'armchair',
    'arm chair',
    'accent chairs',
    'accent chairs',
    'arm chairs',
    'armchairs',
  ],
  'TV Stand': ['tv stand', 'tv stands', 'tv bench', 'tv benches'],
  Benches: ['bench', 'benches'],
  Sideboard: ['sideboard', 'sideboards'],
  'Cabinet & Chests': [
    'cabinet chest',
    'accent cabinet',
    'accent cabinets',
    'cabinet chests',
    'accent chest',
    'accent chests',
  ],
  Ottomans: [
    'ottoman',
    'ottomans',
    'footstool',
    'footstools',
    'foot stool',
    'foot stools',
    'poufs',
    'pouf',
  ],
  Bookcases: ['bookcase', 'bookcases', 'bookshelf', 'bookshelves'],
  Daybed: ['daybed', 'daybeds', 'day bed', 'day beds', 'day-bed', 'day-beds'],
  Nightstands: [
    'nightstands',
    'nightstand',
    'night stand',
    'night stands',
    'night-stands',
    'night-stand',
  ],
  Dresser: ['dresser'],
  Wardrobe: ['wardrobe'],
  'Vanity Table': [
    'makeup table',
    'vanity table',
    'makeup tables',
    'vanities',
    'dressing table',
    'dressing tables',
  ],
  'Vanity Stool': [
    'vanity stool',
    'vanity chair',
    'makeup stool',
    'makeup chair',
    'makeup stools',
    'vanity stools',
    'vanity chairs',
    'makeup chairs',
  ],
  'Bar Stools': ['bar stool', 'bar stools', 'counter stool', 'counter stools'],
  'Wine Rack': [
    'wine rack',
    'bottle rack',
    'wine racks',
    'bottle racks',
    'bottle shelving',
  ],
  Pantries: ['pantry', 'pantries'],
  'Dining Tables': [
    'dining tables',
    'dining table',
    'kitchen table',
    'kitchen tables',
  ],
  'Area Rugs': ['rug', 'rugs'],
  // "Coffee & Cocktail Tables": ["coffee table", "coffee tables", "cocktail table", "cocktail tables"] as string[],
  // "Accent Chairs": ["accent chairs", "accent chair"] as string[],
}

export default function getFurnitureType(product_category_strings) {
  product_category_strings = product_category_strings.map((string) =>
    string.toLowerCase(),
  )
  for (let i = 0; i < Object.keys(product_type_mappings).length; i++) {
    const product_type = Object.keys(product_type_mappings)[i]
    const related_strings = product_type_mappings[product_type]
    if (!related_strings) {
      continue
    }
    for (let related_string of related_strings) {
      for (let furniture_category of product_category_strings) {
        if (furniture_category.includes(related_string)) return product_type
      }
    }
  }
  return ''
}
