import { customTheme } from '../CustomTheme/CustomTheme'

export const classes = {
  container: {
    borderRadius: '1000px',
    padding: '14px 22px',
    border: 'solid 2px #2A2A2A',
    boxSizing: 'border-box',
    cursor: 'pointer',
    width: 'fit-content',
    transition: '0.2s ease-in-out',
  },
  text: {
    fontWeight: 'bold',
    lineHeight: '100%',
    [customTheme.breakpoints.down('sm')]: {},
  },
}
