import React from 'react'
import Footer from '../../components/organisms/Footer/Footer'
import CardMedia from '@mui/material/CardMedia'

// import components
import BlogParallaxRoot from '../../components/molecules/BlogParallaxRoot/BlogParallaxRoot'
import BlogParallaxLayerBack from '../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack'
import BlogParallaxLayerMiddle from '../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle'
import BlogParallaxLayerFront from '../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront'
import BlogContentContainer from '../../components/molecules/BlogContentContainer/BlogContentContainer'
import BlogDate from '../../components/molecules/BlogDate/BlogDate'
import BlogTitle from '../../components/molecules/BlogTitle/BlogTitle'
import BlogParagraph from '../../components/molecules/BlogParagraph/BlogParagraph'
import BlogH2 from '../../components/molecules/BlogH2/BlogH2'
// import BlogH3 from "../../components/molecules/BlogH3/BlogH3";
import BlogImage from '../../components/molecules/BlogImage/BlogImage'
import BlogInlineAd1 from '../../components/molecules/BlogInlineAd1/BlogInlineAd1'
import BlogInlineAd2 from '../../components/molecules/BlogInlineAd2/BlogInlineAd2'

// import images
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-banner.jpg'
const image1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-1.jpg'
const image2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-2.jpg'
const image3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-3.jpg'
const image4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-4.jpg'
const image5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-5.jpg'
const image6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-6.jpg'
const image7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-7.jpg'
const image8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-8.jpg'
const image9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-9.jpg'
const image10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-10.jpg'
const image11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-11.jpg'
const image12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-12.jpg'
const image13 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-13.jpg'
const image14 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog7/blog-7-image-14.jpg'

function BlogPost7() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle marginTop="340vh" height="460vh" />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 14, 2022" />
          <BlogTitle title="Furniture Mod for Minecraft: 15 Best Mods to Make Your World Look Wicked Amazing" />
          <BlogParagraph>
            Looking to make your Minecraft world stand out from the rest? Look
            no further than these 15 furniture mods, each of which offers unique
            and innovative ways to customize your game.
          </BlogParagraph>
          <BlogParagraph>
            Whether you’re looking to add furniture, decorative lighting, or new
            functional items, these mods are sure to take your Minecraft
            experience to the next level.
          </BlogParagraph>
          <BlogParagraph>
            Some furniture mods even add new types of furniture, such as beds,
            couches, and tables, while others focus on adding more decorative
            items, such as wall hangings, tapestries, and paintings. Whether
            you’re looking to add a cozy touch to your living room or create the
            perfect ambiance for your bedroom, there’s a furniture mod that can
            help you achieve your vision.
          </BlogParagraph>
          <BlogParagraph>
            So if you’re ready to take your Minecraft world to the next level,
            then check out these 15 furniture mods.
          </BlogParagraph>

          <BlogH2 title="1. Decocraft" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogParagraph>
            If you’re looking for a furniture mod that offers a wide variety of
            furniture and decor items, look no further than Decocraft. This mod
            adds over 200 new furniture and decor items to the game, including
            chairs, tables, beds, sofas, lamps, paintings, and much more. With
            so many options to choose from, you’re sure to find the perfect
            furniture and decor to suit your needs.
          </BlogParagraph>

          <BlogH2 title="2. Bibliocraft" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            If you’re looking for a furniture mod that offers both functional
            and decorative items, look no further than Bibliocraft. This mod
            adds a variety of furniture and decor items to the game, including
            bookshelves, desks, lamps, and paintings. What’s more, each of these
            items is fully customizable, so you can create the perfect furniture
            piece to suit your needs.
          </BlogParagraph>

          <BlogH2 title="3. Rustic Furniture Pack" />
          <BlogImage image={image3} alt="" caption="" />
          <BlogParagraph>
            Looking for furniture that has a more rustic or antique feel to it?
            Then check out the Rustic Furniture Pack mod, which adds furniture
            items with a natural and organic look. With this mod, you can add
            tables and chairs that are perfect for creating cozy and inviting
            spaces in your Minecraft world.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="4. MrCrayfish Furniture Mod" />
          <BlogImage image={image4} alt="" caption="" />
          <BlogParagraph>
            If you’re looking for furniture that’s full of character, look no
            further than the MrCrayfish Furniture Mod. This furniture mod adds
            furniture items with a variety of different designs and finishes,
            each of which looks truly unique. The best part? With the MrCrayfish
            Furniture Mod at your fingertips, customizing your Minecraft world
            has never been easier—or more fun!
          </BlogParagraph>

          <BlogH2 title="5. Tinker’s Construct" />
          <BlogImage image={image5} alt="" caption="" />
          <BlogParagraph>
            Think about all the custom furniture you can make! With this neat
            little mod, there are no limitations on what shapes or sizes your
            placed items should take. You’ll be able to fully edit blocks and
            create pieces of decor that shouldn’t exist in Minecraft - which
            means anything is possible when it comes down to how creative your
            home will become with these new designs for places like bedrooms--or
            even bathrooms (ew).
          </BlogParagraph>

          <BlogH2 title="6. Scarab’s Missing Furniture Mod" />
          <BlogImage image={image6} alt="" caption="" />
          <BlogParagraph>
            The Minecraft home add-on will add all the necessary items to create
            your ideal home. Is there no espresso machine for coffee lovers like
            yourself who just can’t get enough of that delicious beverage while
            playing video games! This wonderful mod resolves these problems by
            providing new furniture which often appears inside homes but not on
            official servers--so we had no choice but put them here instead.
          </BlogParagraph>

          <BlogH2 title="7. Carpet Mod " />
          <BlogImage image={image7} alt="" caption="" />
          <BlogParagraph>
            This Minecraft carpet mod adds carpets of every color into the game.
            You can craft 3 different pieces using 2 wool blocks and a dye,
            which will give your home an instant makeover!
          </BlogParagraph>

          <BlogH2 title="8. Macaw’s Furniture Forge" />
          <BlogImage image={image8} alt="" caption="" />
          <BlogParagraph>
            Macaw’s Furniture has all your Minecraft house needs! If you love
            creativity and expression, then here is the right place for
            furniture that will help unleash that inner creative beast. With
            options like merging pieces together or just sitting on one chair to
            relax after creating some new combinations with the wide variety of
            styles; we’re sure there’ll be something perfect for everyone who
            visits Macaws’ shop today!
          </BlogParagraph>

          <BlogH2 title="9. Jammy Furniture Mod" />
          <BlogImage image={image9} alt="" caption="" />
          <BlogParagraph>
            Jammy’s Furniture Mod has managed to create a cool set of furniture
            with all sorts of new items. You can get it from his Minecraft Forum
            page too! Check out the whole bunch and see what kinds of styles he
            offers, like wood beds for example (though we’re not sure if they
            actually make any noise when you sleep on them).
          </BlogParagraph>

          <BlogH2 title="10. Dany Furniture" />
          <BlogImage image={image10} alt="" caption="" />
          <BlogParagraph>
            If you’re looking for some new household items to add to your game,
            I really think Dany Furniture is one of the best mods out there.
            Some of the items included are a coffee table, modern table, deco
            flower, PC, monitor, keyboard, mouse, modern shelf, nightstand,
            shelf, and computer table.
          </BlogParagraph>

          <BlogH2 title="11. Medieval Architecture" />
          <BlogImage image={image11} alt="" caption="" />
          <BlogParagraph>
            The medieval architecture of this mod takes inspiration from
            Romanesque and gothic styles. The workstations introduce new blocks
            for carpentry, furniture design as well drafting capabilities with
            17 types of available stones!
          </BlogParagraph>

          <BlogH2 title="12. Furniture Mod" />
          <BlogImage image={image12} alt="" caption="" />
          <BlogParagraph>
            Many of the item types that SquareGames here created were later
            added to other Minecraft versions, including from Mojang. Though
            this mod was created in 2012, some furniture that they’re planning
            on adding includes new stair types, slab types, and fences.
          </BlogParagraph>

          <BlogH2 title="13. Tamriel Treasures MOD" />
          <BlogImage image={image13} alt="" caption="" />
          <BlogParagraph>
            The goal of this Mod is to give you an immense amount of assets that
            can be used in your adventures. The pack is based on the Tamriel
            Treasures Resource Pack and its add-ons!
          </BlogParagraph>

          <BlogH2 title="14. Outdoor Craft for MC" />
          <BlogImage image={image14} alt="" caption="" />
          <BlogParagraph>
            The idea for Outdoor Craft was born out of frustration with
            Minecraft. The lack of plants and food made gameplay less enjoyable,
            so the creator made this mod to make things more immersive! It
            includes trees, crops (4 currently, many more to come), flowers,
            outdoor furniture, food & drink, and lots more.
          </BlogParagraph>

          <BlogH2 title="Wrapping It Up" />
          <BlogParagraph>
            There you have it—15 furniture mods that will make your Minecraft
            world look wicked amazing! With so many furniture mods to choose
            from, you’re sure to find the perfect furniture modpack for your
            personal style and taste. So what are you waiting for? Start
            customizing your world today!
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  )
}

export default BlogPost7
