import React, { useState, useEffect, useContext, useRef } from 'react'
import { Box } from '@mui/material'
// import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
// import { AnalyticsBrowser } from "@segment/analytics-next";

// import components
import { classes } from './ImageTextGridCMSResultsSwapFurnitureStyle'
// import { customTheme } from "../../atoms/CustomTheme/CustomTheme";
// import Text from "../../atoms/Text/Text";
// import Loading from "../../atoms/Loading/Loading";
import ImageTextCardExploreCMSResultsSwapFurniture from '../../molecules/ImageTextCardExploreCMSResultsSwapFurniture/ImageTextCardExploreCMSResultsSwapFurniture'
import ImageTextCardOurPicksCMSResultsSwapFurniture from '../../molecules/ImageTextCardOurPicksCMSResultsSwapFurniture/ImageTextCardOurPicksCMSResultsSwapFurniture'
// import ScrollButtonLeft from "../../molecules/ScrollButtonLeft/ScrollButtonLeft";
// import ScrollButtonRight from "../../molecules/ScrollButtonRight/ScrollButtonRight";
// import { segmentWriteKey } from "../../../components/atoms/Segment/Segment";

// import icons
const closeIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg'

// import context
import { Context } from '../../../context/context'
import { UserContext } from '../../../App'

// import services
import saveExistingPlan from '../../../services/saveExistingPlan'
// import getListOfFurniture from "../../../services/getListOfFurniture";

// const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey });

const propTypes = {
  urlPlanId: PropTypes.number,
  furnitureResultObject: PropTypes.object,
  otherRecommendationsObject: PropTypes.object,
  selectedFurnitureType: PropTypes.string,
  viewer: PropTypes.bool,
}
const defaultProps = {
  urlPlanId: 0,
  furnitureResultObject: {},
  otherRecommendationsObject: {},
  selectedFurnitureType: null,
  viewer: false,
}

// After waiting TIME_TO_UPDATE seconds, the plan will save to DB
const TIME_TO_UPDATE = 2

function ImageTextGridCMSResultsSwapFurniture({
  urlPlanId,
  furnitureResultObject,
  otherRecommendationsObject,
  selectedFurnitureType,
  viewer,
}) {
  const [globalState, globalDispatch] = useContext(Context)
  const { setCmsResultsSaving, setMobileShowOriginalCommands } =
    React.useContext(UserContext)
  // const { user, setMobileShowOriginalCommands } = React.useContext(UserContext);

  const [otherOptions, setOtherOptions] = useState([])
  // const [page, setPage] = useState(1);
  const [seconds, setSeconds] = useState(0)
  // const [isLoading, setIsLoading] = useState(false);
  // const [atEnd, setAtEnd] = useState(false);
  const [isActive, setIsActive] = useState(false)
  // const [exploreAllOtherFurniture, setExploreAllOtherFurniture] = useState([]);
  const [productCategory, setProductCategory] = useState('')

  const ref = useRef(null)

  // const isMobileOrTabletScreen = useMediaQuery(
  //   customTheme.breakpoints.down("md")
  // );

  const uploadedImageKeys = Object.keys(globalState.uploadedImages)

  useEffect(() => {
    // If-statement sets the other furniture that should be shown if an uploaded list exists
    if (Array.isArray(uploadedImageKeys) && uploadedImageKeys.length > 0) {
      uploadedImageKeys.map((imageObj) => {
        if (selectedFurnitureType === imageObj) {
          const productId = selectedFurnitureType
          const productCategory =
            globalState.uploadedImages[productId]?.category
          if (productCategory in furnitureResultObject) {
            setOtherOptions(furnitureResultObject[productCategory])
            setProductCategory(productCategory)
          } else if (productCategory in otherRecommendationsObject) {
            setOtherOptions(otherRecommendationsObject[productCategory])
            setProductCategory(productCategory)
          }
        } else {
          if (selectedFurnitureType in furnitureResultObject) {
            setOtherOptions(furnitureResultObject[selectedFurnitureType])
            setProductCategory(selectedFurnitureType)
          } else if (selectedFurnitureType in otherRecommendationsObject) {
            setOtherOptions(otherRecommendationsObject[selectedFurnitureType])
            setProductCategory(selectedFurnitureType)
          }
        }
      })
    } else {
      if (selectedFurnitureType in furnitureResultObject) {
        setOtherOptions(furnitureResultObject[selectedFurnitureType])
        setProductCategory(selectedFurnitureType)
      } else if (selectedFurnitureType in otherRecommendationsObject) {
        setOtherOptions(otherRecommendationsObject[selectedFurnitureType])
        setProductCategory(selectedFurnitureType)
      }
    }
  }, [selectedFurnitureType])

  function reset() {
    setSeconds(0)
    setIsActive(true)
  }

  function resetTurnOff() {
    setSeconds(0)
    setIsActive(false)
  }

  function closeSwapCommands() {
    globalDispatch({ type: 'RESET_FURNITURE_TYPE_SELECTED' })
    globalDispatch({
      type: 'SET_FURNITURE_COMMAND',
      command: 'null',
    })
  }

  // useEffect(() => {
  //   if (!isLoading) {
  //     getListOfFurnitureCall(page);
  //   }
  // }, []);
  //
  useEffect(() => {
    let interval = null
    if (isActive) {
      if (seconds == TIME_TO_UPDATE) {
        if (!viewer) {
          saveExistingPlanCall()
        }
        resetTurnOff()
      }
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1)
      }, 1000)
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds])

  // function LoadingCard() {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <Loading />
  //     </Box>
  //   );
  // }

  // function onEndReached() {
  //   if (atEnd) {
  //     return;
  //   }

  //   setAtEnd(true);
  //   const newPage = page + 1;
  //   setPage(newPage);
  //   getListOfFurnitureCall(newPage);
  // }

  // function getListOfFurnitureCall(newPage) {
  //   setIsLoading(true);
  //   let selectedFurnitureOptions = "";

  //   if (Array.isArray(uploadedImageKeys) && uploadedImageKeys.length > 0) {
  //     uploadedImageKeys.map((imageObj) => {
  //       if (selectedFurnitureType === imageObj) {
  //         const productId = selectedFurnitureType;
  //         const productCategory =
  //           globalState.uploadedImages[productId]?.category;
  //         return (selectedFurnitureOptions = productCategory);
  //       } else {
  //         selectedFurnitureOptions = selectedFurnitureType;
  //       }
  //     });
  //   } else {
  //     selectedFurnitureOptions = selectedFurnitureType;
  //   }

  //   getListOfFurniture({
  //     furnitureOptions: [selectedFurnitureOptions],
  //     designStyles: null,
  //     brandSelections: ["Wayfair_crawl"], // As of now, we only want Wayfair_crawl
  //     randomized: true,
  //     showDisplayOnly: true,
  //     page: newPage,
  //   })
  //     .then((res) => {
  //       setExploreAllOtherFurniture(exploreAllOtherFurniture.concat(res));
  //       setIsLoading(false);
  //       setAtEnd(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setIsLoading(false);
  //       setAtEnd(false);
  //     });
  // }

  function updateSelectedFurniture(furnitureInfo, index) {
    // analytics.track("Design Shared", {
    //   user_email: user?.email,
    //   user_name: user?.first_name,
    //   old_item_link: ,
    //   new_item_link: https://www.amazon.com/dp/B08CZZVTQZ?tag=craftle-20&linkCode=osi&th=1&psc=1,
    // });
    globalDispatch({
      type: 'SET_CURRENT_FURNITURE_SELECTION_IN_RESULT',
      payload: {
        furnitureKey: selectedFurnitureType,
        furnitureInfo: {
          ...furnitureInfo,
          category: productCategory,
        },
        index: index,
      },
    })
    globalDispatch({ type: 'RESET_FURNITURE_COMMAND' })
    setMobileShowOriginalCommands(true)
  }

  function saveExistingPlanCall() {
    setCmsResultsSaving('Saving...')

    saveExistingPlan({
      urlPlanId: urlPlanId,
      selectedFurniturePieces: globalState.selectedFurniturePieces,
      selectedOtherFurniturePieces: globalState.selectedOtherFurniturePieces,
      newPositions:
        globalState.furniturePositions === undefined ||
        Object.keys(globalState.furniturePositions).length === 0
          ? []
          : globalState.furniturePositions,
    })
      .then((res) => {
        console.log(res)
        // setCurrentlySaving(false);
        setTimeout(() => {
          setCmsResultsSaving('Saved')
        }, 2000)
        setTimeout(() => {
          setCmsResultsSaving('')
        }, 4000)
      })
      // .then(() => {
      //   setCmsResultsSaving("Saved");
      // })
      .catch((error) => {
        console.log(error)
      })
  }

  function ourPicks() {
    return (
      <ImageTextCardOurPicksCMSResultsSwapFurniture
        infoBox
        // itemOnClick={() => {
        //   onEndReached();
        // }}
      />
    )
  }

  // function exploreAllOthers() {
  //   return (
  //     <ImageTextCardExploreCMSResultsSwapFurniture
  //       infoBox
  //       itemOnClick={() => {
  //         onEndReached();
  //       }}
  //     />
  //   );
  // }

  let renderFurniturePiece = otherOptions.map((val, index) => {
    return (
      <ImageTextCardExploreCMSResultsSwapFurniture
        key={val.id}
        cardDetail={val}
        itemOnClick={() => {
          updateSelectedFurniture(val, index)
          reset()
        }}
      />
    )
  })

  // let renderExploreAllOthers = exploreAllOtherFurniture.map((val, index) => {
  //   return (
  //     <ImageTextCardExploreCMSResultsSwapFurniture
  //       key={`${val.id}_${index}`}
  //       cardDetail={val}
  //       itemOnClick={() => {
  //         // We don't save the updated plan here
  //         // updateSelectedFurniture(furnitureInfo, index);
  //         updateSelectedFurniture(val, index);
  //         reset();
  //       }}
  //     />
  //   );
  // });

  // function scrollEvent(e) {
  //   if (e.target.scrollLeft === 0) {
  //     console.log("Start reached");
  //   } else if (
  //     e.target.scrollLeft + e.target.offsetWidth >=
  //     e.target.scrollWidth - 50
  //   ) {
  //     onEndReached();
  //   }
  // }

  // function MobileAndTabletVersion() {
  //   return (
  //     <Box
  //       sx={{
  //         ...classes.mobileContainer,
  //         bottom: globalState.currFurnitureCommand === "SWAP" ? "0px" : "-100%",
  //       }}
  //     >
  //       <Box sx={classes.mobileSwapAndCloseContainer}>
  //         <Box
  //           onClick={() => {
  //             globalDispatch({ type: "RESET_FURNITURE_TYPE_SELECTED" });
  //           }}
  //           sx={classes.mobileCloseBtn}
  //         >
  //           <CardMedia
  //             component="img"
  //             image={closeIcon}
  //             alt="Close button"
  //             sx={classes.mobileCloseIcon}
  //           />
  //         </Box>
  //         <Text sx={classes.mobileSwapTxt}>Swap Furniture Piece</Text>
  //         <Box sx={{ width: "24px" }} />
  //       </Box>
  //       <Box
  //         sx={classes.mobilePicksAndOtherContainer}
  //         // onScroll={(e) => {
  //         //   scrollEvent(e);
  //         // }}
  //       >
  //         {ourPicks()}
  //         {renderFurniturePiece}
  //         {/* {exploreAllOthers()}
  //         {renderExploreAllOthers} */}
  //         {/* {isLoading && LoadingCard()} */}
  //       </Box>
  //     </Box>
  //   );
  // }

  function DesktopVersion() {
    return (
      <Box
        ref={ref}
        // onScroll={(e) => {
        //   scrollEvent(e);
        // }}
        sx={{
          ...classes.desktopContainer,
          display:
            globalState.currFurnitureCommand === 'SWAP' ? 'flex' : 'none',
        }}
      >
        <Box sx={classes.desktopCloseBtnAlignment}>
          <Box
            onClick={() => {
              closeSwapCommands()
            }}
            sx={classes.desktopCloseSwapCommandsButton}
          >
            <CardMedia
              component="img"
              image={closeIcon}
              alt="canvas icon"
              sx={{ width: '24px' }}
            />
          </Box>
        </Box>

        <Box sx={classes.itemsContainer}>
          {/* <ScrollButtonLeft scrollRef={ref} bottom="38%" position="sticky" /> */}

          {ourPicks()}
          {renderFurniturePiece}
          {/* {exploreAllOthers()}
        {renderExploreAllOthers} */}
          {/* {isLoading && LoadingCard()} */}

          {/* <ScrollButtonRight scrollRef={ref} bottom="38%" position="sticky" /> */}
        </Box>
      </Box>
    )
  }

  return (
    selectedFurnitureType &&
    // (isMobileOrTabletScreen ? MobileAndTabletVersion() : DesktopVersion())
    DesktopVersion()
  )
}

ImageTextGridCMSResultsSwapFurniture.propTypes = propTypes
ImageTextGridCMSResultsSwapFurniture.defaultProps = defaultProps

export default ImageTextGridCMSResultsSwapFurniture
