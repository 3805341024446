import * as React from 'react'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'

const propTypes = {
  image: PropTypes.node,
  alt: PropTypes.string,
  link: PropTypes.string,
  caption: PropTypes.string,
  newTab: PropTypes.string,
}

const defaultProps = {
  image: null,
  alt: null,
  link: null,
  caption: null,
  newTab: '_self',
}

BlogImage.propTypes = propTypes
BlogImage.defaultProps = defaultProps

export default function BlogImage({ image, alt, link, caption, newTab }) {
  if (newTab === true) {
    newTab = '_blank'
  }

  return (
    <figure style={{ margin: '24px 0px' }}>
      <a target={newTab} href={link} rel="noreferrer">
        <CardMedia
          component="img"
          image={image}
          alt={alt}
          sx={{
            borderRadius: '10px',
          }}
        />
      </a>
      <figcaption
        style={{
          textAlign: 'center',
          color: '#757575',
          marginTop: '10px',
          fontSize: '14px',
        }}
      >
        {caption}
      </figcaption>
    </figure>
  )
}
