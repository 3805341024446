import React from 'react'
import { Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import useMediaQuery from '@mui/material/useMediaQuery'

// import components
import Text from '../../atoms/Text/Text'

// import icons
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1360,
      md: 1000,
      sm: 600,
      xs: 0,
    },
  },
})

function DeleteDesignModal({
  onPressConfirmDeleteButton,
  onPressCancelButton,
}) {
  const isMobileOrTabletScreen = useMediaQuery(
    customTheme.breakpoints.down('md'),
  )

  const classes = {
    mobileCommandOptionText: {
      marginLeft: '16px',
      fontWeight: 'bold',
    },
  }

  function DesktopVersion() {
    return (
      // Background
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Modal */}
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            padding: '64px 40px 32px 40px',
            textAlign: 'center',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: '32px',
              top: '32px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onPressCancelButton()
            }}
          >
            <XIcon />
          </Box>
          <Text sx={{ fontWeight: 'bold', fontSize: '24px', lineHeight: 1.5 }}>
            Are you sure?
          </Text>
          <Box
            sx={{
              marginTop: '32px',
              textAlign: 'center',
              backgroundColor: '#DA3939',
              color: '#FFFFFF',
              fontWeight: 'bold',
              width: '400px',
              padding: '14px 0',
              borderRadius: '30px',
              cursor: 'pointer',
              transitionDuration: '0.4s',
              '&:hover': {
                backgroundColor: '#CB2A2A',
              },
            }}
            onClick={() => {
              onPressConfirmDeleteButton()
            }}
          >
            Yes, Delete Design
          </Box>
          <Box
            sx={{
              marginTop: '24px',
              textAlign: 'center',
              color: '#2a2a2a',
              fontWeight: 'bold',
              cursor: 'pointer',
              transitionDuration: '0.4s',
              '&:hover': {
                color: '#757575',
              },
            }}
            onClick={() => {
              onPressCancelButton()
            }}
          >
            No, Don&apos;t Delete
          </Box>
        </Box>
      </Box>
    )
  }

  function MobileAndTabletVersion() {
    return (
      // Background
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'none',
            [customTheme.breakpoints.down('md')]: {
              position: 'fixed',
              bottom: 0,
              zIndex: 3,
              padding: '16px 32px 32px',
              backgroundColor: '#FFFFFF',
              width: '100vw',
              display: 'flex',
              flexDirection: 'column',
              boxSizing: 'border-box',
              borderRadius: '24px 24px 0 0',
              boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onPressCancelButton()
            }}
          >
            <XIcon />
          </Box>
          <Box
            sx={{
              marginBottom: '24px',
            }}
            onClick={() => {
              onPressConfirmDeleteButton()
            }}
          >
            <Text
              sx={{
                ...classes.mobileCommandOptionText,
                color: '#DA3939',
              }}
            >
              Yes, Delete Design
            </Text>
          </Box>

          <Box
            onClick={() => {
              onPressCancelButton()
            }}
          >
            <Text
              sx={{
                ...classes.mobileCommandOptionText,
                color: '#2a2a2a',
              }}
            >
              No, Don&apos;t Delete
            </Text>
          </Box>
        </Box>
      </Box>
    )
  }

  return isMobileOrTabletScreen ? MobileAndTabletVersion() : DesktopVersion()
}

const propTypes = {
  onPressConfirmDeleteButton: PropTypes.func,
  onPressCancelButton: PropTypes.func,
}

const defaultProps = {
  onPressConfirmDeleteButton: () => {},
  onPressCancelButton: () => {},
}

DeleteDesignModal.propTypes = propTypes
DeleteDesignModal.defaultProps = defaultProps

export default DeleteDesignModal
