import getColor from './ColorClassifier'
import getFurnitureType from './FurnitureTypeClassifier'

export function PotteryBarnService(pageHTML) {
  function getProductDimensions(dom) {
    const productDimensionsWrapper = dom?.querySelector(
      '.dream-pip-dimensions-tab',
    )
    console.log('productDimensionsWrapper: ', productDimensionsWrapper)

    let dimensions = { width: 0, depth: 0, height: 0 }

    if (productDimensionsWrapper != null) {
      const productDimensionElements =
        productDimensionsWrapper?.querySelectorAll('li')
      console.log('first element: ', productDimensionElements[0])

      const productDimensionElement =
        productDimensionElements[1].innerText.toLowerCase()

      console.log('productDimensionElement: ', productDimensionElement)

      let width = 0,
        depth = 0,
        height = 0

      let widthStringEnd = 0
      let depthStringEnd = 0
      let oldDepthStringEnd = 0
      let heightStringEnd = 0

      // getting the ending position of each dimension type in the productDimensionElement string
      for (let i = 0; i < productDimensionElement.length; i++) {
        if (productDimensionElement[i] === 'w') {
          widthStringEnd = i - 2
          width = parseFloat(
            productDimensionElement.substring(0, widthStringEnd),
          )
        }
        if (productDimensionElement[i] === 'd') {
          depthStringEnd = i - 2
          if (oldDepthStringEnd === 0) {
            depth = parseFloat(
              productDimensionElement.substring(
                widthStringEnd + 6,
                depthStringEnd,
              ),
            )
          }
          if (oldDepthStringEnd > 0) {
            height = parseFloat(
              productDimensionElement.substring(
                oldDepthStringEnd + 6,
                depthStringEnd,
              ),
            )
          }
          oldDepthStringEnd = depthStringEnd
        }
        if (productDimensionElement[i] === 'h') {
          heightStringEnd = i - 2
          height = parseInt(
            productDimensionElement.substring(
              depthStringEnd + 6,
              heightStringEnd,
            ),
          )
        }
      }

      dimensions = { width: width, depth: depth, height: height }
    }

    return dimensions
  }

  const dom = pageHTML
  const retrieveInnerHTML = dom.getElementsByTagName('body')[0].innerHTML
  let productDetails = {}

  console.log('checkmark 1')

  // Get product name
  let productName = dom.querySelector(
    '.pip-info > .pip-summary > h1',
  )?.innerText
  if (productName === undefined) {
    const productNameContainer = dom.querySelector('title')?.innerText
    productName = productNameContainer.substring(
      0,
      productNameContainer.indexOf('|'),
    )
  }
  console.log('productName: ', productName)

  // Get image
  let imageUrl = dom.querySelector('.hero-image > a > img')?.getAttribute('src')
  if (imageUrl === undefined) {
    imageUrl = ''
  }
  console.log('imageURL: ', imageUrl)

  // // Get product description
  // let productDescription = dom.querySelector(
  //   ".product-summary-copy"
  // )?.innerText;
  // if (productDescription === undefined) {
  //   productDescription = "";
  // }
  // console.log("productDescription:", productDescription);

  // Get dimensions
  const dimensions = getProductDimensions(dom)
  console.log('dimensions:', dimensions)

  // Get furniture type
  let title = []
  title.push(dom.querySelector('.pip-summary > h1')?.innerText.trim())
  let furnitureType = ''
  if (title[0] != null) {
    furnitureType = getFurnitureType(title)
  }
  console.log('furnitureType:', furnitureType)

  // Get price
  let priceStringStart = retrieveInnerHTML.substring(
    retrieveInnerHTML.indexOf(`"lowPrice":`),
  )
  let priceString = priceStringStart.substring(
    11,
    priceStringStart.indexOf(`,"highPrice"`),
  )
  let price = parseFloat(priceString)
  console.log('price:1', price)
  if (isNaN(price)) {
    console.log('inside')
    priceStringStart = retrieveInnerHTML.substring(
      retrieveInnerHTML.indexOf(`"retailPrice":`),
    )
    priceString = priceStringStart.substring(
      14,
      priceStringStart.indexOf(`,"sellingPrice"`),
    )
    console.log('priceString:', priceString)
    price = parseFloat(priceString)
  }
  console.log('price:', price)

  // Get color
  let color = ''
  const colorAndFabricString = dom
    .querySelector('.image-caption.imageCaption')
    ?.innerText.trim()
  console.log('colorAndFabricString:', colorAndFabricString)
  if (colorAndFabricString != null) {
    const colorAndFabric = getColor(colorAndFabricString)
    console.log('colorAndFabric:', colorAndFabric)
    if (colorAndFabric.length === 1) {
      // If only color exists and there's no fabric
      color = colorAndFabric[0]
    } else {
      // If color and fabric exist
      color = colorAndFabric[1]
    }
  }

  // Add brand
  const brand = 'Pottery Barn'

  // combine all variables into an object
  productDetails = {
    productName: productName,
    imageURL: imageUrl,
    // description: productDescription,
    furnitureType: furnitureType,
    width: dimensions.width,
    depth: dimensions.depth,
    height: dimensions.height,
    color: color,
    price: price,
    brand: brand,
  }

  return productDetails
}
