import React, { useContext } from 'react'
import { Grid, Box } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import { classes } from './CMSSelectItemsStyle'
import ImageTextGridCheckBox from '../../../components/organisms/ImageTextGridCheckBox/ImageTextGridCheckBox'
import TextDivider from '../../../components/molecules/TextDivider/TextDivider'
import ContinueSection from '../../../components/molecules/ContinueSection/ContinueSection'
import mixpanel from '../../../components/atoms/Mixpanel/Mixpanel'
import { UserContext } from '../../../App'

// import constants
import { furnitureOptions, decorSoftFurnishingsOptions } from '../constants'

// import context
import { Context } from '../../../context/context'

function CMSSelectItems({ onNextPress }) {
  const [globalState, globalDispatch] = useContext(Context)

  const { user } = React.useContext(UserContext)
  const email = user?.email || 'N/A'

  const furnitureCards = furnitureOptions.map((card) => {
    return {
      ...card,
      onClick: () =>
        globalDispatch({
          type: 'UPDATE_FURNITURE_SELECTED_CHECKBOX_VALUE',
          furnitureKey: card.labelKey,
        }),
    }
  })

  const decorSoftFurnishingsCards = decorSoftFurnishingsOptions.map((card) => {
    return {
      ...card,
      onClick: () =>
        globalDispatch({
          type: 'UPDATE_FURNITURE_SELECTED_CHECKBOX_VALUE',
          furnitureKey: card.labelKey,
        }),
    }
  })

  const anySelected = Object.values(
    globalState.furnitureOptionsChecked,
  ).includes(true)

  return (
    <Box sx={classes.container}>
      <TextDivider text="Furniture" />
      <Grid container>
        <ImageTextGridCheckBox
          cardArray={furnitureCards}
          selectedKeys={globalState.furnitureOptionsChecked}
        />
      </Grid>
      <TextDivider text="Décor and Soft Furnishings" />
      <ImageTextGridCheckBox
        cardArray={decorSoftFurnishingsCards}
        selectedKeys={globalState.furnitureOptionsChecked}
      />
      {anySelected && (
        <ContinueSection
          position="sticky"
          onClick={() => {
            mixpanel.track('(CMS) Selected Items', {
              email: email,
            })
            globalDispatch({
              type: 'TRANSFORM_FURNITURE_SELECTED_CHECKBOX_INTO_BACKEND',
            })
            onNextPress(null, null)
          }}
        />
      )}
    </Box>
  )
}

const propTypes = {
  propName: PropTypes.string.isRequired,
  onNextPress: PropTypes.func.isRequired,
  callAlert: PropTypes.func,
}

const defaultProps = {
  callAlert: () => {},
}

CMSSelectItems.propTypes = propTypes
CMSSelectItems.defaultProps = defaultProps

export default CMSSelectItems
