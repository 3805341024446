import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '24px',
    [customTheme.breakpoints.down('sm')]: {},
  },
  buttonWrapper: {},
}
