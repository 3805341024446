import React from 'react'
import Footer from '../../components/organisms/Footer/Footer'
import CardMedia from '@mui/material/CardMedia'

// import components
import BlogParallaxRoot from '../../components/molecules/BlogParallaxRoot/BlogParallaxRoot'
import BlogParallaxLayerBack from '../../components/molecules/BlogParallaxLayerBack/BlogParallaxLayerBack'
import BlogParallaxLayerMiddle from '../../components/molecules/BlogParallaxLayerMiddle/BlogParallaxLayerMiddle'
import BlogParallaxLayerFront from '../../components/molecules/BlogParallaxLayerFront/BlogParallaxLayerFront'
import BlogContentContainer from '../../components/molecules/BlogContentContainer/BlogContentContainer'
import BlogDate from '../../components/molecules/BlogDate/BlogDate'
import BlogTitle from '../../components/molecules/BlogTitle/BlogTitle'
import BlogParagraph from '../../components/molecules/BlogParagraph/BlogParagraph'
import BlogH2 from '../../components/molecules/BlogH2/BlogH2'
import BlogH3 from '../../components/molecules/BlogH3/BlogH3'
import BlogImage from '../../components/molecules/BlogImage/BlogImage'
import BlogInlineAd1 from '../../components/molecules/BlogInlineAd1/BlogInlineAd1'
import BlogInlineAd2 from '../../components/molecules/BlogInlineAd2/BlogInlineAd2'

// import images
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog4/blog-4-banner.jpg'
const image1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog4/blog-4-image-1.jpg'
const image2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog4/blog-4-image-2.jpg'

function BlogPost4() {
  return (
    <BlogParallaxRoot>
      <BlogParallaxLayerBack>
        <CardMedia component="img" image={bannerImage} alt="" />
      </BlogParallaxLayerBack>

      <BlogParallaxLayerMiddle />

      <BlogParallaxLayerFront>
        <BlogContentContainer>
          <BlogDate date="May 8, 2022" />
          <BlogTitle title="How is Furniture Measured? Everything You Need to Know Easily Explained" />
          <BlogParagraph>
            When shopping for furniture, it is important to understand the
            different dimensions that are used by manufacturers. This will help
            you determine whether a piece will fit in your home and whether it
            is the right size for your needs.
          </BlogParagraph>
          <BlogParagraph>
            Several different measurements are commonly used when describing
            furniture. These include width, depth, height, and seat height.
          </BlogParagraph>

          <BlogH2 title="How is Furniture Measured?" />
          <BlogParagraph>
            Most furniture is measured in inches, although some companies use
            centimeters. It is important to know which measurement system the
            company you are buying from uses so that you can be sure to get the
            right size.
          </BlogParagraph>

          <BlogInlineAd1 />

          <BlogH2 title="How to Measure a Furniture Item" />
          <BlogImage image={image1} alt="" caption="" />
          <BlogH3 title="Length" />
          <BlogParagraph>
            The length of a piece of furniture is typically its longest
            dimension. To measure the length, you simply need to measure from
            end to end.
          </BlogParagraph>
          <BlogH3 title="Width" />
          <BlogParagraph>
            The width of a piece of furniture is typically its second-longest
            dimension. To measure the width, you will need to measure from one
            side to the other.
          </BlogParagraph>
          <BlogH3 title="Height" />
          <BlogParagraph>
            The height of a piece of furniture is typically its third-longest
            dimension. To measure the height, you will need to measure from the
            top to the bottom.
          </BlogParagraph>
          <BlogH3 title="Seat Height" />
          <BlogParagraph>
            The seat height of a piece of furniture is measured from the ground
            to the top of its surface, such as a chair or sofa cushion. This
            measurement is important for determining whether a piece will be
            comfortable for you to sit on.
          </BlogParagraph>
          <BlogH3 title="Back Height" />
          <BlogParagraph>
            The back height of a piece of furniture is measured from the ground
            to the top of its backrest. This measurement is important for
            determining whether a piece will provide adequate support for your
            back.
          </BlogParagraph>
          <BlogH3 title="Diagonal Depth" />
          <BlogParagraph>
            The diagonal depth of a piece of furniture is measured from one
            corner to the opposite corner. This measurement can be useful for
            determining whether a piece will fit in a particular space.
          </BlogParagraph>

          <BlogH2 title="Measuring Your Space" />
          <BlogImage image={image2} alt="" caption="" />
          <BlogParagraph>
            If you are shopping online, it can be difficult to know exactly what
            size of furniture will fit in your home. To ensure that you get the
            right size, it is important to measure your space before you start
            shopping.
          </BlogParagraph>
          <BlogParagraph>
            To measure your space, you will need to take three measurements: the
            length, the width, and the height.
          </BlogParagraph>
          <BlogH3 title="Length" />
          <BlogParagraph>
            To measure the length of your space, you will need to measure from
            one end to the other.
          </BlogParagraph>
          <BlogH3 title="Width" />
          <BlogParagraph>
            To measure the width of your space, you will need to measure from
            one side to the other.
          </BlogParagraph>
          <BlogH3 title="Height" />
          <BlogParagraph>
            To measure the height of your space, you will need to measure from
            the floor to the ceiling. Once you have these measurements, you can
            use them to determine exactly what size piece of furniture will fit
            in your space. This way, you can purchase with confidence and know
            that your new furniture will look great in your home.
          </BlogParagraph>

          <BlogH2 title="Wrapping it Up" />
          <BlogParagraph>
            If you are still unsure about which size piece to get, it can be
            helpful to consult with a furniture expert or read online reviews
            from other customers. With the right information, you will be able
            to find the perfect piece of furniture for your space and enjoy
            years of comfort and style.
          </BlogParagraph>

          <BlogInlineAd2 />
        </BlogContentContainer>
        <Footer />
      </BlogParallaxLayerFront>
    </BlogParallaxRoot>
  )
}

export default BlogPost4
