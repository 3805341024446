import * as React from 'react'
// import { Box } from "@mui/material";
import PropTypes from 'prop-types'

// import components
import Text from '../../../components/atoms/Text/Text'
import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

BlogTitle.propTypes = {
  title: PropTypes.string,
}

BlogTitle.defaultProps = {
  title: null,
}

export default function BlogTitle({ title }) {
  return (
    <Text
      variant="h1"
      sx={{
        fontSize: '48px',
        margin: '8px 0',
        [customTheme.breakpoints.down('sm')]: {
          fontSize: '40px',
        },
      }}
    >
      {title}
    </Text>
  )
}
