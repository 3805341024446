import * as React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { createTheme } from '@mui/material/styles'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

BlogContentContainer.propTypes = propTypes
BlogContentContainer.defaultProps = defaultProps

let customTheme = createTheme({})

export default function BlogContentContainer({ children }) {
  return (
    <Box
      sx={{
        maxWidth: '900px',
        width: '100%',
        fontSize: '20px',
        lineHeight: 1.5,
        backgroundColor: '#FFFFFF',
        zIndex: 1,
        paddingTop: '64px',
        paddingBottom: '64px',
        borderRadius: '20px',
        boxSizing: 'border-box',
        [customTheme.breakpoints.down('md')]: {
          paddingTop: '32px',
        },
        [customTheme.breakpoints.down('sm')]: {
          paddingTop: '32px',
        },
      }}
    >
      {children}
    </Box>
  )
}
