import React from 'react'
import { Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import Button from '../../atoms/Button/Button'

let customTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 425,
      zero: 0,
    },
  },
})

const propTypes = {
  position: PropTypes.oneOf(['relative', 'sticky']),
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

const defaultProps = {
  position: 'relative',
  buttonText: 'Continue',
  onClick: () => {},
  disabled: false,
}

function ContinueSection({ position, buttonText, onClick, disabled }) {
  const disabledBackgroundColor = '#919191'
  const notDisabledBackgroundColor = '#2A2A2A'

  return (
    <Box
      padding={0}
      sx={{
        position: { position },
        width: '100%',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        paddingTop: '25px',
        paddingBottom: '25px',
        backgroundColor: 'rgba(255,255,255, 0.85)',
        zIndex: 10,
      }}
    >
      {disabled && (
        <Button
          // color="primary"
          // variant="contained"
          variant="primary"
          // sx={{ boxShadow: "none" }}
          onClick={onClick}
          disabled={disabled}
          style={{ maxWidth: '335px', width: '100%' }}
          sx={{
            maxWidth: '335px',
            width: '100%',
            backgroundColor: disabledBackgroundColor,
            [customTheme.breakpoints.down('sm')]: {
              maxWidth: 'inherit',
            },
          }}
        >
          {buttonText}
        </Button>
      )}
      {!disabled && (
        <Button
          // color="primary"
          // variant="contained"
          variant="primary"
          // sx={{ boxShadow: "none" }}
          onClick={onClick}
          disabled={disabled}
          style={{ maxWidth: '335px', width: '100%' }}
          sx={{
            maxWidth: '335px',
            width: '100%',
            backgroundColor: notDisabledBackgroundColor,
            [customTheme.breakpoints.down('sm')]: {
              maxWidth: 'inherit',
            },
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  )
}

ContinueSection.propTypes = propTypes
ContinueSection.defaultProps = defaultProps

export default ContinueSection
