import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  // guestModeNotification function styles
  desktopContainer: {
    position: 'sticky',
    bottom: '40px',
    backgroundColor: '#FFFFFF',
    padding: '8px 16px',
    zIndex: 3,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
    width: '95%',
    maxWidth: '1200px',
    boxSizing: 'border-box',
    // overflowX: "hidden",
    // overflowX: "scroll",
    scrollBehavior: 'smooth',
    gap: '8px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    [customTheme.breakpoints.down('md')]: {
      width: '100%',
      bottom: 0,
      maxWidth: 'inherit',
    },
  },
  desktopCloseBtnAlignment: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  desktopCloseSwapCommandsButton: {
    // top: "16px",
    // position: "absolute",
    right: '16px',
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: '4px',
    borderRadius: '16px',
    transitionDuration: '0.3s',
    '&:hover': {
      backgroundColor: 'rgba(250, 250, 250, 0.75)',
    },
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
  },

  // mobileContainer: {
  //   display: "none",
  //   [customTheme.breakpoints.down("md")]: {
  //     position: "fixed",
  //     zIndex: 2,
  //     padding: "16px 0 32px 16px",
  //     backgroundColor: "#FFFFFF",
  //     width: "100vw",
  //     display: "flex",
  //     flexDirection: "column",
  //     boxSizing: "border-box",
  //     borderRadius: "24px 24px 0 0",
  //     boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.15)",
  //     transition: "bottom 0.5s ease",
  //   },
  // },
  // mobileSwapAndCloseContainer: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   marginBottom: "24px",
  // },
  // mobileCloseBtn: {
  //   width: "24px",
  //   cursor: "pointer",
  // },
  // mobileCloseIcon: {
  //   width: "24px",
  //   height: "24px",
  // },
  // mobileSwapTxt: {
  //   fontSize: "20px",
  //   fontWeight: "bold",
  // },
  // mobilePicksAndOtherContainer: {
  //   [customTheme.breakpoints.down("md")]: {
  //     display: "flex",
  //     flexDirection: "row",
  //     overflowX: "scroll",
  //     width: "100vw",
  //     "&::-webkit-scrollbar": {
  //       display: "none",
  //     },
  //   },
  // },
}
