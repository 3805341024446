import React from 'react'
import { Box, Input } from '@mui/material'
import PropTypes from 'prop-types'

export default function Textfield({
  label,
  id,
  onKeyDown,
  type,
  name,
  placeholder,
  onChange,
  value,
  fontSize,
  fontWeight,
  inputProps,
  autoComplete,
  border,
  backgroundColor,
  sx,
}) {
  const classes = {
    labelStyle: {
      fontWeight: 'bold',
    },
    inputFieldStyle: {
      border: border,
      height: '48px',
      width: '100%',
      borderRadius: '24px',
      backgroundColor: backgroundColor,
      fontSize: fontSize,
      fontWeight: fontWeight,
    },
  }

  return (
    <Box sx={sx}>
      <label htmlFor={name} style={classes.labelStyle}>
        {label}
      </label>
      <Input
        placeholder={placeholder}
        // variant="standard"
        name={name}
        id={id}
        onKeyDown={onKeyDown}
        disableUnderline={true}
        type={type}
        onChange={onChange}
        value={value}
        inputProps={inputProps}
        autoComplete={autoComplete}
        sx={classes.inputFieldStyle}
      />
    </Box>
  )
}

Textfield.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onKeyDown: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  inputProps: PropTypes.object,
  autoComplete: PropTypes.string,
  border: PropTypes.string,
  backgroundColor: PropTypes.string,
  sx: PropTypes.object,
}
Textfield.defaultProps = {
  label: '',
  id: '',
  onKeyDown: null,
  type: '',
  name: '',
  placeholder: '',
  onChange: null,
  value: '',
  fontSize: '16px',
  fontWeight: 'inherit',
  inputProps: {},
  autoComplete: '',
  border: '2px solid #BFBFBF',
  backgroundColor: '#FFF',
  sx: {},
}
