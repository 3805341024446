import * as React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'

const propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  color: PropTypes.string,
  marginBottom: PropTypes.string,
  onClick: PropTypes.func,
}

const defaultProps = {
  icon: null,
  text: null,
  color: '#2a2a2a',
  marginBottom: null,
  onClick: () => {},
}

MobileCMSResultsCommand.propTypes = propTypes
MobileCMSResultsCommand.defaultProps = defaultProps

export default function MobileCMSResultsCommand({
  icon,
  text,
  color,
  marginBottom,
  onClick,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: marginBottom,
        cursor: 'pointer',
      }}
      onClick={() => {
        onClick()
      }}
    >
      {icon}
      <Text sx={{ color: color, marginLeft: '16px', fontWeight: 'bold' }}>
        {text}
      </Text>
    </Box>
  )
}
