import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// import components
import LandingFurniture from '../../molecules/LandingFurniture/LandingFurniture'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import images
const WeirdIMG1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-1.jpeg'
const WeirdIMG2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-2.jpeg'
const WeirdIMG3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-3.jpeg'
const WeirdIMG4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-4.jpeg'
const WeirdIMG5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-5.jpeg'
const WeirdIMG6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-6.jpeg'
const WeirdIMG7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-7.jpeg'
const WeirdIMG8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-8.jpeg'
const WeirdIMG9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-9.jpeg'
const WeirdIMG10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-10.jpeg'
const WeirdIMG11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-11.jpeg'
const WeirdIMG12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-12.jpeg'
const WeirdIMG13 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-13.jpeg'
const WeirdIMG14 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-14.jpeg'
const WeirdIMG15 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/weird-15.jpeg'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingWeirdAsF() {
  const ref = useRef(null)

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: '90vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '48px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#2a2a2a',
            fontSize: '32px',
            fontWeight: 'bold',
          }}
        >
          👽 Weird as F#$%
        </Typography>

        <Typography
          sx={{
            color: '#2a2a2a',
            fontSize: '16px',
            marginTop: '16px',
          }}
        >
          With some furniture, you have to ask “Why did they even make this…and
          why do I want it?”
        </Typography>

        <ScrollButtonLeft scrollRef={ref} />
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
            width: '95vw',
            marginLeft: '-5vw',
            overflowX: 'scroll',
            paddingLeft: '5vw',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <LandingFurniture
            path="/furniture/hollypalmerfurniture/tea-cup-stool-alice-in-wonderland-designer-seating"
            image={WeirdIMG1}
            brand="HollyPalmerFurniture"
            type="Accent Chair"
            price="233.41"
            url="https://www.etsy.com/listing/230211323/tea-cup-stool-alice-in-wonderland?gpla=1&gao=1&"
          />
          <LandingFurniture
            path="/furniture/fatty-bee/face-vase"
            image={WeirdIMG2}
            brand="Fatty Bee"
            type="Vase"
            price="36.95"
            url="https://www.amazon.com/Modern-Minimalism-Ceramic-Sculpture-Decoration/dp/B07ZMQ13RJ/ref=sr_1_69?keywords=weird%2Bfurniture&qid=1654966756&sr=8-69&th=1"
          />
          <LandingFurniture
            path="/furniture/homary-acrylic-end-table/homary-acrylic-end-table"
            image={WeirdIMG3}
            brand="Homary"
            type="Side Table"
            price="191.99"
            url="https://www.amazon.com/Homary-Acrylic-Modern-Accent-Iridescent/dp/B08B3PYFMC/ref=sr_1_54?keywords=weird+furniture&qid=1654966589&sr=8-54"
          />
          <LandingFurniture
            path="/furniture/woodcurve/handmade-4-ft-curved-bookshelf-oak-stained"
            image={WeirdIMG4}
            brand="WoodCurve"
            type="Bookshelf"
            price="799.99"
            url="https://www.etsy.com/listing/63344489/free-shippinghandmade-4ft-curved?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=unique+furniture&ref=sr_gallery-1-9&frs=1"
          />
          <LandingFurniture
            path="/furniture/ilikepar/mysterious-gummy-bear-scented-candle"
            image={WeirdIMG5}
            brand="iLikePar"
            type="Candle"
            price="15.99"
            url="https://www.amazon.com/Mysterious-Scented-Candle-Secret-Hidden/dp/B08HXRRGYJ/ref=sr_1_71_sspa?keywords=weird%2Bfurniture&qid=1654966756&sr=8-71-spons&smid=A4WQTF6RNMSBT&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExU0pDWjQyR01TUDNRJmVuY3J5cHRlZElkPUEwMjc0MTcwSDJLSFlZWFJBNFhYJmVuY3J5cHRlZEFkSWQ9QTA4OTc4NDUyQk1HOTk3WUsxTDUzJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ&th=1"
          />
          <LandingFurniture
            path="/furniture/rogue-wolf/witch-couldron-coffee-mug-in-gift-box-by-rogue"
            image={WeirdIMG6}
            brand="Rogue + Wolf"
            type="Coffee Mug"
            price="22.70"
            url="https://www.amazon.com/Rogue-Wolf-Porcelain-Halloween-Witchcraft/dp/B074W5XDMF/ref=sr_1_72_sspa?keywords=weird+furniture&qid=1654966756&sr=8-72-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExU0pDWjQyR01TUDNRJmVuY3J5cHRlZElkPUEwMjc0MTcwSDJLSFlZWFJBNFhYJmVuY3J5cHRlZEFkSWQ9QTAwNzQxNjUxMEpINTZRQVRKMVJXJndpZGdldE5hbWU9c3BfbXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ=="
          />
          <LandingFurniture
            path="/furniture/hroome/unique-beside-table-lamp-cat"
            image={WeirdIMG7}
            brand="HROOME"
            type="Table Lamp"
            price="49.98"
            url="https://www.amazon.com/HROOME-Bedside-Adjustable-Dimmable-Bookcase/dp/B091FSBCB1/ref=sr_1_74?keywords=weird%2Bfurniture&qid=1654966756&sr=8-74&th=1"
          />
          <LandingFurniture
            path="/furniture/helenromanenko/alice-in-wonderland-furniture-personalized-baby-book-box"
            image={WeirdIMG8}
            brand="HelenRomanenko"
            type="Jewelry Box"
            price="280"
            url="https://www.etsy.com/listing/1221098371/alice-in-wonderland-furniture?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=alice+in+wonderland+furniture&ref=sr_gallery-1-20&sts=1"
          />
          <LandingFurniture
            path="/furniture/design-toscano/vintage-decor-stacked-books-end-table-with-glass-top"
            image={WeirdIMG9}
            brand="Design Toscano"
            type="End Table"
            price="238.26"
            url="https://www.amazon.com/Design-Toscano-Vintage-Stacked-Polyresin/dp/B002VM47SG/ref=sr_1_38?keywords=weird+furniture&qid=1654966589&sr=8-38"
          />
          <LandingFurniture
            path="/furniture/1st-dibs/black-velvet-lounge-chair-with-solid-brass-legs-and-hardware"
            image={WeirdIMG10}
            brand="1st Dibs"
            type="Lounge Chair"
            price="45,000"
            url="https://www.1stdibs.com/furniture/seating/lounge-chairs/black-velvet-lounge-chair-solid-brass-legs-hardware/id-f_20093082/?utm_content=condensed&allowUniversalLink=no&gclsrc=aw.ds&gclid=Cj0KCQjw-pCVBhCFARIsAGMxhAc2z8YFbKdCfSJGtLYzw-18BJZSFtU0V0Z61sUizR0utT0k6NBq3vsaAnYTEALw_wcB&gclsrc=aw.ds"
          />
          <LandingFurniture
            path="/furniture/wayfair/costanza-43-wide-tufted-genuine-leather-armchair"
            image={WeirdIMG11}
            brand="Willa Arlo™ Interiors"
            type="Armchair"
            price="1,699.99"
            url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-costanza-43-wide-tufted-leather-match-armchair-w003806696.html"
          />
          <LandingFurniture
            path="/furniture/alibaba/antique-designer-strange-loung-chair-rabbit-fabric-chair"
            image={WeirdIMG12}
            brand="Alibaba"
            type="Loung Chair"
            price="350"
            url="https://www.alibaba.com/product-detail/Antique-Designer-Strange-lounge-chair-Rabbit_1600097123732.html?mark=google_shopping&seo=1"
          />
          <LandingFurniture
            path="/furniture/freezah/jotaro-kujo-from-Jojos-bizarre-adventure-affected-by-whitesnake-credenza"
            image={WeirdIMG13}
            brand="Freezah"
            type="Credenza"
            price="849.15"
            url="https://society6.com/product/jotaro-kujo-from-jojos-bizarre-adventure-affected-by-whitesnake_credenza?sku=s6-10780770p79a226v783a227v786&utm_campaign=2426&utm_source=google&utm_medium=organic"
          />
          <LandingFurniture
            path="/furniture/sergio-villa/pensiero-summer-armchair"
            image={WeirdIMG14}
            brand="Sergio Villa"
            type="Accest Chair"
            price="12,240"
            url="https://artemest.com/products/pensiero-summer-armchair"
          />
          <LandingFurniture
            path="/furniture/kukigifts/stainless-steel-vintage-liquor-cabinet"
            image={WeirdIMG15}
            brand="KukiGifts"
            type="Liquor Cabinet"
            price="2,796.50"
            url="https://www.etsy.com/listing/1238940655/impressive-and-unique-brown-acrylic-and?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=unique+furniture&ref=sc_gallery-1-11&pro=1&frs=1&plkey=98c534bef8c1fcc1129bc61d4b3a8484304c451f%3A1238940655"
          />
        </Box>
        <ScrollButtonRight scrollRef={ref} />

        {/* <LandingSeeMore
          text="See more Weird as F#$%"
          mixpanelTracking="See More Weird as f#$%"
        /> */}
      </Box>
    </ThemeProvider>
  )
}

export default LandingWeirdAsF
