import * as React from 'react'
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import { Box } from '@mui/material'
// import { createTheme } from "@mui/material/styles";
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'

// import icons
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg'

// let customTheme = createTheme({});

const propTypes = {
  icon: PropTypes.string,
  boldText: PropTypes.string,
  regularText: PropTypes.string,
  xIconPressed: PropTypes.func,
}

const defaultProps = {
  icon: '',
  boldText: '',
  regularText: '',
  xIconPressed: () => {},
}

function IBubble({ icon, boldText, regularText, xIconPressed }) {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      color: '#2a2a2a',
      padding: '11px 24px',
      borderRadius: '100px',
      boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
      margin: '0 8px',
    },
    icon: {
      width: '24px',
      height: '24px',
      display: 'inline-block',
      marginRight: '8px',
    },
    text: {
      display: 'inline-block',
    },
    close: {
      marginLeft: '8px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }

  function getIcon(icon) {
    return (
      <Box sx={{ ...styles.icon }}>
        {icon == 'user' ? <UserIcon /> : 'Examples'}
      </Box>
    )
  }

  return (
    <Box sx={{ ...styles.container }}>
      {getIcon(icon)}
      <Text sx={{ ...styles.text }}>
        <span style={{ fontWeight: 'bold' }}>{boldText}</span>
        {regularText}
      </Text>
      <Box
        sx={{ ...styles.close }}
        onClick={() => {
          xIconPressed(false)
        }}
      >
        <XIcon />
      </Box>
    </Box>
  )
}

IBubble.propTypes = propTypes
IBubble.defaultProps = defaultProps

export default IBubble
