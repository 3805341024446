import React, { useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'
import { Box } from '@mui/material'
import { AnalyticsBrowser } from '@segment/analytics-next'
import PropTypes from 'prop-types'

// Import components
import { classes } from './LogInStyle'
import Text from '../../components/atoms/Text/Text'
import GoogleLoginCallToBackend from './GoogleLoginCallToBackend'
import { segmentWriteKey } from '../../components/atoms/Segment/Segment'

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

const GoogleSignUpButton = ({
  redirectHref,
  userPreferences,
  step,
  setStep,
  setUser,
}) => {
  const [showErrorMsg, setShowErrorMsg] = useState(false)

  useEffect(() => {
    initializeGoogleSignIn()
  }, [])

  const initializeGoogleSignIn = () => {
    const clientConfig = {
      client_id:
        '510488826764-1d9dquloi17heo3nfbik17svto28i821.apps.googleusercontent.com',
    }

    const buttonDiv = document.getElementById('buttonDiv')
    const outerContainerWidth = window.getComputedStyle(
      buttonDiv.parentElement,
    ).width

    if (window.google && window.google.accounts) {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id: clientConfig.client_id,
        callback: onSignIn,
      })

      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(document.getElementById('buttonDiv'), {
        theme: 'filled_black',
        size: 'large',
        shape: 'pill',
        width: outerContainerWidth,
        text: 'signup_with',
      })

      // eslint-disable-next-line no-undef
      google.accounts.id.prompt()
    } else {
      console.error('Google SDK is not loaded yet!')
    }
  }

  const decodeGoogleResponse = (idToken) => {
    try {
      const decodedToken = jwtDecode(idToken)
      return decodedToken
    } catch (error) {
      console.error('Error decoding Google response:', error)
      return null
    }
  }

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('user', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  async function onSignIn(response) {
    // Handle the sign-in response here, e.g., save user data to state, dispatch an action, etc.
    const id_token = response.credential
    const currentDateTime = new Date() // Current to the user's timezone

    // Decode the Google response credentials to get user information
    const decodedToken = decodeGoogleResponse(id_token)
    if (decodedToken) {
      // You can now access user information from the decodedToken object.
      // For example:
      const { email, given_name } = decodedToken
      let userInfo = {
        email: email || '',
        firstName: given_name || '',
        accountCreatedDateTime: currentDateTime.toLocaleString() || '',
      }

      // Get data from the DB
      const response = await GoogleLoginCallToBackend(userInfo, userPreferences)

      if (response) {
        analytics.track('Account Created', {
          user_email: userInfo.email,
          user_name: userInfo.firstName || '',
        })
        // Store the user in localStorage
        saveUserToken(response).then(() => {
          const signupComplete = response.signup_complete || false

          if (signupComplete) {
            // Redirects to the given link, else defaults to homepage ("/")
            document.location.href = redirectHref
          } else if (step) {
            // If user is currently in the early step of the signup flow, continue the flow.
            setUser(response)
            setStep(step + 1)
          } else {
            document.location.href = '/sign-up'
          }
        })
      } else {
        setShowErrorMsg(true)
      }
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      {showErrorMsg ? (
        <Text sx={classes.errorMsg}>
          We couldn&apos;t log you in using Google Sign In. Please try again, or
          use Email and Password below.
        </Text>
      ) : null}
      <div id="buttonDiv" style={{ width: '100%' }}></div>
    </Box>
  )
}

GoogleSignUpButton.propTypes = {
  redirectHref: PropTypes.string,
  userPreferences: PropTypes.object,
  step: PropTypes.number,
  setStep: PropTypes.func,
  setUser: PropTypes.func,
}

GoogleSignUpButton.defaultProps = {
  redirectHref: '/',
  userPreferences: {},
  step: null,
  setStep: () => {}, // Default to a no-op function
  setUser: () => {},
}

export default GoogleSignUpButton
