import getColor from './ColorClassifier'
import getFurnitureType from './FurnitureTypeClassifier'
import getMisc from './MiscellaneousClassifier'

export function LuluAndGeorgia(pageHTML) {
  function getProductDimensions(retrieveInnerHTML) {
    const productDimensionsStart = retrieveInnerHTML.indexOf(
      'Overall Dimensions:',
    )
    const productDimensionsWrapper = retrieveInnerHTML.substring(
      productDimensionsStart + 19,
      productDimensionsStart + 40,
    )

    const productDimensionElement = productDimensionsWrapper.toLowerCase()
    console.log('productDimensionElement: ', productDimensionElement) // remove later

    let width = 0,
      depth = 0,
      height = 0

    let widthStringEnd = 0
    let depthStringEnd = 0
    let oldDepthStringEnd = 0
    let heightStringEnd = 0

    // getting the position of each dimension type in the productDimensionElement string
    for (let i = 0; i < productDimensionElement.length; i++) {
      if (productDimensionElement[i] === 'w') {
        widthStringEnd = i - 1
        width = parseFloat(productDimensionElement.substring(0, widthStringEnd))
      }
      if (productDimensionElement[i] === 'd') {
        depthStringEnd = i - 1
        if (oldDepthStringEnd === 0) {
          depth = parseFloat(
            productDimensionElement.substring(
              widthStringEnd + 5,
              depthStringEnd,
            ),
          )
        }
        oldDepthStringEnd = depthStringEnd
      }
      if (productDimensionElement[i] === 'h') {
        heightStringEnd = i - 1
        height = parseInt(
          productDimensionElement.substring(
            depthStringEnd + 5,
            heightStringEnd,
          ),
        )
      }
    }

    const dimensions = { width: width, depth: depth, height: height }

    return dimensions
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
  }

  const dom = pageHTML
  const retrieveInnerHTML = dom.getElementsByTagName('body')[0].innerHTML
  let productDetails = {}

  // Get product name
  const productName = dom.querySelector('h1').innerText
  console.log('productName: ', productName)

  // Get furniture type
  let title = []
  title.push(productName.trim())
  const furnitureType = getFurnitureType(title)
  console.log('furnitureType: ', furnitureType)

  // Get color
  let color = ''
  const colorContainer = dom
    .querySelector('div > meta')
    ?.getAttribute('content')
  console.log('colorContainer:', colorContainer)
  let colorString = colorContainer.substring(colorContainer.indexOf('-') + 2)
  console.log('colorString:', colorString)
  let colorAndFabric = getColor(colorString)
  if (colorAndFabric == null) {
    // If color not established, search description for color
    const descriptionContainer = dom.querySelectorAll(
      '.js-product-details > .js-product-details__content > ul > li',
    )
    console.log('descriptionContainer:', descriptionContainer)
    for (let i = 0; i < descriptionContainer.length; i++) {
      console.log('descriptionContainer[i]:', descriptionContainer[i])
      console.log(typeof descriptionContainer[i])
      const toString = JSON.stringify(descriptionContainer[i].innerText)
      console.log('toString:', toString)
      if (toString.includes('color:')) {
        console.log('includes color')
        colorAndFabric = getColor(toString)
      }
    }
  }
  if (colorAndFabric.length === 1) {
    // If only color exists and there's no fabric
    color = colorAndFabric[0]
  } else {
    // If color and fabric exist
    color = colorAndFabric[1]
  }

  // Get miscellaneous
  const misc = getMisc(productName)
  console.log('misc:', misc)

  // Get image
  let imageUrl = ''
  const colorStringFindSlash = colorString.indexOf('/')
  console.log('colorStringFindSlash:', colorStringFindSlash)
  if (colorStringFindSlash != -1) {
    colorString = colorString.substring(0, colorStringFindSlash - 1)
    console.log('new colorString:', colorString)
  }
  const colorStringDashed = replaceAll(colorString, ' ', '-').toLowerCase()
  const dataProductImageGroup = '#color::__' + colorStringDashed
  console.log('dataProductImageGroup:', dataProductImageGroup)
  const dataProductImageGroupIndexOf = retrieveInnerHTML.indexOf(
    dataProductImageGroup,
  )
  console.log('dataProductImageGroupIndexOf:', dataProductImageGroupIndexOf)
  if (dataProductImageGroupIndexOf != -1) {
    const preImageUrlStart = retrieveInnerHTML.substring(
      dataProductImageGroupIndexOf,
    )
    const imageUrlStart = preImageUrlStart.indexOf('data-srcset')
    console.log('imageUrlStart:', imageUrlStart)
    imageUrl =
      'https://' +
      preImageUrlStart.substring(
        preImageUrlStart.indexOf('320w,') + 5,
        preImageUrlStart.indexOf('540w,') - 1,
      )
    console.log('imageUrl:', imageUrl)
  } else {
    const imageContainer = dom
      .querySelector('li.thumbnail-li > a > div > img')
      ?.getAttribute('data-srcset')
    console.log('imageContainer:', imageContainer)
    imageUrl = imageContainer.substring(
      imageContainer.indexOf('320w,') + 5,
      imageContainer.indexOf('540w,') - 1,
    )
    console.log('imageUrl:', imageUrl)
  }

  // Get product description
  // const productDescription = dom.querySelector( // not updated
  //   ".product-summary-copy"
  // )?.innerText;
  // console.log("productDescription: ", productDescription);

  // Get dimensions
  const dimensions = getProductDimensions(retrieveInnerHTML)
  console.log('dimensions: ', dimensions)

  // Get price
  const priceContainer = dom.querySelector('div.flex > span').innerText
  console.log('priceContainer:', priceContainer)
  const priceString = replaceAll(replaceAll(priceContainer, '$', ''), ',', '')
  console.log('priceString:', priceString)
  const price = parseFloat(priceString)
  console.log('price:', price)

  // Add brand
  const brand = 'Lulu And Georgia'

  // combine all variables into an object
  productDetails = {
    productName: productName,
    imageURL: imageUrl,
    // description: productDescription,
    furnitureType: furnitureType,
    width: dimensions.width,
    depth: dimensions.depth,
    height: dimensions.height,
    color: color,
    price: price,
    brand: brand,
    misc: misc,
  }

  return productDetails
}
