import React from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import { Grid } from '@mui/material'
// import Text from "../../components/atoms/Text/Text";
import CardMedia from '@mui/material/CardMedia'

// import components
import MoodboardPageItem from '../../components/organisms/MoodboardPageItem/MoodboardPageItem'
import CraftMyStyle from '../../components/organisms/LandingCraftMyStyle/LandingCraftMyStyle'
// import ProductImages from "../../components/organisms/ProductImages/ProductImages";

// import images
// import TestImage from "../../assets/images/living_room.png";

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

const propTypes = {
  profile: PropTypes.node,
  name: PropTypes.string,
  dates: PropTypes.string,
  moodboard: PropTypes.node,
  description: PropTypes.string,
  url1: PropTypes.string,
  image1: PropTypes.node,
  price1: PropTypes.string,
  url2: PropTypes.string,
  image2: PropTypes.node,
  price2: PropTypes.string,
  url3: PropTypes.string,
  image3: PropTypes.node,
  price3: PropTypes.string,
  url4: PropTypes.string,
  image4: PropTypes.node,
  price4: PropTypes.string,
  url5: PropTypes.string,
  image5: PropTypes.node,
  price5: PropTypes.string,
  url6: PropTypes.string,
  image6: PropTypes.node,
  price6: PropTypes.string,
  url7: PropTypes.string,
  image7: PropTypes.node,
  price7: PropTypes.string,
  url8: PropTypes.string,
  image8: PropTypes.node,
  price8: PropTypes.string,
  url9: PropTypes.string,
  image9: PropTypes.node,
  price9: PropTypes.string,
}
const defaultProps = {
  profile: null,
  name: null,
  dates: null,
  moodboard: null,
  description: null,
  url1: null,
  image1: null,
  price1: null,
  url2: null,
  image2: null,
  price2: null,
  url3: null,
  image3: null,
  price3: null,
  url4: null,
  image4: null,
  price4: null,
  url5: null,
  image5: null,
  price5: null,
  url6: null,
  image6: null,
  price6: null,
  url7: null,
  image7: null,
  price7: null,
  url8: null,
  image8: null,
  price8: null,
  url9: null,
  image9: null,
  price9: null,
}

MoodboardPage.propTypes = propTypes
MoodboardPage.defaultProps = defaultProps

function MoodboardPage({
  profile,
  name,
  dates,
  moodboard,
  description,
  url1,
  image1,
  price1,
  url2,
  image2,
  price2,
  url3,
  image3,
  price3,
  url4,
  image4,
  price4,
  url5,
  image5,
  price5,
  url6,
  image6,
  price6,
  url7,
  image7,
  price7,
  url8,
  image8,
  price8,
  url9,
  image9,
  price9,
}) {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '80px 0',
          [customTheme.breakpoints.down('md')]: { margin: '40px 0' },
        }}
      >
        {/* Top section */}
        <CardMedia
          component="img"
          image={profile}
          alt=""
          sx={{
            width: '64px',
            height: '64px',
            border: '1px solid #000000',
            borderRadius: '32px',
          }}
        />

        <Typography
          variant="h1"
          sx={{
            fontSize: '40px',
            lineHeight: 1.2,
            fontWeight: 'bold',
            [customTheme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
            [customTheme.breakpoints.down('sm')]: {
              fontSize: '32px',
            },
          }}
        >
          {name}
        </Typography>

        <Typography
          sx={{
            marginTop: '16px',
            fontSize: '16px',
            lineHeight: 1.5,
            fontWeight: 'bold',
            [customTheme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          {dates}
        </Typography>

        <Typography
          sx={{
            marginTop: '24px',
            fontSize: '16px',
            lineHeight: 1.5,
            maxWidth: '752px',
            textAlign: 'center',
            padding: '0 16px',
            [customTheme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          }}
        >
          {description}
        </Typography>

        {/* Grid start */}
        <Grid
          container
          spacing={4}
          sx={{
            width: '90vw',
            marginTop: '32px',
            marginBottom: '24px',
            [customTheme.breakpoints.down('md')]: { width: '98vw' },
          }}
        >
          <Grid
            item
            md={7}
            sm={12}
            sx={{
              paddingTop: '0',
              maxHeight: '100vh',
              [customTheme.breakpoints.down('lg')]: {
                maxHeight: 'inherit',
              },
            }}
          >
            <CardMedia
              component="img"
              image={moodboard}
              alt=""
              sx={{
                position: 'relative',
              }}
            />
          </Grid>

          <Grid
            item
            md={5}
            sm={12}
            sx={{
              maxHeight: '100vh',
              overflow: 'scroll',
              // border: "1px solid #E3E3E3",
              [customTheme.breakpoints.down('sm')]: {
                paddingTop: '24px !important',
                maxHeight: 'inherit',
                overflow: 'inherit',
              },
            }}
          >
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                [customTheme.breakpoints.down('md')]: {
                  justifyContent: 'center',
                },
              }}
            >
              <MoodboardPageItem url={url1} image={image1} price={price1} />
              <MoodboardPageItem url={url2} image={image2} price={price2} />
              <MoodboardPageItem url={url3} image={image3} price={price3} />
              <MoodboardPageItem url={url4} image={image4} price={price4} />
              <MoodboardPageItem url={url5} image={image5} price={price5} />
              <MoodboardPageItem url={url6} image={image6} price={price6} />
              <MoodboardPageItem url={url7} image={image7} price={price7} />
              <MoodboardPageItem url={url8} image={image8} price={price8} />
              <MoodboardPageItem url={url9} image={image9} price={price9} />
            </Grid>
          </Grid>
        </Grid>
        <CraftMyStyle />
      </Box>
    </ThemeProvider>
  )
}

export default MoodboardPage
