import React, { useContext } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'
import { ShowLoginModalContext } from '../../../pages/CraftMyStyle/pages/AllMyDesigns'
import { UserContext } from '../../../App'

// import global store
import { Context } from '../../../context/context'

// import icons
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'

export default function ImageTextCardStartNewDesign({ savedDesigns }) {
  const [, globalDispatch] = useContext(Context)
  const { setShowLoginModal } = React.useContext(ShowLoginModalContext)
  const { user } = React.useContext(UserContext)

  return (
    <CardActionArea
      sx={{
        height: '100%',
      }}
      onClick={() => {
        savedDesigns.length < 1 || user
          ? globalDispatch({
              type: 'SET_WORKFLOW_PROP_VALUE',
              payload: {
                propName: 'showCMSSavedDesignsPage',
                value: false,
              },
            })
          : setShowLoginModal(true)
      }}
    >
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // border: "dashed 2px #D8D8D8",
          // borderColor: "#D8D8D8",
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
          boxShadow: 0,
          [customTheme.breakpoints.down('sm')]: {
            borderRadius: '10px',
          },
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PlusIcon />
          <Text variant="body" sx={{ marginTop: '15px' }}>
            Start new design
          </Text>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

ImageTextCardStartNewDesign.propTypes = {
  savedDesigns: PropTypes.arrayOf(
    PropTypes.shape({
      planId: PropTypes.number.isRequired,
      planThumbnail: PropTypes.string,
      lastUpdated: PropTypes.string,
    }),
  ),
}

ImageTextCardStartNewDesign.defaultProps = {}
