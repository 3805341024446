import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import CardMedia from "@mui/material/CardMedia";

// import components
import LandingFurniture from '../../molecules/LandingFurniture/LandingFurniture'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import images
const DarkAcademiaIMG1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-1.jpeg'
const DarkAcademiaIMG2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-2.jpeg'
const DarkAcademiaIMG3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-3.jpeg'
const DarkAcademiaIMG4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-4.jpeg'
const DarkAcademiaIMG5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-5.jpeg'
const DarkAcademiaIMG6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-6.jpeg'
const DarkAcademiaIMG7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-7.jpeg'
const DarkAcademiaIMG8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-8.jpeg'
const DarkAcademiaIMG9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-9.jpeg'
const DarkAcademiaIMG10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-10.jpeg'
const DarkAcademiaIMG11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-11.jpeg'
const DarkAcademiaIMG12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-12.jpeg'
const DarkAcademiaIMG13 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-13.jpeg'
const DarkAcademiaIMG14 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-14.jpeg'
const DarkAcademiaIMG15 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-15.jpeg'
const DarkAcademiaIMG16 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-16.jpeg'
const DarkAcademiaIMG17 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-17.jpeg'
const DarkAcademiaIMG18 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-18.jpeg'
const DarkAcademiaIMG19 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-19.jpeg'
const DarkAcademiaIMG20 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-20.jpeg'
const DarkAcademiaIMG21 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-21.jpeg'
const DarkAcademiaIMG22 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-22.jpeg'
const DarkAcademiaIMG23 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/dark-academia-23.jpeg'

// import icons

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingDarkAcademia() {
  const ref = useRef(null)

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: '90vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '48px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#2a2a2a',
            fontSize: '32px',
            fontWeight: 'bold',
          }}
        >
          🎭 Dark Academia
        </Typography>

        <Typography
          sx={{
            color: '#2a2a2a',
            fontSize: '16px',
            marginTop: '16px',
          }}
        >
          Join the elite group deeply invested in higher education, creative
          writing, art, and poetry...with an added measure of darkness thrown in
          for good measure.
        </Typography>

        <ScrollButtonLeft scrollRef={ref} />
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
            width: '95vw',
            marginLeft: '-5vw',
            overflowX: 'scroll',
            paddingLeft: '5vw',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <LandingFurniture
            image={DarkAcademiaIMG1}
            path="/boutiques-de-musees/authentic-models-gl019-terrestrial-globe-with-compass"
            brand="boutiques de musées"
            type="globe"
            price="104.68"
            url="https://www.google.com/shopping/product/11522340944982548925?q=dark+academia+globe&biw=1382&bih=801&sxsrf=ALiCzsbbCS1aP8FxcllHGKF_d-oScVaRfw:1655407366733&uact=5&oq=dark+academia+globe&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDoICAAQgAQQsAM6CwgAEB4QsAMQCBAKOgsIABAeELADEAgQGDoLCK4BEMoDELADECc6CAgAEB4QFhAYOgoIABAeEBYQChAYSgQIQRgBUPQBWNwGYKwHaAJwAHgAgAGaAYgBwgOSAQMxLjOYAQCgAQHIAQnAAQE&sclient=products-cc&prds=eto:1758398022086140498_0,pid:9733684320762589633,rsk:PC_16678223491672629545&sa=X&ved=0ahUKEwjRge6L2bL4AhWQjIkEHVYrBPAQ8wIIxws"
          />
          <LandingFurniture
            image={DarkAcademiaIMG2}
            path="/northprint/moody-portrait-woman-painting-vintage-dark-academia-digital-printable-487"
            brand="NorthPrint"
            type="Print"
            price="3.60"
            url="https://www.etsy.com/listing/932186964/moody-portrait-woman-painting-vintage?click_key=efa75351782da6615d1f78365b166e32e7fc0b20%3A932186964&click_sum=4e9fed17&ref=related-2&pro=1"
          />
          <LandingFurniture
            image={DarkAcademiaIMG3}
            path="/vagabond-house/majestic-forest-three-taper-oak-leaf-metal-candelabra"
            brand="Vagabond House"
            type="Candelabra"
            price="399.99"
            url="https://www.wayfair.com/Vagabond-House--Majestic-Forest-Three-Taper-Oak-Leaf-Metal-Candelabra-L101M-L6125-K~VJZ10797.html?refid=GX560448447253-VJZ10797&device=c&ptid=561575403794&network=g&targetid=aud-352581795486:pla-561575403794&channel=GooglePLA&ireid=126727388&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkVu0d1ZJPLyVhU3CtNgk2rcU0PaV-4fpAPOI3TEcKg2kbN7qaF_tERoCZIwQAvD_BwE"
          />
          <LandingFurniture
            image={DarkAcademiaIMG4}
            path="/antique-beauty/20th-century-handheld-mirror-in-a-wooden-frame"
            brand="Antique Beauty"
            type="Mirror"
            price="380"
            url="https://www.chairish.com/product/2468311/20th-century-handheld-mirror-in-a-wooden-frame"
          />
          <LandingFurniture
            image={DarkAcademiaIMG5}
            path="/fleur-de-lis-living/bust"
            brand="Fleur De Lis Living"
            type="Statue"
            price="70.99"
            url="https://www.wayfair.com/Fleur-De-Lis-Living--Bust-X114999188-L1191-K~W003888077.html?refid=GX560448448444-W003888077&device=c&ptid=1333591459850&network=g&targetid=aud-352581795486:pla-1333591459850&channel=GooglePLA&ireid=131395056&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkZYTGifd6KEPpZ6jVUZ5C7C5zCEdYCkkYmXlZqAjiHFXAvjwwSztLRoCI2oQAvD_BwE"
          />
          <LandingFurniture
            image={DarkAcademiaIMG6}
            path="/wayfair/akeem-29-52-wide-loung-chair-and-ottoman-steelside-leather-type-black-coffee-faux-leather"
            brand="Wayfair"
            type="Lounge Chair"
            price="325.99"
            url="https://www.google.com/shopping/product/13249498113223234045?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=opd:2885774659783174810,rsk:PC_18207498840583086963&sa=X&ved=0ahUKEwicueeT17L4AhVTjokEHZtKDmYQlIUHCDsoAQ"
          />
          <LandingFurniture
            image={DarkAcademiaIMG7}
            path="/home-furniture/ashley-norcastle-dark-brown-sofa-table"
            brand="Home Furniture"
            type="Table"
            price="369.99"
            url="https://www.google.com/shopping/product/2254463309018450886?q=dark+academia+furniture&sxsrf=ALiCzsbyO0-w4GDw6Q9ZlbVsh1wHNSKI6w:1655406602148&biw=1382&bih=801&dpr=2.5&prds=eto:729390655429284556_0,pid:11218993991437407517,rsk:PC_953740240928859488&sa=X&ved=0ahUKEwi0goTJ17L4AhW7kYkEHUHpDao4PBDyAgj0DygA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG8}
            path="/article/adjustable-walnut-side-table-black-metal-base-article-portima-modern-furniture"
            brand="Article"
            type="Side Table"
            price="219"
            url="https://www.google.com/shopping/product/11191727386418829307?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:17566511559738666887_0,pid:1835096425187704294&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8gIIsRIoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG9}
            path="/at-home/home-chesterfield-tufted-brown-faux-leather-rolled-arm-chair-40-5-l-x-28-h-x-31-5-w-fabric"
            brand="At Home"
            type="Arm Chair"
            price="399.99"
            url="https://www.google.com/shopping/product/14614532644858971112?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:1656550031187495639_0,local:1,pid:14873709598407971824,prmr:2&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8gII_RMoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG10}
            path="/hayneedle/home-interior-mission-style-chair-with-microfiber-covering-oak-olive"
            brand="Hayneedle"
            type="Arm Chair"
            price="419.99"
            url="https://www.google.com/shopping/product/17100656661206561396?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:2733836990048418463_0,pid:11910662895531905898,rsk:PC_451577727082943&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8wII9RU"
          />
          <LandingFurniture
            image={DarkAcademiaIMG11}
            path="/overstock/middlebrook-glass-top-fluted-door-nightstand-dark-walnut"
            brand="Overstock"
            type="Nightstand"
            price="203.99"
            url="https://www.google.com/shopping/product/14760028789706194322?q=dark+academia+furniture&sxsrf=ALiCzsb7ZL3mzlaimRmKCXCey9hXfxhGpg:1655406435553&biw=1382&bih=801&dpr=2.5&prds=eto:9950240093588098255_0,pid:598125463055966041&sa=X&ved=0ahUKEwiAy-qT1rL4AhVFg4kEHQyGB5cQ8wIIkRU"
          />
          <LandingFurniture
            image={DarkAcademiaIMG12}
            path="/aspenhome/grand-classic-door-bookcase"
            brand="Aspenhome"
            type="Bookcase"
            price="1,369"
            url="https://www.furniturepick.com/grand-classic-door-bookcase-aspenhome-ap-i91-332.html"
          />
          <LandingFurniture
            image={DarkAcademiaIMG13}
            path="/walmart/benzara-bm213452-wooden-bookcase-shelf-with-carved-details-and-filigree-accents-brown"
            brand="Walmart"
            type="Bookcase"
            price="782.86"
            url="https://www.google.com/shopping/product/501509090072714181?q=traditional+bookshelf&biw=1382&bih=801&sxsrf=ALiCzsadpELBYBrA8gnLN6MLGWZ7GRoZNA:1655406982140&uact=5&oq=traditional+bookshelf&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBAgAEBgyCAgAEB4QFhAYMggIABAeEBYQGDIKCAAQHhAPEBYQGDIKCAAQHhAPEBYQGDIICAAQHhAWEBgyCAgAEB4QFhAYMggIABAeEBYQGDoHCCMQsAMQJzoHCAAQsAMQGDoJCAAQHhCwAxAIOgsIABAeELADEAgQGDoLCK4BEMoDELADECc6BAgAEEM6CggAELEDEIMBEEM6BAgAEAM6CwgAEIAEELEDEIMBOgYIABAeEBZKBAhBGAFQrxdYrCpgzStoBnAAeACAAegBiAGOFJIBBjYuMTIuMpgBAKABAcgBB8ABAQ&sclient=products-cc&prds=pid:4711050759776201992&sa=X&ved=0ahUKEwjI8qba17L4AhUZkYkEHXRiCi4Q8gII3BgoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG14}
            path="/wayfair/astoria-grand-dolson-traditional-sofa-red"
            brand="Wayfair"
            type="Sofa"
            price="2,439.99"
            url="https://www.google.com/shopping/product/1217450497835995801?q=traditional+couch&biw=1382&bih=801&sxsrf=ALiCzsZmSa8q_OCg14BCCzj4aw3L1cE0ew:1655407018371&uact=5&oq=traditional+couch&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjIICAAQHhAWEBgyCAgAEB4QFhAYOggIABCABBCwAzoHCAAQsAMQGDoJCAAQHhCwAxAYOgsIABAeEA8QsAMQGDoLCAAQHhCwAxAFEBg6CwgAEB4QsAMQCBAYOgsIrgEQygMQsAMQJzoLCAAQgAQQsQMQgwFKBAhBGAFQ4wxYpRBgthJoBHAAeACAAV2IAZ8DkgEBNZgBAKABAcgBEMABAQ&sclient=products-cc&prds=eto:2840784286479846566_0,pid:6979541997483484738,rsk:PC_16011520220234949102&sa=X&ved=0ahUKEwikuqjz17L4AhVbjokEHfeKA5EQ8gII8REoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG15}
            path="/1stopbedrooms/coaster-victoria-classic-button-tufted-sofa-in-brown"
            brand="1StopBedrooms"
            type="Sofa"
            price="2,123.95"
            url="https://www.google.com/shopping/product/10792621119575961946?q=traditional+couch&biw=1382&bih=801&sxsrf=ALiCzsZmSa8q_OCg14BCCzj4aw3L1cE0ew:1655407018371&uact=5&oq=traditional+couch&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIGCAAQHhAWMgYIABAeEBYyBggAEB4QFjIICAAQHhAWEBgyCAgAEB4QFhAYOggIABCABBCwAzoHCAAQsAMQGDoJCAAQHhCwAxAYOgsIABAeEA8QsAMQGDoLCAAQHhCwAxAFEBg6CwgAEB4QsAMQCBAYOgsIrgEQygMQsAMQJzoLCAAQgAQQsQMQgwFKBAhBGAFQ4wxYpRBgthJoBHAAeACAAV2IAZ8DkgEBNZgBAKABAcgBEMABAQ&sclient=products-cc&prds=eto:10950498811076425245_0,pid:13906862639292814261,rsk:PC_2969889320145883560&sa=X&ved=0ahUKEwikuqjz17L4AhVbjokEHfeKA5EQ8gII8RMoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG16}
            path="/wayfair/albryna-26-75-wide-armchair"
            brand="Wayfair"
            type="Armchair"
            price="589.99"
            url="https://www.wayfair.com/Astoria-Grand--Albryna-67.95Cm-Wide-Polyester-Armchair-X116593011-L54-K~W005482900.html?refid=GX527465635140-W005482900_202084474&device=c&ptid=1330755365947&network=g&targetid=aud-352581795486:pla-1330755365947&channel=GooglePLA&ireid=59811125&fdid=1817&PiID%5B%5D=202084474&campaignid=398251723&gclid=CjwKCAjwqauVBhBGEiwAXOepkXnopbp8hLk49cCcJfoWuLl_BMV_byev8F1YxKLgoi_2n9-09DdXzBoCShwQAvD_BwE"
          />
          <LandingFurniture
            image={DarkAcademiaIMG17}
            path="/efurnish/traditional-accent-chair-velvet-cushioned-seat-with-button-tufted-back-grey"
            brand="eFurnish"
            type="Accent Chair"
            price="519"
            url="https://www.houzz.com/products/traditional-accent-chair-velvet-cushioned-seat-with-button-tufted-back-grey-prvw-vr~184062907?m_refid=PLA_HZ_184062907"
          />
          <LandingFurniture
            image={DarkAcademiaIMG18}
            path="/wayfair/makale-black-decorative-boxes-lark-manor"
            brand="Wayfair"
            type="Boxes"
            price="54.99"
            url="https://www.google.com/shopping/product/12700643328912726907?q=traditional+box&biw=1382&bih=801&sxsrf=ALiCzsZoI0trllYPYPlenOPAmy6L1dgl8w:1655407120259&uact=5&oq=traditional+box&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIECAAQGDIECAAQGDIECAAQGDoICAAQgAQQsAM6CQgAEB4QsAMQBzoJCAAQHhCwAxAFOgkIABAeELADEAg6CQgAEB4QsAMQGDoLCAAQHhAPELADEBg6CwiuARDKAxCwAxAnOgQIIxAnSgQIQRgBUNAHWPMJYIgLaAJwAHgAgAFZiAH4AZIBATOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:13717041244781852935_0,pid:4098870992432654214,rsk:PC_8844350286627489659&sa=X&ved=0ahUKEwjL3u-z2LL4AhUOhIkEHQbhAUEQ8gIIiAsoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG19}
            path="/wayfair/alie-traditional-beveled-distressed-accent-mirror-finish-rust"
            brand="Wayfair"
            type="Mirror"
            price="123"
            url="https://www.google.com/shopping/product/1817063172358171433?q=traditional+mirror&biw=1382&bih=801&sxsrf=ALiCzsaWootvmhwN8uzPnnZVVZpaj9GXZA:1655407206210&uact=5&oq=traditional+mirror&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEBgyBAgAEBgyBAgAEBgyBggAEB4QFjIGCAAQHhAWOggIABCABBCwAzoHCAAQsAMQGDoLCK4BEMoDELADECc6CwgAEIAEELEDEIMBSgQIQRgBUOMEWJMJYKgKaAJwAHgAgAGJAYgB5wSSAQMzLjOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:4165158100908007824_0,pid:8444268806718031113,rsk:PC_10358024954413677484&sa=X&ved=0ahUKEwjmkJTQ2LL4AhWekYkEHQNxAMMQ8gIItRMoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG20}
            path="/wayfair/astoria-grand-rogan-wall-mirror-finish-classic-black"
            brand="Wayfair"
            type="Mirror"
            price="599.99"
            url="https://www.google.com/shopping/product/10545679247649453726?q=traditional+mirror&biw=1382&bih=801&sxsrf=ALiCzsaWootvmhwN8uzPnnZVVZpaj9GXZA:1655407206210&uact=5&oq=traditional+mirror&gs_lcp=Cgtwcm9kdWN0cy1jYxADMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBAgAEBgyBAgAEBgyBAgAEBgyBggAEB4QFjIGCAAQHhAWOggIABCABBCwAzoHCAAQsAMQGDoLCK4BEMoDELADECc6CwgAEIAEELEDEIMBSgQIQRgBUOMEWJMJYKgKaAJwAHgAgAGJAYgB5wSSAQMzLjOYAQCgAQHIARDAAQE&sclient=products-cc&prds=eto:4824287901745241529_0,pid:550150075090472135,rsk:PC_3100816083338268538&sa=X&ved=0ahUKEwjmkJTQ2LL4AhWekYkEHQNxAMMQ8gII0hcoAA"
          />
          <LandingFurniture
            image={DarkAcademiaIMG21}
            path="/wayfair/ringwold-27-5-wide-armchair"
            brand="Wayfair"
            type="Armchair"
            price="319.99"
            url="https://www.wayfair.com/Ebern-Designs--Ringwold-69.85Cm-Wide-Polyester-Armchair-X116516310-L54-K~W005405209.html?refid=GX523493935915-W005405209_1672461610&device=c&ptid=1304711861748&network=g&targetid=aud-352581795486:pla-1304711861748&channel=GooglePLA&ireid=40774324&fdid=1817&PiID%5B%5D=1672461610&campaignid=398251723&gclid=CjwKCAjwqauVBhBGEiwAXOepkZ57D847ARgJvanR6rqqXyTGEJRul_jt6_6DerbJXR_DdsprNWaDdhoCgXAQAvD_BwE"
          />
          <LandingFurniture
            image={DarkAcademiaIMG22}
            path="/wayfair/dianalee-secretary-desk"
            brand="Wayfair"
            type="Desk"
            price="3,299.99"
            url="https://www.wayfair.com/Bungalow-Rose--Dianalee-Secretary-Desk-X111352721-L32-K~W000241610.html?refid=GX444304079481-W000241610&device=c&ptid=902660081001&network=g&targetid=aud-352581795486:pla-902660081001&channel=GooglePLA&ireid=8613764&fdid=1817&gclid=CjwKCAjwqauVBhBGEiwAXOepkQjXHogV1lMa9wH2yav61j_V96F5rgFOR80O45XWIY2NgDQHRSAIHxoCZ_0QAvD_BwE"
          />
          <LandingFurniture
            image={DarkAcademiaIMG23}
            path="/furniture-of-america/lewis-writing-desk-dark-walnut"
            brand="Furniture of America"
            type="Desk"
            price="1,215"
            url="https://www.topdrawerfurniturestore.com/products/lewis-writing-desk-dark-walnut?variant=39306707632212&currency=USD&gclid=CjwKCAjwqauVBhBGEiwAXOepkWZeGzzXVCxCJUsuS1HmzVCYBpmgjx49F0YaUIUS9YdJxshw96Hp_hoCFlEQAvD_BwE"
          />
        </Box>
        <ScrollButtonRight scrollRef={ref} />

        {/* <LandingSeeMore
          text="See more Dark Academia"
          mixpanelTracking="See more Dark Academia"
        /> */}
      </Box>
    </ThemeProvider>
  )
}

export default LandingDarkAcademia
