import * as React from 'react'
import { Box } from '@mui/material'

export default function BackgroundGradient() {
  const classes = {
    backgroundGradient: {
      backgroundImage: 'linear-gradient(#FFF4D7, #FFFFFF)',
      position: 'absolute',
      width: '100%',
      height: '830px',
      zIndex: -1,
    },
  }
  return <Box sx={classes.backgroundGradient}></Box>
}
