import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// import components
import LandingFurniture from '../../molecules/LandingFurniture/LandingFurniture'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import images
const CoolIMG1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-1.jpg'
const CoolIMG2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-2.jpg'
const CoolIMG3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-3.jpg'
const CoolIMG4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-4.jpg'
const CoolIMG5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-5.jpg'
const CoolIMG6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-6.jpg'
const CoolIMG7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-7.jpg'
const CoolIMG8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-8.jpg'
const CoolIMG9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-9.jpg'
const CoolIMG10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-10.jpg'
const CoolIMG11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-11.jpg'
const CoolIMG12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-12.jpg'
const CoolIMG13 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-13.jpg'
const CoolIMG14 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-14.jpg'
const CoolIMG15 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-15.jpg'
const CoolIMG16 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-16.jpg'
const CoolIMG17 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-17.jpg'
const CoolIMG18 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-18.jpg'
const CoolIMG19 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/cool-19.jpg'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingCoolAsS() {
  const ref = useRef(null)

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          width: '90vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: '48px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: '#2a2a2a',
            fontSize: '32px',
            fontWeight: 'bold',
          }}
        >
          😎 Cool as S*^%
        </Typography>

        <Typography
          sx={{
            color: '#2a2a2a',
            fontSize: '16px',
            marginTop: '16px',
          }}
        >
          Sometimes our jaws collectively drop when we find the coolest s*^%
          ever, like down below. Impressed? You’re welcome.
        </Typography>

        <ScrollButtonLeft scrollRef={ref} />
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
            width: '95vw',
            marginLeft: '-5vw',
            overflowX: 'scroll',
            paddingLeft: '5vw',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <LandingFurniture
            path="/furniture/yesie/16-inch-cordless-led-cube-chair-light"
            image={CoolIMG1}
            brand="YESIE Store"
            type="Chair"
            price="139.99"
            url="https://www.amazon.com/dp/B07HWW8GN4/?coliid=IDVZVVQRGBXS5&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <LandingFurniture
            path="/furniture/mousarris/rocket-table"
            image={CoolIMG2}
            brand="Mousarris"
            type="Coffee Table"
            price="5,827.94"
            url="https://www.mousarris.com/product-page/rocket-table"
          />
          <LandingFurniture
            path="/furniture/seatcraft/seatcraft-omega-home-theater-seating"
            image={CoolIMG3}
            brand="Seatcraft Store"
            type="Sofa"
            price="1,999"
            url="https://www.amazon.com/dp/B06XPGHMGF/?coliid=I3I9S840A3SW80&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <LandingFurniture
            path="/furniture/fernando-mastrangelo-studio/drift-sofa"
            image={CoolIMG4}
            brand="Fernando Mastrangela Studio"
            type="Sofa"
            price="--"
            url="https://fernandomastrangelo.com/limited-edition/drift-sofa.html"
          />
          <LandingFurniture
            path="/furniture/rpr/ollie-chair"
            image={CoolIMG5}
            brand="RPR"
            type="Chair"
            price="395"
            url="https://rockpaperrobot.com/collections/frontpage/products/ollie-chair"
          />
          <LandingFurniture
            path="/furniture/christopher-knight-home/ckh-wicker-tear-drop-hanging"
            image={CoolIMG6}
            brand="Christopher Knight Home Store"
            type="Chair"
            price="435.08"
            url="https://www.amazon.com/dp/B00IFQEMYG/?coliid=I2PLJ0NY7SHPNF&colid=255TVYK0MHN2M&psc=1&ref_=lv_ov_lig_dp_it"
          />
          <LandingFurniture
            path="/furniture/qazplm/modern-coffee-table-with-romote-multi-color"
            image={CoolIMG7}
            brand="qazplm"
            type="Coffee Table"
            price="265"
            url="https://www.amazon.com/dp/B0998BMNYS/?coliid=I10ETI6ZURBULL&colid=255TVYK0MHN2M&psc=1&ref_=lv_ov_lig_dp_it"
          />
          <LandingFurniture
            path="/furniture/mousarris/wave-city-table"
            image={CoolIMG8}
            brand="Mousarris"
            type="Coffee Table"
            price="7,000"
            url="https://www.mousarris.com/product-page/wave-city-table"
          />
          <LandingFurniture
            path="/furniture/mousarris/carpet-chair"
            image={CoolIMG9}
            brand="Mousarris"
            type="Chair"
            price="7,000"
            url="https://www.mousarris.com/product-page/carpet-chair"
          />
          <LandingFurniture
            path="/furniture/giantex-store/3-pcs-cushioned-outdoor-wicker-patio-set"
            image={CoolIMG10}
            brand="Giantex Store"
            type="Patio Set"
            price="219.99"
            url="https://www.amazon.com/dp/B00WWR2CWS/?coliid=IL5TEVNMTEI0S&colid=255TVYK0MHN2M&ref_=lv_ov_lig_dp_it&th=1"
          />
          <LandingFurniture
            path="/furniture/umbra-store/umbra-318211-040-sticks-multi-rack"
            image={CoolIMG11}
            brand="Umbra Store"
            type="Rack"
            price="23.97"
            url="https://www.amazon.com/Umbra-Sticks-Multi-Hook-Coat/dp/B005M8YWOK/ref=sxin_24_ac_d_rm?ac_md=3-3-bW9kZXJuIGZ1cm5pdHVyZQ%3D%3D-ac_d_rm_rm_rm&content-id=amzn1.sym.568df61d-e115-4cb1-a96a-ba070b8f0935%3Aamzn1.sym.568df61d-e115-4cb1-a96a-ba070b8f0935&cv_ct_cx=Unique%2BFurniture&keywords=Unique%2BFurniture&pd_rd_i=B005M8YWOK&pd_rd_r=a9610730-9bf8-4a16-a39e-1d6e07ed466b&pd_rd_w=wEd3v&pd_rd_wg=ICpK5&pf_rd_p=568df61d-e115-4cb1-a96a-ba070b8f0935&pf_rd_r=7FVNTGS3SK615XEDCYG9&qid=1656098608&sr=1-4-7d9bfb42-6e38-4445-b604-42cab39e191b&th=1"
          />
          <LandingFurniture
            path="/furniture/mousarris/willy-walnut"
            image={CoolIMG12}
            brand="Mousarris"
            type="Coffee Table"
            price="4,000"
            url="https://www.mousarris.com/product-page/willy-walnut"
          />
          <LandingFurniture
            path="/furniture/deco-79-store/deco-79-silver-aluminum-contemporary-accent-table"
            image={CoolIMG13}
            brand="Deco 79 Store"
            type="Accent Table"
            price="220.39"
            url="https://www.amazon.com/Deco-79-Accent-Table-Silver/dp/B097QGTZ4J/ref=sr_1_5?keywords=Unique%2BFurniture&qid=1656098608&sr=8-5&th=1"
          />
          <LandingFurniture
            path="/furniture/duffy-london/abyss-kraken-coffee-table"
            image={CoolIMG14}
            brand="Duffy London"
            type="Coffee Table"
            price="61,460.57"
            url="https://duffylondon.com/collections/furniture/tables/kraken-abyss-table/"
          />
          <LandingFurniture
            path="/furniture/nouhaus-store/classic-massage-chair-with-ottoman"
            image={CoolIMG15}
            brand="Nouhaus Store"
            type="Chair and Ottoman"
            price="2,499.99"
            url="https://www.amazon.com/Nouhaus-Ottoman-Percussive-Bluetooth-Recliner/dp/B089RS5FGH/ref=sr_1_16_sspa?keywords=Unique%2BFurniture&qid=1656098608&sr=8-16-spons&smid=A1DPRB9NBV0XDD&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzMlA0M0U4UE9FTFk3JmVuY3J5cHRlZElkPUEwNTA4MDk4M0RNMU1IVEpXVTBPTiZlbmNyeXB0ZWRBZElkPUEwODQ2OTc4MjYyVDZWWlNLVkJLWCZ3aWRnZXROYW1lPXNwX210ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU&th=1"
          />
          <LandingFurniture
            path="/furniture/deco-79-store/deco-79-industrial-wood-and-metal-wheel-console-table"
            image={CoolIMG16}
            brand="Deco 79 Store"
            type="Console Table"
            price="218.54"
            url="https://www.amazon.com/Deco-79-Metal-Wheel-Console/dp/B01MV3DEAA/ref=sr_1_55?keywords=Unique+Furniture&qid=1656098608&sr=8-55"
          />
          <LandingFurniture
            path="/furniture/fitueyes/corner-floor-tv-stand-for-55-65-inch-tv"
            image={CoolIMG17}
            brand="FITUEYES"
            type="TV Stand"
            price="169.99"
            url="https://www.amazon.com/FITUEYES-Display-Concealed-Furniture-Original/dp/B092LYH4JB/ref=sr_1_50_sspa?keywords=Unique+Furniture&qid=1656098738&sr=8-50-spons&psc=1&smid=A2T4RI5JPP2USG&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFSWlJYVUZaNVVEVEsmZW5jcnlwdGVkSWQ9QTA0NDYwMDFJVEwxS0o0N05TUzcmZW5jcnlwdGVkQWRJZD1BMDc2OTk1NTFSMEpGRTlPRkdJTzUmd2lkZ2V0TmFtZT1zcF9hdGZfbmV4dCZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU="
          />
          <LandingFurniture
            path="/furniture/villa-p/air-lounge"
            image={CoolIMG18}
            brand="Villa P."
            type="Lounge Seating"
            price="5,720.89"
            url="https://www.proidee.de/P=HPN205012?HI=produktuebersicht&ID_KATEGORIE=120307"
          />
          <LandingFurniture
            path="/furniture/mousarris/saita"
            image={CoolIMG19}
            brand="Mousarris"
            type="Table Lamp"
            price="529.76"
            url="https://www.mousarris.com/product-page/saita"
          />
        </Box>
        <ScrollButtonRight scrollRef={ref} />

        {/* <LandingSeeMore
          text="See more Cool as S*^%"
          mixpanelTracking="See More Cool as S*^%"
        /> */}
      </Box>
    </ThemeProvider>
  )
}

export default LandingCoolAsS
