import axios from 'axios'

// import components
import { EndPoint } from '../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://b493go548k.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend',
  test: 'https://wsr62w4eqb.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend-for-testing',
  local: 'http://127.0.0.1:8080/furniture/suggest',
}

export default async function AISearchEngineBackendCall(convoArray) {
  const responseFromAWS = await (async () => {
    try {
      const response = await axios.post(END_POINTS[EndPoint], {
        request_method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        post_data: JSON.stringify({
          purpose: 'ai_search_engine_search',
          conversation_messages: convoArray,
        }),
      })
      return response
    } catch (e) {
      console.log(e.message)
      return null
    }
  })()

  let returnedData = []

  returnedData[0] = {
    content: responseFromAWS.data.body.ai_search_engine_call_response,
  }

  // returnedData[0] = {
  //   role: "assistant",
  //   content:
  //     "Oh no...I'm having trouble answering your question. Please refresh the page and try again.",
  // };

  // // This will replace the error text if a message was successfully returned
  // if (responseFromAWS?.data?.body) {
  //   returnedData[0] = {
  //     ...returnedData[0],
  //     content: responseFromAWS.data.body.ai_search_engine_call_response,
  //   };
  // }

  return returnedData[0].content
}
