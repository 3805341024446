import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '130px',
    [customTheme.breakpoints.down('md')]: {
      paddingTop: '50px',
    },
    [customTheme.breakpoints.down('sm')]: {
      paddingTop: '20px',
    },
  },
}
