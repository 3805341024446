import React from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import ImageTextCardAllMyDesigns from '../../molecules/ImageTextCardAllMyDesigns/ImageTextCardAllMyDesigns'
import ImageTextCardStartNewDesign from '../../molecules/ImageTextCardStartNewDesign/ImageTextCardStartNewDesign'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

function ImageTextGridAllMyDesigns({ savedDesigns, onPressDeleteButton }) {
  const styles = {
    gridItemStyle: {
      minWidth: '250px',
      minHeight: '250px',
      [customTheme.breakpoints.down('xs')]: {
        minWidth: '100%',
        minHeight: '100%',
      },
    },
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        [customTheme.breakpoints.down('lg')]: {
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px',
        },
      }}
    >
      <Grid
        sx={styles.gridItemStyle}
        item
        xs={12}
        sm={4}
        md={3}
        justifyContent="center"
      >
        <ImageTextCardStartNewDesign savedDesigns={savedDesigns} />
      </Grid>
      {savedDesigns &&
        savedDesigns.map((currentDesign, idx) => {
          return (
            <Grid
              sx={styles.gridItemStyle}
              item
              xs={12}
              sm={4}
              md={3}
              justifyContent="center"
              key={idx}
            >
              <ImageTextCardAllMyDesigns
                {...currentDesign}
                onPressDeleteButton={onPressDeleteButton}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}

const propTypes = {
  savedDesigns: PropTypes.arrayOf(
    PropTypes.shape({
      planId: PropTypes.number.isRequired,
      planThumbnail: PropTypes.string,
      lastUpdated: PropTypes.string,
    }),
  ),
  onPressDeleteButton: PropTypes.func,
}

const defaultProps = {
  onPressDeleteButton: () => {},
}

ImageTextGridAllMyDesigns.propTypes = propTypes
ImageTextGridAllMyDesigns.defaultProps = defaultProps

export default ImageTextGridAllMyDesigns
