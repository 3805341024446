import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  categoryContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(20% - 16px)',
    margin: '8px 8px 16px 8px',
    [customTheme.breakpoints.down('md')]: {
      width: 'calc(33.33333% - 16px)',
    },
    [customTheme.breakpoints.down('sm')]: {
      width: 'calc(50% - 8px)',
      margin: '4px',
    },
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%', // This maintains a 1:1 aspect ratio (square)
    overflow: 'hidden',
    border: 'solid 1px #F3F3F3',
    borderRadius: '16px',
  },
  favoriteContainer: {
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(191, 191, 191, 0.5)',
    borderRadius: '20px',
    position: 'absolute',
    zIndex: 1,
    right: '16px',
    top: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(191, 191, 191, 0.4)',
    },
    [customTheme.breakpoints.down('sm')]: {
      right: '8px',
      top: '8px',
    },
  },
  favoriteIcon: {
    width: '24px',
    height: '24px',
  },
  categoryImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ratingContainer: {
    position: 'absolute',
    bottom: '16px',
    padding: '2px 8px',
    backgroundColor: '#FFF',
    borderRadius: '0 8px 8px 0',
    display: 'flex',
    flexDirection: 'row',
    opacity: '0.75',
  },
  starIcon: {
    width: '24px',
    height: '24px',
  },
  rating: {
    fontWeight: 'bold',
    marginLeft: '4px',
  },
  firstRowText: {
    fontWeight: 'bold',
    marginTop: '8px',
    [customTheme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  secondRowText: {
    marginTop: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dealTxt: {
    fontSize: '20px',
    color: '#EC5858',
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginRight: '8px',
  },
  costTxt: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  strikeThrough: {
    fontSize: '16px',
    textDecoration: 'line-through',
    color: '#757575',
  },
}
