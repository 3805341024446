import React, { useState, useEffect } from 'react'
import { Box, CardMedia } from '@mui/material'
import { UserContext } from '../../App'
import parse from 'html-react-parser' // Import the parser

// Import components
import { classes } from './ProfileStyle.js'
import Text from '../../components/atoms/Text/Text'
import Button from '../../components/atoms/Button/Button'
import PreferencesMsgCallToBackend from '../../components/molecules/preferencesMsgCallToBackend/PreferencesMsgCallToBackend.jsx'
import DeleteAccountCallToBackend from './DeleteAccountCallToBackend'
import Modal from '../../components/organisms/Modal/Modal'

const exampleImageBaseUrl =
  'https://craftle-static.s3.us-east-2.amazonaws.com/craft-my-style-results-page/'

// Import images
const DesignStyleImages = {
  bohemian: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-bohemian-2.jpg',
      cap: 'Displate',
      capLink:
        'https://blog.displate.com/wp-content/uploads/2021/12/Boho-Living-Room-Ideas_hanging-chair_2-891x1024.jpg',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-bohemian-1.jpg',
      cap: 'blackandblooms',
      capLink:
        'https://www.blackandblooms.com/blog/creating-a-jungle-record-corner',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-bohemian-3.jpg',
      cap: 'Thebeautyrevival',
      capLink: 'https://thebeautyrevival.com/',
    },
  },
  eclectic: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-eclectic-1.jpg',
      cap: 'Christopherlonggroup',
      capLink:
        'https://christopherlonggroup.com/blog/create-a-happy-hygge-home-interior',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-eclectic-2.jpg',
      cap: 'Istitutomarangoni',
      capLink:
        'https://www.instagram.com/istitutomarangoni_miami/p/Cu9zMbpOlYN/',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-eclectic-3.jpg',
      cap: 'Digsdigs',
      capLink:
        'https://www.digsdigs.com/photos/2019/06/a-colorful-eclectic-living-room-with-a-green-statement-wall-with-artworks-a-boho-rug-a-mustard-sofa-and-wicker-touches-1.jpg',
    },
  },
  industrial: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-industrial-1.jpg',
      cap: 'Houzz',
      capLink: 'https://www.houzz.com/',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-industrial-2.jpg',
      cap: 'Poltronafrau',
      capLink: 'https://www.poltronafrau.com/us/en.html',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-industrial-3.jpg',
      cap: 'Pinterest',
      capLink: 'https://www.pinterest.com/pin/527906387575708901/',
    },
  },
  'mid-century modern': {
    img1: {
      img:
        exampleImageBaseUrl + 'cms-result-example-img-mid-century-modern-3.jpg',
      cap: 'Apartmenttherapy',
      capLink:
        'https://www.apartmenttherapy.com/cheap-mid-century-modern-style-36713253',
    },
    img2: {
      img:
        exampleImageBaseUrl + 'cms-result-example-img-mid-century-modern-1.jpg',
      cap: 'Joybird',
      capLink: 'https://joybird.com/sofas/briar-leather-sofa/',
    },
    img3: {
      img:
        exampleImageBaseUrl + 'cms-result-example-img-mid-century-modern-2.jpg',
      cap: 'Livingspaces',
      capLink:
        'https://www.livingspaces.com/globalassets/images/inspiration/styles/mid-century/mid-century-small-apartment-malika-sofa_2.jpg?w=580&h=440&mode=pad',
    },
  },
  modern: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-modern-2.jpg',
      cap: 'Decormatters',
      capLink:
        'https://decormatters.com/blog/spruce-up-your-decor-5-modern-living-room-and-bedroom-interior-design-ideas-for-your-home',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-modern-1.jpg',
      cap: 'Beatifulhomes',
      capLink: 'https://www.beautifulhomes.com/',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-modern-3.jpg',
      cap: 'Eymericwidling',
      capLink: 'https://eymericwidling.com/',
    },
  },
  rustic: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-rustic-3.jpg',
      cap: 'Countryliving',
      capLink:
        'https://www.countryliving.com/home-design/decorating-ideas/g29237051/rustic-living-room-ideas/?slide=9',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-rustic-2.jpg',
      cap: 'Countryliving',
      capLink:
        'https://www.countryliving.com/home-design/decorating-ideas/g29237051/rustic-living-room-ideas/?slide=13',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-rustic-1.jpg',
      cap: 'Countryliving',
      capLink:
        'https://www.countryliving.com/home-design/house-tours/g24849024/tennessee-fixer-upper/',
    },
  },
  scandinavian: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-scandinavian-1.jpg',
      cap: '',
      capLink: '',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-scandinavian-2.jpg',
      cap: '',
      capLink: '',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-scandinavian-3.jpg',
      cap: '',
      capLink: '',
    },
  },
  traditional: {
    img1: {
      img: exampleImageBaseUrl + 'cms-result-example-img-traditional-1.jpg',
      cap: 'Rinfretltd',
      capLink: 'https://www.rinfretltd.com/',
    },
    img2: {
      img: exampleImageBaseUrl + 'cms-result-example-img-traditional-2.jpg',
      cap: '',
      capLink: '',
    },
    img3: {
      img: exampleImageBaseUrl + 'cms-result-example-img-traditional-3.jpg',
      cap: '',
      capLink: '',
    },
  },
}

const titleIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/profile/profile-title-icon.svg'

export default function Profile() {
  const { user } = React.useContext(UserContext)
  const [userId, setUserId] = useState(null)
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false)
  const [userPreferencesMsg, setUserPreferencesMsg] = useState('')
  const [userInteriorDesignPreference, setUserInteriorDesignPreference] =
    useState('')

  useEffect(() => {
    const fetchPreferencesMsg = async () => {
      if (user) {
        setUserId(user?.user_id)

        try {
          const response = await PreferencesMsgCallToBackend(user?.user_id)
          const preferenceMsg = response?.preferences_msg
          const interiorDesignPreference = response?.preferred_design_style

          if (preferenceMsg) {
            setUserPreferencesMsg(preferenceMsg)
          }
          if (interiorDesignPreference) {
            setUserInteriorDesignPreference(interiorDesignPreference)
          }
        } catch (error) {
          console.error('Error fetching user preferences message:', error)
        }
      }
    }

    fetchPreferencesMsg()
  }, [user])

  function onPressPrimaryBtn() {
    setShowConfirmDeletePrompt(false)

    // Get data from the DB
    DeleteAccountCallToBackend(userId).then(() => {
      // Remove user data from localStorage
      localStorage.removeItem('user')

      document.location.href = '/'
    })
  }

  function onPressCancelButton() {
    setShowConfirmDeletePrompt(false)
  }

  async function StartDeleteAccount() {
    setShowConfirmDeletePrompt(true)
  }

  // Split userPreferencesMsg using the exclamation point
  const [firstSentence, ...rest] = userPreferencesMsg.split('!')
  const remainingMsg = rest.join('!') // Join the rest back in case there are multiple exclamation points

  return (
    <Box sx={classes.container}>
      {user ? (
        <>
          {showConfirmDeletePrompt ? (
            <Modal
              prompt="Are you sure you want to delete your account?"
              primaryBtnTxt="Yes, delete my account"
              secondaryBtnTxt="No, don't delete"
              onPressPrimaryBtn={onPressPrimaryBtn}
              onPressCancelButton={onPressCancelButton}
            />
          ) : null}
          <Box sx={classes.backgroundColor} />
          <Box sx={classes.content}>
            <Box sx={classes.titleRow}>
              <CardMedia
                component="img"
                image={titleIcon}
                alt=""
                sx={classes.titleIcon}
              />
              <Text variant="h1" sx={classes.h1}>
                {user.first_name}&apos;s Profile
              </Text>
            </Box>

            <Box sx={classes.preferenceMsgContainer}>
              {/* Render the first sentence with larger font size */}
              {firstSentence && (
                <Text sx={classes.preferenceMsgHeader}>
                  {parse(firstSentence.trim())}!
                </Text>
              )}
              {/* Render the rest of the message */}
              {remainingMsg && (
                <Text sx={classes.preferenceMsgBody}>
                  {parse(remainingMsg.trim())}
                </Text>
              )}
              <Text sx={classes.futureUpdatesMsg}>
                We&apos;re crafting a personalized furniture experience that
                will evolve with you every step of the way. Stay tuned—exciting
                updates are coming!
              </Text>
              <Box sx={classes.preferenceImagesContainer}>
                <Box sx={classes.img1Container}>
                  <CardMedia
                    component="img"
                    image={
                      DesignStyleImages[userInteriorDesignPreference]?.img1?.img
                    }
                    alt=""
                    sx={classes.img1}
                  />
                  <a
                    href={
                      DesignStyleImages[userInteriorDesignPreference]?.img1
                        ?.capLink
                    }
                    target="_blank" // Opens the link in a new tab
                    rel="noopener noreferrer" // Adds security for external links
                  >
                    <Text sx={classes.imgCaption}>
                      {
                        DesignStyleImages[userInteriorDesignPreference]?.img1
                          ?.cap
                      }
                    </Text>
                  </a>
                </Box>
                <Box sx={classes.rightImagesContainer}>
                  <Box sx={classes.img2Container}>
                    <CardMedia
                      component="img"
                      image={
                        DesignStyleImages[userInteriorDesignPreference]?.img2
                          ?.img
                      }
                      alt=""
                      sx={classes.img2}
                    />
                    <a
                      href={
                        DesignStyleImages[userInteriorDesignPreference]?.img2
                          ?.capLink
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text sx={classes.imgCaption}>
                        {
                          DesignStyleImages[userInteriorDesignPreference]?.img2
                            ?.cap
                        }
                      </Text>
                    </a>
                  </Box>
                  <Box sx={classes.img3Container}>
                    <CardMedia
                      component="img"
                      image={
                        DesignStyleImages[userInteriorDesignPreference]?.img3
                          ?.img
                      }
                      alt=""
                      sx={classes.img3}
                    />
                    <a
                      href={
                        DesignStyleImages[userInteriorDesignPreference]?.img3
                          ?.capLink
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Text sx={classes.imgCaption}>
                        {
                          DesignStyleImages[userInteriorDesignPreference]?.img3
                            ?.cap
                        }
                      </Text>
                    </a>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Button
              variant="fourth"
              onClick={() => {
                StartDeleteAccount()
              }}
              sx={classes.deleteAccount}
            >
              Delete Account
            </Button>
          </Box>
        </>
      ) : (
        <Text sx={classes.notLoggedInTxt}>
          Uh oh! You need to be{' '}
          <Button variant="fourth" href="/log-in" sx={classes.inlineTxt}>
            logged in
          </Button>{' '}
          to see a profile.
        </Text>
      )}
    </Box>
  )
}
