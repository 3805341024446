import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
// import CardMedia from "@mui/material/CardMedia";

// import components
import LandingFurniture from '../../molecules/LandingFurniture/LandingFurniture'
// import LandingSeeMore from "../../molecules/LandingSeeMore/LandingSeeMore";
import ScrollButtonLeft from '../../molecules/ScrollButtonLeft/ScrollButtonLeft'
import ScrollButtonRight from '../../molecules/ScrollButtonRight/ScrollButtonRight'

// import icons

// import images
const HarryPotter1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-1.jpg'
const HarryPotter2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-2.jpg'
const HarryPotter3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-3.jpg'
const HarryPotter4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-4.jpg'
const HarryPotter5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-5.jpg'
const HarryPotter6 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-6.jpg'
const HarryPotter7 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-7.jpg'
const HarryPotter8 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-8.jpg'
const HarryPotter9 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-9.jpg'
const HarryPotter10 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-10.jpg'
const HarryPotter11 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-11.jpg'
const HarryPotter12 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-12.jpg'
const HarryPotter13 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-13.jpg'
const HarryPotter14 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-14.jpg'
const HarryPotter15 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-15.jpg'
const HarryPotter16 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-16.jpg'
const HarryPotter17 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-17.jpg'
const HarryPotter18 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-18.jpg'
const HarryPotter19 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-19.jpg'
const HarryPotter20 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/landing-page/harry-potter-20.jpg'

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function LandingHarryPotter() {
  const ref = useRef(null)

  const classes = {
    funCardContainer: {
      marginRight: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [customTheme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      },
    },
    funCard: {
      padding: '0 16px',
      borderRadius: '16px',
      boxSizing: 'border-box',
      height: '100%',
      width: '240px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      [customTheme.breakpoints.down('sm')]: {
        width: '200px',
      },
    },
    funCardText: {
      fontSize: '32px',
      lineHeight: 1.2,
      color: '#FFFFFF',
    },
    funCardText2: {
      fontSize: '32px',
      lineHeight: 1.2,
      fontWeight: 'bold',
      color: '#FFFFFF',
    },
  }

  return (
    <ThemeProvider theme={customTheme}>
      {/* popular furniture section */}
      <Box
        sx={{
          background: '#F7DEDE',
          marginTop: '48px',
          width: '100vw',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '40px 0',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: '90vw',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: '#2a2a2a',
              fontSize: '32px',
              fontWeight: 'bold',
            }}
          >
            🧙‍♂ Hogwarts Houses&apos; Magical Decor
          </Typography>

          <Typography
            sx={{
              color: '#2a2a2a',
              fontSize: '16px',
              marginTop: '16px',
              // fontWeight: "bold",
            }}
          >
            Inspired by the houses of Hogwarts. Where will the sorting hat place
            you?
          </Typography>

          <ScrollButtonLeft scrollRef={ref} left="5.5vw" top="54%" />
          <Box
            ref={ref}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '16px',
              width: '95vw',
              marginLeft: '-5vw',
              overflowX: 'scroll',
              paddingLeft: '5vw',
              scrollBehavior: 'smooth',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {/* Gryffindor */}
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down('sm')]: {
                  alignItems: 'flex-start !important',
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: '#740001',
                  [customTheme.breakpoints.down('sm')]: {
                    width: '200px !important',
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  Daring
                  <br />
                  Brave
                  <br />
                  Nerve
                  <br />
                  Valiant
                  <br />
                  <br />
                </Typography>
                <Typography style={classes.funCardText2}>Gryffindor</Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/native-interiors/nativa-interiors-london-tufted-sofa"
              image={HarryPotter1}
              brand="Nativa Interiors"
              type="Sofa"
              price="1,610.00"
              url="https://www.afastores.com/nativa-interiors-london-tufted-sofa-72-in-red.html?utm_source=google&utm_medium=cse&utm_term=SOF-LONDON-72-CL-MF-RED"
            />
            <LandingFurniture
              path="/furniture/perigold/highland-peele-bolster-pillow-cover-and-insert"
              image={HarryPotter2}
              brand="Perigold"
              type="Pillow Cover"
              price="235"
              url="https://www.perigold.com/Eastern-Accents--Hyland-Peele-Bolster-Pillow-Cover-and-Insert-HYL02-L1227-K~OWBF1026.html?refid=GX204792852953-OWBF1026&device=c&ptid=330116196935&targetid=pla-330116196935&ireid=47443218&gclid=EAIaIQobChMI37Xbn7nO-AIVi77ICh0CgQ5GEAQYASABEgLzuvD_BwE"
            />
            <LandingFurniture
              path="/furniture/vcf/orville-side-table"
              image={HarryPotter3}
              brand="VCF"
              type="Side Table"
              price="199.99"
              url="https://www.valuecityfurniture.com/product/group/living-room/tables/end-tables/orville-side-table-hazelnut/2388018/2385650?mc=yes&region_id=401323&gclid=EAIaIQobChMI-prYzbzO-AIVkWtvBB0fWA0vEAsYAyABEgKy0fD_BwE&gclsrc=aw.ds"
            />
            <LandingFurniture
              path="/furniture/lane-furniture/glenrock-tawny-hi-leg-recliner"
              image={HarryPotter4}
              brand="Lane Furniture"
              type="Armchair"
              price="443"
              url="https://www.afastores.com/lane-furniture-glenrock-tawny-hi-leg-recliner.html?utm_source=googlepla&utm_medium=cse&utm_term=6002-11-Glenrock-Tawny&gclid=EAIaIQobChMI1NDM0rXO-AIVyxbUAR2NxgEqEAQYFiABEgKFvPD_BwE"
            />
            <LandingFurniture
              path="/furniture/well-woven/sultan-sarouk-red-traditional-poriental-persian-floral-formal-area-rug"
              image={HarryPotter5}
              brand="Well Woven"
              type="Rug"
              price="170"
              url="https://www.houzz.com/products/sultan-sarouk-red-traditional-oriental-persian-floral-formal-area-rug-6-7x9-prvw-vr~121337068?m_refid=PLA_HZ_121337068"
            />
            {/* Slytherin */}
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down('sm')]: {
                  alignItems: 'flex-start !important',
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: '#1A472A',
                  [customTheme.breakpoints.down('sm')]: {
                    width: '200px !important',
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  Cunning
                  <br />
                  Ambitious
                  <br />
                  Creative
                  <br />
                  Ingenious
                  <br />
                  <br />
                </Typography>
                <Typography style={classes.funCardText2}>Slytherin</Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/homeroots/homeroots-green-velvet-loveseat"
              image={HarryPotter6}
              brand="Homeroots"
              type="Loveseat"
              price="1437.41"
              url="https://www.1stopbedrooms.com/homeroots-green-velvet-loveseat-318849?cq_src=google_ads&cq_cmp=16773501769&cq_con=136847008964&cq_term=&cq_med=pla&cq_plac=&cq_net=g&cq_pos=&cq_plt=gp&gclid=EAIaIQobChMIs8DX0L3O-AIVD4zICh2gUg8FEAQYBSABEgLY1_D_BwE"
            />
            <LandingFurniture
              path="/furniture/wayfair/beaulah-30-wide-velvet-tufted-round-storage-ottoman-with-storage"
              image={HarryPotter7}
              brand="Wayfair"
              type="Ottoman"
              price="296.99"
              url="https://www.wayfair.com/Kelly-Clarkson-Home--Beulah-30-Wide-Velvet-Tufted-Round-Storage-Ottoman-X118879334-L310-K~W007768223.html?refid=FR49-W007768223_1701520078&PiID%5B%5D=1701520078"
            />
            <LandingFurniture
              path="/furniture/wayfair/pittsburgh-terracotta-pot-planter"
              image={HarryPotter8}
              brand="Wayfair"
              type="Planter"
              price="256.99"
              url="https://www.wayfair.com/outdoor/pdp/bungalow-rose-pittsburgh-terracotta-pot-planter-w005107565.html?piid=1510183272"
            />
            <LandingFurniture
              path="/furniture/TalaveraWithLove/handmade-wooden-chess-set"
              image={HarryPotter9}
              brand="TalaveraWithLove"
              type="Chess Set"
              price="295"
              url="https://www.etsy.com/listing/1050006089/handmade-wooden-chess-set-luxury-stone?gpla=1&gao=1&&utm_source=google&utm_medium=cpc&utm_campaign=shopping_us_a-toys_and_games-games_and_puzzles-board_games-chess&utm_custom1=_k_EAIaIQobChMInZ7T3L7O-AIVyMmUCR3wsgtYEAsYAiABEgIZKfD_BwE_k_&utm_content=go_12569403118_122509905347_507439707251_pla-314262775387_c__1050006089_471034953&utm_custom2=12569403118&gclid=EAIaIQobChMInZ7T3L7O-AIVyMmUCR3wsgtYEAsYAiABEgIZKfD_BwE"
            />
            <LandingFurniture
              path="/furniture/wayfair/plumerville-14-57-bankers-lamp"
              image={HarryPotter10}
              brand="Wayfair"
              type="Table Lamp"
              price="94.99"
              url="https://www.wayfair.com/lighting/pdp/charlton-home-plumerville-1457-bankers-lamp-trch1025.html?piid=44677483"
            />
            {/* Ravenclaw */}
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down('sm')]: {
                  alignItems: 'flex-start !important',
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: '#0E1A40',
                  [customTheme.breakpoints.down('sm')]: {
                    width: '200px !important',
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  Intelligent
                  <br />
                  Clever
                  <br />
                  Witty
                  <br />
                  Creative
                  <br />
                  <br />
                </Typography>
                <Typography style={classes.funCardText2}>Ravenclaw</Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/wayfair/kannon-tufted-right-arm-chaise-lounge"
              image={HarryPotter11}
              brand="Wayfair"
              type="Chaise"
              price="769.99"
              url="https://www.wayfair.com/furniture/pdp/willa-arlo-interiors-kannon-tufted-right-arm-chaise-lounge-w004769075.html?piid=1346369929"
            />
            <LandingFurniture
              path="/furniture/dover/5-x-5-dover-round-rug"
              image={HarryPotter12}
              brand="Dover"
              type="Rug"
              price="69"
              url="https://rugs.com/navy-blue-5-ft-round-bexley-area-rug-6288354?gclid=EAIaIQobChMI6capisDQ-AIV5_HjBx3SDw0UEAQYASABEgJkrPD_BwE"
            />
            <LandingFurniture
              path="/furniture/unique-art-since-1996/gemstone-world-globe-with-silver-tripod-stand"
              image={HarryPotter13}
              brand="Unique Art Since 1996"
              type="Globe"
              price="95.55"
              url="https://www.amazon.com/Unique-Art-10-Inch-Gemstone-Silver/dp/B007VC4NX0?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&psc=1&smid=ATVPDKIKX0DER"
            />
            <LandingFurniture
              path="/furniture/sauder/sauder-5-shelf-split-bookcase"
              image={HarryPotter14}
              brand="Sauder"
              type="Bookcase"
              price="149.24"
              url="https://www.amazon.com/Sauder-410367-5-Shelf-Split-Bookcase/dp/B004HBCVGE?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=ATVPDKIKX0DER&th=1"
            />
            <LandingFurniture
              path="/furniture/wayfair/evianna-79-5-velvet-rolled-arm-sofa"
              image={HarryPotter15}
              brand="Wayfair"
              type="Sofa"
              price="899.99"
              url="https://www.wayfair.com/Rosdorf-Park--Evianna-79.5-Velvet-Rolled-Arm-Sofa-X116516470-L305-K~W005405369.html?refid=GX522955358536-W005405369_14619790&device=c&ptid=1318622065591&network=g&targetid=pla-1318622065591&channel=GooglePLA&ireid=121927893&fdid=1817&PiID%5B%5D=14619790&campaignid=398251723&gclid=EAIaIQobChMI9_eygbzQ-AIVpHNvBB262QHAEAQYASABEgIjHPD_BwE"
            />
            {/* Hufflepuff */}
            <Box
              style={classes.funCardContainer}
              sx={{
                [customTheme.breakpoints.down('sm')]: {
                  alignItems: 'flex-start !important',
                },
              }}
            >
              <Box
                style={classes.funCard}
                sx={{
                  backgroundColor: '#ECB939',
                  [customTheme.breakpoints.down('sm')]: {
                    width: '200px !important',
                  },
                }}
              >
                <Typography style={classes.funCardText}>
                  Reliable
                  <br />
                  Loyal
                  <br />
                  Diligent
                  <br />
                  Kind
                  <br />
                  <br />
                </Typography>
                <Typography style={classes.funCardText2}>Hufflepuff</Typography>
              </Box>
            </Box>
            <LandingFurniture
              path="/furniture/jayden-creation/mustard-polyester-2-seats-loveseats"
              image={HarryPotter16}
              brand="JAYDEN CREATION"
              type="Loveseat"
              price="374.34"
              url="https://www.homedepot.com/p/JAYDEN-CREATION-Irvington-Lambskin-Sherpa-45-in-Retro-Color-Legs-Mustard-Polyester-2-Seats-Loveseats-with-Wood-Base-HSFS0102-MUSTARD/315832203?source=shoppingads&locale=en-US#overlay"
            />
            <LandingFurniture
              path="/furniture/threshold/round-decorative-baskets-natural"
              image={HarryPotter17}
              brand="Threshold"
              type="Basket"
              price="36"
              url="https://www.target.com/p/round-rattan-tall-decorative-baskets-natural-threshold-8482/-/A-76556248?ref=tgt_adv_XS000000&AFID=google_pla_df_free_local&CPNG=Storage%2BOrganization&adgroup=2-7"
            />
            <LandingFurniture
              path="/furniture/noble-house/spencer-yellow-round-knit-pouf"
              image={HarryPotter18}
              brand="Noble House"
              type="Ottoman"
              price="52.76"
              url="https://www.homedepot.com/p/Noble-House-Spencer-Yellow-Round-Knit-Pouf-94223/315450943?source=shoppingads&locale=en-US#overlay"
            />
            <LandingFurniture
              path="/furniture/rugshop/geometric-boho-area-rug-5-x-7-yellow"
              image={HarryPotter19}
              brand="Rugshop"
              type="Rug"
              price="70.99"
              url="https://www.amazon.com/Rugshop-Geometric-Bohemian-Design-Yellow/dp/B086DTDPXY?source=ps-sl-shoppingads-lpcontext&ref_=fplfs&smid=ATVPDKIKX0DER&th=1"
            />
            <LandingFurniture
              path="/furniture/salvador-brown/hanging-indoor-outdoor-planter"
              image={HarryPotter20}
              brand="SALVADOR BROWN"
              type="Planer"
              price="24.95"
              url="https://www.cb2.com/salvador-brown-hanging-indoor-outdoor-planter-small/s503035?localedetail=US"
            />
          </Box>
          <ScrollButtonRight scrollRef={ref} right="5.5vw" top="54%" />

          {/* <LandingSeeMore
            text="See more Hogwarts Houses' Magical Decor"
            mixpanelTracking="See more Hogwarts Houses' Magical Decor"
          /> */}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default LandingHarryPotter
