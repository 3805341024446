import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  // Image function styles
  // meshStyle: {
  //   opacity: "50%",
  // },

  // Moodboard from homepage
  parentBoxStyleForHomePageView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  canvasStyleForHomePageView: {
    position: 'relative',
    zIndex: 2,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    [customTheme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
    },
  },

  // Craft My Style moodboard
  parentBoxStyle: {
    top: '80px',
    position: 'sticky',
    height: '496px',
    // height: "calc(100vh - 80px)",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: "#F8F8F8",
    [customTheme.breakpoints.down(1288)]: {
      marginTop: '16px',
      flexDirection: 'column',
      alignItems: 'center',
      height: 'inherit',
      // width: "100vw",
      position: 'inherit',
    },
  },
  canvasStyle: {
    zIndex: 2,
    backgroundColor: '#FFF',
    position: 'relative',
    width: '496px',
    height: '496px',
    // width: "95%",
    // height: "95%",
    boxShadow: '0px 5px 15px rgba(0, 0, 0, .15)',
    borderRadius: '16px',
    overflow: 'hidden',
    // "&::before": {
    //   content: '""',
    //   display: "block",
    // },
    [customTheme.breakpoints.down('md')]: {
      boxSizing: 'border-box',
      padding: '0 16px',
      width: '95vw',
      height: '95vw',
    },
  },
  navDots: {
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
  },
  dots: {
    width: '10px',
    height: '10px',
    backgroundColor: '#C4C4C4',
    borderRadius: 1.5,
    margin: '0px 4px',
  },
  dotSelected: {
    backgroundColor: '#2a2a2a',
  },
  moodboardDescriptionMobileAndTablet: {
    display: 'flex',
    padding: '16px 20px',
    backgroundColor: '#E3E3E3',
    width: '100%',
    boxSizing: 'border-box',
    color: '#2a2a2a',
    justifyContent: 'center',
    zIndex: 2,
    textAlign: 'center',
    marginBottom: '16px',
    fontSize: '18px',
    [customTheme.breakpoints.down('md')]: {
      display: 'inherit',
      padding: '16px 20px',
      backgroundColor: '#E3E3E3',
      width: '100%',
      boxSizing: 'border-box',
      color: '#2a2a2a',
      fontSize: '16px',
    },
  },
  moodboardDescriptionDesktop: {
    marginTop: '24px',
    color: '#757575',
    zIndex: 2,
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}
