import React, { useState, createContext, useContext } from 'react'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { UserContext } from '../../../App'

// Import components
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'
import { classes } from './WebAppBarStyle'
import GlobalNavButtons from './GlobalNavButtons'
import { CMSResultsContext } from '../../../pages/CraftMyStyle/pages/CMSResults'
import CMSResultsOnboarding from '../../molecules/CMSResultsOnboarding/CMSResultsOnboarding'

// Import images
import { ReactComponent as CraftleIconWhite } from '../../../assets/logos/craftle_icon_white.svg'
import { ReactComponent as CraftleIconBlack } from '../../../assets/logos/craftle_icon_black.svg'
import { ReactComponent as CraftleLogoWhite } from '../../../assets/logos/craftle_logo_white.svg'
import { ReactComponent as CraftleLogoBlack } from '../../../assets/logos/craftle_logo_black.svg'

// import global store
import { Context } from '../../../context/context'

export const WebAppBarScrollChanges = createContext()

const propTypes = {
  urls: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      color: PropTypes.string,
      variant: PropTypes.string,
      external: PropTypes.bool,
      target: PropTypes.string,
      subLink: PropTypes.array,
      newText: PropTypes.string,
    }),
  ).isRequired,
  theme: PropTypes.string,
}

const defaultProps = {
  theme: 'black',
}

WebAppBar.propTypes = propTypes
WebAppBar.defaultProps = defaultProps

export default function WebAppBar({ urls, theme }) {
  const isSmall = customTheme.breakpoints.down('sm')
  const isMedium = customTheme.breakpoints.down('md')
  const isLarge = customTheme.breakpoints.down('lg')

  const [anchorEl, setAnchorEl] = useState(null)

  const [globalState] = useContext(Context)
  const { user } = React.useContext(UserContext)
  const { urlPlanId, setShowLoginModal } =
    React.useContext(CMSResultsContext) ?? {}

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = (link) => {
    setAnchorEl(null)
    if (link == 'Log out') {
      localStorage.removeItem('user')
      localStorage.removeItem('craftleUserContext')
    }
  }

  function iconRender(color) {
    let width = 24

    if (isSmall) {
      width = 24
    } else if (isMedium) {
      width = 24
    } else if (isLarge) {
      width
    }

    if (color === 'white') {
      return <CraftleIconWhite width={width} />
    } else {
      return <CraftleIconBlack width={width} />
    }
  }

  function logoRender(color) {
    let width = 100

    if (isSmall) {
      width = 80
    } else if (isMedium) {
      width = 80
    } else if (isLarge) {
      width
    }

    if (color === 'white') {
      return <CraftleLogoWhite width={width} />
    } else {
      return <CraftleLogoBlack width={width} />
    }
  }

  return (
    <Box sx={classes.webAppBarContainer}>
      <Box sx={classes.contentContainer}>
        {/* Left content */}
        <Box sx={classes.leftContentContainer}>
          <Link to="/" style={classes.verticalAlign}>
            <Box sx={{ display: 'inline-block' }}>{iconRender(theme)}</Box>
            <Box sx={classes.logoStyle}>{logoRender(theme)}</Box>
          </Link>
        </Box>

        {/* Note: Our global nav buttons have special style conditions that don't normally meet our usual button styles */}
        <Box>
          {/* For larger screens - middle content */}
          <Box sx={classes.mdUpDisplay}>
            <Box sx={classes.centerNavs}>
              {/* Exclude the log in / sign up / profile buttons */}
              {urls.map((url) =>
                url.text === 'Log In / Sign Up' ||
                (user && url.text === user.first_name) ? null : (
                  <Box key={url.text} sx={classes.buttonContainer}>
                    <GlobalNavButtons url={url} />
                  </Box>
                ),
              )}
            </Box>

            {urls.map((url) =>
              url.text === 'Log In / Sign Up' ||
              (user && url.text === user.first_name) ? (
                user && url.text === 'Log In / Sign Up' ? null : (
                  <Box key={url.text} sx={classes.buttonContainer}>
                    <GlobalNavButtons url={url} />
                  </Box>
                )
              ) : null,
            )}
          </Box>

          {/* For smaller screens */}
          <Box sx={classes.mdDownDisplay}>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
              sx={{
                ...classes.mobileMenuContainer,
                ...(anchorEl && { backgroundColor: '' }),
              }}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => {
                handleMenuClose()
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                className: classes.menuPaper,
                square: true,
              }}
              autoFocus={false}
              disableScrollLock
            >
              {urls.map((url) => {
                // For dropdown menus with sub-links
                if (url.subLink && url.text) {
                  return url.subLink.map((subURL) => (
                    <MenuItem
                      key={subURL.text}
                      onClick={() => {
                        if (subURL.text === 'Log In / Sign Up' && urlPlanId) {
                          setShowLoginModal?.(true)
                        }
                        handleMenuClose(subURL.text)
                      }}
                      component="a"
                      href={
                        subURL.text === 'Log In / Sign Up' && urlPlanId
                          ? '#'
                          : subURL.url
                      }
                      sx={classes.mobileMenuItem}
                    >
                      {subURL.text}
                    </MenuItem>
                  ))
                } else {
                  // If the user is logged in, don't show the "Log In / Sign Up" button
                  if (
                    (user && url.text === 'Log In / Sign Up') ||
                    (!user && url.subLink)
                  ) {
                    return null
                  }
                  // For regular menu items
                  return (
                    <MenuItem
                      key={url.text}
                      onClick={(event) => {
                        if (url.text === 'Log In / Sign Up' && urlPlanId) {
                          event.preventDefault()
                          setShowLoginModal?.(true)
                        }
                        handleMenuClose()
                      }}
                      component="a"
                      href={url.url}
                      sx={classes.mobileMenuItem}
                    >
                      {url.text}
                    </MenuItem>
                  )
                }
              })}
            </Menu>
          </Box>
        </Box>
        {globalState.completed && <CMSResultsOnboarding />}
      </Box>
    </Box>
  )
}
