import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  searchContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFE9AF',
    borderRadius: '32px',
    border: '2px solid #2A2A2A',
    width: '100%',
    // [customTheme.breakpoints.down("md")]: {
    //   width: "100%",
    // },
  },
  textfieldAndSearch: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  textfield: {
    // width: "680px",
    width: '100%',
    '& .MuiFormLabel-root': {
      paddingLeft: '24px',
      marginTop: '-8px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '24px',
      paddingRight: '8px',
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0,
      display: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  searchButton: {},
  exampleSearches: {
    padding: '16px 16px 0 16px',
  },
  exampleSearchRow: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
    gap: '8px',
  },
  exampleSearchText: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  arrowUpRight: {
    width: '24px',
    height: '24px',
  },
}
