import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  // guestModeNotification function styles
  guestModeNotificationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '96px',
    width: '100vw',
    position: 'fixed',
    zIndex: 10,
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '64px',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '192px 0 0 0',
    [customTheme.breakpoints.down('sm')]: {
      padding: '128px 0 0 0',
    },
  },

  headerSection: {
    width: '1136px',
    [customTheme.breakpoints.down(1136)]: {
      width: '100vw',
      padding: '0 16px',
      boxSizing: 'border-box',
    },
  },
  h1: {
    fontSize: '32px',
    fontFamily: 'roboto',
    fontWeight: 'normal !important',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  titleContainer: {
    position: 'relative',
    width: 'fit-content',
  },
  title: {
    marginLeft: '24px',
    fontSize: '128px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [customTheme.breakpoints.down('md')]: {
      fontSize: '100px',
    },
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '48px',
    },
    [customTheme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  titleHighlight: {
    marginTop: '-96px',
    position: 'absolute',
    width: '150vw',
    height: '64px',
    borderRadius: '1000px', // lmao
    zIndex: -1,
    right: 0,
    [customTheme.breakpoints.down('md')]: {
      marginTop: '-80px',
      height: '56px',
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '-40px',
      height: '40px',
    },
  },

  definitionSection: {
    marginTop: '64px',
    width: '1096px',
    [customTheme.breakpoints.down(1096)]: {
      width: '100vw',
      padding: '0 16px',
      boxSizing: 'border-box',
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '32px',
    },
  },
  definedBy: {
    fontSize: '54px',
    fontWeight: 'bold',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  definitionBullets: {
    fontSize: '24px',
    fontWeight: 'bold',
    whiteSpace: 'pre-line',
    marginTop: '32px',
    marginLeft: '112px',
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '16px',
      marginLeft: 0,
      fontSize: '18px',
    },
  },

  exampleImgSection: {
    marginTop: '32px',
    width: '1093px',
    position: 'relative',
    [customTheme.breakpoints.down(1093)]: {
      width: '100vw',
      padding: '0 16px',
      boxSizing: 'border-box',
    },
  },
  exampleImg1Container: {
    overflow: 'hidden',
    position: 'relative',
    height: '600px',
    width: 'calc(384px + 24px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: '67px',
    transformOrigin: 'center top',
    [customTheme.breakpoints.down(1150)]: {
      // marginLeft: "67px",
    },
    [customTheme.breakpoints.down(959)]: {
      marginLeft: 0,
    },
    [customTheme.breakpoints.down(820)]: {
      height: 'auto',
      width: '100%',
    },
    [customTheme.breakpoints.down('sm')]: {
      transform: 'inherit',
      display: 'inline-block',
      width: '48%',
      overflow: 'inherit',
      textAlign: 'center',
    },
  },
  exampleImg2Container: {
    overflow: 'hidden',
    position: 'relative',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(424px + 32px)',
    marginTop: '-440px',
    marginLeft: '666px',
    transformOrigin: 'center top',
    [customTheme.breakpoints.down(1150)]: {
      position: 'absolute',
      marginLeft: 'inherit',
      right: 0,
    },
    [customTheme.breakpoints.down(820)]: {
      marginTop: '16px',
      position: 'relative',
      width: '100%',
      height: 'auto',
    },
    [customTheme.breakpoints.down('sm')]: {
      transform: 'inherit',
      marginTop: 0,
      marginLeft: '4%',
      display: 'inline-block',
      width: '48%',
      overflow: 'inherit',
      textAlign: 'center',
    },
  },
  exampleImg3Container: {
    overflow: 'hidden',
    position: 'relative',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(496px + 40px)',
    marginTop: '-280px',
    marginLeft: '297px',
    transformOrigin: 'center top',
    [customTheme.breakpoints.down(1150)]: {
      marginTop: '-140px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [customTheme.breakpoints.down(820)]: {
      marginTop: '16px',
      position: 'relative',
      width: '100%',
      height: 'auto',
    },
    [customTheme.breakpoints.down('sm')]: {
      transform: 'inherit',
      marginTop: '8px',
    },
  },
  exampleImg1: {
    marginTop: '8px',
    width: '384px',
    height: 'auto', // Maintain aspect ratio
    borderRadius: '24px',
    // boxShadow: "0 5px 12px rgba(0, 0, 0, 0.32)",
    [customTheme.breakpoints.down(959)]: {
      width: '340px',
    },
    [customTheme.breakpoints.down(820)]: {
      width: '100%',
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: 0,
      borderRadius: '16px',
    },
  },
  exampleImg2: {
    marginTop: '12px',
    borderRadius: '24px',
    width: '424px',
    height: 'auto',
    // boxShadow: "0 5px 16px rgba(0, 0, 0, 0.28)",
    [customTheme.breakpoints.down(959)]: {
      width: '384px',
    },
    [customTheme.breakpoints.down(820)]: {
      width: '100%',
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: 0,
      borderRadius: '16px',
    },
  },
  exampleImg3: {
    marginTop: '21px',
    borderRadius: '24px',
    width: '496px',
    height: 'auto',
    // boxShadow: "0 5px 24px rgba(0, 0, 0, 0.26)",
    [customTheme.breakpoints.down(959)]: {
      width: '496px',
    },
    [customTheme.breakpoints.down(820)]: {
      width: '100%',
    },
    [customTheme.breakpoints.down('sm')]: {
      borderRadius: '16px',
    },
  },
  exampleImgCaption: {
    marginTop: '-32px',
    fontSize: '12px',
    color: '#FFFFFF',
    textDecoration: 'underline',
    // boxShadow: "0 5px 24px rgba(0, 0, 0, 0.2)",
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '-24px',
    },
  },

  recommendationSection: {
    marginTop: '-80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  whatYouToldUS: {
    fontSize: '24px',
    marginLeft: '-460px',
    lineHeight: 1.2,
    [customTheme.breakpoints.down('md')]: {
      fontSize: '20px',
      marginLeft: '-240px',
      padding: '0 16px',
    },
    [customTheme.breakpoints.down('md')]: {
      marginLeft: 0,
      padding: '0 16px',
    },
  },
  weRecommendTxt: {
    marginTop: '8px',
    fontSize: '54px',
    marginLeft: '150px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [customTheme.breakpoints.down('md')]: {
      fontSize: '40px',
      marginLeft: '80px',
    },
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '32px',
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  moodboardAndProducts: {
    marginTop: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: '40px',
    position: 'relative',
    [customTheme.breakpoints.down(1288)]: {
      marginTop: '40px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 0,
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  moodboardOuterContainer: {
    top: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'sticky',
    height: 'fit-content',
    [customTheme.breakpoints.down(1288)]: {
      position: 'inherit',
      top: 0,
      height: 'inherit',
    },
  },
  desktopMoodboardContainer: {
    marginTop: '32px',
    maxWidth: '496px',
    maxHeight: '496px',
    // width: `calc(100vw - 536px)`,
    // [customTheme.breakpoints.down("md")]: {
    //   display: "none",
    // },
    // [customTheme.breakpoints.down("md")]: {
    //   display: "inherit",
    //   width: "100vw",
    //   backgroundColor: "#F8F8F8",
    //   padding: "0 0 120px 0",
    // },
  },
  moodboardSubtitle: {
    marginTop: '24px',
    color: '#757575',
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '8px',
    },
  },
  itemResultsContainer: {
    width: '752px',
    [customTheme.breakpoints.down('md')]: {
      width: 'calc(100vw - 32px)',
      // padding: "0 16px",
      boxSizing: 'border-box',
    },
  },
  costContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFE9AF',
    padding: '8px 32px',
    borderRadius: '16px',
    textAlign: 'center',
  },
  totalIs: {
    fontSize: '20px',
  },
  cost: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  otherRecommendationsContainer: {
    marginTop: '32px',
    width: '100%',
    backgroundColor: '#F3F3F3',
    padding: '40px 16px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  otherRecsTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  otherRecsSubtitle: {
    fontSize: '20px',
    marginTop: '8px',
    textAlign: 'center',
  },
  otherRecsItemConstraint: {
    // maxWidth: "500px",
    [customTheme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: 'inherit',
    },
  },

  saveSection: {
    marginTop: '80px',
    display: 'flex',
    flexDirection: 'column',
    [customTheme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  saveRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    [customTheme.breakpoints.down('md')]: {
      gap: 0,
      width: '100%',
      padding: '0 16px',
      boxSizing: 'border-box',
    },
  },
  saveImg1: {
    width: '360px',
    height: '360px',
    borderRadius: '24px',
    [customTheme.breakpoints.down(1572)]: {
      width: '240px',
    },
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  saveImg1Overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
  },
  saveBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '80px',
    position: 'relative',
    borderRadius: '24px',
    border: '4px solid #2A2A2A',
    [customTheme.breakpoints.down(1572)]: {
      width: '100%',
      padding: '56px',
    },
    [customTheme.breakpoints.down('sm')]: {
      padding: '56px 24px',
      boxSizing: 'border-box',
    },
  },
  heartTokenContainer: {
    position: 'absolute',
    top: '-30px',
    padding: '20px',
    border: '4px solid #2A2A2A',
    borderRadius: '100px',
    backgroundColor: '#F3F3F3',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
  },
  heartIcon: {
    width: '36px',
    height: '36px',
  },
  saveTitle: {
    fontSize: '48px',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.2,
    [customTheme.breakpoints.down(1572)]: {
      fontSize: '40px',
    },
    [customTheme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  },
  signUpButtonContainer: {
    marginTop: '40px',
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {},
    [customTheme.breakpoints.down(486)]: {
      width: '100%',
    },
  },
  logInContainer: {
    marginTop: '24px',
    textAlign: 'center',
  },
  newtoCraftleText: {
    display: 'inline-block',
    fontSize: '20px',
  },
  logInButton: {
    display: 'inline-block',
    fontSize: '20px',
  },
  saveImg2: {
    width: '360px',
    height: '360px',
    borderRadius: '24px',
    [customTheme.breakpoints.down(1572)]: {
      width: '240px',
    },
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  saveImg2Overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
  },
  legalNotice: {
    marginTop: '16px',
    fontSize: '16px',
    textAlign: 'center',
    padding: '0 16px',
    boxSizing: 'border-box',
  },
  legalBtns: {
    display: 'inline',
    fontSize: '16px',
  },

  exploreMoreSection: {
    marginTop: '80px',
    marginBottom: '80px',
  },
  exploreMoreTitle: {
    fontSize: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      fontWeight: 'normal',
    },
  },
  exploreMoreSubtitle: {
    fontSize: '54px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [customTheme.breakpoints.down('md')]: {
      fontSize: '40px',
      textAlign: 'center',
    },
  },
  moodboardContainer: {
    marginTop: '64px',
    display: 'flex',
    flexDirection: 'row',
    [customTheme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  moodboardInnerContainer: {},
  totalCostContainer: {
    position: 'relative',
    margin: '0 auto',
    marginTop: '-36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DAEBCF',
    width: 'fit-content',
    padding: '16px 24px',
    borderRadius: '8px',
    border: 'solid 4px #2A2A2A',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.15)',
    zIndex: 3,
    [customTheme.breakpoints.down(346)]: {
      marginTop: '-16px',
    },
  },
  totalCost: {
    color: '#2A2A2A',
    fontSize: '18px',
    lineHeight: 1.5,
    fontWeight: 'bold',
    textAlign: 'center',
    [customTheme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  commandsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '40px',
    [customTheme.breakpoints.down('md')]: {
      alignItems: 'center',
      width: '100%',
      padding: '0 16px',
      boxSizing: 'border-box',
      marginLeft: 0,
    },
  },
  generateDesignBtn: {
    marginTop: '16px',
    padding: '10px 24px',
    display: 'flex',
    flexDirection: 'row',
    [customTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shopTheseProductsBtn: {
    [customTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  moodboardOuter: {
    border: '4px solid #2A2A2A',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    overflow: 'hidden',
    width: 'fit-content',
  },
  moodboardLoadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: "0 8px",
    width: '528px',
    height: '528px',
    [customTheme.breakpoints.down('md')]: {
      width: '480px',
      height: '480px',
    },
    [customTheme.breakpoints.down(900)]: {
      width: '95vw',
      height: '95vw',
    },
  },
  moodboardLoadTxt: {
    color: '#2a2a2a',
    fontSize: '20px',
    lineHeight: 1.5,
    marginTop: '8px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  moodboardInner: {
    width: '528px',
    height: '528px',
    [customTheme.breakpoints.down('md')]: {
      width: '480px',
      height: '480px',
    },
    [customTheme.breakpoints.down(900)]: {
      width: '95vw',
      height: '95vw',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },

  // ------------

  styleContainer: {
    backgroundColor: '#FAF3EE',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginTop: '64px',
    padding: '24px 0',
  },
  styleInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1008px',
    gap: '80px',
  },
  styleImage: {
    maxWidth: '496px',
    maxHeight: '496px',
    borderRadius: '24px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.15)',
  },
  yourStyleContainer: {},
  yourStyle: {
    fontSize: '32px',
    marginBottom: '16px',
    fontWeight: 'bold',
  },
  designMatchedDescription: {
    whiteSpace: 'pre-line',
  },
  h2: {
    marginTop: '64px',
    fontSize: '32px',
    fontWeight: 'bold',
  },

  desktopGridContainer: {
    [customTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  desktopGridItem1: {
    height: '100vh',
  },
  desktopGridItem2: {
    width: '100%',
    backgroundColor: '#FAF3EE',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  scrollDownContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  scrollDownIcon: {
    width: '25px',
    height: '25px',
  },
  mobileAboveFoldContainer: {
    display: 'none',
  },
  mobileYourStyleContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: 'rgb(0,0,0,0.5)',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mobileYourStyleIs: {
    marginBottom: '10px',
    color: '#ffffff',
  },
  mobileYourStyle: {
    fontSize: '30px',
    lineHeight: 1,
    marginBottom: '20px',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  mobileDesignMatchedDescription: {
    maxWidth: '600px',
    marginBottom: '20px',
    color: '#ffffff',
  },
  mobileSwipeUpContainer: {
    position: 'absolute',
    bottom: '30px',
    alignSelf: 'center',
  },
  mobileSwipeUp: {
    color: '#ffffff',
    display: 'inline-block',
    fontWeight: 'bold',
  },

  // Below the fold
  belowFoldContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    [customTheme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  // Tablet and mobile: social section
  mobileSocialSection: {
    display: 'none',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '24px',
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: '24px',
    },
  },
  mobileShareLinkContainer: {
    [customTheme.breakpoints.down('md')]: {
      width: 'fit-content',
    },
  },
  mobileShareLink: {
    marginLeft: '8px',
    fontWeight: 'bold',
  },
  mobileMoodboardContainer: {
    display: 'none',
    [customTheme.breakpoints.down('md')]: {
      display: 'inherit',
      width: '100vw',
      // backgroundColor: "#F8F8F8",
    },
  },
}
