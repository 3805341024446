import React, { useEffect, useContext } from 'react'
import { Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'

// import components
import Text from '../../../components/atoms/Text/Text'
import ContinueSection from '../../../components/molecules/ContinueSection/ContinueSection'

// import graphics
import ResultsErrorGraphic from '../../../assets/graphics/results-error-image.png'

// import Context
import { Context } from '../../../context/context'

const customTheme = createTheme({})

function ResultsError() {
  const [, globalDispatch] = useContext(Context)

  useEffect(() => {
    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName: 'completed',
        value: true,
      },
    })
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        [customTheme.breakpoints.down('md')]: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
        [customTheme.breakpoints.down('sm')]: {
          // paddingLeft: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginBottom: '50px',
        }}
      >
        <Box
          component="img"
          sx={{ width: '95%' }}
          src={ResultsErrorGraphic}
          alt=""
        />
      </Box>
      <Text
        sx={{
          fontWeight: 'bold',
          marginBottom: '5px',
          fontSize: '20px',
          textAlign: 'center',
        }}
      >
        Uh oh...something went wrong
      </Text>
      <ContinueSection
        position="relative"
        buttonText="Try Again"
        onButtonClick={() => {
          window.open('https://craftle.app/designs')
        }}
      />
    </Box>
  )
}

export default ResultsError
