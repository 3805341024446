import React, { createContext, useEffect, useState, useMemo } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import getNavItems from './components/molecules/GetNavItems/GetNavItems'
import { CMSAppBar, Footer, WebAppBar } from './components/organisms'
import {
  Favorites,
  Explore,
  LookalikeFinder,
  LogIn,
  SignUp,
  Profile,
  LivingRoom,
  Home,
  AIFurnitureShopper,
} from './pages/index'
import { ResultsError } from './pages/CraftMyStyle/pages'
import AppConfig from './config/app_config'
import Store from './context/context'
import { MixpanelTracking } from './services'
import {
  popularFurnitureRoutes,
  blogRoutes,
  craftMyStyleRoutes,
  aiFurnitureShopperRoutes,
  footerContentRoutes,
} from './routes'
import CategoryCatalog from './components/templates/CategoryCatalog/CategoryCatalog'
import { getUserContextValue } from './context/userContextValue'

const propTypes = {
  domElement: PropTypes.object.isRequired,
}

const growthbook = new GrowthBook({
  enableDevMode: false,
  trackingCallback: (experiment, result) => {
    // let userId = localStorage.getItem("user-id");
    MixpanelTracking.getInstance().featureFlag(experiment.key, result.value)
  },
})

export const UserContext = createContext()

function App() {
  const [user, setUser] = useState() // user includes email, first_name, and user_id
  const [userGlobalContext, setUserGlobalContext] = useState({}) // Set user actions in here
  const [CMSResultsShowMobileCanvas, setCMSResultsShowMobileCanvas] =
    useState('none')
  const [CMSResultsSelectedFile, setCMSResultsSelectedFile] = useState(null)
  const [itemToDelete, setItemToDelete] = useState()
  const [showCommandBar, setShowCommandBar] = useState()
  const [cmsResultsSaving, setCmsResultsSaving] = useState('')
  const [cmsAddItem, setCmsAddItem] = useState(false)
  const [mobileCmsOtherCommandsClicked, setMobileCmsOtherCommandsClicked] =
    useState(false)
  const [mobileShowOriginalCommands, setMobileShowOriginalCommands] =
    useState(true)
  const [lookalikeFinderSearchText, setLookalikeFinderSearchText] = useState('')

  let firstName = ''
  if (user?.first_name) {
    firstName = user.first_name
  }
  const navLinks = getNavItems(firstName)

  const contextValue = useMemo(
    () =>
      getUserContextValue({
        user,
        setUser,
        userGlobalContext,
        setUserGlobalContext,
        CMSResultsShowMobileCanvas,
        setCMSResultsShowMobileCanvas,
        CMSResultsSelectedFile,
        setCMSResultsSelectedFile,
        itemToDelete,
        setItemToDelete,
        showCommandBar,
        setShowCommandBar,
        cmsResultsSaving,
        setCmsResultsSaving,
        cmsAddItem,
        setCmsAddItem,
        mobileCmsOtherCommandsClicked,
        setMobileCmsOtherCommandsClicked,
        mobileShowOriginalCommands,
        setMobileShowOriginalCommands,
        lookalikeFinderSearchText,
        setLookalikeFinderSearchText,
      }),
    [
      user,
      setUser,
      userGlobalContext,
      setUserGlobalContext,
      CMSResultsShowMobileCanvas,
      setCMSResultsShowMobileCanvas,
      CMSResultsSelectedFile,
      setCMSResultsSelectedFile,
      itemToDelete,
      setItemToDelete,
      showCommandBar,
      setShowCommandBar,
      cmsResultsSaving,
      setCmsResultsSaving,
      cmsAddItem,
      setCmsAddItem,
      mobileCmsOtherCommandsClicked,
      setMobileCmsOtherCommandsClicked,
      mobileShowOriginalCommands,
      setMobileShowOriginalCommands,
      lookalikeFinderSearchText,
      setLookalikeFinderSearchText,
    ],
  )

  useEffect(() => {
    // Load feature definitions from API
    let userId = localStorage.getItem('user-id')
    if (!userId) {
      userId = uuidv4()
      localStorage.setItem('user-id', userId)
    }

    // Get user information if previously logged in
    const loggedInUser = localStorage.getItem('user')
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser)
      setUser(foundUser)
    }

    fetch(AppConfig.GrowthBook.SDKEndpoint)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features)
      })
    growthbook.setAttributes({
      id: userId,
      loggedIn: false,
      employee: false,
    })
  }, [])

  return (
    <CookiesProvider>
      <UserContext.Provider value={contextValue}>
        <GrowthBookProvider growthbook={growthbook}>
          <Store>
            <div className="App">
              <Routes>
                {/* Landing page related routes */}
                <Route
                  path="/"
                  element={
                    <>
                      <Helmet>
                        <title>
                          Craftle • AI Interior Design and Furniture Shopping
                        </title>
                        <meta
                          name="description"
                          content="Discover your dream space with Craftle, the forefront of AI-driven interior design. Dive into a seamless furniture shopping experience, personalized to fit your style and needs. From contemporary aesthetics to traditional charm, Craftle brings innovative design solutions and a vast collection of furniture right to your fingertips."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <Home />
                      <Footer />
                    </>
                  }
                />

                {/* AI furniture shopper */}
                <Route
                  path="/ai-furniture-shopper"
                  element={
                    <>
                      <Helmet>
                        <title>Craftle • AI Shopper</title>
                        <meta
                          name="description"
                          content="Use our AI furniture shopper to find your perfect furniture."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <AIFurnitureShopper />
                      <Footer />
                    </>
                  }
                />

                {/* AI furniture shopper DEMO routes */}
                {aiFurnitureShopperRoutes(navLinks)}

                {/* Shop */}
                <Route
                  path="/living-room"
                  element={
                    <>
                      <Helmet>
                        <title>Living Room Furniture • Craftle</title>
                        <meta
                          name="description"
                          content="Shop for living room furniture."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <LivingRoom />
                      <Footer />
                    </>
                  }
                />
                <Route
                  path="/living-room/:category"
                  element={
                    <>
                      <Helmet>
                        <title>Furniture Category • Craftle</title>
                        <meta
                          name="description"
                          content="Shop for furniture."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <CategoryCatalog />
                      <Footer />
                    </>
                  }
                />

                {/* Favorites */}
                <Route
                  path="/favorites"
                  element={
                    <>
                      <Helmet>
                        <title>Favorites • Craftle</title>
                        <meta
                          name="description"
                          content="Displaying your favorited products."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <Favorites />
                      <Footer />
                    </>
                  }
                />

                {/* Explore category */}
                <Route
                  path="/explore"
                  element={
                    <>
                      <Helmet>
                        <title>Explore Furniture • Craftle</title>
                        <meta
                          name="description"
                          content="Explore furniture that's trending, culturally relevant, and more."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <Explore />
                      <Footer />
                    </>
                  }
                />

                {/* Lookalike finder */}
                <Route
                  path="/dupe-finder"
                  element={
                    <>
                      <Helmet>
                        <title>Lookalike Finder • Craftle</title>
                        <meta
                          name="description"
                          content="We'll help you find similar looking products that are more affordable"
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <LookalikeFinder />
                      <Footer />
                    </>
                  }
                />

                {/* Log in */}
                <Route
                  path="/log-in"
                  element={
                    <>
                      <Helmet>
                        <title>Craftle - Log in</title>
                        <meta
                          name="description"
                          content="Log in to your Craftle account"
                        />
                        <script
                          src="https://accounts.google.com/gsi/client"
                          async
                          defer
                        ></script>
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <LogIn />
                      <Footer />
                    </>
                  }
                />

                {/* Sign Up */}
                <Route
                  path="/sign-up"
                  element={
                    <>
                      <Helmet>
                        <title>Craftle - Sign up</title>
                        <meta
                          name="description"
                          content="Sign up for Craftle"
                        />
                        <script
                          src="https://accounts.google.com/gsi/client"
                          async
                          defer
                        ></script>
                      </Helmet>
                      {/* <WebAppBar urls={navLinks} /> */}
                      <SignUp />
                      {/* <Footer /> */}
                    </>
                  }
                />

                {/* Profile */}
                <Route
                  path="/profile"
                  element={
                    <>
                      <Helmet>
                        <title>Craftle - Profile</title>
                        <meta name="description" content="" />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <Profile />
                      <Footer />
                    </>
                  }
                />

                {/* Category catalog */}
                <Route
                  path="/category-catalog"
                  element={
                    <>
                      <Helmet>
                        <title>Category Catalog • Craftle</title>
                        <meta name="description" content="Items you'll love." />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <CategoryCatalog />
                      <Footer />
                    </>
                  }
                />

                {/* Popular furniture routes*/}
                {popularFurnitureRoutes(navLinks)}

                {/* Business page routes */}
                {/* <Route
                  path="/business"
                  element={
                    <>
                      <Helmet>
                        <title>
                          AI Interior Design for Your Business - Craftle
                        </title>
                        <meta
                          name="description"
                          content="Evolve your website's offerings with Craftle's AI interior design widget for your website or store."
                        />
                      </Helmet>
                      <WebAppBar urls={navLinks} />
                      <Business />
                      <Footer />
                    </>
                  }
                /> */}

                {/* Footer content routes*/}
                {footerContentRoutes(navLinks)}

                {/* Blog */}
                {blogRoutes(navLinks)}

                {/* Start of CMS */}
                {craftMyStyleRoutes(navLinks)}

                {/*
                  The "*" has special meaning here.
                  It will match only when no other routes do. i.e., the "error" route
                */}
                <Route
                  path="*"
                  element={
                    <>
                      <Helmet>
                        <title>Craftle - Oops, Try Again</title>
                        <meta
                          name="description"
                          content="We ran into an issue, please try again."
                        />
                      </Helmet>
                      <CMSAppBar />
                      <ResultsError />
                      <Footer />
                    </>
                  }
                />
              </Routes>
            </div>
          </Store>
        </GrowthBookProvider>
      </UserContext.Provider>
    </CookiesProvider>
  )
}

App.propTypes = propTypes

export default App
