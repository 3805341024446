import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    margin: '80px 0 120px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [customTheme.breakpoints.down('md')]: {
      padding: '0 16px',
      margin: '80px 0 0 0',
    },
    [customTheme.breakpoints.down('sm')]: {
      padding: '0 8px',
    },
  },

  bougieTasteBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
  bougieTasteTxt: {
    fontWeight: 'bold',
    fontSize: '24px',
  },
  arrow: { marginTop: '8px', width: '47.1px' },

  idSection: {
    paddingTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  yellowContainer: {
    backgroundColor: '#FFF6DE',
    border: 'solid 4px #2A2A2A',
    borderRadius: '16px',
    maxWidth: '1136px',
    width: '100%',
    padding: '0 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '674px',
    boxSizing: 'border-box',
    [customTheme.breakpoints.down('md')]: {
      height: 'inherit',
      padding: '8px 8px',
    },
  },

  // Header section
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: '40px',
    [customTheme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  lookalikeFinderIcon: {
    width: '56px',
    marginRight: '16px',
    // [customTheme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  title: {
    fontSize: '40px',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  },
  title2: {
    fontWeight: 'bold',
    marginLeft: '8px',
    paddingBottom: '6px',
  },

  description: {
    marginTop: '24px',
    fontSize: '20px',
    textAlign: 'center',
  },
  cta: {
    marginTop: '24px',
  },

  howItWorksContainer: {
    backgroundColor: '#FFF',
    borderRadius: '16px',
    width: '100%',
    maxWidth: '944px',
    marginTop: '24px',
    padding: '32px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.15)',
    [customTheme.breakpoints.down('xs')]: {
      padding: '16px 8px',
    },
  },
  howItWorksTitle: {
    fontSize: '32px',
    textAlign: 'center',
  },
  howItWorksSubtitle: {
    marginTop: '16px',
    textAlign: 'center',
  },
  sofa1: {
    marginTop: '16px',
    width: 'fit-content',
    [customTheme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  howItWorksSubtitle2: {
    marginTop: '24px',
    textAlign: 'center',
  },
  sofasContainer: {
    marginTop: '16px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [customTheme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  sofas: {
    margin: '0 4px',
    maxWidth: '158px',
    [customTheme.breakpoints.down('md')]: {
      margin: '4px',
    },
    [customTheme.breakpoints.down('xs')]: {
      maxWidth: '200px',
    },
  },
}
