import React, { useState } from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'

// Import icons
const triangle =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/dropdown-triangle.svg'

const propTypes = {
  url: PropTypes.object,
  variant: PropTypes.string,
}

const defaultProps = {
  url: {},
  variant: 'tertiary',
}

MenuDropdown.propTypes = propTypes
MenuDropdown.defaultProps = defaultProps

export default function MenuDropdown({ url, variant }) {
  const [showMenu, setShowMenu] = useState(false)

  const classes = {
    container: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    dropdownBtn: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      boxShadow:
        variant === 'primary' || variant === 'secondary'
          ? 'inset 0 0 0 2px #2A2A2A'
          : 'none',
      fontSize: '16px',
      fontWeight: 'bold',
      padding: '16px 24px',
      borderRadius: '100px',
      boxSizing: 'border-box',
      mozBoxSizing: 'border-box',
      webkitBoxSizing: 'border-box',
      cursor: 'pointer',
    },
    triangle: {
      width: '12px',
      height: '100%',
      marginLeft: '8px',
    },

    // Dropdown styling
    dropdownContent: {
      position: 'absolute',
      top: 50,
      backgroundColor: '#FFF',
      boxShadow: '0 4px 15px rgba(0,0,0,0.15)',
      borderRadius: '16px',
      width: url.text === 'Design & Discover' ? '424px' : '200px',
      zIndex: 1,
      padding: url.text === 'Design & Discover' ?? '8px',
    },

    // Design & Discover styling
    AIFeaturesDropdownItemContainer: {
      cursor: 'pointer',
      padding: '16px',
      margin: '8px',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: 'rgba(243,243,243,0.5)',
      borderRadius: '16px',
      transition: '0.2s',
      'transition-timing-function': 'ease-in-out',
      '&:hover': {
        backgroundColor: 'rgba(255,233,175,0.5)',
        borderRadius: '16px',
      },
    },
    aiFeaturesDropdownIconContainer: {},
    dropdownBtnIcon: {
      width: '24px',
    },
    aiFeaturesDropdownTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginLeft: '16px',
    },
    aiFeaturesDropdownTitle: {
      fontWeight: 'bold',
    },
    aiFeaturesDropdownDescription: {
      marginTop: '8px',
    },

    // Other styling
    dropdownItemContainer: {
      cursor: 'pointer',
      padding: '16px',
      '&:hover': {
        backgroundColor: '#FAFAFA',
        borderRadius: '16px',
      },
    },
    dropdownItemText: {
      fontWeight: 'bold',
    },
  }

  const handleOnClick = () => {
    if (!showMenu) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  const handleOnMouseEnter = () => {
    if (!showMenu) {
      setShowMenu(true)
    }
  }

  const handleOnMouseLeave = () => {
    setShowMenu(false)
  }

  function handleDropdownBtnClick(link) {
    if (link.text === 'Log out') {
      localStorage.removeItem('user')
      localStorage.removeItem('craftleUserContext')
    }
    document.location.href = link.url
  }

  return url.text ? (
    <Box
      className="dropdown-container"
      sx={classes.container}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Box onClick={handleOnClick} sx={classes.dropdownBtn}>
        {url.text}
        <CardMedia
          component="img"
          image={triangle}
          alt=""
          sx={classes.triangle}
        />
      </Box>
      {showMenu && (
        <Box sx={classes.dropdownContent}>
          {url.subLink.map((link, linkKey) => (
            <Box key={linkKey}>
              {link.dropdownVariant === 'Design & Discover' ? (
                <Box
                  onClick={() => {
                    handleDropdownBtnClick(link)
                  }}
                  sx={classes.AIFeaturesDropdownItemContainer}
                >
                  <Box sx={classes.aiFeaturesDropdownIconContainer}>
                    <CardMedia
                      component="img"
                      image={link.icon}
                      alt=""
                      sx={classes.dropdownBtnIcon}
                    />
                  </Box>
                  <Box sx={classes.aiFeaturesDropdownTextContainer}>
                    <Text sx={classes.aiFeaturesDropdownTitle}>
                      {link.text}
                    </Text>
                    <Text sx={classes.aiFeaturesDropdownDescription}>
                      {link.description}
                    </Text>
                  </Box>
                </Box>
              ) : (
                <Box
                  onClick={() => {
                    handleDropdownBtnClick(link)
                  }}
                  sx={classes.dropdownItemContainer}
                >
                  <Text variant="fourth" sx={classes.dropdownItemText}>
                    {link.text}
                  </Text>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  ) : null
}
