import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
  backgroundColor: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#FFFDF4',
  },

  // Header
  topNavContainer: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '26px',
    boxSizing: 'border-box',
    gap: '8px',
  },
  craftleIcon: {
    width: '25px',
  },
  craftleWordmark: {
    width: '118px',
  },

  // Body
  bodyContainer: {
    minHeight: 'calc(100vh - 80.547px)', // Takes into account header height
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: '0 8px 40px 8px', // Bottom takes into accont the footer height + padding
    [customTheme.breakpoints.down('sm')]: {},
  },
  hiddenH1: {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  },
  greetingTxt: {
    fontFamily: 'poppins',
    fontSize: '48px',
    fontWeight: 300,
    [customTheme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
  },
  question: {
    fontSize: '32px',
    lineHeight: '150%',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  welcomeTxt: {
    fontSize: '32px',
  },
  description: {
    marginTop: '8px',
    fontSize: '20px',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  multiSelectContainer: {
    marginTop: '32px',
    maxWidth: '800px',
  },
  singleSelectContainer: {
    marginTop: '32px',
    maxWidth: '810px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
  },
  housingTypeButton: {},
  inputField: {
    marginTop: '32px',
    maxWidth: '496px',
    width: '100%',
    '& .MuiFormLabel-root': {
      paddingLeft: '16px',
      marginTop: '-8px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      paddingRight: '8px',
    },
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root:after': {
      borderBottom: 0,
      display: 'none',
    },
    '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
    [customTheme.breakpoints.down('sm')]: {
      maxWidth: 'inherit',
      width: '100%',
    },
  },
  dreamRoomSelectContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    [customTheme.breakpoints.down('md')]: {
      gap: '8px',
    },
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  dreamRoomImgContainer: {
    position: 'relative',
    maxWidth: '356px',
  },
  dreamRoomImg: {
    borderRadius: '16px',
    maxWidth: '356px',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      opacity: 0.95,
      transform: 'scale(1.02)',
    },
    [customTheme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 16px)',
    },
  },
  colorsContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    [customTheme.breakpoints.down('md')]: {
      gap: '8px',
    },
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  piecesContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    [customTheme.breakpoints.down('md')]: {
      gap: '8px',
    },
    [customTheme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imgWithCaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    [customTheme.breakpoints.down('sm')]: {
      gap: '8px',
    },
  },
  colorAmountImgContainer: {
    position: 'relative',
    maxWidth: '356px',
  },
  colorAmountImg: {
    maxWidth: '356px',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      opacity: 0.95,
      transform: 'scale(1.02)',
    },
    [customTheme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 16px)',
    },
  },
  piecesAmountImgContainer: {
    position: 'relative',
    maxWidth: '356px',
  },
  piecesAmountImg: {
    maxWidth: '356px',
    borderRadius: '16px',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      opacity: 0.95,
      transform: 'scale(1.02)',
    },
    [customTheme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 16px)',
    },
  },
  caption: {
    fontSize: '20px',
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  youColorsContainer: {
    marginTop: '32px',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    gap: '16px',
    [customTheme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto auto auto',
    },
    [customTheme.breakpoints.down(525)]: {
      gridTemplateColumns: 'auto auto',
      gap: '8px',
    },
    [customTheme.breakpoints.down(338)]: {
      gridTemplateColumns: 'auto',
      width: '100%',
      gap: '8px',
    },
  },
  youColorOption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '12px',
    boxSizing: 'border-box',
    gap: '8px',
    backgroundColor: '#FFF',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      opacity: 0.95,
      transform: 'scale(1.02)',
    },
  },
  youColorContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '4px',
    [customTheme.breakpoints.down(338)]: {
      width: '100%',
    },
  },
  youColor: {
    width: '62px',
    height: '62px',
    borderRadius: '4px',
    [customTheme.breakpoints.down(338)]: {
      width: '100%',
    },
  },
  youColorLabel: {
    fontWeight: 500,
  },
  liveInHomeOptionsContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: '80px',
    [customTheme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  liveInHomeOption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  liveInHomeImg: {},
  liveInHomeLabel: {
    marginTop: '24px',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  liveInHomeAge: {
    fontSize: '20px',
  },
  liveInHomeSelectNumContainer: {
    marginTop: '8xp',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  minusBtn: {
    fontWeight: 'bold',
    fontSize: '40px',
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '8px',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  livesInNum: {
    padding: '8px 24px',
    backgroundColor: '#FFE9AF',
    border: '2px solid #2A2A2A',
    borderRadius: '24px',
  },
  plusBtn: {
    fontWeight: 'bold',
    fontSize: '40px',
    width: '48px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '8px',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  multiSelectPetsContainer: {
    marginTop: '32px',
    maxWidth: '480px',
  },
  multiSelectMaterialsContainer: {
    marginTop: '32px',
    maxWidth: '984px',
  },
  birthdayContainer: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    [customTheme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    },
  },
  monthInput: {
    backgroundColor: 'inherit',
    width: '88px',
    [customTheme.breakpoints.down('xs')]: {
      width: '56px',
    },
  },
  monthUnderline: {
    width: '88px',
    height: '2px',
    backgroundColor: '#2A2A2A',
    [customTheme.breakpoints.down('xs')]: {
      width: '56px',
    },
  },
  dayInput: {
    fontSize: '40px',
    width: '88px',
    [customTheme.breakpoints.down('xs')]: {
      width: '56px',
    },
  },
  dayUnderline: {
    width: '88px',
    height: '2px',
    backgroundColor: '#2A2A2A',
    [customTheme.breakpoints.down('xs')]: {
      width: '56px',
    },
  },
  yearInput: {
    fontSize: '40px',
    width: '124px',
    [customTheme.breakpoints.down('xs')]: {
      width: '92px',
    },
  },
  yearUnderline: {
    width: '124px',
    height: '2px',
    backgroundColor: '#2A2A2A',
    [customTheme.breakpoints.down('xs')]: {
      width: '92px',
    },
  },
  slash: {
    fontSize: '56px',
    fontWeight: 300,
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '32px',
    },
  },
  locationContainer: {
    position: 'relative',
    maxWidth: '496px',
    width: '100%',
  },
  locationDropdown: {
    position: 'absolute',
    backgroundColor: '#FFF',
    border: '1px solid #ddd',
    width: '100%',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '16px',
    zIndex: 1000,
  },
  doneTxt: {
    fontSize: '48px',
    fontWeight: 300,
  },
  dreamImagesDoneSection: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  noDreamRoomsTxt: {
    fontSize: '20px',
    fontWeight: 'bold',
  },

  // Action buttons
  actionBtns: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',
    [customTheme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  primaryBtn: {},
  secondaryBtn: {
    backgroundColor: 'inherit',
  },
  signUpBtnsAndLegal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '400px',
    width: '100%',
  },
  signUpBtns: {
    maxWidth: '400px',
    width: '100%',
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  signUpEmailBtn: {
    backgroundColor: 'none',
    width: '100%',
  },
  separator: {
    margin: '40px 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  },
  separatorLine: {
    width: '45%',
    height: '1px',
    backgroundColor: '#757575',
  },
  errorText: {
    marginTop: '16px',
    color: '#D12626',
  },

  // Legal notice section
  legalNotice: {
    marginTop: '24px',
  },
  legalBtns: {
    display: 'inline',
  },

  // Footer
  progressBarContainer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '24px',
    zIndex: 99,
  },
  progressBar: {
    height: '24px',
    backgroundColor: '#2A2A2A',
    transition: 'width 0.3s ease-in-out',
  },
}
