import * as React from 'react'
import { Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CardMedia from '@mui/material/CardMedia'
// import { useNavigate } from "react-router-dom";
import mixpanel from 'mixpanel-browser'

mixpanel.init('d60542dff5aaf889760e7cc2f6316298', { debug: false })

// import images
// import TestImage from "../../../assets/images/living_room.png";

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

const propTypes = {
  name: PropTypes.string,
  profile: PropTypes.node,
  image: PropTypes.node,
  dates: PropTypes.string,
  path: PropTypes.string,
}

const defaultProps = {
  name: null,
  profile: null,
  image: null,
  dates: null,
  path: null,
}

LandingFurnitureHighlighted2.propTypes = propTypes
LandingFurnitureHighlighted2.defaultProps = defaultProps

export default function LandingFurnitureHighlighted2({
  name,
  profile,
  image,
  dates,
  path,
}) {
  // let navigate = useNavigate();

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        onClick={() => {
          window.open(path)
          mixpanel.track('(Explore) Clicked on Product')
          // navigate(path);
          // navigate("/see-more");
        }}
        target="_blank"
        sx={{
          marginTop: '40px',
          marginRight: '16px',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            padding: '0 8px 8px 8px',
            borderRadius: '16px',
            // [customTheme.breakpoints.down("sm")]: {
            //   alignItems: "flex-start",
            // },
          }}
        >
          <Box
            sx={{
              width: '80px',
              height: '80px',
              borderRadius: '40px',
              marginTop: '-40px',
              backgroundColor: '#FFFFFF',
              padding: '8px',
              boxSizing: 'border-box',
            }}
          >
            <CardMedia component="img" image={profile} alt="" sx={{}} />
          </Box>

          <Typography
            sx={{
              marginTop: '8px',
              fontWeight: 'bold',
            }}
          >
            {name}
          </Typography>

          <Typography
            sx={{
              color: '#757575',
              fontSize: '16px',
              fontWeight: '500',
              textAlign: 'center',
            }}
          >
            {dates}
          </Typography>

          <CardMedia
            component="img"
            image={image}
            alt=""
            sx={{
              width: '240px',
              height: '240px',
              borderRadius: '16px',
              marginTop: '8px',
              [customTheme.breakpoints.down('sm')]: {
                width: '200px',
                height: '200px',
              },
            }}
          />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
