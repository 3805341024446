import * as React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
}

const defaultProps = {
  title: null,
  link: null,
}

BlogH2.propTypes = propTypes
BlogH2.defaultProps = defaultProps

export default function BlogH2({ title, link }) {
  return (
    <h2
      style={{
        marginTop: '72px',
      }}
    >
      <a href={link}>{title}</a>
    </h2>
  )
}
