import * as React from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const propTypes = {
  children: PropTypes.node,
}

const defaultProps = {
  children: null,
}

BlogParallaxRoot.propTypes = propTypes
BlogParallaxRoot.defaultProps = defaultProps

export default function BlogParallaxRoot({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box
      sx={{
        perspective: '1px',
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  )
}
