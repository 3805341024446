import React, { useState, useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import { useMediaQuery } from '@mui/material'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

// Import lists
import {
  interiorStyleImages,
  colorAmount,
  youColors,
  piecesAmount,
  livesInOptions,
  petTypes,
  materialTypes,
} from './SignUpData.js'

// Import Definitions
import {
  mainHousingTypes,
  otherHousingTypes,
  handleOtherToggle,
  handleContinue,
  handlePrevious,
  renderDreamRoomSelection,
  handleSkip,
  handleColorSelect,
  handleCitySelect,
  handleCityChange,
  handleComplete,
} from './SignUpDefinitions'

// Import components
import { classes } from './SignUpStyle.js'
import Text from '../../../components/atoms/Text/Text'
import Button from '../../../components/atoms/Button/Button'
import Textfield from '../../../components/molecules/Textfield/Textfield'
import MultiSelectBtnGroup from '../../../components/molecules/multiSelectBtnGroup/MultiSelectBtnGroup'
import GoogleSignUpButton from '../GoogleSignUpButton'
import ModalSignup from '../../../components/organisms/modalSignUp/ModalSignUp.jsx'
import GoogleLoginCallToBackend from '../GoogleLoginCallToBackend'

// import context
import { UserContext } from '../../../App'

const craftleIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/branding/icons/craftle_icon_black.svg'
const craftleWordmark =
  'https://craftle-static.s3.us-east-2.amazonaws.com/branding/wordmarks/craftle_wordmark_black.svg'
const rightArrow =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/arrow-right-white.svg'

export default function SignUp() {
  const { user, setUser } = React.useContext(UserContext)

  const [step, setStep] = useState(1)
  const [firstName, setFirstName] = useState('')
  const [selectedColorAmount, setSelectedColorAmount] = useState('')
  const [selectYouColors, setSelectYouColors] = useState([])
  const [selectedPiecesAmount, setSelectedPiecesAmount] = useState('')
  const [selectedPets, setSelectedPets] = useState(0)
  const [selectedChildren, setSelectedChildren] = useState(0)
  const [selectedTeens, setSelectedTeens] = useState(0)
  const [selectedAdults, setSelectedAdults] = useState(1)
  const [birthday, setBirthday] = useState('')
  const [cityAndCountry, setCityAndCountry] = useState('')
  const [suggestedCities, setSuggestedCities] = useState([])
  const [displaySignupModal, setDisplaySignupModal] = useState(false)
  const [error, setError] = useState('')
  const [imageLoadStates, setImageLoadStates] = useState({})
  const [colorImageLoadStates, setColorImageLoadStates] = useState({})
  const [piecesImageLoadStates, setPiecesImageLoadStates] = useState({})
  const [userPreferences, setUserPreferences] = useState({})
  const [selectedHousingType, setSelectedHousingType] = useState(null)
  const [showOtherHousingTypes, setShowOtherHousingTypes] = useState(false)
  const [viewedEarlySignupPage, setViewedEarlySignupPage] = useState(false)

  // Options with isSelected status
  const [selectedPetTypes, setSelectedPetTypes] = useState([])
  const [selectedMaterialTypes, setSelectedMaterialTypes] = useState([])

  // Keep remainingImages as an object with styles as keys
  const [remainingImages, setRemainingImages] = useState({
    ...interiorStyleImages,
  })
  const [displayedImages, setDisplayedImages] = useState([])
  const [displayedImagesPerStep, setDisplayedImagesPerStep] = useState({})
  const [selectedStyles, setSelectedStyles] = useState({})

  const dropdownRef = useRef(null)

  const totalSteps = 17 // Since step 18 is the final step
  const progress = Math.min((step / totalSteps) * 100, 100)

  const redirectHref = '/profile'

  const isSmUp = useMediaQuery(customTheme.breakpoints.up('sm'))
  const chooseOneRmMsg =
    'If you could choose one room to live in, which would it be?'
  const scrollMsg = <b>Scroll to see all options.</b>

  const handleContinueParams = {
    user,
    setError,
    step,
    firstName,
    selectedHousingType,
    selectedColorAmount,
    selectYouColors,
    selectedPiecesAmount,
    selectedChildren,
    selectedTeens,
    selectedAdults,
    selectedPetTypes,
    birthday,
    cityAndCountry,
    selectedPets,
    setSelectedPetTypes,
    setStep,
    setViewedEarlySignupPage,
  }

  const handlePreviousParams = {
    user,
    setError,
    step,
    selectedPets,
    setStep,
  }

  useEffect(() => {
    if ([4, 7, 9, 12, 14, 16, 18].includes(step)) {
      if (displayedImagesPerStep[step]) {
        setDisplayedImages(displayedImagesPerStep[step])
      } else {
        let stylesWithImages = Object.keys(remainingImages)
        let totalRemainingImagesCount = 0
        for (let style in remainingImages) {
          totalRemainingImagesCount += remainingImages[style].length
        }

        const selectedImages = []
        let updatedRemainingImages = { ...remainingImages }

        if (step === 18 && totalRemainingImagesCount <= 3) {
          // Collect all remaining images
          for (let style in updatedRemainingImages) {
            updatedRemainingImages[style].forEach((image) => {
              selectedImages.push({ style, image })
            })
          }

          // Clear remainingImages since all images are displayed
          updatedRemainingImages = {}
        } else if (stylesWithImages.length >= 3) {
          // Enough styles to pick one image per style
          while (selectedImages.length < 3 && stylesWithImages.length > 0) {
            const randomStyleIndex = Math.floor(
              Math.random() * stylesWithImages.length,
            )
            const style = stylesWithImages.splice(randomStyleIndex, 1)[0]

            const images = updatedRemainingImages[style]
            const imageIndex = Math.floor(Math.random() * images.length)
            const image = images.splice(imageIndex, 1)[0]

            selectedImages.push({ style, image })

            if (images.length === 0) {
              delete updatedRemainingImages[style]
            }
          }
        } else {
          // Fewer than 3 styles left, pick additional images from available styles
          let allRemainingImages = []
          for (let style in updatedRemainingImages) {
            updatedRemainingImages[style].forEach((image) => {
              allRemainingImages.push({ style, image })
            })
          }

          while (selectedImages.length < 3 && allRemainingImages.length > 0) {
            const randomIndex = Math.floor(
              Math.random() * allRemainingImages.length,
            )
            const selectedImageObj = allRemainingImages.splice(
              randomIndex,
              1,
            )[0]
            selectedImages.push(selectedImageObj)

            // Remove the image from updatedRemainingImages
            const { style, image } = selectedImageObj
            const indexInStyleArray =
              updatedRemainingImages[style].indexOf(image)
            if (indexInStyleArray > -1) {
              updatedRemainingImages[style].splice(indexInStyleArray, 1)
              if (updatedRemainingImages[style].length === 0) {
                delete updatedRemainingImages[style]
              }
            }
          }
        }

        setDisplayedImages(selectedImages)
        setRemainingImages(updatedRemainingImages)
        setDisplayedImagesPerStep((prev) => ({
          ...prev,
          [step]: selectedImages,
        }))
      }
    }
  }, [step])

  // Effect to detect clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSuggestedCities([]) // Hide dropdown when clicking outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('user', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  return (
    <Box sx={classes.container}>
      {/* Background color */}
      <Box sx={classes.backgroundColor} />

      {/* Top nav */}
      <Box sx={classes.topNavContainer}>
        <Button variant="fourth" href="/">
          <CardMedia
            component="img"
            image={craftleIcon}
            alt="Craftle Icon"
            sx={classes.craftleIcon}
          />
          <CardMedia
            component="img"
            image={craftleWordmark}
            alt="Craftle Wordmark"
            sx={classes.craftleWordmark}
          />
        </Button>
      </Box>

      <Box sx={classes.bodyContainer}>
        {step === 1 ? (
          <React.Fragment>
            <Text sx={classes.hiddenH1}>Welcome to Craftle – Sign Up</Text>
            <Text sx={classes.greetingTxt}>Hi there 👋</Text>
            <Text variant="h2" sx={classes.question}>
              Let&apos;s start with your first name. What shall I call you?
            </Text>

            <Textfield
              type="text"
              id="input"
              name="input"
              placeholder="Enter first name..."
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
              sx={classes.inputField}
            />
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 2 ? (
          <React.Fragment>
            <ModalSignup
              displaySignupModal={displaySignupModal}
              step={step}
              setStep={setStep}
              setUser={setUser}
              onClose={setDisplaySignupModal.bind(null, false)} // Directly bind the function
            />

            <Text sx={classes.welcomeTxt}>Welcome, {firstName} 🤗</Text>
            <Text variant="h2" sx={classes.question}>
              Before we begin, what’s your email?
            </Text>

            <Box sx={classes.signUpBtnsAndLegal}>
              <Box sx={classes.signUpBtns}>
                <GoogleSignUpButton
                  step={step}
                  setStep={setStep}
                  setUser={setUser}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    setDisplaySignupModal(true)
                  }}
                  sx={classes.signUpEmailBtn}
                >
                  Sign up with email
                </Button>
              </Box>

              <Box sx={classes.separator}>
                <Box sx={classes.separatorLine} />
                <Text>OR</Text>
                <Box sx={classes.separatorLine} />
              </Box>

              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step + 1)
                }}
                sx={classes.signUpEmailBtn}
              >
                Sign up later
              </Button>

              <Text sx={classes.legalNotice}>
                By continuing, you agree to Craftle&apos;s{' '}
                <Button
                  variant="fourth"
                  href="/terms"
                  target="_blank"
                  sx={classes.legalBtns}
                >
                  Terms of Use
                </Button>
                . Read our{' '}
                <Button
                  variant="fourth"
                  href="/policy"
                  target="_blank"
                  sx={classes.legalBtns}
                >
                  Privacy Policy
                </Button>
                .
              </Text>
            </Box>
          </React.Fragment>
        ) : step === 3 ? (
          <React.Fragment>
            {!viewedEarlySignupPage && (
              <Text sx={classes.welcomeTxt}>Welcome, {firstName} 🤗</Text>
            )}
            <Text variant="h2" sx={classes.question}>
              What type of home do you live in?
            </Text>

            <Box sx={classes.singleSelectContainer}>
              {mainHousingTypes.map((type, index) => (
                <Button
                  key={index}
                  variant="secondary"
                  onClick={() => setSelectedHousingType(type.label)}
                  sx={{
                    ...classes.housingTypeButton,
                    backgroundColor:
                      selectedHousingType === type.label ? '#FFE9AF' : '#FFF',
                  }}
                >
                  {type.label}
                </Button>
              ))}

              <Button
                variant="secondary"
                onClick={() =>
                  handleOtherToggle(
                    showOtherHousingTypes,
                    setShowOtherHousingTypes,
                    selectedHousingType,
                    setSelectedHousingType,
                  )
                }
                sx={{
                  ...classes.housingTypeButton,
                  backgroundColor: showOtherHousingTypes ? '#FFE9AF' : '#FFF',
                }}
              >
                Other
              </Button>

              {showOtherHousingTypes &&
                otherHousingTypes.map((type, index) => (
                  <Button
                    key={`other-${index}`}
                    variant="secondary"
                    onClick={() => setSelectedHousingType(type.label)}
                    sx={{
                      ...classes.housingTypeButton,
                      backgroundColor:
                        selectedHousingType === type.label ? '#FFE9AF' : '#FFF',
                    }}
                  >
                    {type.label}
                  </Button>
                ))}
            </Box>

            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 4 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 5 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              How do you feel about color in your home?
            </Text>
            {!isSmUp && <Text sx={{ marginTop: '8px' }}>{scrollMsg}</Text>}

            <Box sx={classes.colorsContainer}>
              {colorAmount.map((option, index) => {
                const isLoaded = colorImageLoadStates[option.img]
                return (
                  <Box key={index} sx={classes.imgWithCaption}>
                    <Box sx={classes.colorAmountImgContainer}>
                      {!isLoaded && (
                        <Skeleton
                          variant="square"
                          width={356}
                          height={356}
                          sx={{ borderRadius: '16px' }}
                        />
                      )}
                      <CardMedia
                        component="img"
                        image={option.img}
                        alt="Color amount image"
                        onClick={() => {
                          setSelectedColorAmount(option.value)
                          setStep(step + 1)
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }}
                        onLoad={() => {
                          setColorImageLoadStates((prevState) => ({
                            ...prevState,
                            [option.img]: true,
                          }))
                        }}
                        sx={{
                          ...classes.colorAmountImg,
                          display: isLoaded ? 'block' : 'none',
                          opacity: isLoaded ? 1 : 0,
                        }}
                      />
                    </Box>
                    <Text sx={classes.caption}>{option.label}</Text>
                  </Box>
                )
              })}
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 6 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              What colors make your space feel like &quot;you&quot;?
            </Text>
            <Text sx={classes.description}>You can choose multiple!</Text>

            <Box sx={classes.youColorsContainer}>
              {youColors.map((option, index) => (
                <Box
                  key={index}
                  onClick={() =>
                    handleColorSelect(
                      option,
                      selectYouColors,
                      setSelectYouColors,
                    )
                  }
                  sx={{
                    ...classes.youColorOption,
                    border: selectYouColors.includes(option.label)
                      ? 'solid 2px #2A2A2A'
                      : 'solid 2px #F3F3F3',
                  }}
                >
                  <Box sx={classes.youColorContainer}>
                    {option.colors.map((color, index) => (
                      <Box
                        key={index}
                        sx={{
                          ...classes.youColor,
                          backgroundColor: color,
                        }}
                      />
                    ))}
                  </Box>
                  <Text sx={classes.youColorLabel}>{option.label}</Text>
                </Box>
              ))}
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 7 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 8 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              How much do you like to fill your space?
            </Text>
            {!isSmUp && <Text sx={{ marginTop: '8px' }}>{scrollMsg}</Text>}

            <Box sx={classes.piecesContainer}>
              {piecesAmount.map((option, index) => {
                const isLoaded = piecesImageLoadStates[option.img]
                return (
                  <Box key={index} sx={classes.imgWithCaption}>
                    <Box sx={classes.piecesAmountImgContainer}>
                      {!isLoaded && (
                        <Skeleton
                          variant="square"
                          max-width={356}
                          height={356}
                          sx={{ borderRadius: '16px' }}
                        />
                      )}
                      <CardMedia
                        component="img"
                        image={option.img}
                        alt="Amount of pieces image"
                        onClick={() => {
                          setSelectedPiecesAmount(option.value)
                          setStep(step + 1)
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }}
                        onLoad={() => {
                          setPiecesImageLoadStates((prevState) => ({
                            ...prevState,
                            [option.img]: true,
                          }))
                        }}
                        sx={{
                          ...classes.piecesAmountImg,
                          display: isLoaded ? 'block' : 'none',
                          opacity: isLoaded ? 1 : 0,
                        }}
                      />
                    </Box>
                    <Text sx={classes.caption}>{option.label}</Text>
                  </Box>
                )
              })}
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 9 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 10 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              How many live in your home, including you?
            </Text>

            <Box sx={classes.liveInHomeOptionsContainer}>
              {livesInOptions.map((option, index) => (
                <Box key={index} sx={classes.liveInHomeOption}>
                  <CardMedia
                    component="img"
                    image={option.img}
                    alt={`${option.label} image`}
                    sx={{
                      ...classes.liveInHomeImg,
                      width:
                        option.label === 'Pets' || option.label === 'Children'
                          ? '104px'
                          : option.label === 'Teens'
                            ? '166px'
                            : option.label === 'Adults'
                              ? '200px'
                              : 'inherit',
                    }}
                  />
                  <Text sx={classes.liveInHomeLabel}>{option.label}</Text>
                  <Text sx={classes.liveInHomeAge}>{option.age}</Text>
                  <Box sx={classes.liveInHomeSelectNumContainer}>
                    {/* Don't allow the subtraction to go below 0 */}
                    <Box
                      onClick={() => {
                        if (option.label === 'Pets') {
                          setSelectedPets(Math.max(selectedPets - 1, 0))
                        } else if (option.label === 'Children') {
                          setSelectedChildren(Math.max(selectedChildren - 1, 0))
                        } else if (option.label === 'Teens') {
                          setSelectedTeens(Math.max(selectedTeens - 1, 0))
                        } else if (option.label === 'Adults') {
                          setSelectedAdults(Math.max(selectedAdults - 1, 0))
                        }
                      }}
                      sx={classes.minusBtn}
                    >
                      -
                    </Box>
                    <Text sx={classes.livesInNum}>
                      {option.label === 'Pets'
                        ? selectedPets
                        : option.label === 'Children'
                          ? selectedChildren
                          : option.label === 'Teens'
                            ? selectedTeens
                            : option.label === 'Adults'
                              ? selectedAdults
                              : null}
                    </Text>
                    <Box
                      onClick={() => {
                        if (option.label === 'Pets') {
                          setSelectedPets(selectedPets + 1)
                        } else if (option.label === 'Children') {
                          setSelectedChildren(selectedChildren + 1)
                        } else if (option.label === 'Teens') {
                          setSelectedTeens(selectedTeens + 1)
                        } else if (option.label === 'Adults') {
                          setSelectedAdults(selectedAdults + 1)
                        }
                      }}
                      sx={classes.plusBtn}
                    >
                      +
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 11 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              What kind of pet(s) do you own?
            </Text>

            <Box sx={classes.multiSelectPetsContainer}>
              <MultiSelectBtnGroup
                options={petTypes}
                selectionOptions={selectedPetTypes}
                onSelectionChange={setSelectedPetTypes}
                inlineBlock={true}
                gap="8px"
              />
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 12 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  if (selectedPets === 0) {
                    // Skip pet types question if no pets
                    setStep(step - 2)
                  } else {
                    setStep(step - 1)
                  }
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 13 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Are there any materials we should avoid suggesting?
            </Text>

            <Box sx={classes.multiSelectMaterialsContainer}>
              <MultiSelectBtnGroup
                options={materialTypes}
                selectionOptions={selectedMaterialTypes}
                onSelectionChange={setSelectedMaterialTypes}
                inlineBlock={true}
                gap="8px"
              />
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                {selectedMaterialTypes.length > 0 ? 'Continue' : 'Skip'}
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 14 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 15 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              When is your birthday? 🎂
            </Text>

            <Box sx={classes.birthdayContainer}>
              <Box>
                {/* Month input */}
                <Textfield
                  type="number"
                  id="month-input"
                  name="month"
                  placeholder="MM"
                  onChange={(e) => {
                    let value = e.target.value

                    // Allow deletion (empty string should be valid)
                    if (value === '') {
                      setError('')
                      setBirthday((prev) => ({
                        ...prev,
                        month: value,
                      }))
                      return
                    }

                    // Ensure the input is between 1 and 12
                    if (value >= 1 && value <= 12) {
                      setError('') // Clear error for valid input
                      setBirthday((prev) => ({
                        ...prev,
                        month: value,
                      }))
                    } else {
                      setError('Please enter a valid month (01-12)')
                    }
                  }}
                  value={birthday.month || ''}
                  fontSize={isSmUp ? '40px' : '16px'}
                  border="none"
                  backgroundColor="none"
                  inputProps={{ min: 1, max: 12 }}
                  sx={classes.monthInput}
                />
                <Box sx={classes.monthUnderline} />
              </Box>
              <Text sx={classes.slash}>/</Text>
              <Box>
                {/* Day input */}
                <Textfield
                  type="number"
                  id="day-input"
                  name="day"
                  placeholder="DD"
                  onChange={(e) => {
                    let value = e.target.value

                    // Allow deletion (empty string should be valid)
                    if (value === '') {
                      setError('')
                      setBirthday((prev) => ({
                        ...prev,
                        day: value,
                      }))
                      return
                    }

                    // Ensure the input is between 1 and 31
                    if (value >= 1 && value <= 31) {
                      setError('') // Clear error for valid input
                      setBirthday((prev) => ({
                        ...prev,
                        day: value,
                      }))
                    } else {
                      setError('Please enter a valid day (01-31)')
                    }
                  }}
                  value={birthday.day || ''}
                  fontSize={isSmUp ? '40px' : '16px'}
                  border="none"
                  backgroundColor="none"
                  inputProps={{ min: 1, max: 31 }}
                  sx={classes.dayInput}
                />
                <Box sx={classes.dayUnderline} />
              </Box>
              <Text sx={classes.slash}>/</Text>
              <Box>
                {/* Year input */}
                <Textfield
                  type="number"
                  id="year-input"
                  name="year"
                  placeholder="YYYY"
                  onChange={(e) => {
                    let value = e.target.value
                    const currentYear = new Date().getFullYear()

                    if (value.length === 4) {
                      if (value < 1900 || value > currentYear) {
                        setError(
                          `Please enter a valid year (1900-${currentYear})`,
                        )
                      } else {
                        setError('')
                        setBirthday((prev) => ({
                          ...prev,
                          year: value,
                        }))
                      }
                    } else {
                      setError('')
                      setBirthday((prev) => ({
                        ...prev,
                        year: value,
                      }))
                    }
                  }}
                  value={birthday.year || ''}
                  fontSize={isSmUp ? '40px' : '16px'}
                  border="none"
                  backgroundColor="none"
                  inputProps={{
                    min: 1900,
                    max: new Date().getFullYear(),
                    maxLength: 4,
                  }}
                  sx={classes.yearInput}
                />
                <Box sx={classes.yearUnderline} />
              </Box>
            </Box>

            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 16 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick a dream room!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                chooseOneRmMsg
              ) : (
                <>
                  {chooseOneRmMsg} {scrollMsg}
                </>
              )}
            </Text>

            {renderDreamRoomSelection(
              displayedImages,
              imageLoadStates,
              setSelectedStyles,
              step,
              remainingImages,
              setRemainingImages,
              setDisplayedImages,
              setStep,
              setError,
              setImageLoadStates,
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleSkip(setStep, setError, step)}
                sx={classes.primaryBtn}
              >
                Skip
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 17 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Where do you currently call home?
            </Text>

            <Box sx={classes.locationContainer}>
              <Textfield
                type="text"
                id="city-input"
                name="city"
                placeholder="Enter city here..."
                onChange={(event) =>
                  handleCityChange(event, setCityAndCountry, setSuggestedCities)
                }
                value={cityAndCountry}
                sx={classes.inputField}
              />

              {/* Dropdown for suggested cities */}
              {/* Force users to choose one from the dropdown list from cityData, otherwise error */}
              {/* cityData looks like const cityList = ["Afghanistan","Herat (Afghanistan)","Kabul (Afghanistan)"...] */}
              {suggestedCities.length > 0 && (
                <Box ref={dropdownRef} sx={classes.locationDropdown}>
                  {suggestedCities.map((suggestion, index) => (
                    <MenuItem
                      key={index}
                      onClick={() =>
                        handleCitySelect(
                          suggestion,
                          setCityAndCountry,
                          setSuggestedCities,
                        )
                      }
                      sx={{ cursor: 'pointer' }}
                    >
                      {suggestion}
                    </MenuItem>
                  ))}
                </Box>
              )}
            </Box>
            {error && <Text sx={classes.errorText}>{error}</Text>}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  setStep(step - 1)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                icon={rightArrow}
                iconRight={true}
                onClick={() => handleContinue(handleContinueParams)}
                sx={classes.primaryBtn}
              >
                Continue
              </Button>
            </Box>
          </React.Fragment>
        ) : step === 18 ? (
          <React.Fragment>
            <Text variant="h2" sx={classes.question}>
              Pick as many dream rooms as you want!
            </Text>
            <Text sx={classes.description}>
              {isSmUp ? (
                <>
                  When you’re done, just click{' '}
                  <span style={{ fontWeight: 'bold' }}>Complete</span>.
                </>
              ) : (
                <>
                  When you’re done, just click{' '}
                  <span style={{ fontWeight: 'bold' }}>Complete</span>.{' '}
                  {scrollMsg}
                </>
              )}
            </Text>

            {Object.keys(remainingImages).length > 0 &&
            displayedImages.length > 0 ? (
              renderDreamRoomSelection(
                displayedImages,
                imageLoadStates,
                setSelectedStyles,
                step,
                remainingImages,
                setRemainingImages,
                setDisplayedImages,
                setStep,
                setError,
                setImageLoadStates,
              )
            ) : (
              <Box sx={classes.dreamImagesDoneSection}>
                <Text sx={{ ...classes.noDreamRoomsTxt, fontSize: '64px' }}>
                  🎉
                </Text>
                <Text sx={classes.noDreamRoomsTxt}>
                  There are no options left.
                </Text>
                <Text sx={classes.noDreamRoomsTxt}>You&apos;re done!</Text>
              </Box>
            )}

            <Box sx={classes.actionBtns}>
              <Button
                variant="secondary"
                onClick={() => {
                  handlePrevious(handlePreviousParams)
                }}
                sx={classes.secondaryBtn}
              >
                Previous
              </Button>
              <Button
                iconRight={true}
                onClick={() => {
                  handleComplete(
                    birthday,
                    setError,
                    selectedStyles,
                    firstName,
                    selectedHousingType,
                    selectedColorAmount,
                    selectYouColors,
                    selectedPiecesAmount,
                    selectedPets,
                    selectedChildren,
                    selectedTeens,
                    selectedAdults,
                    cityAndCountry,
                    selectedPetTypes,
                    selectedMaterialTypes,
                    setUserPreferences,
                    user,
                    GoogleLoginCallToBackend,
                    saveUserToken,
                    setStep,
                    step,
                  )
                }}
                sx={classes.primaryBtn}
              >
                Complete
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          // Step 19 (Final step)
          <React.Fragment>
            <ModalSignup
              displaySignupModal={displaySignupModal}
              redirectHref={redirectHref}
              userPreferences={userPreferences}
              onClose={setDisplaySignupModal.bind(null, false)}
            />

            <Text sx={classes.doneTxt}>You&apos;re done!</Text>
            <Text variant="h2" sx={classes.question}>
              Sign up and see the magic 🔮
            </Text>

            <Box sx={classes.signUpBtnsAndLegal}>
              <Box sx={classes.signUpBtns}>
                <GoogleSignUpButton
                  redirectHref={redirectHref}
                  userPreferences={userPreferences}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    setDisplaySignupModal(true)
                  }}
                  sx={classes.signUpEmailBtn}
                >
                  Sign up with email
                </Button>
              </Box>

              <Text sx={classes.legalNotice}>
                By continuing, you agree to Craftle&apos;s{' '}
                <Button
                  variant="fourth"
                  href="/terms"
                  target="_blank"
                  sx={classes.legalBtns}
                >
                  Terms of Use
                </Button>
                . Read our{' '}
                <Button
                  variant="fourth"
                  href="/policy"
                  target="_blank"
                  sx={classes.legalBtns}
                >
                  Privacy Policy
                </Button>
                .
              </Text>
            </Box>
          </React.Fragment>
        )}
      </Box>

      {/* Bottom progress bar */}
      <Box sx={classes.progressBarContainer}>
        <Box
          sx={{
            ...classes.progressBar,
            width: `${progress}%`,
            borderRadius: progress < 100 ? '0 25px 25px 0' : '0px',
          }}
        />
      </Box>
    </Box>
  )
}
