import { v4 as uuidv4 } from 'uuid'

export const addMoodboardItem = (
  CMSResultsSelectedFile,
  setCurrentMoodBoardView,
  globalState,
  globalDispatch,
) => {
  let newItemPosition = {}
  const uniqueKey = uuidv4()

  // For new items that are from our catalog (and not uploaded images)
  if (CMSResultsSelectedFile && CMSResultsSelectedFile.ourProducts) {
    newItemPosition = {
      key: uniqueKey,
      positionX: '35%',
      positionY: '35%',
      width: '30%',
      imageUrl: '',
      scale: 1,
    }

    setCurrentMoodBoardView((prevMoodBoardView) => {
      const updatedMoodBoardView = [...prevMoodBoardView, newItemPosition]
      return updatedMoodBoardView
    })

    globalDispatch({
      type: 'UPDATE_UPLOADED_IMAGES',
      payload: {
        uploadedImages: {
          [uniqueKey]: {
            image_url:
              CMSResultsSelectedFile.transparent_image_url ||
              CMSResultsSelectedFile.image_url,
            product_page_url: CMSResultsSelectedFile.product_page_url,
            category: CMSResultsSelectedFile.category,
          },
        },
      },
    })
  } else {
    // For uploaded images
    const objectUrl = URL.createObjectURL(CMSResultsSelectedFile)

    const img = new Image()
    img.src = objectUrl
    img.onload = function () {
      newItemPosition = {
        key: uniqueKey,
        positionX: '35%',
        positionY: '35%',
        width: '30%',
        imageUrl: '',
        scale: 1,
      }

      setCurrentMoodBoardView((prevMoodBoardView) => {
        const updatedMoodBoardView = [...prevMoodBoardView, newItemPosition]
        return updatedMoodBoardView
      })

      URL.revokeObjectURL(objectUrl)
    }

    globalDispatch({
      type: 'UPDATE_UPLOADED_IMAGES',
      payload: {
        uploadedImages: {
          [uniqueKey]: {
            image_url: CMSResultsSelectedFile,
            uploadedImage: true,
          },
        },
      },
    })
  }
}
