import React from 'react'
import { Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import components
import { classes } from './BlogPostStyles'
import BlogContentContainer from '../../components/molecules/BlogContentContainer/BlogContentContainer'
import BlogDate from '../../components/molecules/BlogDate/BlogDate'
import BlogTitle from '../../components/molecules/BlogTitle/BlogTitle'
import BlogParagraph from '../../components/molecules/BlogParagraph/BlogParagraph'
import BlogH2 from '../../components/molecules/BlogH2/BlogH2'
import BlogImage from '../../components/molecules/BlogImage/BlogImage'
import BlogInlineAd1 from '../../components/molecules/BlogInlineAd1/BlogInlineAd1'
import BlogInlineAd2 from '../../components/molecules/BlogInlineAd2/BlogInlineAd2'

// import images
const bannerImage =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-banner.jpg'
const image1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-image-1.jpg'
const image2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-image-2.jpg'
const image3 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-image-3.jpg'
const image4 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-image-4.jpg'
const image5 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/blog/blog1/blog-1-image-5.jpg'

function BlogPost1() {
  return (
    <Box sx={classes.container}>
      <CardMedia
        component="img"
        image={bannerImage}
        alt=""
        sx={classes.bannerImage}
      />

      <BlogContentContainer>
        <BlogDate date="APRIL 19, 2022" />
        <BlogTitle title="Baby & Kids' Furniture: 5 Best Places to Buy" />
        <BlogParagraph>
          Baby & kids’ furniture isn’t always easy to find out there. You want
          to find furniture that’s fun and exciting for kids but also safely
          built and affordable to buy. And as a busy parent, it’s harder than
          ever to find the time to do the research!
        </BlogParagraph>
        <BlogParagraph>
          Baby & kids’ furniture isn’t always easy to find out there. You want
          to find furniture that’s fun and exciting for kids but also safely
          built and affordable to buy. And as a busy parent, it’s harder than
          ever to find the time to do the research!
        </BlogParagraph>
        <BlogParagraph>
          So if you’re looking for the best baby & kids’ furniture, you’ve come
          to the right place. We did some research around the internet and found
          some of the best options out there for you.
        </BlogParagraph>
        <BlogParagraph>
          Without further ado, let’s go brand shopping!
        </BlogParagraph>

        <BlogH2 title="1. Maisonette" link="https://www.maisonette.com" />
        <BlogParagraph>
          Our first pick for the best baby & kids’ furniture is Maisonette. On
          this website, you’ll find a long list of clothes, toys, and items
          suited to your child’s every need. Founded by two moms who know the
          pain and struggles of finding the right items for their kids,
          Maisonette is expertly-curated to feature over 800 brands from around
          the globe. From a table with a storage built-in to a cozy chair made
          with sheepskin, you’ll likely find the exact european-style furniture
          you need.
        </BlogParagraph>
        <BlogParagraph>
          “When we started Maisonette, our goal was to make it easier to find
          high-quality products for our kids, so we could spend less time
          scrolling, and more time on the good stuff. Today, our mission goes
          well beyond the product itself; it’s a commitment to family. To
          empower parents to make the best decisions for their families. And to
          bring a little bit of magic into family life every day.”
          <br />– Sylvana Durrett, CEO
        </BlogParagraph>
        <BlogImage
          image={image1}
          alt=""
          link="https://www.maisonette.com/product/forest-table"
          caption="Maisonette Forest Table by Maisonette"
          newTab="true"
        />

        <BlogH2
          title="2. Ikea"
          link="https://www.ikea.com/us/en/cat/baby-kids-bc001/"
        />
        <BlogParagraph>
          {`This brand needs no introduction, but Ikea is a global Swedish home
            store that features affordable furniture, including baby & kids’
            furniture. Ikea prides itself on creating safely built furniture for
            your little ones that are both beautiful and fun. You’ll find all
            sorts of items here, including kids&apos; mattresses, furniture,
            storage, beds, toys, and lighting. If “simplicity” is your thing
            (though not to be confused with cheap), than Ikea might be for you.`}
        </BlogParagraph>
        <BlogParagraph>
          “The IKEA spirit is a strong and living reality. Simplicity in our
          behavior gives us strength. Simplicity and humbleness characterize us
          in our relations with each other, with our suppliers, and with our
          customers.”
          <br />- Ingvar Kamprad, Founder
        </BlogParagraph>
        <BlogImage
          image={image2}
          alt=""
          link="https://www.ikea.com/us/en/p/sundvik-ext-bed-frame-with-slatted-bed-base-white-s69046072/"
          caption="Sundvik Extendable Bed Frame by Ikea"
          newTab="true"
        />

        <BlogH2
          title="3. Wayfair"
          link="https://www.wayfair.com/baby-kids/cat/baby-kids-c45226.html?cjevent=bcb231d8bdd011ec821501610a82b82a&refID=CJ3507027-CJ3507027&PID=CJ8758487&clickid=bcb231d8bdd011ec821501610a82b82a&cjdata=MXxOfDB8WXww"
        />
        <BlogParagraph>
          Another brand that needs no introduction, Wayfair is one of the
          largest furniture websites in the world. Though they don’t have any
          physical locations, you can find whatever furniture you need from
          their website. (You can return most furniture within 30 days of
          delivery if that’s a concern of yours.)
        </BlogParagraph>
        <BlogParagraph>
          In the baby & kids’ furniture section of Wayfair’s website, you’ll
          find furniture included for a nursery, toddler’s bedroom, playroom,
          and more. They even have a section specifically for sales…and there
          are many sales!
        </BlogParagraph>
        <BlogParagraph>
          So if affordably priced, mid-century style furniture is for you and
          your little one, then Wayfair is the way to go.
        </BlogParagraph>
        <BlogParagraph>
          “We empower you to create spaces that reflect who you are, what you
          need, and what you value, so that you and the ones you love can feel
          right at home.”
          <br />- Wayfair
        </BlogParagraph>
        <BlogImage
          image={image3}
          alt=""
          link="https://www.wayfair.com/baby-kids/pdp/davinci-meadow-4-in-1-convertible-crib-w001374263.html"
          caption="Meadow 4-in-1 Convertible Crib by Craftle"
          newTab="true"
        />

        <BlogInlineAd1 />

        <BlogH2
          title="4. Crate & Kids"
          link="https://www.crateandbarrel.com/kids/"
        />
        <BlogParagraph>
          You may have heard of Crafte & Barrel, but have you heard of their
          baby & kid’s furniture brand, Crate & Kids? If you’re looking for
          minimalist yet fashion-forward furniture design, then their brand
          might be up your alley. You’ll find all kinds of furniture on their
          website for a range of ages, such as cribs, swivel chairs, bookcases,
          and bassinets. You won’t find the cheapest options here, but you’ll
          certainly get getting high-quality, timeless pieces when you buy from
          them. Not to mention, their products are incredibly drool-worthy!
        </BlogParagraph>
        <BlogParagraph>
          “We curate inspiration for the home, connecting the creative work of
          artisans and designers to people and places around the world.”
          <br />- Crate & Barrel
        </BlogParagraph>
        <BlogImage
          image={image4}
          alt=""
          link="https://www.crateandbarrel.com/joya-cream-nursery-rocking-chair/s472081"
          caption="Joya Cream Nursery Rocking Chair by Crate & Kids"
          newTab="true"
        />

        <BlogH2
          title="5. Serena & Lily"
          link="https://www.serenaandlily.com/living-kids-furniture/"
        />
        <BlogParagraph>
          Do you like coastal and minimal interior design styles? Then check out
          Serena & Lily! Their beautifully crafted babies and kids furniture
          bring life to any room without screaming at you with loud colors and
          tons of moving parts. They don’t have as many options or categories as
          some of the others that we’ve listed, but every choice available has
          been hand-selected by their team to look California-style breezy and
          ocean ready. And if you like hand-woven designs, then you’ll
          definitely appreciate what they have to offer you.
        </BlogParagraph>
        <BlogParagraph>
          “We love spaces that feel put together, yet livable – well-traveled
          with a personal point of view. We believe home should be a welcoming
          oasis – your favorite place to retreat, gather, and live well every
          day.”
          <br />- Serena & Lily
        </BlogParagraph>
        <BlogImage
          image={image5}
          alt=""
          link="https://www.serenaandlily.com/bar-island-chest/597291.html?searchCategory=Nursery%20Furniture#page2scroll0=&start=1"
          caption="Bar Island Chest by Serena & Lily"
          newTab="true"
        />

        <BlogInlineAd2 />
      </BlogContentContainer>
    </Box>
  )
}

export default BlogPost1
