import axios from 'axios'

// import components
import { EndPoint } from '../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://lmxsvhr2rk.execute-api.us-east-2.amazonaws.com/default/craftle-user-call',
  test: 'https://lmxsvhr2rk.execute-api.us-east-2.amazonaws.com/default/craftle-user-call',
  local: 'http://127.0.0.1:8080/user',
}

export default async function DeleteAccountCallToBackend(userId) {
  try {
    const endPoint = END_POINTS[EndPoint] || END_POINTS.prod
    const response = await axios.post(
      endPoint,
      {
        purpose: 'delete_user',
        user_id: userId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    // Backend returns true or false
    if (response?.data?.body?.user_token) {
      return response.data.body.user_token
    } else if (response?.data?.user_token) {
      return response.data.user_token
    } else {
      return null
    }
  } catch (e) {
    console.log(e.message)
    return null
  }
}
