import axios from 'axios'
import config from '../config.json'

const saveExistingPlan = async ({
  urlPlanId,
  selectedFurniturePieces,
  selectedOtherFurniturePieces,
  uploadedImages,
  newPositions,
}) => {
  const END_POINT = `${config.END_POINT}designs/savePlan`

  const selectedFurniturePiecesKey = {}
  Object.entries(selectedFurniturePieces).forEach(([key, val]) => {
    // Start with an empty object for each key
    selectedFurniturePiecesKey[key] = {}

    // If the 'id' exists, add it to the object
    if (val.id) {
      selectedFurniturePiecesKey[key].id = val.id
    }
    // If the 'product_id' exists, add it to the object
    if (val.product_id) {
      selectedFurniturePiecesKey[key].product_id = val.product_id
    }
    // Always add the 'product_page_url' (assuming it always exists)
    selectedFurniturePiecesKey[key].product_page_url = val.product_page_url
  })

  const selectedOtherFurniturePiecesKey = {}
  Object.entries(selectedOtherFurniturePieces).forEach(([key, val]) => {
    // Start with an empty object for each key
    selectedOtherFurniturePiecesKey[key] = {}

    if (val.id) {
      selectedOtherFurniturePiecesKey[key].id = val.id
    }
    if (val.product_id) {
      selectedOtherFurniturePiecesKey[key].product_id = val.product_id
    }
    selectedOtherFurniturePiecesKey[key].product_page_url = val.product_page_url
  })

  // Function to convert image file to base64 string
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  // Convert image files to base64 strings
  for (let imageId in uploadedImages) {
    if (
      uploadedImages[imageId].uploadedImage &&
      uploadedImages[imageId].image_url instanceof Blob
    ) {
      uploadedImages[imageId].image_url = await toBase64(
        uploadedImages[imageId].image_url,
      )
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .post(END_POINT, {
        planId: urlPlanId,
        selectedFurniturePieces: selectedFurniturePiecesKey,
        selectedOtherFurniturePieces: selectedOtherFurniturePiecesKey,
        uploadedImages: uploadedImages ? uploadedImages : {},
        furniturePositions: {
          positions:
            newPositions === undefined || newPositions.length === 0
              ? []
              : newPositions,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export default saveExistingPlan
