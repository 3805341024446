import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import components
import { CMSResults } from '../pages/CraftMyStyle/pages'
import { CMSAppBar, Footer } from '../components/organisms'
import CMSRouter from '../pages/CraftMyStyle/CMSRouter'

const craftMyStyleRoutes = (navLinks) => (
  <>
    <Route
      path="/designs"
      element={
        <>
          <Helmet>
            <title>Craftle - Design Questions</title>
            <meta
              name="description"
              content="Finding your personal interior design style using Craftle by answering simple questions."
            />
          </Helmet>
          <CMSAppBar />
          <CMSRouter />
          {/* <SurveyWidget /> */}
          <Footer />
        </>
      }
    />
    <Route
      path="/designs/v/:planId"
      element={
        <>
          <Helmet>
            <title>Craftle - Your Personal Design</title>
            <meta name="description" content="Your personal design results." />
          </Helmet>
          <CMSResults viewer navLinks={navLinks} />
          {/* <SurveyWidget /> */}
          <Footer />
        </>
      }
    />
    <Route
      path="/designs/d/:planId"
      element={
        <>
          <Helmet>
            <title>Craftle - Your Personal Design</title>
            <meta name="description" content="Your personal design results." />
          </Helmet>
          <CMSResults duplicatePlan navLinks={navLinks} />
          {/* <SurveyWidget /> */}
          <Footer />
        </>
      }
    />
    <Route
      path="/designs/e/:planId"
      element={
        <>
          <Helmet>
            <title>Craftle - Your Personal Design</title>
            <meta name="description" content="Your personal design results." />
          </Helmet>
          <CMSResults navLinks={navLinks} />
          {/* <SurveyWidget /> */}
          <Footer />
        </>
      }
    />
  </>
)

export default craftMyStyleRoutes
