import React, {
  useContext,
  useEffect,
  useState,
  createContext,
  useRef,
  useLayoutEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Container, Box } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import mixpanel from 'mixpanel-browser'
// import { useCookies } from "react-cookie";
import PropTypes from 'prop-types'
// import useMediaQuery from "@mui/material/useMediaQuery";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { useLocation } from 'react-router-dom'

// Import utils
import { getCurrentDate, sortSavedDesignsArray } from '../../../utils/utils'

mixpanel.init('d60542dff5aaf889760e7cc2f6316298', { debug: false })

// import components
// import { customTheme } from "../../../components/atoms/CustomTheme/CustomTheme";
import WebAppBar from '../../../components/organisms/WebAppBar/WebAppBar'
import Text from '../../../components/atoms/Text/Text'
import Button from '../../../components/atoms/Button/Button'
import ImageTextGridCMSResultsSwapFurniture from '../../../components/organisms/ImageTextGridCMSResultsSwapFurniture/ImageTextGridCMSResultsSwapFurniture'
import ImageTextGridCMSResults from '../../../components/organisms/ImageTextGridCMSResults/ImageTextGridCMSResults'
// import FurnitureClickCommands from "../../../components/organisms/FurnitureClickCommands/FurnitureClickCommands";
import MobileOtherFurnitureClickCommands from '../../../components/organisms/MobileOtherFurnitureClickCommands/MobileOtherFurnitureClickCommands'
import IBubble from '../../../components/molecules/IBubble/IBubble'
// import Sidebar from "../../../components/molecules/CMSResultsSidebar/CMSResultsSidebar";
import { classes } from './CMSResultsStyle'
// import CMSResultsSavedPopup from "../../../components/organisms/CMSResultsSavedPopup/CMSResultsSavedPopup";
import { segmentWriteKey } from '../../../components/atoms/Segment/Segment'
import ModalLogInCMSResults from '../../../components/organisms/ModalLogInCMSResults/ModalLogInCMSResults'
import Loading from '../../../components/atoms/Loading/Loading'
import getCMSBetaSuggestion from '../../../services/getCMSBetaSuggestion'
import { generateSampleInput } from '../constants'
import getPreselectedPlan from '../../../services/getPreselectedPlan'
import RetrieveFavoritesCallToBackend from '../../profileRelated/favorites/RetrieveFavoritesCallToBackend'

// import context
import { UserContext } from '../../../App'

// import pages
import LoadingResults from './LoadingResults'
import ResultsError from './ResultsError'
import MoodBoard from '../../../components/templates/MoodBoard/MoodBoard'

// import icons
// import { ReactComponent as DownChevronIcon } from "../../../assets/icons/down-chevron.svg";
// const linkIcon =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/icons/link-white.svg";
const heartIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/heart-filled.svg'
const shoppingBag =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/shopping-bag-white.svg'

// Import images
const saveImg1 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/craft-my-style-results-page/save-decoration-img-1.jpg'
const saveImg2 =
  'https://craftle-static.s3.us-east-2.amazonaws.com/craft-my-style-results-page/save-decoration-img-2.jpg'
const lightBulb =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/light-bulb.svg'

// Import services
import getExistingPlan from '../../../services/getExistingPlan'
import createDuplicatePlan from '../../../services/duplicatePlan'

// Import constants
import { DesignStyleDescription } from '../constants'

// import global store
import { Context } from '../../../context/context'

// import Call to Backened file
import CMSDesignsCallToBackend from './CMSDesignsInsertCallToBackend'

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

const propTypes = {
  viewer: PropTypes.bool,
  duplicatePlan: PropTypes.bool,
  navLinks: PropTypes.array,
}
const defaultProps = {
  viewer: false,
  duplicatePlan: false,
  navLinks: {},
}

export const CMSResultsContext = createContext()

function CMSResults({ viewer, duplicatePlan, navLinks }) {
  const [globalState, globalDispatch] = useContext(Context)
  const {
    user,
    showCommandBar,
    setShowCommandBar,
    // mobileCmsOtherCommandsClicked,
    // setMobileCmsOtherCommandsClicked,
  } = React.useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [showGuestModeNotification, setShowGuestModeNotification] =
    useState(viewer)
  const [furnitureResultObject, setFurnitureResultObject] = useState({})
  const [otherRecommendationsObject, setOtherRecommendationsObject] = useState(
    {},
  )
  const [designMatched, setDesignMatched] = useState(
    DesignStyleDescription['bohemian'],
  )
  const [showLoginModal, setShowLoginModal] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [plans, setPlans] = useState([])
  const [index, setIndex] = useState(0)
  const [selectedFurniturePieces, setSelectedFurniturePieces] = useState(null)
  const [selectedOtherFurniturePieces, setSelectedOtherFurniturePieces] =
    useState(null)
  const [furniturePositions, setFurniturePositions] = useState([])
  // const [budget, setBudget] = useState(3000);
  // const [budgetResult, setBudgetResult] = useState(3000);
  const [planId, setPlanId] = useState(null)
  const [currResultCost, setCurrResultCost] = useState(null)
  const [cmsBudgetLoading, setCmsBudgetLoading] = useState(true)
  const [numberOfPreselectedView, setNumberOfPreselectedView] = useState(0)
  const [preselectedPlansViewed, setPreselectedPlansViewed] = useState([])
  // const [input, setInput] = useState();
  const [shouldGetPreselectedPlans, setShouldGetPreselectedPlans] =
    useState(false)
  const [
    showCreateCustomizedPlanMoodBoard,
    setShowCreateCustomizedPlanMoodBoard,
  ] = useState(false)
  const [planBudget, setPlanBudget] = useState(null)
  // const [cookies] = useCookies(["savedDesigns"]);
  // const [copyLinkPressed, setCopyLinkPressed] = useState(false);
  // const [showOnboarding, setShowOnboarding] = useState(
  //   cookies.displayCMSResultsOnboarding
  //     ? JSON.parse(cookies.displayCMSResultsOnboarding)
  //     : true
  // );
  const [currFurnitureCommand, setCurrFurnitureCommand] = useState(
    globalState?.currFurnitureCommand,
  )
  const [mobileShowOtherCommands, setMobileShowOtherCommands] = useState(false)
  const [mobileShowOriginalCommands, setMobileShowOriginalCommands] =
    useState(true)
  const [userFavorites, setUserFavorites] = useState()

  // For parallax affect
  const [offset, setOffset] = useState(0)
  const containerRef = useRef(null)
  const [elementTop, setElementTop] = useState(0)
  const [isDuplicating, setIsDuplicating] = useState(false)

  useLayoutEffect(() => {
    const calculateInitialTopPosition = () => {
      if (containerRef.current) {
        const elementRect = containerRef.current.getBoundingClientRect()
        const scrollTop = window.scrollY || document.documentElement.scrollTop
        const topPosition = elementRect.top + scrollTop
        setElementTop(topPosition) // Set the element's top position relative to the document
      }
    }

    // Calculate initial top position when the component mounts
    window.addEventListener('load', calculateInitialTopPosition)
    calculateInitialTopPosition() // Also calculate once immediately

    const handleScroll = () => {
      const calculatedOffset = window.scrollY - elementTop
      setOffset(calculatedOffset)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('load', calculateInitialTopPosition)
    }
  }, [])

  let params = useParams()
  let urlPlanId = parseInt(params.planId, 10)
  let navigate = useNavigate()
  let cmsDesigns = JSON.parse(localStorage.getItem('cmsDesigns')) || []
  let numSavedDesign = cmsDesigns.length

  const CREATE_PERSONALIZED_PLAN_MOODBOARD_PAGE = 6
  const NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW = 7
  const MIN_BUDGET = 1000

  const location = useLocation()
  const currentUrl = `${window.location.origin}${location.pathname}`

  // const isTabletScreen = useMediaQuery(customTheme.breakpoints.down("md"));

  function setError(error) {
    globalDispatch({
      type: 'SET_ERROR',
      error,
    })
  }

  useEffect(() => {
    // Tracking that the moodboard was viewed
    analytics.track('Moodboard Viewed', {
      user_email: user?.email,
      user_name: user?.first_name,
      flow: 'Craft My Style Moodboard',
      room: 'Living Room',
      moodboard_id: urlPlanId,
    })
  }, [])

  async function saveUserToken(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('cmsDesigns', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  async function saveDesignToCookie(planId, planDesignType) {
    const currentDateTime = getCurrentDate()
    const email =
      null !== localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')).email
        : null

    const newDesignObj = {
      planId: planId,
      planThumbnail: DesignStyleDescription[planDesignType]['imageUrl'],
      lastUpdated: currentDateTime,
      email: email,
    }

    if (null !== email) {
      // Get data from the DB
      await CMSDesignsCallToBackend(newDesignObj)
    } else {
      let newSavedDesignList = [newDesignObj]
      if (null !== localStorage.getItem('cmsDesigns')) {
        let savedDesignsList = JSON.parse(localStorage.getItem('cmsDesigns'))
        newSavedDesignList = savedDesignsList.filter((design) => {
          return design.planId !== planId
        })
        newSavedDesignList = sortSavedDesignsArray(
          [newDesignObj, ...newSavedDesignList].slice(0, 7),
        )
      }
      saveUserToken(newSavedDesignList)
    }
  }

  function duplicatePlanCall(urlPlanId) {
    setIsDuplicating(true)
    createDuplicatePlan({
      planId: urlPlanId,
    })
      .then((res) => {
        const newPlanId = res.plan_id
        const planResult = res.plan_result
        const planDesignType = res.plan_param['selected_design_type']
        const otherRecommendations = res.other_recommendations
        const selectedFurniturePieces = res.selected_furniture_pieces
        const selectedOtherFurniturePieces = res.selected_other_furniture_pieces
        const uploadedImages = res.uploaded_images
        const furniturePositions = res.furniture_positions

        // Update cookies when a user opens in edit mode
        if (!viewer) {
          saveDesignToCookie(newPlanId, planDesignType)
        }

        navigate('/designs/e/' + newPlanId)

        setError(null)
        setDesignMatched(DesignStyleDescription[planDesignType])
        setFurnitureResultObject(planResult)
        setOtherRecommendationsObject(otherRecommendations)

        globalDispatch({
          type: 'SET_WORKFLOW_RESULT',
          payload: {
            planResult: planResult,
            otherRecommendations: otherRecommendations,
            selectedFurniturePieces: selectedFurniturePieces,
            selectedOtherFurniturePieces: selectedOtherFurniturePieces,
            uploadedImages: uploadedImages,
            furniturePositions: furniturePositions,
          },
        })

        setIsDuplicating(false)
      })
      .catch((error) => {
        console.log(error)
        setError(error)
        setIsDuplicating(false)
      })
  }

  useEffect(() => {
    const fetchPlanData = async () => {
      setIsLoading(true) // Start loading
      try {
        // Check for duplicate plan and fetch accordingly
        if (duplicatePlan) {
          duplicatePlanCall(urlPlanId)
        } else if (urlPlanId) {
          // Dispatch global action to set planId
          globalDispatch({
            type: 'SET_WORKFLOW_PROP_VALUE',
            payload: {
              propName: 'planId',
              value: urlPlanId,
            },
          })

          // Fetch existing plan
          const res = await getExistingPlan({ planId: urlPlanId })
          const {
            plan_result,
            plan_param,
            other_recommendations,
            selected_furniture_pieces,
            selected_other_furniture_pieces,
            uploaded_images,
            furniture_positions,
          } = res
          const { selected_design_type, selected_budget } = plan_param

          // Update cookies when a user opens in edit mode
          if (!viewer) {
            saveDesignToCookie(urlPlanId, selected_design_type)
          }

          // Update local state with fetched data
          setDesignMatched(DesignStyleDescription[selected_design_type])
          setPlanBudget(selected_budget)
          setFurnitureResultObject(plan_result)
          setOtherRecommendationsObject(other_recommendations)

          // Dispatch global action with fetched data
          globalDispatch({
            type: 'SET_WORKFLOW_RESULT',
            payload: {
              planResult: plan_result,
              otherRecommendations: other_recommendations,
              selectedFurniturePieces: selected_furniture_pieces,
              selectedOtherFurniturePieces: selected_other_furniture_pieces,
              uploadedImages: uploaded_images,
              furniturePositions: furniture_positions,
            },
          })

          setError(null)
          setIsLoading(false)
        } else {
          throw new Error('Invalid plan ID')
        }
      } catch (error) {
        console.error('Error fetching plan data:', error)
        setError(error)
        setIsLoading(false)
      }
    }

    // Call the fetch function
    fetchPlanData()

    // Fetch preselected plans
    getPreselectedPlans()

    // Set workflow as completed
    globalDispatch({
      type: 'SET_WORKFLOW_PROP_VALUE',
      payload: {
        propName: 'completed',
        value: true,
      },
    })
  }, [urlPlanId, duplicatePlan]) // Dependencies array to re-run the effect when these values change

  useEffect(() => {
    if (user) {
      const fetchFavorites = async () => {
        try {
          const favoritesList = await RetrieveFavoritesCallToBackend(user.email)
          setUserFavorites(favoritesList)
        } catch (error) {
          console.error('Error fetching favorites:', error)
        }
      }

      fetchFavorites()
    }
  }, [user])

  function guestModeNotification() {
    return (
      showGuestModeNotification && (
        <Box sx={classes.guestModeNotificationContainer}>
          <IBubble
            xIconPressed={setShowGuestModeNotification}
            icon="user"
            boldText="You’re in guest mode."
            regularText="Your changes won’t save for this design because
            you're not the original creator."
          />
        </Box>
      )
    )
  }

  // function copyLinkPressedNotification() {
  //   return (
  //     <Box sx={classes.copyLinkNotificationContainer}>
  //       <Text sx={classes.copyLinkText}>Copied link</Text>
  //       <Box sx={classes.speechBubbleTriangle} />
  //     </Box>
  //   );
  // }

  // function copyLinkPressedAction() {
  //   mixpanel.track(`(CMS) Clicked "Share link"`);
  //   setCopyLinkPressed(true);
  //   navigator.clipboard.writeText(`craftle.app/designs/v/${urlPlanId}`);
  //   setTimeout(() => {
  //     setCopyLinkPressed(false);
  //   }, 1000);
  // }

  useEffect(() => {
    // Updates when a new moodboard plan is created
    if (plans.length > 0) {
      const lastIndex = plans.length - 1
      setIndex(lastIndex)
      setPlanId(plans[lastIndex].plan_id)
      setSelectedFurniturePieces(plans[lastIndex].selected_furniture_pieces)
      setSelectedOtherFurniturePieces(
        plans[lastIndex].selected_other_furniture_pieces,
      )
      setFurniturePositions(plans[lastIndex].furniture_positions)
    }
  }, [plans.length])

  useEffect(() => {
    // Updates when a user revisits an existing moodboard plan
    // and when a new moodboard is created (it's redundant, fix later)
    if (plans.length > 0) {
      setPlanId(plans[index].plan_id)
      setSelectedFurniturePieces(plans[index].selected_furniture_pieces)
      setSelectedOtherFurniturePieces(
        plans[index].selected_other_furniture_pieces,
      )
      setFurniturePositions(plans[index].furniture_positions)
    }
  }, [index])

  useEffect(() => {
    if (selectedFurniturePieces) {
      let currResultTotal = 0
      Object.entries(selectedFurniturePieces).forEach((item) => {
        const [, val] = item

        currResultTotal += val.cost
      })
      setCurrResultCost(currResultTotal)
    } else {
      setCurrResultCost(null)
    }
  }, [selectedFurniturePieces])

  useEffect(() => {
    if (shouldGetPreselectedPlans) {
      getPreselectedPlans()
      setShouldGetPreselectedPlans(false)
    }
  }, [shouldGetPreselectedPlans])

  function getPreselectedPlans() {
    getPreselectedPlanCall(preselectedPlansViewed)
  }

  function runRandomCall() {
    const randomInput = generateSampleInput(MIN_BUDGET, planBudget)
    getCMSBetaSuggestionCall(randomInput)
  }

  function generateAnotherPressed() {
    setShowCreateCustomizedPlanMoodBoard(false)
    // const nextIndex = index + 1;
    // if (index < plans.length - 1) {
    //   // When a user revisits an existing moodboard
    //   setIndex(nextIndex);
    // } else {
    setCmsBudgetLoading(true)

    // Call the API and get more designs
    if (numberOfPreselectedView < NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW) {
      getPreselectedPlans()
    } else {
      runRandomCall()
    }

    setPlanId(null)
    setSelectedFurniturePieces(null)
    setSelectedOtherFurniturePieces(null)
    setFurniturePositions([])
    // }
  }

  function getPreselectedPlanCall(preselectedPlansViewed) {
    getPreselectedPlan({
      visitedId: preselectedPlansViewed,
      budget: planBudget,
    })
      .then((response) => {
        analytics.track('Moodboard Viewed', {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: 'Craft My Style Pre-Created Moodboard',
          room: 'Living Room',
        })
        mixpanel.track('(CMS Results) Viewed Pre-created Moodboard')

        setPreselectedPlansViewed([...preselectedPlansViewed, response.plan_id])
        setNumberOfPreselectedView(
          (prevNumberOfPreselectedView) => prevNumberOfPreselectedView + 1,
        )
        setPlans([...plans, response])

        setCmsBudgetLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setCmsBudgetLoading(false)
      })
  }

  function getCMSBetaSuggestionCall(randomInput) {
    getCMSBetaSuggestion({
      ...randomInput,
    })
      .then((response) => {
        analytics.track('Moodboard Viewed', {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: 'Craft My Style Custom Moodboard',
          room: 'Living Room',
        })
        mixpanel.track('(CMS Results) Created Moodboard')

        setPlans([...plans, response])
        setCmsBudgetLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setCmsBudgetLoading(false)
      })
  }

  function seeAndEditPlanPressed() {
    mixpanel.track(`(CMS Results) Clicked "Customize" moodboard`)
    if (numSavedDesign < 3 || user) {
      if (!user) {
        numSavedDesign += 1
      }
      if (index < NUMBER_OF_PRESELECTED_VIEWS_TO_SHOW) {
        // Only duplicate preselected plans
        analytics.track('Moodboard Created', {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: 'Craft My Style Pre-Created Moodboard',
          room: 'Living Room',
          budget: planBudget,
          style: plans[index].plan_param.selected_design_type,
          moodboard_id: planId,
        })
        window.open(`/designs/d/${planId}`, '_blank')
      } else {
        analytics.track('Moodboard Created', {
          user_email: user?.email,
          user_name: user?.first_name,
          flow: 'Craft My Style Custom Moodboard',
          room: 'Living Room',
          budget: planBudget,
          style: plans[index].plan_param.selected_design_type,
          moodboard_id: planId,
        })
        window.open(`/designs/e/${planId}`, '_blank')
      }
    } else {
      setShowLoginModal(true)
    }
  }

  function MoodBoardComponent() {
    return (
      <Box sx={classes.moodboardOuter}>
        {cmsBudgetLoading &&
        !selectedFurniturePieces &&
        !selectedOtherFurniturePieces ? (
          <Box sx={classes.moodboardLoadingContainer}>
            <Loading />
            <Text sx={classes.moodboardLoadTxt}>
              This can take a minute while we try to find the best plan for your
              budget.
            </Text>
          </Box>
        ) : (
          <Box
            onClick={() => {
              if (index === CREATE_PERSONALIZED_PLAN_MOODBOARD_PAGE) {
                window.open('/designs', '_blank', 'noopener,noreferrer')
              } else {
                seeAndEditPlanPressed()
              }
            }}
            sx={classes.moodboardInner}
          >
            <MoodBoard
              selectedFurniturePieces={selectedFurniturePieces}
              selectedOtherFurniturePieces={selectedOtherFurniturePieces}
              furniturePositions={furniturePositions}
              showCreateCustomizedPlanMoodBoard={
                showCreateCustomizedPlanMoodBoard
              }
              homePageView
            />
          </Box>
        )}
      </Box>
    )
  }

  return isDuplicating || isLoading ? (
    <LoadingResults />
  ) : globalState.error ? (
    <ResultsError />
  ) : (
    <CMSResultsContext.Provider
      value={{
        furnitureResultObject,
        setFurnitureResultObject,
        otherRecommendationsObject,
        setOtherRecommendationsObject,
        // showOnboarding,
        // setShowOnboarding,
        showCommandBar,
        setShowCommandBar,
        currFurnitureCommand,
        setCurrFurnitureCommand,
        mobileShowOtherCommands,
        setMobileShowOtherCommands,
        mobileShowOriginalCommands,
        setMobileShowOriginalCommands,
        userFavorites,
        setUserFavorites,
        urlPlanId,
        showLoginModal,
        setShowLoginModal,
        showSignUpModal,
        setShowSignUpModal,
      }}
    >
      <WebAppBar urls={navLinks} />
      <Container disableGutters maxWidth={false} sx={classes.container}>
        {guestModeNotification()}
        {showLoginModal && (
          <ModalLogInCMSResults
            redirectHref={currentUrl}
            setShowLoginModal={setShowLoginModal}
          />
        )}
        {/* {displayLoginModal && } */}
        <Box sx={classes.headerSection}>
          <Text variant="h1" sx={classes.h1}>
            Your style is{' '}
            <span style={{ display: 'none' }}>{designMatched.title}</span>
          </Text>
          <Box sx={classes.titleContainer}>
            <Text sx={classes.title}>{designMatched.title}</Text>
            <Box
              sx={{
                ...classes.titleHighlight,
                backgroundColor:
                  designMatched.title === 'Bohemian'
                    ? '#F3D5B9'
                    : designMatched.title === 'Eclectic'
                      ? '#FFE9AF'
                      : designMatched.title === 'Industrial'
                        ? '#BFBFBF'
                        : designMatched.title === 'Mid-Century Modern'
                          ? '#DAEBCF'
                          : designMatched.title === 'Modern'
                            ? '#C6E1EF'
                            : designMatched.title === 'Rustic'
                              ? '#CFA587'
                              : designMatched.title === 'Scandinavian'
                                ? '#F3E3CA'
                                : designMatched.title === 'Traditional'
                                  ? '#EBCFCF'
                                  : '#F3E3CA',
              }}
            />
          </Box>
        </Box>

        <Box sx={classes.definitionSection}>
          <Text sx={classes.definedBy}>Defined by</Text>
          <Text sx={classes.definitionBullets}>
            {designMatched.description}
          </Text>
        </Box>

        <Box sx={classes.exampleImgSection}>
          <Box
            sx={{
              ...classes.exampleImg1Container,
              transform: `translateY(${offset * -0.02}px)`, // Apply the parallax effect here
            }}
            ref={containerRef}
          >
            <CardMedia
              component="img"
              image={designMatched.exampleImages.img1.img}
              alt="Example image of the style"
              sx={classes.exampleImg1}
            />
            <Link
              component="a"
              href={designMatched.exampleImages.img1.capLink}
              rel="nofollow"
              target="_blank"
            >
              <Text sx={classes.exampleImgCaption}>
                {designMatched.exampleImages.img1.cap}
              </Text>
            </Link>
          </Box>
          <Box
            sx={{
              ...classes.exampleImg2Container,
              transform: `translateY(${offset * -0.05}px)`,
            }}
            ref={containerRef}
          >
            <CardMedia
              component="img"
              image={designMatched.exampleImages.img2.img}
              alt="Example image of the style"
              sx={classes.exampleImg2}
            />
            <Link
              component="a"
              href={designMatched.exampleImages.img2.capLink}
              rel="nofollow"
              target="_blank"
            >
              <Text sx={classes.exampleImgCaption}>
                {designMatched.exampleImages.img2.cap}
              </Text>
            </Link>
          </Box>
          <Box
            sx={{
              ...classes.exampleImg3Container,
              transform: `translateY(${offset * -0.07}px)`,
            }}
            ref={containerRef}
          >
            <CardMedia
              component="img"
              image={designMatched.exampleImages.img3.img}
              alt="Example image of the style"
              sx={classes.exampleImg3}
            />
            <Link
              component="a"
              href={designMatched.exampleImages.img3.capLink}
              rel="nofollow"
              target="_blank"
            >
              <Text sx={classes.exampleImgCaption}>
                {designMatched.exampleImages.img3.cap}
              </Text>
            </Link>
          </Box>
        </Box>

        <Box sx={classes.recommendationSection}>
          <Text sx={classes.whatYouToldUS}>
            Based on what you’ve told us...
          </Text>
          <Text sx={classes.weRecommendTxt}>We recommend these products</Text>

          <Box sx={classes.moodboardAndProducts}>
            <Box sx={classes.moodboardOuterContainer}>
              <Box sx={classes.desktopMoodboardContainer}>
                <MoodBoard
                  selectedFurniturePieces={globalState.selectedFurniturePieces}
                  selectedOtherFurniturePieces={
                    globalState.selectedOtherFurniturePieces
                  }
                  furniturePositions={
                    globalState.furniturePositions === undefined ||
                    Object.keys(globalState.furniturePositions).length === 0
                      ? []
                      : globalState.furniturePositions
                  }
                  urlPlanId={urlPlanId}
                  homePageView={false}
                />
              </Box>
              <Text sx={classes.moodboardSubtitle}>
                How the products look together.
              </Text>
            </Box>

            {/* Item results */}
            <Box sx={classes.itemResultsContainer}>
              <Box sx={classes.costContainer}>
                <Text sx={classes.totalIs}>The total is:</Text>
                <Text sx={classes.cost}>
                  $
                  {Intl.NumberFormat().format(
                    globalState.currResultTotal.toFixed(2).toString(),
                  )}
                </Text>
              </Box>
              <ImageTextGridCMSResults
                furnitureResultObject={furnitureResultObject}
                viewer={viewer}
              />
              {Object.keys(otherRecommendationsObject).length > 0 && (
                <Box sx={classes.otherRecommendationsContainer}>
                  <Text sx={classes.otherRecsTitle}>Goes well with</Text>
                  <Text sx={classes.otherRecsSubtitle}>
                    These products would complement your plan very well.
                  </Text>
                  <Box sx={classes.otherRecsItemConstraint}>
                    <ImageTextGridCMSResults
                      otherRecommendationsObject={otherRecommendationsObject}
                      viewer={true}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {!user && (
          <Box sx={classes.saveSection}>
            <Box sx={classes.saveRow}>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <CardMedia
                  component="img"
                  image={saveImg1}
                  alt="Living room image"
                  sx={classes.saveImg1}
                />
                <Box sx={classes.saveImg1Overlay} />
              </Box>
              <Box sx={classes.saveBox}>
                <Box sx={classes.heartTokenContainer}>
                  <CardMedia
                    component="img"
                    image={heartIcon}
                    alt=""
                    sx={classes.heartIcon}
                  />
                </Box>
                <Text sx={classes.saveTitle}>Sign up to save this design!</Text>
                <Box sx={classes.signUpButtonContainer}>
                  <Button variant="primary" target="_blank" href={`/sign-up`}>
                    Sign Up Now 🎉
                  </Button>
                </Box>
                <Box sx={classes.logInContainer}>
                  <Text sx={classes.newtoCraftleText}>
                    Already have an account?{' '}
                  </Text>
                  <Button
                    variant="fourth"
                    onClick={() => {
                      setShowLoginModal(true)
                    }}
                    sx={classes.logInButton}
                  >
                    Log in
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  // ... any other style you want to apply to the container
                }}
              >
                <CardMedia
                  component="img"
                  image={saveImg2}
                  alt="Living room image"
                  sx={classes.saveImg2}
                />
                <Box sx={classes.saveImg2Overlay} />
              </Box>
            </Box>
            <Text sx={classes.legalNotice}>
              By continuing, you agree to Craftle&apos;s{' '}
              <Button
                variant="fourth"
                href="/terms"
                target="_blank"
                sx={classes.legalBtns}
              >
                Terms of Use.
              </Button>
              <br />
              Read our{' '}
              <Button
                variant="fourth"
                href="/policy"
                target="_blank"
                sx={classes.legalBtns}
              >
                Privacy Policy
              </Button>
              .
            </Text>
          </Box>
        )}

        <Box sx={classes.exploreMoreSection}>
          <Text sx={classes.exploreMoreTitle}>Want to explore more?</Text>
          {planBudget === null ? (
            <Text>Loading budget...</Text>
          ) : (
            <Text sx={classes.exploreMoreSubtitle}>
              Here are some designs under $
              {parseInt(planBudget, 10).toLocaleString('en-US')}
            </Text>
          )}
          <Box sx={classes.moodboardContainer}>
            <Box sx={classes.moodboardInnerContainer}>
              {MoodBoardComponent()}
              {!showCreateCustomizedPlanMoodBoard && (
                <Box sx={classes.totalCostContainer}>
                  <Text sx={classes.totalCost}>
                    {cmsBudgetLoading
                      ? 'Calculating...'
                      : `Total cost for these products: $${parseInt(
                          currResultCost,
                          10,
                        ).toLocaleString('en-US')}`}
                  </Text>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                ...classes.commandsContainer,
                marginTop: showCreateCustomizedPlanMoodBoard ? '47px' : '16px',
              }}
            >
              <Button
                onClick={() => {
                  currResultCost &&
                    planId &&
                    !showCreateCustomizedPlanMoodBoard &&
                    seeAndEditPlanPressed()
                }}
                icon={shoppingBag}
                sx={{
                  ...classes.shopTheseProductsBtn,
                  opacity:
                    currResultCost &&
                    planId &&
                    !showCreateCustomizedPlanMoodBoard
                      ? '100%'
                      : '50%',
                }}
              >
                Shop These Products
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  generateAnotherPressed()
                }}
                icon={lightBulb}
                sx={{
                  ...classes.generateDesignBtn,
                  ...(cmsBudgetLoading &&
                  (plans.length === 0 || index === plans.length - 1)
                    ? {
                        backgroundColor: 'rgba(243,243,243)',
                        color: '#BFBFBF',
                        '&:hover': {
                          cursor: 'default',
                        },
                      }
                    : {
                        cursor: 'pointer',
                      }),
                }}
              >
                Generate Another Design
              </Button>
            </Box>
          </Box>
        </Box>

        {/* --------------------------------------------------------------------------------- */}

        {/* <Box sx={{ marginTop: "240px" }}></Box> */}

        {/* --------------------------------------------------------------------------------- */}

        {/* Old code */}
        {/* <Grid
          container
          spacing={0}
          columnSpacing={0}
          sx={classes.desktopGridContainer}
        >
          <Grid item xs={5} sx={{ width: "100%" }}>
            <CardMedia
              component="img"
              sx={classes.desktopGridItem1}
              image={designMatched.imageUrl}
              alt="designMatchedImage"
            />
          </Grid>
          <Grid item xs={7} sx={classes.desktopGridItem2}>
            <Box sx={classes.yourStyleContainer}>
              <Text>YOUR STYLE IS</Text>
              <Text sx={classes.yourStyle}>{designMatched.title}</Text>
              <Text sx={classes.designMatchedDescription}>
                {designMatched.description}
              </Text>
              <Box sx={classes.scrollDownContainer}>
                <Text>Scroll down for more</Text>
                <Box sx={classes.scrollDownIcon}>
                  <DownChevronIcon />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid> */}

        {/* Phone size: Section above the fold */}
        {/* <Box
          sx={{
            ...classes.mobileAboveFoldContainer,
            [customTheme.breakpoints.down("sm")]: {
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundImage: `url(${designMatched.imageUrl})`,
              backgroundColor: "#000000",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            },
          }}
        >
          <Box sx={classes.mobileYourStyleContainer}>
            <Text sx={classes.mobileYourStyleIs}>YOUR STYLE IS</Text>
            <Text sx={classes.mobileYourStyle}>{designMatched.title}</Text>
            <Text sx={classes.mobileDesignMatchedDescription}>
              {designMatched.description}
            </Text>
          </Box>
          <Box sx={classes.mobileSwipeUpContainer}>
            <Text sx={classes.mobileSwipeUp}>Swipe up to continue</Text>
          </Box>
        </Box> */}

        {/* ________________________________________________________________ */}
        {/* The following are below the fold */}
        {/* ________________________________________________________________ */}

        {/* <Box sx={classes.belowFoldContainer}> */}
        {/* Tablet and mobile: social section */}
        {/* <Box sx={classes.mobileSocialSection}>
            {copyLinkPressed && isTabletScreen && copyLinkPressedNotification()}
            <Button
              variant="tertiary"
              onClick={() => {
                copyLinkPressedAction();
              }}
              icon={linkIcon}
              sx={classes.mobileShareLinkContainer}
            >
              <Text sx={classes.mobileShareLink}>Share this design</Text>
            </Button>
          </Box> */}

        {/* Desktop, tablet, and mobile: Recommended Furniture and Other Recommendations sections */}
        {/* <Sidebar viewer={viewer} /> */}

        {/* Desktop, tablet, and mobile: Moodboard/canvas section */}
        {/* <Box
            onClick={() => {
              if (!mobileCmsOtherCommandsClicked) {
                globalDispatch({
                  type: "UPDATE_FURNITURE_TYPE_SELECTED",
                  furnitureTypeSelected: null,
                });
              } else {
                setMobileCmsOtherCommandsClicked(false);
              }
            }}
            sx={classes.desktopMoodboardContainer}
          >
            <MoodBoard
              selectedFurniturePieces={globalState.selectedFurniturePieces}
              selectedOtherFurniturePieces={
                globalState.selectedOtherFurniturePieces
              }
              furniturePositions={
                globalState.furniturePositions === undefined ||
                Object.keys(globalState.furniturePositions).length === 0
                  ? []
                  : globalState.furniturePositions
              }
              urlPlanId={urlPlanId}
              homePageView={false}
            />
          </Box>
        </Box> */}

        {/* Desktop, tablet, and mobile: Swap command options */}
        <ImageTextGridCMSResultsSwapFurniture
          urlPlanId={urlPlanId}
          furnitureResultObject={furnitureResultObject}
          otherRecommendationsObject={otherRecommendationsObject}
          selectedFurnitureType={globalState.currFurnitureTypeSelected}
          viewer={viewer}
        />

        {/* Desktop: Saving message */}
        {/* <CMSResultsSavedPopup /> */}

        {/* Desktop, tablet, and mobile: Commands when user clicks on an item */}
        {/* <FurnitureClickCommands
          selectedFurnitureType={globalState.currFurnitureTypeSelected}
          urlPlanId={urlPlanId}
        /> */}
        {/* Mobile: Additional commands when user clicks "..." */}
        <MobileOtherFurnitureClickCommands
          selectedFurnitureType={globalState.currFurnitureTypeSelected}
          urlPlanId={urlPlanId}
        />
      </Container>
    </CMSResultsContext.Provider>
  )
}

CMSResults.propTypes = propTypes
CMSResults.defaultProps = defaultProps

export default CMSResults
