import mixpanel from 'mixpanel-browser'
import AppConfig from '../config/app_config'

export default class MixpanelTracking {
  static _instance

  static getInstance() {
    if (MixpanelTracking._instance == null) {
      return (MixpanelTracking._instance = new MixpanelTracking())
    }
    return this._instance
  }

  constructor() {
    if (MixpanelTracking._instance) {
      throw new Error(
        'Error: Instance Creation of MixpanelTracking is not allowed. Use Mixpanel.getInstance instead.',
      )
    }
    mixpanel.init(AppConfig.Mixpanel.ProjectId, {
      debug: AppConfig.Mixpanel.DebugEnabled,
    })
  }

  getSource() {
    const urlParams = new URLSearchParams(window.location.search)
    const source = urlParams.get('s')
    if (!source) {
      return 'direct'
    } else {
      if (source === 'r') {
        return 'reddit'
      } else if (source === 'e') {
        return 'email'
      } else if (source === 'fb') {
        return 'facebook'
      } else if (source === 'yt') {
        return 'youtube'
      } else if (source === 't') {
        return 'tiktok'
      }
      return source
    }
  }

  track(name, data = {}) {
    data['Environment'] = AppConfig.Env
    mixpanel.track(name, { ...data, Source: this.getSource() })
  }

  pageViewed(pageName, extraData = {}) {
    this.track('page_viewed', { Name: pageName, ...extraData })
  }

  featureFlag(featureName, variation) {
    this.track('feature_flag', { Name: featureName, variation: variation })
  }

  formSubmitted(formName, form_data = {}) {
    this.track('form_submitted', { Name: formName, 'Form Data': form_data })
  }
}
