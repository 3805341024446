import React, { useState, createContext } from 'react'
import { Container, Box } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import Text from '../../../components/atoms/Text/Text'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'
import ImageTextGridAllMyDesigns from '../../../components/organisms/ImageTextGridAllMyDesigns/ImageTextGridAllMyDesigns'
import DeleteDesignModal from '../../../components/organisms/DeleteDesignModal/DeleteDesignModal'
import ModalStartDesignLimit from '../../../components/organisms/ModalStartDesignLimit/ModalStartDesignLimit'
import CMSDesignsCallToBackend from './CMSDesignsDeleteCallToBackend'

export const ShowLoginModalContext = createContext()

function AllMyDesigns(props) {
  const [showConfirmDeletePrompt, setShowConfirmDeletePrompt] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [showLoginModal, setShowLoginModal] = useState(false)

  function onPressDeleteButton(value, planId) {
    setShowConfirmDeletePrompt(value)
    setSelectedPlanId(planId)
  }

  async function saveCMSDesign(token) {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem('cmsDesigns', JSON.stringify(token))
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  async function onPressConfirmDeleteButton() {
    const email =
      null !== localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user')).email
        : null

    if (null != email) {
      const cmsDesignInfo = {
        email: email,
        planId: selectedPlanId,
      }

      const response = await CMSDesignsCallToBackend(cmsDesignInfo)

      if (response) {
        console.log(response)
      }
    } else {
      if (null !== localStorage.getItem('cmsDesigns')) {
        let cmsDesigns = JSON.parse(localStorage.getItem('cmsDesigns'))
        cmsDesigns = cmsDesigns.filter(function (design) {
          return design.planId !== selectedPlanId
        })
        saveCMSDesign(cmsDesigns)
      }
    }

    setSelectedPlanId(null)
    setShowConfirmDeletePrompt(false)
    window.location.reload()
  }

  function onPressCancelButton() {
    console.log('cancel delete pressed')
    setSelectedPlanId(null)
    setShowConfirmDeletePrompt(false)
  }

  return (
    <ShowLoginModalContext.Provider
      value={{
        setShowLoginModal,
      }}
    >
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '80px 0 80px 0',
          [customTheme.breakpoints.down('sm')]: {
            padding: '40px 0 40px 0',
          },
        }}
      >
        {showConfirmDeletePrompt && selectedPlanId && (
          <DeleteDesignModal
            onPressConfirmDeleteButton={onPressConfirmDeleteButton}
            onPressCancelButton={onPressCancelButton}
          />
        )}

        <Box sx={{ maxWidth: '1137px', position: 'relative' }}>
          <Text
            sx={{
              textAlign: 'center',
              color: '#757575',
              fontSize: '14px',
              [customTheme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          >
            AUTO-SAVED
          </Text>
          <Text
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: '30px',
              lineHeight: 1.5,
              marginBottom: '50px',
              [customTheme.breakpoints.down('sm')]: {
                marginBottom: '20px',
                fontSize: '20px',
                fontWeight: 'bold',
              },
            }}
          >
            All My Designs
          </Text>
          <ImageTextGridAllMyDesigns
            onPressDeleteButton={onPressDeleteButton}
            savedDesigns={props.cmsDesigns}
          />
          {showLoginModal && <ModalStartDesignLimit />}
        </Box>
      </Container>
    </ShowLoginModalContext.Provider>
  )
}

AllMyDesigns.propTypes = {
  cmsDesigns: PropTypes.array.isRequired,
}
export default AllMyDesigns
