import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'
import Text from '../../../components/atoms/Text/Text'
import { AnalyticsBrowser } from '@segment/analytics-next'

// Import components
import { classes } from './FooterStyle'
import { segmentWriteKey } from '../../../components/atoms/Segment/Segment'

// Import images
import craftleIcon from '../../../assets/logos/craftle_icon_white.svg'

const propTypes = {}
const defaultProps = {}

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

function Footer() {
  let visitRecorded =
    JSON.parse(localStorage.getItem('userVisitRecorded')) || false

  const currentPageUrl = window.location.href

  useEffect(() => {
    let loggedInUser

    try {
      // Attempt to parse the user data from localStorage
      const userData = localStorage.getItem('user')
      loggedInUser = userData ? JSON.parse(userData) : null
    } catch (error) {
      console.log('There was an error:', error)
      loggedInUser = null
    }

    // Record user page views and visits
    if (loggedInUser) {
      const accountCreatedDateTime = loggedInUser.created || ''

      analytics.identify(loggedInUser.user_id, {
        email: loggedInUser.email,
        firstName: loggedInUser.first_name,
        created: accountCreatedDateTime,
      })
      analytics.track('Page Viewed', {
        user_email: loggedInUser.email,
        user_name: loggedInUser.first_name,
        url: currentPageUrl,
      })
      if (!visitRecorded) {
        analytics.track('User Visited', {
          user_email: loggedInUser.email,
          user_name: loggedInUser.first_name,
        })
        visitRecorded = true
        localStorage.setItem('userVisitRecorded', JSON.stringify(visitRecorded))
      }
    } else {
      analytics.track('Page Viewed', {
        url: currentPageUrl,
      })
      if (!visitRecorded) {
        analytics.track('User Visited', {})
        visitRecorded = true
        localStorage.setItem('userVisitRecorded', JSON.stringify(visitRecorded))
      }
    }
  }, [])

  return (
    <Box sx={classes.container}>
      <Grid container sx={classes.gridContainer}>
        {/* column 1 */}
        <Grid item md={6} sm={12} sx={classes.column1Style}>
          <Box sx={classes.logoSection}>
            <CardMedia
              component="img"
              image={craftleIcon}
              alt="Craftle Icon"
              sx={classes.logoStyle}
            />
            <Text
              sx={{
                ...classes.dreamHomeText,
                ...classes.sectionTitle,
                marginBottom: 0,
              }}
            >
              Crafting dream homes
            </Text>
          </Box>
          <Box sx={classes.legalSection}>
            <a href="/terms" style={classes.linkStyle}>
              Terms of Service
            </a>
            <Text sx={classes.dotSeparator}>&nbsp;&nbsp;•&nbsp;&nbsp;</Text>
            <a href="/policy" style={classes.linkStyle}>
              Privacy Policy
            </a>
          </Box>
          <Text sx={classes.copyrightText}>Copyright © Craftle 2024</Text>
        </Grid>

        {/* column 2 */}
        <Grid item md={3} sm={6} xs={12} sx={classes.column2Style}>
          <Text sx={classes.sectionTitle}>Socials</Text>
          <a
            href="https://www.facebook.com/groups/craftle"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            href="https://www.instagram.com/craftlehome/"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            href="https://www.tiktok.com/@craftlehome?lang=en"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            target="_blank"
            rel="noopener noreferrer"
          >
            TikTok
          </a>
          <a
            href="https://twitter.com/Craftle"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.linkedin.com/company/craftlehome/?viewAsMember=true"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </Grid>

        {/* column 3 */}
        <Grid item md={3} sm={6} xs={12} sx={classes.column3Style}>
          <Text sx={classes.sectionTitle}>Explore</Text>
          {/* <a
              href="business"
              style={{ ...classes.linkStyle, ...classes.linkSeparator }}
            >
              Enterprise
            </a> */}
          <a
            href="/blog"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
          >
            Blog
          </a>
          <a
            href="explore"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
          >
            Explore
          </a>
          <a
            href="about"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
          >
            About
          </a>
          <a
            href="contact"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
          >
            Contact
          </a>
          <a
            href="https://forms.gle/DKZR9eKrdV4HJdPN9"
            target="_blank"
            rel="noreferrer"
            style={{ ...classes.linkStyle, ...classes.linkSeparator }}
          >
            Feedback
          </a>
        </Grid>
      </Grid>
      <Box sx={classes.affiliateContainer}>
        <Text sx={classes.affiliateText}>
          Affiliate disclosure: As an Amazon Associate, we may earn commissions
          from qualifying purchases from Amazon.com.
        </Text>
      </Box>
    </Box>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer
