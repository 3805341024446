import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'

// import components

let customTheme = createTheme({
  breakpoints: {
    values: {
      customMd: 1300,
      xl: 1536,
      lg: 1200,
      md: 900,
      sm: 600,
      xs: 0,
    },
  },
})

function Contact() {
  return (
    <ThemeProvider theme={customTheme}>
      <Grid
        container
        sx={{
          maxWidth: '1137px',
          width: '100%',
          margin: 'auto',
          marginTop: '73px',
          marginBottom: '100px',
          [customTheme.breakpoints.down('lg')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
        }}
      >
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h1" sx={{ fontSize: 75, fontWeight: 'bold' }}>
            Contact
          </Typography>
          <Box style={{ marginTop: 30, lineHeight: 1.5 }}>
            If you need to get in contact with us, please email us at{' '}
            <span style={{ fontWeight: 800 }}>angela.kim@craftle.app</span>.
          </Box>
        </Grid>
        {/* <Grid item md={6} xs={12} className={classes.bannerTextSection}>
          <Box className={classes.bannerTitle}>
            How can we help you?
          </Box>
          <Box>
            <form action="">
              <label for="message reason" className={classes.formElementLabel}>What is this about?</label>
              <select id="message reason" name="message reason" className={classes.formElement}>
                <option value="distributor">I’m a distributor, and I want to sell on Craftle</option>
                <option value="customer with order problem">I’m a customer, and there’s something wrong with my order</option>
                <option value="customer with problem not related to order">I’m a customer, and I have a question not related to my order</option>
                <option value="press">I’m part of the press</option>
                <option value="other">Other</option>
              </select>
              
              <label for="email" className={classes.formElementLabel}>Email address</label>
              <input type="text" id="email" name="email" placeholder="Enter your email address" className={classes.formElement}></input>

              <label for="message" className={classes.formElementLabel}>Please leave your message below</label>
              <textarea type="text" id="message" name="message" placeholder="Enter your message here..." className={classes.formElement} style={{height: 215, paddingTop: 15, fontFamily: "Arial"}}></textarea>

              <input type="submit" value="Submit" className={classes.submit}></input>
            </form>
          </Box>
        </Grid>
        <Grid item md={6} xs={12} className={classes.verticalCenterChildren}>
          <Box>
            <img
              src={bannerImage}
              alt="banner image"
              loading="lazy"
              className={classes.bannerImage}
              //sizes="(max-width: 991px) 100vw, 772px"
            />
          </Box>
        </Grid> */}
      </Grid>
    </ThemeProvider>
  )
}

export default Contact
