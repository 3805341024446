import React, { useContext, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import CardMedia from '@mui/material/CardMedia'
import { AnalyticsBrowser } from '@segment/analytics-next'

// import components
import mixpanel from '../../atoms/Mixpanel/Mixpanel'
import { classes } from './FurnitureClickCommandsStyle'
import Text from '../../atoms/Text/Text'
import { segmentWriteKey } from '../../../components/atoms/Segment/Segment'

// import icons
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh-icon.svg'
import { ReactComponent as ExternalLinkWhiteIcon } from '../../../assets/icons/external-link-white.svg'
const closeIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg'
// const trashIcon =
//   "https://craftle-static.s3.us-east-2.amazonaws.com/icons/trash-red.svg";
const moreIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/more-horizontal.svg'
const externalLinkIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/external-link.svg'

// import context
import { Context } from '../../../context/context'
import { UserContext } from '../../../App'
import { CMSResultsContext } from '../../../pages/CraftMyStyle/pages/CMSResults'

const analytics = AnalyticsBrowser.load({ writeKey: segmentWriteKey })

const propTypes = {
  selectedFurnitureType: PropTypes.string,
  isMobile: PropTypes.bool,
  urlPlanId: PropTypes.number,
}
const defaultProps = {
  selectedFurnitureType: null,
  isMobile: false,
  urlPlanId: 0,
}

function FurnitureClickCommands({
  selectedFurnitureType,
  isMobile,
  urlPlanId,
}) {
  const [globalState, globalDispatch] = useContext(Context)
  const [addedOurProduct, setAddedOurProduct] = useState(false)

  const { setMobileShowOtherCommands, setCurrFurnitureCommand } =
    React.useContext(CMSResultsContext)
  const {
    user,
    // setItemToDelete,
    showCommandBar,
    // setShowCommandBar,
    setMobileCmsOtherCommandsClicked,
    mobileShowOriginalCommands,
    setMobileShowOriginalCommands,
  } = React.useContext(UserContext)

  const acquisitionSource =
    JSON.parse(localStorage.getItem('acquisitionSource')) || 'N/A'

  useEffect(() => {
    if (selectedFurnitureType) {
      const productId = selectedFurnitureType
      // Determines if the selected furntiure item is an added...
      // ...item and if that added item is one of our products
      if (globalState.uploadedImages && globalState.uploadedImages[productId]) {
        const productCategory = globalState.uploadedImages[productId].category
        const productFurnitureType =
          globalState.uploadedImages[productId].product_type
        if (productCategory || productFurnitureType) {
          setAddedOurProduct(true)
        } else {
          setAddedOurProduct(false)
        }
      } else {
        setAddedOurProduct(false)
      }
    }
  }, [selectedFurnitureType])

  useEffect(() => {
    setCurrFurnitureCommand(globalState.currFurnitureCommand)
  }, [globalState.currFurnitureCommand])

  const allFurnitureResultCurrentlyViewing = {
    ...globalState.selectedFurniturePieces,
    ...globalState.selectedOtherFurniturePieces,
  }

  function closeFurnitureCommands() {
    globalDispatch({ type: 'RESET_FURNITURE_TYPE_SELECTED' })
    globalDispatch({
      type: 'SET_FURNITURE_COMMAND',
      command: 'null',
    })
  }

  // function deleteImageCommands() {
  //   setItemToDelete(selectedFurnitureType);
  //   setShowCommandBar("none");
  // }

  function pressViewItem() {
    if (addedOurProduct) {
      const productId = selectedFurnitureType
      const productPageUrl =
        globalState.uploadedImages[productId]?.product_page_url

      analytics.track('Product-Link Opened', {
        user_email: user?.email,
        user_name: user?.first_name,
        flow: 'Craft My Style',
        survey_acquisition_source: acquisitionSource,
        moodboard_id: urlPlanId,
        product_link: productPageUrl,
        product_name: 'N/A',
        product_price: 'N/A',
        product_length: 'N/A',
        product_width: 'N/A',
        product_height: 'N/A',
      })
      mixpanel.track(`(CMS) Clicked "View Item"`, {
        'Product Link': productPageUrl,
      })
      window.open(productPageUrl)
    } else {
      const cardDetail =
        allFurnitureResultCurrentlyViewing[selectedFurnitureType]

      analytics.track('Product-Link Opened', {
        user_email: user?.email,
        user_name: user?.first_name,
        flow: 'Craft My Style',
        survey_acquisition_source: acquisitionSource,
        moodboard_id: urlPlanId,
        product_link: cardDetail?.product_page_url,
        product_name: cardDetail?.name,
        product_price: cardDetail?.cost,
        product_length: 'N/A',
        product_width: 'N/A',
        product_height: 'N/A',
      })
      mixpanel.track(`(CMS) Clicked "View Item"`, {
        'Product Link': cardDetail.product_page_url,
      })
      window.open(cardDetail.product_page_url)
    }
  }

  function pressSwapItem() {
    globalDispatch({
      type: 'SET_FURNITURE_COMMAND',
      command: 'SWAP',
    })
  }

  function DesktopVersion() {
    return (
      <Box sx={{ ...classes.container, display: showCommandBar }}>
        {/* Shop Now button shouldn't appear for uploaded images */}
        {!globalState.uploadedImages ||
        (globalState.uploadedImages &&
          !(selectedFurnitureType in globalState.uploadedImages)) ||
        addedOurProduct ? (
          <Box
            sx={classes.itemCommandsFilled}
            onClick={() => {
              pressViewItem()
            }}
          >
            <ExternalLinkWhiteIcon />
            <Text sx={classes.viewItemText}>Shop Now</Text>
          </Box>
        ) : null}

        {/* Swap button shouldn't appear for uploaded images */}
        {!globalState.uploadedImages ||
        (globalState.uploadedImages &&
          !(selectedFurnitureType in globalState.uploadedImages)) ||
        addedOurProduct ? (
          <Box
            sx={classes.itemCommandsOutlined}
            onClick={() => {
              pressSwapItem()
            }}
          >
            <RefreshIcon />
            <Text sx={classes.swapText}>Swap</Text>
          </Box>
        ) : null}

        {/* <Box
          sx={classes.deleteContainer}
          onClick={() => {
            deleteImageCommands();
          }}
        >
          <CardMedia
            component="img"
            image={trashIcon}
            alt="trash icon"
            sx={classes.trashIcon}
          />
          <Text sx={classes.deleteText}>Delete</Text>
        </Box> */}

        <Box
          sx={classes.closeContainer}
          onClick={() => {
            closeFurnitureCommands()
          }}
        >
          <CardMedia
            component="img"
            image={closeIcon}
            alt="canvas icon"
            sx={classes.closeIcon}
          />
        </Box>
      </Box>
    )
  }

  function MobileAndTabletVersion() {
    return (
      <Box classes={classes.mobileContainer}>
        {/* Original commands */}
        {mobileShowOriginalCommands && (
          <Box sx={classes.mobileOriginalCommands}>
            {/* <Box
              onClick={() => {
                deleteImageCommands();
              }}
            >
              <CardMedia
                component="img"
                image={trashIcon}
                alt="Trash button"
                sx={classes.trashIcon}
              />
            </Box> */}

            {!globalState.uploadedImages ||
            (globalState.uploadedImages &&
              !(selectedFurnitureType in globalState.uploadedImages)) ||
            addedOurProduct ? (
              <Box
                onClick={() => {
                  pressViewItem()
                }}
                sx={classes.externalIconBtn}
              >
                <CardMedia
                  component="img"
                  image={externalLinkIcon}
                  alt="External link button"
                  sx={classes.externalIcon}
                />
                <Text sx={classes.shopNowTxt}>Shop Now</Text>
              </Box>
            ) : null}

            {/* Separator line */}
            <Box sx={classes.separator} />

            {/* Shop Now button shouldn't appear for uploaded images */}
            {!globalState.uploadedImages ||
            (globalState.uploadedImages &&
              !(selectedFurnitureType in globalState.uploadedImages)) ||
            addedOurProduct ? (
              <Box
                onClick={(event) => {
                  event.stopPropagation() // This prevents the click event from bubbling up
                  setMobileShowOriginalCommands(false)
                  setMobileShowOtherCommands(true)
                  setMobileCmsOtherCommandsClicked(true)
                }}
              >
                <CardMedia
                  component="img"
                  image={moreIcon}
                  alt="More commands button"
                  sx={classes.moreIcon}
                />
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    )
  }

  return (
    selectedFurnitureType &&
    (isMobile ? <MobileAndTabletVersion /> : <DesktopVersion />)
  )
}

FurnitureClickCommands.propTypes = propTypes
FurnitureClickCommands.defaultProps = defaultProps

export default FurnitureClickCommands
