import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: "100vh",
    boxSizing: 'border-box',
    padding: '264px 0 0 0',
    [customTheme.breakpoints.down('md')]: {
      padding: '152px 16px 0 16px',
    },
    [customTheme.breakpoints.down('sm')]: {
      padding: '136px 16px 0 16px',
    },
  },
  backDecoration1: {
    position: 'absolute',
    top: '88px',
    right: 0,
    width: '216px',
    zIndex: -1,
    [customTheme.breakpoints.down('md')]: {
      width: '176px',
    },
    [customTheme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  backDecoration2: {
    position: 'absolute',
    bottom: '488px',
    left: 0,
    width: '182px',
    zIndex: -1,
    [customTheme.breakpoints.down('md')]: {
      width: '142px',
    },
    [customTheme.breakpoints.down('xs')]: {
      bottom: 0,
    },
  },
  h1Section: {
    position: 'relative',
  },
  h1: {
    fontSize: '48px',
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '40px',
    },
    [customTheme.breakpoints.down('xs')]: {
      fontSize: '32px',
    },
  },
  heroTextHighlight: {
    zIndex: -1,
    width: '256px',
    position: 'absolute',
    right: '40px',
    bottom: '-32px',
    [customTheme.breakpoints.down(1024)]: {
      width: '184px',
      right: '160px',
    },
  },
  sparkle: {
    width: '48px',
    position: 'absolute',
    right: '-48px',
    bottom: '20px',
    [customTheme.breakpoints.down(1024)]: {
      bottom: '12px',
    },
    [customTheme.breakpoints.down(724)]: {
      display: 'none',
    },
    [customTheme.breakpoints.down(659)]: {
      display: 'inherit',
      right: '198px',
    },
    [customTheme.breakpoints.down(649)]: {
      right: '178px',
    },
    [customTheme.breakpoints.down(608)]: {
      right: '160px',
    },
    [customTheme.breakpoints.down(574)]: {
      right: '140px',
    },
    [customTheme.breakpoints.down(532)]: {
      right: '124px',
    },
    [customTheme.breakpoints.down(519)]: {
      right: '56px',
    },
    [customTheme.breakpoints.down(502)]: {
      right: '42px',
    },
    [customTheme.breakpoints.down(478)]: {
      right: '24px',
    },
    [customTheme.breakpoints.down(440)]: {
      right: '8px',
    },
    [customTheme.breakpoints.down(425)]: {
      right: '112px',
      bottom: '8px',
      width: '32px',
    },
    [customTheme.breakpoints.down(422)]: {
      right: '40px',
    },
    [customTheme.breakpoints.down(402)]: {
      right: '24px',
    },
    [customTheme.breakpoints.down(372)]: {
      right: '16px',
    },
    [customTheme.breakpoints.down(353)]: {
      right: '8px',
    },
    [customTheme.breakpoints.down(328)]: {
      right: '0px',
    },
    [customTheme.breakpoints.down(310)]: {
      right: '-8px',
    },
    [customTheme.breakpoints.down(307)]: {
      display: 'none',
    },
  },
  subtitle: {
    marginTop: '24px',
    fontSize: '24px',
    fontFamily: 'poppins',
    maxWidth: '750px',
    textAlign: 'center',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
  },
  arrow1: {
    position: 'absolute',
    width: '56px',
    right: '-24px',
    bottom: '-32px',
    [customTheme.breakpoints.down(1024)]: {
      width: '54px',
      right: '80px',
      bottom: '-28px',
    },
    [customTheme.breakpoints.down(731)]: {
      width: '48px',
      right: '24px',
      bottom: '-28px',
    },
    [customTheme.breakpoints.down(713)]: {
      right: '16px',
    },
    [customTheme.breakpoints.down(699)]: {
      display: 'none',
    },
  },

  action1: {
    marginTop: '32px',
    fontSize: '24px',
    fontWeight: 500,
    padding: '8px 32px',
    lineHeight: 1.2,
    display: 'inline-block',
    backgroundColor: '#FFF3D7',
    [customTheme.breakpoints.down('md')]: {
      marginTop: '32px',
      fontSize: '20px',
    },
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
  },
  action2: {
    marginTop: '16px',
    fontSize: '24px',
    fontWeight: 500,
    padding: '8px 32px',
    lineHeight: 1.2,
    display: 'inline-block',
    backgroundColor: '#FFF3D7',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [customTheme.breakpoints.down('sm')]: {
      marginTop: '8px',
      fontSize: '20px',
    },
  },

  exploreSection: {
    marginTop: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // position: "absolute",
    // bottom: "16px",
    cursor: 'pointer',
    [customTheme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  exploreText: {
    fontSize: '24px',
    fontWeight: 'bold',
    [customTheme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [customTheme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  arrow2: {
    width: '48px',
    [customTheme.breakpoints.down('md')]: {
      width: '40px',
    },
    [customTheme.breakpoints.down('sm')]: {
      width: '32px',
    },
  },
}
