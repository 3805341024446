import getColor from './ColorClassifier'
import getMisc from './MiscellaneousClassifier'
import getFurnitureType from './FurnitureTypeClassifier'

export function CrateAndBarrelService(pageHTML) {
  // Get product name
  const productNameElement = pageHTML.querySelector(
    '.product-name.text-xl-bold',
  )
  const productName = productNameElement
    ? productNameElement.textContent.trim()
    : ''

  // Get image url
  let imageUrlElement = pageHTML.querySelector(
    '.main-carousel-item > button > img',
  )
  let imageUrl = imageUrlElement ? imageUrlElement.getAttribute('src') : null
  if (!imageUrl) {
    // Fallback to the specific selector if the initial query fails
    imageUrlElement = pageHTML.querySelector(
      ".galleryCarouselItem_SHSrn img[data-testid='image']",
    )
    imageUrl = imageUrlElement ? imageUrlElement.getAttribute('src') : null
  }

  // Get dimensions
  const dimensions = getProductDimensions(pageHTML)

  // Find furniture type (e.g., Sofas)
  let furnitureType = ''
  const furnitureTypeElements = pageHTML.querySelectorAll('.breadcrumb-list li')
  if (furnitureTypeElements.length > 0) {
    furnitureType = getFurnitureType(
      Array.from(furnitureTypeElements).map((elem) => elem.textContent.trim()),
    )
  }

  // Find price
  const price = getPrice(pageHTML)

  // Find color and fabric
  let color = ''
  let fabric = ''
  const colorAndFabricResults = getColorAndFabric(pageHTML)
  if (colorAndFabricResults) {
    color = colorAndFabricResults.color || ''
    fabric = colorAndFabricResults.fabric || ''
  }

  // Find miscellaneous
  const misc = getMisc(productName)

  // Combine all variables into an object
  const productDetails = {
    productName,
    imageUrl,
    furnitureType,
    dimensions,
    fabric,
    color,
    price,
    misc,
    brand: 'Crate & Barrel',
  }

  return productDetails
}

function getProductDimensions(pageHTML) {
  const innerHTML = pageHTML.getElementsByTagName('body')[0].innerHTML

  // Find the section starting with "Overall Dimensions"
  const overallDimensionsIndex = innerHTML.indexOf('Overall Dimensions')
  if (overallDimensionsIndex === -1)
    return { width: null, depth: null, height: null }

  // Extract a substring that likely contains the needed dimensions
  const dimensionsSubstring = innerHTML.substring(
    overallDimensionsIndex,
    overallDimensionsIndex + 500,
  )

  // Use regular expressions to find the dimension values
  const widthMatch = dimensionsSubstring.match(/"width":(\d+(\.\d+)?)/)
  const depthMatch = dimensionsSubstring.match(/"depth":(\d+(\.\d+)?)/)
  const heightMatch = dimensionsSubstring.match(/"height":(\d+(\.\d+)?)/)

  const width = widthMatch ? widthMatch[1] : null
  const depth = depthMatch ? depthMatch[1] : null
  const height = heightMatch ? heightMatch[1] : null

  return { width, depth, height }
}

function getPrice(pageHTML) {
  let price = 0
  const priceElement = pageHTML.querySelector(
    'div.header-container > div.shop-bar-price-area > span > span > span.regPrice',
  )

  if (priceElement) {
    const priceText = priceElement.textContent.trim()

    // Extract the price using a regular expression
    const matches = priceText.match(/\$(\d{1,3}(?:,\d{3})*(\.\d+)?)/)

    if (matches && matches[1]) {
      // Remove commas and parse as a floating point number
      price = parseFloat(matches[1].replace(/,/g, ''))
    }
  }

  return price
}

function getColorAndFabric(pageHTML) {
  let color = ''
  let fabric = ''

  // Check if the .product-summary-container element exists
  const productSummaryContainer = pageHTML.querySelector(
    '.product-summary-container',
  )

  if (productSummaryContainer) {
    const spans = productSummaryContainer.querySelectorAll('span')
    spans.forEach((span) => {
      const content = span.textContent.trim()
      const result = getColor(content)
      if (result?.color) {
        color = result.color
      }
      if (result?.hyphenatedFabric) {
        fabric = result.hyphenatedFabric
      }
    })
  } else {
    // Find the script or element that contains 'choiceName'
    const scripts = pageHTML.querySelectorAll('script')
    scripts.forEach((script) => {
      const scriptContent = script.textContent
      if (scriptContent.includes('choiceName')) {
        const choiceNameStart = scriptContent.indexOf('choiceName')
        const choiceNameEnd = scriptContent.indexOf('choiceSequenceNumber')
        if (choiceNameStart !== -1 && choiceNameEnd !== -1) {
          const choiceName = scriptContent.substring(
            choiceNameStart + 'choiceName'.length + 3, // Adjust for the length of "choiceName" and quotes/colon
            choiceNameEnd - 2, // Adjust for comma and space before "choiceSequenceNumber"
          )
          color = getColor(choiceName).color || ''
        }
      }
    })
  }

  return {
    color,
    fabric,
  }
}
