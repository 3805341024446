import axios from 'axios'

// import components
import { EndPoint } from '../../components/atoms/EndPoint/EndPoint'

const endPoints = {
  prod: 'https://la27cbl255.execute-api.us-east-2.amazonaws.com/default/craftle-lookalike-finder',
  //   test: "https://wsr62w4eqb.execute-api.us-east-2.amazonaws.com/default/craftle-new-backend-for-testing",
  local: 'http://127.0.0.1:8080/lookalike-finder-calls',
}

export default async function ExistingCrateAndBarrelItemCall(urlInput) {
  const endPointUrl = endPoints[EndPoint]

  if (!endPointUrl) {
    throw new Error(`Endpoint for ${EndPoint} is not defined.`)
  }

  try {
    const response = await axios.post(
      endPointUrl,
      {
        purpose: 'find_existing_lookalike_items',
        url_input: urlInput,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (response?.data) {
      // Check if 'body' is present and is a string
      if (response.data.body && typeof response.data.body === 'string') {
        const parsedBody = JSON.parse(response.data.body)
        return parsedBody
      } else if (response.data) {
        return response.data
      } else {
        console.error('Unexpected response format:', response.data)
        return {}
      }
    } else {
      console.error('Scrape operation failed:', response.data?.body)
      return {}
    }
  } catch (error) {
    console.error('Error during the request:', error.message)
    return {}
  }
}
