import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// import components
import { Footer } from '../components/organisms'
import { About, Contact, Policy, Terms } from '../pages/index.jsx'
import WebAppBar from '../components/organisms/WebAppBar/WebAppBar'

const footerContentRoutes = (navLinks) => (
  <>
    <Route
      path="/about"
      element={
        <>
          <Helmet>
            <title>About Us - Why We Started Craftle</title>
            <meta
              name="description"
              content="We built Craftle because we also need it. As we were the customers, we know and understand what customers are looking for when it comes to interior design."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <About />
          <Footer />
        </>
      }
    />
    <Route
      path="/contact"
      element={
        <>
          <Helmet>
            <title>Get in Touch with Craftle</title>
            <meta
              name="description"
              content="Contact. You can get in touch with Craftle using this email. Or you can find Backlinko on Twitter."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <Contact />
          <Footer />
        </>
      }
    />
    <Route
      path="/terms"
      element={
        <>
          <Helmet>
            <title>Craftle Terms of Service</title>
            <meta
              name="description"
              content="These Terms of Use (the Terms or the Agreement) set forth the binding legal agreement between you and Craftle Inc. The Agreement governs your use of www.craftle.app..."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <Terms />
          <Footer />
        </>
      }
    />
    <Route
      path="/policy"
      element={
        <>
          <Helmet>
            <title>Craftle Privacy Policy</title>
            <meta
              name="description"
              content="This Privacy Policy describes what personal information we collect, how we use this information, how this information may be shared, and what choices and rights..."
            />
          </Helmet>
          <WebAppBar urls={navLinks} />
          <Policy />
          <Footer />
        </>
      }
    />
  </>
)

export default footerContentRoutes
