import React from 'react'
import { Card, CardMedia, Box } from '@mui/material'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'
import { classes } from './ImageTextCardExploreCMSResultsSwapFurnitureStyle'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

// import icons
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'

const propTypes = {
  // furnitureKey: PropTypes.string.isRequired,
  cardDetail: PropTypes.object,
  itemOnClick: PropTypes.func,
  infoBox: PropTypes.bool,
}

const defaultProps = {
  cardDetail: {
    id: 1,
    cost: 1685,
    product_type: 'Area Rugs',
    image_url:
      'https://ksassets.timeincuk.net/wp/uploads/sites/56/2021/06/Small-living-room-ideas_Grey-sofa.jpg',
  },
  itemOnClick: () => {},
  infoBox: false,
}

function ImageTextCardExploreCMSResultsSwapFurniture({
  cardDetail,
  itemOnClick,
  infoBox,
}) {
  function exploreAllOthersButton() {
    return (
      <Box
        sx={{
          border: 'solid',
          borderRadius: '8px',
          borderWidth: '2px',
          borderColor: '#2A2A2A',
          width: '144px',
          height: '144px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          [customTheme.breakpoints.down('md')]: {
            width: '26vw',
            height: '26vw',
          },
          [customTheme.breakpoints.down('sm')]: {
            width: '45vw',
            height: '45vw',
            borderRadius: '10px',
          },
        }}
      >
        <Box
          sx={{
            paddingLeft: '17px',
            paddingRight: '17px',
          }}
        >
          <Text
            sx={{
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            {`
            Explore
            All Others
            `}
          </Text>
          <ArrowRight height={40} width={40} />
        </Box>
      </Box>
    )
  }

  function furnitureImage() {
    return (
      <CardMedia
        component="img"
        sx={{
          width: '144px',
          height: '144px',
          borderRadius: '8px',
          [customTheme.breakpoints.down('md')]: {
            width: '26vw',
            height: '26vw',
          },
          [customTheme.breakpoints.down('sm')]: {
            width: '45vw',
            height: '45vw',
            borderRadius: '8px',
          },
        }}
        image={
          cardDetail.transparent_image_url ||
          cardDetail.image_url ||
          cardDetail.original_image_url
        }
        alt="Furniture image"
      />
    )
  }

  function furniturePrice() {
    return (
      // <Box
      //   sx={{
      //     position: "absolute",
      //     bottom: "24px",
      //     left: "0",
      //     padding: "5px 10px",
      //     backgroundColor: "rgba(255, 255, 255, 0.75)",
      //     [customTheme.breakpoints.down("lg")]: {
      //       bottom: "16px",
      //       padding: "4px 8px",
      //     },
      //   }}
      // >
      <Text
        sx={{
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        ${cardDetail.cost.toFixed(2).toString()}
      </Text>
      // </Box>
    )
  }

  return (
    <Card
      onClick={() => {
        itemOnClick()
      }}
      sx={classes.container}
    >
      <Box sx={classes.innerContainer}>
        {infoBox ? exploreAllOthersButton() : furnitureImage()}
        <Box sx={classes.priceContainer}>
          {/* Price box */}
          {!infoBox && furniturePrice()}
        </Box>
      </Box>
    </Card>
  )
}

ImageTextCardExploreCMSResultsSwapFurniture.propTypes = propTypes
ImageTextCardExploreCMSResultsSwapFurniture.defaultProps = defaultProps

export default ImageTextCardExploreCMSResultsSwapFurniture
