import React, {
  useEffect,
  useState,
  createContext,
  useMemo,
  useCallback,
  useRef,
} from 'react'
import {
  Box,
  CardMedia,
  Divider,
  CircularProgress,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material'
import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'
import isEqual from 'lodash/isEqual'
import { v4 as uuidv4 } from 'uuid'
import SaveNotification from '../../../components/molecules/SaveNotification/SaveNotification'

// Import components
import { classes } from './FavoritesStyle'
import Text from '../../../components/atoms/Text/Text'
import Button from '../../../components/atoms/Button/Button'
import { Icons } from '../../../components/atoms/Icon/Icon'
import FavoriteItem from '../../../components/organisms/FavoriteItem/FavoriteItem'
import Acknowledgment from '../../../components/molecules/Acknowledgment/Acknowledgment'
import RetrieveFavoritesCallToBackend from './RetrieveFavoritesCallToBackend'
import SaveFavoriteCallToBackend from './SaveFavoriteCallToBackend'
import GoogleSignUpButton from '../GoogleSignUpButton'
import IsMobileOrTablet from '../../../components/atoms/IsMobileOrTablet/IsMobileOrTablet'

export const FavoritesContext = createContext()

const MemoizedFavoriteItem = React.memo(FavoriteItem)
const MemoizedText = React.memo(Text)
const MemoizedButton = React.memo(Button)
const MemoizedGoogleSignUpButton = React.memo(GoogleSignUpButton)

const debounce = (func, delay) => {
  let timeoutId
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const Favorites = () => {
  const [favoritesList, setFavoritesList] = useState([])
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState({})
  const [productTypeNames, setProductTypeNames] = useState({})
  const [contextMenu, setContextMenu] = useState(null)
  const [moreOptionsMenu, setMoreOptionsMenu] = useState(null)
  const [categoryNumbers, setCategoryNumbers] = useState([])
  const [hoveredProductType, setHoveredProductType] = useState(null)
  const [menuHoveredProductType, setMenuHoveredProductType] = useState(false)
  const [hoveredFieldKey, setHoveredFieldKey] = useState(-1)
  const [notification, setNotification] = useState({ message: '', type: '' })

  const currentProductTypeRef = useRef(null)
  const initialMount = useRef(true)
  const notificationTimeoutRef = useRef(null)

  const isMobileOrTablet = IsMobileOrTablet()

  const user = useMemo(() => {
    const userData = JSON.parse(localStorage.getItem('user'))
    return userData || {}
  }, [])

  const capitalizeWords = useCallback((string) => {
    return string
      ? string
          .split(/[\s-_]+/)
          .map(
            (word) =>
              word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
          )
          .join(' ')
      : null
  }, [])

  const updateFavoritesList = (newResponse) => {
    if (!isEqual(favoritesList, newResponse)) {
      setFavoritesList(newResponse)
    }
  }

  useEffect(() => {
    const getFavorites = async () => {
      setLoading(true)
      const favoriteInfo = await RetrieveFavoritesCallToBackend(user?.email)
      setLoading(false)

      if (favoriteInfo) {
        const processedFavorites = processFavorites(favoriteInfo)
        updateFavoritesList(processedFavorites)
        initializeCategoryNumbers(processedFavorites)
      }
    }

    if (user && user.user_id) {
      getFavorites()
    }
  }, [user])

  const initializeCategoryNumbers = useCallback((favorites) => {
    const existingNumbers = favorites
      .map((item) => {
        const match = item.product_type.match(/New Category (\d+)/)
        return match ? parseInt(match[1], 10) : null
      })
      .filter((num) => num !== null)
    setCategoryNumbers([...new Set(existingNumbers)])
  }, [])

  const updateCategoryNumbersFromFavorites = useCallback(() => {
    const existingNumbers = favoritesList
      .map((item) => {
        const match = item.product_type.match(/New Category (\d+)/)
        return match ? parseInt(match[1], 10) : null
      })
      .filter((num) => num !== null)
    setCategoryNumbers([...new Set(existingNumbers)])
  }, [favoritesList])

  const debouncedSaveFavoriteCall = useMemo(() => {
    return debounce(async (email, favoritesList) => {
      if (!initialMount.current) {
        await SaveFavoriteCallToBackend(email, favoritesList)
        setNotification({ message: 'All changes saved', type: 'saved' })

        if (notificationTimeoutRef.current) {
          clearTimeout(notificationTimeoutRef.current)
        }

        notificationTimeoutRef.current = setTimeout(() => {
          setNotification({ message: '', type: 'end' })
        }, 1500)
      }
    }, 500)
  }, [])

  useEffect(() => {
    if (!initialMount.current) {
      if (user) {
        if (!initialMount.current) {
          setNotification({ message: 'Saving changes', type: 'saving' })
        }
        debouncedSaveFavoriteCall(user.email, favoritesList)
      }
    } else {
      initialMount.current = false
    }
  }, [favoritesList, debouncedSaveFavoriteCall])

  const uuidRef = useRef({})
  const textFieldRef = useRef(null)
  const currentEditingUuidRef = useRef(null)

  const processFavorites = useCallback((favorites) => {
    const groupedItems = {}
    const basicOtherFields = [
      {
        field_id: uuidv4(),
        field_label: 'Dimensions',
        field_value: '',
      },
    ]

    return favorites.map((item) => {
      const defaultValues = {
        product_type: 'undefined category',
        allImages: [],
        brand: '',
        dimensions: '',
        email: '',
        height: '',
        length: '',
        width: '',
        link: '',
        price: 0,
        product_name: '',
        product_id: uuidv4(),
      }

      const updatedItem = {
        ...defaultValues,
        ...item,
        product_id:
          item.product_id && item.product_id !== ''
            ? item.product_id
            : uuidv4(),
      }

      if (
        Array.isArray(updatedItem.allImages) &&
        updatedItem.allImages.length === 1 &&
        updatedItem.allImages[0] === null
      ) {
        updatedItem.allImages = []
      }

      if (!groupedItems[updatedItem.product_type]) {
        groupedItems[updatedItem.product_type] = {
          group_id: uuidv4(),
        }
      }

      return {
        ...updatedItem,
        group_id: groupedItems[updatedItem.product_type].group_id,
        other_fields:
          item.other_fields && item.other_fields.length > 0
            ? item.other_fields
            : basicOtherFields,
      }
    })
  }, [])

  const groupByProductType = useCallback(
    (items) => {
      if (!Array.isArray(items)) {
        console.error('Expected an array but received:', items)
        return {}
      }

      const grouped = items.reduce((acc, item) => {
        const { group_id, product_type } = item
        if (!group_id) {
          console.error('Missing group_id for item:', item)
          return acc
        }

        const newCategoryName = productTypeNames[group_id] || product_type

        if (!acc[newCategoryName]) {
          acc[newCategoryName] = {
            uuid: group_id,
            items: [],
          }
        }
        acc[newCategoryName].items.push(item)
        return acc
      }, {})
      return grouped
    },
    [productTypeNames],
  )

  const setFirstItem = useCallback(() => {
    const groupId = uuidv4()
    setFavoritesList([
      {
        product_id: uuidv4(),
        group_id: groupId,
        allImages: [],
        brand: '',
        email: '',
        link: '',
        price: '',
        product_name: '',
        product_type: 'Category Name',
        length: '',
        width: '',
        height: '',
        other_fields: [
          {
            field_id: uuidv4(),
            field_label: 'Dimensions',
            field_value: '',
          },
        ],
      },
    ])
    uuidRef.current['Category Name'] = groupId
    setProductTypeNames((prevNames) => ({
      ...prevNames,
      [groupId]: 'Category Name',
    }))
  }, [])

  const handleNameChange = (e, uuid) => {
    const { value } = e.target
    setProductTypeNames((prevNames) => ({
      ...prevNames,
      [uuid]: value,
    }))
  }

  const getNewCategoryName = () => {
    const newCategoryNumber = getNewCategoryNumber()
    return `New Category ${newCategoryNumber}`
  }

  const handleEditClick = (uuid, productType) => {
    setEditMode((prevEditMode) => ({
      ...prevEditMode,
      [uuid]: !prevEditMode[uuid],
    }))

    if (editMode[uuid]) {
      let newProductType = productTypeNames[uuid] || ''

      if (newProductType.trim() === '') {
        newProductType = getNewCategoryName()
      }

      const match = newProductType.match(/New Category (\d+)/)
      const newCategoryNumber = match ? parseInt(match[1], 10) : null

      if (
        newCategoryNumber !== null &&
        categoryNumbers.includes(newCategoryNumber)
      ) {
        newProductType = getNewCategoryName()
      } else if (newCategoryNumber !== null) {
        setCategoryNumbers((prevNumbers) => {
          const oldMatch =
            currentProductTypeRef.current.match(/New Category (\d+)/)
          const oldCategoryNumber = oldMatch ? parseInt(oldMatch[1], 10) : null

          const updatedNumbers = prevNumbers.filter(
            (num) => num !== oldCategoryNumber,
          )
          return [...new Set([...updatedNumbers, newCategoryNumber])]
        })
      } else {
        setCategoryNumbers((prevNumbers) => {
          const oldMatch =
            currentProductTypeRef.current.match(/New Category (\d+)/)
          const oldCategoryNumber = oldMatch ? parseInt(oldMatch[1], 10) : null

          const updatedNumbers = prevNumbers.filter(
            (num) => num !== oldCategoryNumber,
          )
          return [...new Set(updatedNumbers)]
        })
      }

      setFavoritesList((prevFavoritesList) =>
        prevFavoritesList.map((item) =>
          item.group_id === uuid
            ? {
                ...item,
                product_type: newProductType,
              }
            : item,
        ),
      )

      uuidRef.current[newProductType] = uuidRef.current[uuid]
      delete uuidRef.current[uuid]

      setProductTypeNames((prevNames) => ({
        ...prevNames,
        [uuid]: newProductType,
      }))
    } else {
      currentEditingUuidRef.current = uuid
      currentProductTypeRef.current = productType
    }
  }

  const handleClickOutside = (event) => {
    if (
      textFieldRef.current &&
      !textFieldRef.current.contains(event.target) &&
      currentEditingUuidRef.current
    ) {
      handleEditClick(
        currentEditingUuidRef.current,
        currentProductTypeRef.current,
      )
      currentEditingUuidRef.current = null
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [productTypeNames, editMode])

  const handleAddProduct = (uuid, productType) => {
    const groupID = uuid

    const lastProductWithSameType = favoritesList
      .filter((item) => item.product_type === productType)
      .slice(-1)[0]

    const newOtherFields = lastProductWithSameType
      ? lastProductWithSameType.other_fields.map((field) => ({
          field_id: uuidv4(),
          field_label: field.field_label,
          field_value: '',
        }))
      : [
          {
            field_id: uuidv4(),
            field_label: 'Dimensions',
            field_value: '',
          },
        ]

    setFavoritesList((prevFavoritesList) => [
      ...prevFavoritesList,
      {
        product_id: uuidv4(),
        group_id: groupID,
        allImages: [],
        brand: '',
        email: '',
        link: '',
        price: '',
        product_name: '',
        product_type: productType,
        length: '',
        width: '',
        height: '',
        other_fields: newOtherFields,
      },
    ])
    uuidRef.current[productType] = groupID
    setProductTypeNames((prevNames) => ({
      ...prevNames,
      [groupID]: productType,
    }))
  }

  const handleAddRow = (event, productType) => {
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            productType: productType,
          }
        : null,
    )
  }

  const handleMoreOptions = (event, uuid) => {
    setMenuHoveredProductType(uuid)
    setMoreOptionsMenu(
      moreOptionsMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
            uuid: uuid,
          }
        : null,
    )
  }

  const handleClose = () => {
    setContextMenu(null)
    setMoreOptionsMenu(null)
  }

  const handleAddProductDetails = () => {
    setFavoritesList((prevFavoritesList) => {
      const updatedFavoritesList = prevFavoritesList.map((item) => {
        if (item.product_type === contextMenu.productType) {
          return {
            ...item,
            other_fields: [
              ...item.other_fields,
              { field_id: uuidv4(), field_label: '', field_value: '' },
            ],
          }
        }
        return item
      })
      return updatedFavoritesList
    })
    handleClose()
  }

  const getNewCategoryNumber = () => {
    let newCategoryNumber = 1
    while (categoryNumbers.includes(newCategoryNumber)) {
      newCategoryNumber++
    }
    return newCategoryNumber
  }

  const handleAddNewCategory = () => {
    updateCategoryNumbersFromFavorites()

    const newCategoryNumber = getNewCategoryNumber()
    const newCategory = `New Category ${newCategoryNumber}`

    const groupId = uuidv4()

    setCategoryNumbers((prev) => [...prev, newCategoryNumber])
    setFavoritesList((prevFavoritesList) => [
      ...prevFavoritesList,
      {
        product_id: uuidv4(),
        group_id: groupId,
        allImages: [],
        brand: '',
        email: '',
        link: '',
        price: '',
        product_name: '',
        product_type: newCategory,
        length: '',
        width: '',
        height: '',
        other_fields: [
          {
            field_id: uuidv4(),
            field_label: 'Dimensions',
            field_value: '',
          },
        ],
      },
    ])
    uuidRef.current[newCategory] = groupId
    setProductTypeNames((prevNames) => ({
      ...prevNames,
      [groupId]: newCategory,
    }))
    handleClose()
  }

  const handleDeleteCategory = (uuid) => {
    setFavoritesList((prevFavoritesList) => {
      const updatedList = prevFavoritesList.filter(
        (item) => item.group_id !== uuid,
      )
      const deletedCategory = prevFavoritesList.find(
        (item) => item.group_id === uuid,
      )

      if (deletedCategory) {
        const match = deletedCategory.product_type.match(/New Category (\d+)/)
        const categoryNumber = match ? parseInt(match[1], 10) : null

        if (categoryNumber !== null) {
          setCategoryNumbers((prevNumbers) =>
            prevNumbers.filter((num) => num !== categoryNumber),
          )
        }
      }

      return updatedList
    })
    handleClose()
  }

  const ShownFavorites = useMemo(() => {
    const groupedFavorites = groupByProductType(favoritesList || [])

    return (
      <Box sx={classes.contentContainer}>
        <Acknowledgment
          userId={user.user_id}
          messageId="compareFavoritesTips"
          messageContent={
            <>
              You can add your own products by clicking{' '}
              <span style={classes.boldFont}>Add Product</span>. You can also
              add product details or new categories by clicking{' '}
              <span style={classes.boldFont}>Add Row</span>.
            </>
          }
          sx={classes.shownFavoritesAcknowledgment}
        />
        {Object.entries(groupedFavorites).map(
          ([productType, { uuid, items }]) => {
            const multipleItems = items.length > 1
            const isEditing = editMode[uuid]
            const currentName =
              !isEditing && !productTypeNames[uuid]
                ? productType
                : capitalizeWords(productTypeNames[uuid])

            const handleKeyDown = (e, uuid, productType) => {
              if (e.key === 'Enter') {
                handleEditClick(uuid, productType)
              }
            }

            return (
              <Box key={uuid} sx={classes.favoriteCategoryContainer}>
                {isEditing ? (
                  <TextField
                    ref={textFieldRef}
                    value={currentName}
                    onChange={(e) => handleNameChange(e, uuid)}
                    onBlur={() => handleEditClick(uuid, productType)}
                    onKeyDown={(e) => handleKeyDown(e, uuid, productType)}
                    autoFocus
                    sx={classes.productTypeTextfieldContainer}
                  />
                ) : (
                  <Box
                    onMouseOver={() => setHoveredProductType(uuid)}
                    onMouseLeave={() => setHoveredProductType(null)}
                    sx={classes.productTypeContainer}
                  >
                    {(hoveredProductType === uuid ||
                      menuHoveredProductType === uuid ||
                      isMobileOrTablet) && (
                      <Box
                        onClick={(e) => handleMoreOptions(e, uuid)}
                        sx={classes.verticalMoreOptions}
                      >
                        <CardMedia
                          component="img"
                          image={Icons.dotsVertical}
                          alt=""
                          sx={classes.icon}
                        />
                      </Box>
                    )}
                    <Box
                      onClick={() => handleEditClick(uuid, productType)}
                      sx={classes.productTypeTxtContainer}
                    >
                      <MemoizedText variant="h2" sx={classes.productTypeTxt}>
                        {currentName === uuid
                          ? 'New Category'
                          : currentName
                            ? currentName
                            : 'New Category'}
                      </MemoizedText>
                    </Box>
                  </Box>
                )}

                <Box sx={classes.favoriteItemsRow}>
                  {items &&
                    items.map((product, index) => (
                      <React.Fragment key={product.product_id}>
                        <MemoizedFavoriteItem
                          item={product}
                          multipleItems={multipleItems}
                          itemPosition={index}
                          lastPosition={index === items.length - 1} // Sends True or False
                        />
                      </React.Fragment>
                    ))}
                  <Box
                    onClick={() => handleAddProduct(uuid, productType)}
                    sx={classes.addProductBtn}
                  >
                    <CardMedia
                      component="img"
                      image={Icons.plusIcon}
                      alt=""
                      sx={classes.icon}
                    />
                    <Text sx={classes.addProductTxt}>
                      Add <br /> Product
                    </Text>
                  </Box>
                </Box>
                <Box
                  sx={classes.addRowBtnOuter}
                  onClick={(e) => handleAddRow(e, productType)}
                >
                  <Box sx={classes.addRowBtnInner}>
                    <CardMedia
                      component="img"
                      image={Icons.plusIcon}
                      alt=""
                      sx={classes.icon}
                    />
                    <Text sx={classes.addRowTxt}>Add Row</Text>
                  </Box>
                </Box>
              </Box>
            )
          },
        )}
      </Box>
    )
  }, [
    favoritesList,
    capitalizeWords,
    editMode,
    productTypeNames,
    hoveredProductType,
    menuHoveredProductType,
  ])

  const NoFavorites = useMemo(() => {
    return (
      <Box sx={classes.noFavoritesSection}>
        <MemoizedText sx={classes.noFavoritesText1}>
          You have no saved favorites.
        </MemoizedText>
        {user &&
        user.user_id &&
        (favoritesList?.length === 0 || !favoritesList) ? (
          <>
            <Box>
              <MemoizedText sx={classes.noFavoritesText2}>
                Save your favorites by clicking the ♡ on{' '}
              </MemoizedText>
              <MemoizedButton
                variant="fourth"
                href="/living-room"
                sx={classes.shopLink}
              >
                products
              </MemoizedButton>
              <MemoizedText sx={classes.noFavoritesText2}>.</MemoizedText>
            </Box>

            <Acknowledgment
              userId={user.user_id}
              messageId="compareFavoritesTips"
              messageContent={
                <>
                  You can add your own products by clicking{' '}
                  <span style={classes.boldFont}>Add First Category</span> and{' '}
                  <span style={classes.boldFont}>Add Product</span>. You can
                  also add product details or new categories by clicking{' '}
                  <span style={classes.boldFont}>Add Row</span>.
                </>
              }
            />

            <Box
              onClick={() => {
                setFirstItem()
              }}
              sx={classes.addFirstCategoryContainer}
            >
              <CardMedia
                component="img"
                image={Icons.plusIcon}
                alt=""
                sx={classes.icon}
              />
              <Text sx={classes.addFirstCategoryTxt}>Add First Category</Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <MemoizedText sx={classes.noFavoritesText2}>
                Save your favorites by clicking the ♡ on{' '}
              </MemoizedText>
              <MemoizedButton
                variant="fourth"
                href="/living-room"
                sx={classes.shopLink}
              >
                products
              </MemoizedButton>
              <MemoizedText sx={classes.noFavoritesText2}>,</MemoizedText>
            </Box>
            <Box>
              <MemoizedText sx={classes.noFavoritesText2}>or </MemoizedText>
              <MemoizedButton
                variant="fourth"
                href="/log-in"
                sx={classes.logInLink}
              >
                log in
              </MemoizedButton>
              <MemoizedText sx={classes.noFavoritesText2}>
                {' '}
                to access your Favorites list.
              </MemoizedText>
            </Box>

            <Box sx={classes.orContainer}>
              <Divider sx={classes.orDivider} />
              <MemoizedText sx={classes.orText}>OR</MemoizedText>
              <Divider sx={classes.orDivider} />
            </Box>
            <MemoizedText sx={classes.signUpTxt}>Sign Up</MemoizedText>
            <Box sx={classes.googleSignUpButtonOuterContainer}>
              <Box sx={classes.googleSignUpButtonContainer}>
                <MemoizedGoogleSignUpButton redirectHref="/favorites" />
              </Box>
            </Box>
          </>
        )}
      </Box>
    )
  }, [user, favoritesList])

  return (
    <FavoritesContext.Provider
      value={{
        favoritesList,
        setFavoritesList,
        hoveredFieldKey,
        setHoveredFieldKey,
      }}
    >
      <Box
        sx={{
          [customTheme.breakpoints.down('sm')]: {
            padding:
              user && favoritesList?.length > 0 ? '0 0 120px' : '0 16px 120px',
          },
          ...classes.container,
        }}
      >
        <Box sx={classes.backgroundGradient} />
        <Box sx={classes.content}>
          <Box sx={classes.titleRow}>
            <CardMedia
              component="img"
              image={Icons.titleIcon}
              alt=""
              sx={classes.titleIcon}
            />
            <MemoizedText variant="h1" sx={classes.h1}>
              Compare Favorites
            </MemoizedText>
          </Box>
          {loading ? (
            <Box sx={classes.spinnerContainer}>
              <CircularProgress />
            </Box>
          ) : user && favoritesList?.length > 0 ? (
            ShownFavorites
          ) : (
            NoFavorites
          )}
        </Box>
        {/* Render saving notification */}
        {notification.message && <SaveNotification {...notification} />}
      </Box>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={classes.addRowMenu}
      >
        <MenuItem onClick={handleAddProductDetails}>
          <CardMedia
            component="img"
            image={Icons.plusIcon}
            alt=""
            sx={{ marginRight: '8px', ...classes.icon }}
          />
          <Text sx={classes.menuItemTxt}>Add Product Details</Text>
        </MenuItem>
        <MenuItem onClick={handleAddNewCategory}>
          <CardMedia
            component="img"
            image={Icons.dashedSquarePlus}
            alt=""
            sx={{ marginRight: '8px', ...classes.icon }}
          />
          <Text sx={classes.menuItemTxt}>Add New Category</Text>
        </MenuItem>
      </Menu>

      <Menu
        open={moreOptionsMenu !== null}
        onClose={() => {
          handleClose()
          setMenuHoveredProductType(null)
        }}
        anchorReference="anchorPosition"
        anchorPosition={
          moreOptionsMenu !== null
            ? { top: moreOptionsMenu.mouseY, left: moreOptionsMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => handleDeleteCategory(moreOptionsMenu.uuid)}>
          <CardMedia
            component="img"
            image={Icons.trash}
            alt=""
            sx={{ marginRight: '8px', ...classes.icon }}
          />
          <Text sx={{ color: '#D12626', ...classes.menuItemTxt }}>
            Delete Category
          </Text>
        </MenuItem>
      </Menu>
    </FavoritesContext.Provider>
  )
}

export default React.memo(Favorites)
