import { customTheme } from '../../atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    marginTop: '24px',
    border: 'solid 4px #2A2A2A',
    borderRadius: '16px',
    // boxShadow: "0 5px 15px rgba(0, 0, 0, 0.15)",
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [customTheme.breakpoints.down('sm')]: {
      padding: '32px 16px',
    },
  },
  title: {
    fontSize: '40px',
    textAlign: 'center',
  },
  button: {
    marginTop: '16px',
    backgroundColor: '#FFE9AF',
  },
  image: {
    marginTop: '24px',
    border: 'solid 2px #BFBFBF',
    borderRadius: '16px',
  },
  tagGridContainer: {
    marginTop: '20px',
  },
  tagGridItem: {
    padding: '4px',
  },
  tagContainer: {
    backgroundColor: '#F8F8F8',
    borderRadius: '8px',
    padding: '8px 16px',
    height: '100%',
  },
  tagTitle: {
    fontWeight: 'bold',
  },
  tag: {
    backgroundColor: '#DAEBCF',
    width: 'fit-content',
    padding: '8px 16px',
    borderRadius: '8px',
    fontWeight: 'bold',
    display: 'inline-block',
    margin: '4px',
  },
  productDetailsContainer: { marginTop: '24px' },
  productDetailsTitle: { fontWeight: 'bold' },
  productDetails: { marginTop: '8px' },
  proConContainer: {
    marginTop: '24px',
  },
  proConItem: {
    padding: '8px 8px 8px 0',
  },
  proConTitle: {
    fontWeight: 'bold',
  },
  proCon: {
    marginTop: '8px',
  },
}
