import React, { useState } from 'react'
import CardMedia from '@mui/material/CardMedia'
import { Box } from '@mui/material'
import { useCookies } from 'react-cookie'
import PropTypes from 'prop-types'

// import components
import Text from '../../../components/atoms/Text/Text'
import { classes } from './AffiliateBlurbStyle'

// import icons
const illustration =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/affiliate-icon.svg'
const closeIcon =
  'https://craftle-static.s3.us-east-2.amazonaws.com/icons/x.svg'

AffiliateBlurb.propTypes = {
  showIllustration: PropTypes.string,
  cmsResultsMobile: PropTypes.bool,
}
AffiliateBlurb.defaultProps = {
  showIllustration: 'inherit',
  cmsResultsMobile: false,
}

export default function AffiliateBlurb({ showIllustration, cmsResultsMobile }) {
  const [cookies, setCookie] = useCookies(['displayAffiliateBlurb'])
  const [showAffiliateBlurb, setShowAffiliateBlurb] = useState(
    cookies.displayAffiliateBlurb
      ? JSON.parse(cookies.displayAffiliateBlurb)
      : true,
  )

  function onPressClose() {
    setCookie('displayAffiliateBlurb', JSON.stringify(false), {
      path: '/',
    })
    setShowAffiliateBlurb(false)
  }

  return (
    showAffiliateBlurb && (
      <Box
        sx={{
          ...classes.container,
          width: cmsResultsMobile ? '189px' : 'initial',
          borderRadius: cmsResultsMobile ? '8px' : '16px',
          height: cmsResultsMobile ? '100%' : 'initial',
          border: cmsResultsMobile ? 'solid 1px #E3E3E3' : 'solid 2px #E3E3E3',
        }}
      >
        <CardMedia
          component="img"
          image={illustration}
          alt="Product image from your link"
          sx={{ ...classes.illustration, display: showIllustration }}
        />
        <Text sx={classes.text}>
          We may earn a small commission if you buy (at no extra cost to you).
        </Text>
        <Box
          onClick={() => {
            onPressClose()
          }}
          sx={classes.closeContainer}
        >
          <CardMedia
            component="img"
            image={closeIcon}
            alt="Close"
            sx={classes.closeIcon}
          />
        </Box>
      </Box>
    )
  )
}
