import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

// import components

// import images
import { classes } from './AboutStyle'
import Text from '../components/atoms/Text/Text'
import bannerImage from '../assets/images/about-banner-image.png'
import angelaPhoto from '../assets/images/angela-photo.png'
import alexanderPhoto from '../assets/images/alexander-photo.png'
import idrisPhoto from '../assets/images/idris-photo.png'

function About() {
  return (
    <Box>
      <Box sx={classes.container}>
        <Grid container spacing={6} sx={classes.heroGridContainer}>
          <Grid item md={6} xs={12} sx={classes.heroGridItem1}>
            <Typography variant="h1" sx={classes.h1}>
              We built Craftle because WE need it.
            </Typography>
            <Text>
              Each of our founders has a story on why we needed a service like
              Craftle. From wanting feedback while designing a tiny room, to
              finding helpful inspiration, to trying to find the right furniture
              while on a small budget,{' '}
              <span style={{ fontWeight: 700 }}>
                Craftle came to life because we couldn’t find the right
                solutions
              </span>
              . As we are also the customers, we know and understand what people
              are looking for.
            </Text>
          </Grid>
          <Grid item md={6} xs={12} sx={classes.heroGridItem2}>
            <Box>
              <CardMedia
                component="img"
                image={bannerImage}
                alt="banner image"
                sx={classes.bannerImage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Meet the team section */}
      <Box container sx={classes.teamSectionContainer}>
        <Grid item md={12} xs={12}>
          <Typography variant="h2" sx={classes.teamTitle}>
            Meet the team
          </Typography>
        </Grid>
        <Grid container md={12} xs={12} sx={classes.teamGridContainer}>
          <Grid item md={3} sm={6} xs={12} sx={classes.teamGridItem}>
            <CardMedia
              component="img"
              image={angelaPhoto}
              alt=""
              sx={classes.teamPhoto}
            />
            <Box sx={classes.teamName}>Angela Kim</Box>
            <Box sx={classes.teamPosition}>Co-Founder & CEO</Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={classes.teamGridItem}>
            <CardMedia
              component="img"
              image={alexanderPhoto}
              alt=""
              sx={classes.teamPhoto}
            />
            <Box sx={classes.teamName}>Alexander Georges</Box>
            <Box sx={classes.teamPosition}>Co-Founder & CTO</Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={classes.teamGridItem}>
            <CardMedia
              component="img"
              image={idrisPhoto}
              alt=""
              sx={classes.teamPhoto}
            />
            <Box sx={classes.teamName}>Idris Hanafi</Box>
            <Box sx={classes.teamPosition}>Co-Founder & Tech Advisor</Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default About
