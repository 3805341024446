// This function converts a base64 string into a Blob object
export function base64ToBlob(base64, mimeType = 'image/png') {
  const byteCharacters = atob(base64)
  const byteNumbers = new Array(byteCharacters.length)

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  return new Blob([byteArray], { type: mimeType })
}

// This function converts a Blob object into a URL that can be used in the img src attribute
export function createObjectURL(imageData, mimeType = 'image/png') {
  let blob

  // Check if imageData is a Blob
  if (imageData instanceof Blob) {
    blob = imageData
  } else if (typeof imageData === 'string') {
    // Extract Base64 data from the Data URL
    const base64Data = imageData.split(',')[1]

    if (base64Data) {
      // Decode Base64 string and create Blob
      blob = base64ToBlob(base64Data, mimeType)
    }
  }

  // Create object URL only if a Blob has been created
  if (blob) {
    return URL.createObjectURL(blob)
  } else {
    return null
  }
}
