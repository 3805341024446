import React from 'react'
import { Card, CardMedia, Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

// import components
import Text from '../../atoms/Text/Text'
import { classes } from './ImageTextCardOurPicksCMSResultsSwapFurnitureStyle'

// import context
// import { Context } from "../../../context/context";

// import icons
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'

let customTheme = createTheme({})

const propTypes = {
  // furnitureKey: PropTypes.string.isRequired,
  cardDetail: PropTypes.object,
  itemOnClick: PropTypes.func,
  infoBox: PropTypes.bool,
}

const defaultProps = {
  cardDetail: {
    id: 1,
    cost: 1685,
    product_type: 'area-rugs',
    image_url:
      'https://ksassets.timeincuk.net/wp/uploads/sites/56/2021/06/Small-living-room-ideas_Grey-sofa.jpg',
  },
  itemOnClick: () => {},
  infoBox: false,
}

function ImageTextCardOurPicksCMSResultsSwapFurniture({
  cardDetail,
  // itemOnClick,
  infoBox,
}) {
  function exploreAllOthersButton() {
    return (
      <Box sx={classes.ourPicksContainer}>
        <Box sx={classes.ourPicksTextContainer}>
          <Text sx={classes.scrollText}>Scroll for our picks</Text>
          <Text sx={classes.swipeText}>Swipe for our picks</Text>
        </Box>
        <ArrowRight height={40} width={40} />
      </Box>
    )
  }

  function furnitureImage() {
    return (
      <CardMedia
        component="img"
        sx={{
          width: '144px',
          height: '144px',
          borderRadius: '0px',
          [customTheme.breakpoints.down('md')]: {
            // width: "26vw",
            // height: "26vw",
          },
          [customTheme.breakpoints.down('sm')]: {
            // width: "45vw",
            // height: "45vw",
            borderRadius: '8px',
          },
        }}
        image={
          cardDetail.transparent_image_url ||
          cardDetail.image_url ||
          cardDetail.original_image_url
        }
        alt="furnitureImage"
      />
    )
  }

  return (
    <Card sx={classes.container}>
      <Box sx={classes.innerContainer}>
        {infoBox ? exploreAllOthersButton() : furnitureImage()}
      </Box>
    </Card>
  )
}

ImageTextCardOurPicksCMSResultsSwapFurniture.propTypes = propTypes
ImageTextCardOurPicksCMSResultsSwapFurniture.defaultProps = defaultProps

export default ImageTextCardOurPicksCMSResultsSwapFurniture
