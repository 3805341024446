import axios from 'axios'

// import components
import { EndPoint } from '../../../components/atoms/EndPoint/EndPoint'

const END_POINTS = {
  prod: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  test: 'https://bftmyfoam8.execute-api.us-east-2.amazonaws.com/default/craftle-favorites-call',
  local: 'http://127.0.0.1:8080/favorites',
}

export default async function DeleteFavImgCallToBackend(imgName) {
  try {
    const endPoint = END_POINTS[EndPoint] || END_POINTS.prod
    const response = await axios.post(endPoint, {
      request_method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      post_data: JSON.stringify({
        purpose: 'deleting_fav_img',
        img_name: imgName,
      }),
    })

    if (response?.data?.body?.success) {
      return response.data.body
    } else if (response?.data?.success) {
      return response.data
    } else {
      return null
    }
  } catch (e) {
    console.log(e.message)
    return null
  }
}
