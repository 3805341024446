import { customTheme } from '../../../components/atoms/CustomTheme/CustomTheme'

export const classes = {
  container: {
    position: 'relative',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '231px',
    height: '231px',
    boxShadow: 'none',
    cursor: 'pointer',
    // position: "relative",
    borderRadius: '8px',
    boxSizing: 'border-box',
    // [customTheme.breakpoints.down("md")]: {
    //   // width: "189px",
    //   // height: "189px",
    //   width: "100%",
    //   height: "auto",
    // },
    [customTheme.breakpoints.down('md')]: {
      width: '100%',
      // This creates a padding-top that is the same as the width, giving us a square
      paddingTop: '100%', // This is what makes the height equal to the width
      position: 'relative', // Needed for absolutely positioning the image inside
      overflow: 'hidden', // Ensures no overflow from the content
      height: 'auto',
    },
  },
  imageStyle: {
    // maxWidth: "-webkit-fill-available",
    // maxHeight: "-webkit-fill-available",
    // width: "auto",
    position: 'absolute', // Position the image absolutely within the container
    top: 0,
    left: 0,
    width: '100%', // Set image width to cover the container
    height: '100%', // Set image height to match the container
    objectFit: 'contain', // This will make sure the image is contained within the container without being cropped
    borderRadius: '8px',
    [customTheme.breakpoints.down('md')]: {
      borderRadius: '8px',
    },
  },
  priceBox: {
    position: 'absolute',
    bottom: '8px',
    left: '0px',
    borderRadius: '0 4px 4px 0',
    padding: '5px 10px',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
  },
  priceBoxText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  furnitureTypeContainer: {
    padding: '5px',
    textAlign: 'center',
  },
  favoriteContainer: {
    width: '40px',
    height: '40px',
    backgroundColor: 'rgba(191, 191, 191, 0.5)',
    borderRadius: '20px',
    position: 'absolute',
    zIndex: 1,
    right: '16px',
    top: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(191, 191, 191, 0.4)',
    },
  },
  favoriteIcon: {
    width: '24px',
    height: '24px',
  },
}
