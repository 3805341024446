export const deleteMoodboardItem = (
  setCurrentMoodBoardView,
  itemToDelete,
  globalDispatch,
) => {
  setCurrentMoodBoardView((prevMoodBoardView) => {
    const updatedMoodBoardView = prevMoodBoardView.filter(
      (item) => item.key !== itemToDelete,
    )
    return updatedMoodBoardView
  })

  // Assuming your globalState has the furniturePositions object
  globalDispatch({
    type: 'DELETE_UPLOADED_IMAGE',
    payload: {
      imageId: itemToDelete,
    },
  })
}
